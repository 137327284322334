import {rawRequest} from './helper';
import {REQUEST_TYPE, RESPONSE_DATA_TYPE} from './const';
import {
	io_request_processors,
	io_response_processors,
	io_default_request_options,
	io_default_upload_request_options, io_default_response_data_type, auth_default_auto_token
} from '../../config';
import {addErrorMessage} from "../helpers/message";
import {translate} from "../i18n";

/**
 * Low-level function for making an IO request
 * @note Use of 'autoToken' and 'token' when 'processRequest' is true will depend on enabled request processors. This 
 * means that if there are no enabled request processors that handle tokens, token will not be added to the request.
 *
 * @param {string} [type=''] - Internal request type (see imported REQUEST_TYPE const). This is used to auto-generate
 * request headers, body and possibly other request values based on app config.
 * @param {boolean} [isDownload=false] - Flag that specifies if request should be build as a download request (uses
 * 'io_download_request_headers' io config value). This will be ignored if 'type' is REQUEST_TYPE.UPLOAD because request
 * cannot be upload and download at the same time.
 * @param {string} url - Request url.
 * @param {string} [api=''] - Request's API (see 'io_base_urls' io config value for available APIs). Raw requests don't
 * have this value.
 * @param {string} [endpoint=''] - Request's API endpoint (see 'io_base_urls' io config value for available APIs). Raw
 * requests don't have this value.
 * @param {any} data - Request body.
 * @param {string} method - Request method ('get', 'post', ...).
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage and added to the request. If 
 * 'processRequest' is true, token will be added to the request only if auth request processor is enabled.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false. If 'processRequest' is true, token
 * will be added to the request only if auth request processor is enabled.
 * @param {object} [options={}] - Other request options tha will be sent.
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config 
 * will be used.
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {boolean} [processRequest=false] - If true, request will be processed by request processors.
 * @param {boolean} [processResponse=false] - If true, response will be processed by response processors. Otherwise raw
 * fetch promise will be returned.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [canPause=true] - If true this request could be paused using a global request pause flag.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note 
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token 
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate 
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @return {Promise<any|Response>}
 */
export const ioRequest = async ({
	type = '', isDownload = false, url, api = '', endpoint = '', data, method, autoToken = auth_default_auto_token, 
	token = '', options = {}, headers = null, responseType, processRequest = false, processResponse = false, 
	abortCallback = (abortController) => {}, canPause = true, useCaptcha = false, captchaAction = ''
}) => {
	// IMPORTANT: This is required in order for dynamic import to load properly when app is built for production. 
	// Dynamic import cannot resolve imported values like these so there needs to be a local const getting the value 
	// before it can be used in the dynamic import.
	let requestProcessors = (processRequest ? io_request_processors : []);
	const responseProcessors = (processResponse ? io_response_processors : []);
	
	// Add captcha request processor if needed
	if (useCaptcha && requestProcessors.indexOf('captcha') === -1) requestProcessors.push('captcha');
	
	return rawRequest({
		type, isDownload, url, api, endpoint, data, method, autoToken, token, options, headers, responseType, canPause, 
		captchaAction, requestProcessors, responseProcessors, abortCallback
	});
};

/**
 * Make a standard IO request
 * @note This function makes a request using all request and response processors.
 *
 * @param {boolean} [isDownload=false] - Flag that specifies if request should be build as a download request (uses
 * 'io_download_request_headers' io config value). This will be ignored if 'type' is REQUEST_TYPE.UPLOAD because request
 * cannot be upload and download at the same time.
 * @param {string} url - Request url.
 * @param {string} [api=''] - Request's API (see 'io_base_urls' io config value for available APIs). Raw requests don't
 * have this value.
 * @param {string} [endpoint=''] - Request's API endpoint (see 'io_base_urls' io config value for available APIs). Raw
 * requests don't have this value.
 * @param {object} data - Data object used to create request body.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config
 * will be used.
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [canPause=true] - If true this request could be paused using a global request pause flag.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @return {Promise<*>} Promise that will resolve with the response data. Promise can be rejected either by the 'fetch'
 * function or the 'handleResponse' function in which case rejection reason will be an object with the following
 * properties {{response: Response, request: Object}}.
 */
export const ioStandardRequest = ({
	isDownload = false, url, api = '', endpoint = '', data, method = 'post', autoToken = auth_default_auto_token, 
	token = '', options = io_default_request_options, headers = null, responseType = io_default_response_data_type, 
	abortCallback = (abortController) => {}, canPause = true, useCaptcha = false, captchaAction = ''
}) => {
	return ioRequest({
		type: REQUEST_TYPE.STANDARD, 
		isDownload,
		method, 
		url, 
		api, 
		endpoint, 
		data, 
		autoToken, 
		token, 
		options, 
		headers, 
		responseType, 
		processRequest: true, 
		processResponse: true, 
		abortCallback,
		canPause,
		useCaptcha,
		captchaAction
	});
};

/**
 * Make a standard JSON IO request
 * @note This function makes a request using all request and response processors.
 *
 * @param {boolean} [isDownload=false] - Flag that specifies if request should be build as a download request (uses
 * 'io_download_request_headers' io config value). This will be ignored if 'type' is REQUEST_TYPE.UPLOAD because request
 * cannot be upload and download at the same time.
 * @param {string} url - Request url.
 * @param {string} [api=''] - Request's API (see 'io_base_urls' io config value for available APIs). Raw requests don't
 * have this value.
 * @param {string} [endpoint=''] - Request's API endpoint (see 'io_base_urls' io config value for available APIs). Raw
 * requests don't have this value.
 * @param {object} data - Data object used to create request body.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config 
 * ("/src/config/io.js").
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config
 * will be used.
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [canPause=true] - If true this request could be paused using a global request pause flag.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @return {Promise<*>} Promise that will resolve with the response data. Promise can be rejected either by the 'fetch'
 * function or the 'handleResponse' function in which case rejection reason will be an object with the following
 * properties {{response: Response, request: Object}}.
 */
export const ioStandardJsonRequest = ({
	isDownload = false, url, api = '', endpoint = '', data, method = 'post', autoToken = auth_default_auto_token, 
	token = '', options = io_default_request_options, headers = null, responseType = io_default_response_data_type, 
	abortCallback = (abortController) => {}, canPause = true, useCaptcha = false, captchaAction = ''
}) => {
	return ioRequest({
		type: REQUEST_TYPE.JSON,
		isDownload,
		method,
		url,
		api,
		endpoint,
		data,
		autoToken,
		token,
		options,
		headers,
		responseType,
		processRequest: true,
		processResponse: true,
		abortCallback,
		canPause,
		useCaptcha,
		captchaAction
	});
}

/**
 * Make an upload IO request
 * @description This function makes a request with all predefined and added options and values (like tokens).
 * @note Upload request always uses 'post' request method.
 *
 * @param {string} url - Request url.
 * @param {string} [api=''] - Request's API (see 'io_base_urls' io config value for available APIs). Raw requests don't
 * have this value.
 * @param {string} [endpoint=''] - Request's API endpoint (see 'io_base_urls' io config value for available APIs). Raw
 * requests don't have this value.
 * @param {FormData} data - FormData object that contains the file and all other params.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config
 * will be used.
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [canPause=true] - If true this request could be paused using a global request pause flag.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @return {Promise<*>} Promise that will resolve with the response data. Promise can be rejected either by the 'fetch'
 * function or the 'handleResponse' function in which case rejection reason will be an object with the following
 * properties {{response: Response, request: Object}}.
 */
export const ioUploadRequest = ({
	url, api = '', endpoint = '', data, method = 'post', autoToken = auth_default_auto_token, token = '', 
	options = io_default_upload_request_options, headers = null, responseType = io_default_response_data_type, 
	abortCallback = (abortController) => {}, canPause = true, useCaptcha = false, captchaAction = ''
}) => {
	return ioRequest({
		type: REQUEST_TYPE.UPLOAD,
		method,
		url,
		api,
		endpoint,
		data,
		autoToken,
		token,
		options,
		headers,
		responseType,
		processRequest: true,
		processResponse: true,
		abortCallback,
		canPause,
		useCaptcha,
		captchaAction
	});
}

/**
 * Low-level function to make a file download IO request
 * @note This function makes a request using all request and response processors.
 *
 * @param {string} [type=''] - Internal request type (see imported REQUEST_TYPE const). This is used to auto-generate
 * request headers, body and possibly other request values based on app config.
 * @param {string} url - Request url.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {string} [api=''] - Request's API (see 'io_base_urls' io config value for available APIs). Raw requests don't
 * have this value.
 * @param {string} [endpoint=''] - Request's API endpoint (see 'io_base_urls' io config value for available APIs). Raw
 * requests don't have this value.
 * @param {object} data - Data object used to create request body.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config
 * will be used.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [canPause=true] - If true this request could be paused using a global request pause flag.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @return {Promise<*>} Promise that will resolve with the response data. Promise can be rejected either by the 'fetch'
 * function or the 'handleResponse' function in which case rejection reason will be an object with the following
 * properties {{response: Response, request: Object}}.
 */
export const ioDownloadRequest = ({
	type = '', url, downloadFilename = 'file', api = '', endpoint = '', data, method = 'post', 
	autoToken = auth_default_auto_token, token = '', options = io_default_request_options, headers = null, 
	abortCallback = (abortController) => {}, canPause = true, useCaptcha = false, captchaAction = ''
}) => {
	return ioRequest({
		type,
		isDownload: true,
		method,
		url,
		api,
		endpoint,
		data,
		autoToken,
		token,
		options,
		headers,
		responseType: RESPONSE_DATA_TYPE.BLOB,
		processRequest: true,
		processResponse: true,
		abortCallback,
		canPause,
		useCaptcha,
		captchaAction
	})
		// Start the download if file can ge reached
		.then(data => {
			if (data) {
				// Create a dummy download link with the downloaded file
				let dummyLink = document.createElement('a');
				const url = window.URL.createObjectURL(data);
				dummyLink.href = url;
				dummyLink.download = downloadFilename;
				document.body.append(dummyLink);

				// Simulate a click on the download link which should start the download
				dummyLink.click();
				dummyLink.remove();

				// Clear object URL from memory to prevent memory leaks
				window.URL.revokeObjectURL(url);
			}

			return data;
		})
		.catch(e => {
			addErrorMessage(translate('Download error!', 'errors.io'));
			console.error(`Error downloading file '${url}': `, e);
		});
};

/**
 * Make a standard file download IO request
 * @note This function makes a request using all request and response processors.
 *
 * @param {string} url - Request url.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {string} [api=''] - Request's API (see 'io_base_urls' io config value for available APIs). Raw requests don't
 * have this value.
 * @param {string} [endpoint=''] - Request's API endpoint (see 'io_base_urls' io config value for available APIs). Raw
 * requests don't have this value.
 * @param {object} data - Data object used to create request body.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config
 * will be used.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [canPause=true] - If true this request could be paused using a global request pause flag.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @return {Promise<*>} Promise that will resolve with the response data. Promise can be rejected either by the 'fetch'
 * function or the 'handleResponse' function in which case rejection reason will be an object with the following
 * properties {{response: Response, request: Object}}.
 */
export const ioStandardDownloadRequest = ({
	url, downloadFilename = 'file', api = '', endpoint = '', data, method = 'post', autoToken = auth_default_auto_token,
	token = '', options = io_default_request_options, headers = null, abortCallback = (abortController) => {}, 
	canPause = true, useCaptcha = false, captchaAction = ''
}) => {
	return ioRequest({
		type: REQUEST_TYPE.STANDARD,
		isDownload: true,
		method,
		url,
		api,
		endpoint,
		data,
		autoToken,
		token,
		options,
		headers,
		responseType: RESPONSE_DATA_TYPE.BLOB,
		processRequest: true,
		processResponse: true,
		abortCallback,
		canPause,
		useCaptcha,
		captchaAction
	})
		// Start the download if file can ge reached
		.then(data => {
			if (data) {
				// Create a dummy download link with the downloaded file
				let dummyLink = document.createElement('a');
				const url = window.URL.createObjectURL(data);
				dummyLink.href = url;
				dummyLink.download = downloadFilename;
				document.body.append(dummyLink);

				// Simulate a click on the download link which should start the download
				dummyLink.click();
				dummyLink.remove();
				
				// Clear object URL from memory to prevent memory leaks
				window.URL.revokeObjectURL(url);
			}

			return data;
		})
		.catch(e => {
			addErrorMessage(translate('Download error!', 'errors.io'));
			console.error(`Error downloading file '${url}': `, e);
		});
};

/**
 * Make a standard file download IO request with JSON body
 * @note This function makes a request using all request and response processors.
 *
 * @param {string} url - Request url.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {string} [api=''] - Request's API (see 'io_base_urls' io config value for available APIs). Raw requests don't
 * have this value.
 * @param {string} [endpoint=''] - Request's API endpoint (see 'io_base_urls' io config value for available APIs). Raw
 * requests don't have this value.
 * @param {object} data - Data object used to create request body.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config
 * will be used.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [canPause=true] - If true this request could be paused using a global request pause flag.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @return {Promise<*>} Promise that will resolve with the response data. Promise can be rejected either by the 'fetch'
 * function or the 'handleResponse' function in which case rejection reason will be an object with the following
 * properties {{response: Response, request: Object}}.
 */
export const ioStandardJsonDownloadRequest = ({
	url, downloadFilename = 'file', api = '', endpoint = '', data, method = 'post', autoToken = auth_default_auto_token,
	token = '', options = io_default_request_options, headers = null, abortCallback = (abortController) => {}, 
	canPause = true, useCaptcha = false, captchaAction = ''
}) => {
	return ioRequest({
		type: REQUEST_TYPE.JSON,
		isDownload: true,
		method,
		url,
		api,
		endpoint,
		data,
		autoToken,
		token,
		options,
		headers,
		responseType: RESPONSE_DATA_TYPE.BLOB,
		processRequest: true,
		processResponse: true,
		abortCallback,
		canPause,
		useCaptcha,
		captchaAction
	})
		// Start the download if file can ge reached
		.then(data => {
			if (data) {
				// Create a dummy download link with the downloaded file
				let dummyLink = document.createElement('a');
				const url = window.URL.createObjectURL(data);
				dummyLink.href = url;
				dummyLink.download = downloadFilename;
				document.body.append(dummyLink);

				// Simulate a click on the download link which should start the download
				dummyLink.click();
				dummyLink.remove();

				// Clear object URL from memory to prevent memory leaks
				window.URL.revokeObjectURL(url);
			}
			
			return data;
		});
}