import "./default.style.css";

import React from "react";
import DataComponent, {executeComponentCallback} from "../../DataComponent";
import PropTypes from "prop-types";
import FormWrapper, {FormField} from "../FormWrapper";
import TextInput from "../../input/TextInput";
import {FORM_FIELD_LABEL_POSITION} from "../FormWrapper/FormField";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../display/Button";
import Label from "../../display/Label";
import DataValueValidation from "../../../validation";
import Icon from "../../display/Icon";
import {getString} from "../../../helpers/data";
import {omit} from "lodash";

class Login extends DataComponent {
	constructor(props) {
		super(props, {
			data: {
				username: '',
				password: ''
			}
		}, {
			translationPath: "Login",
			disableLoad: true,
		});

		// Custom component methods
		this.login = this.login.bind(this);
	}

	// Validation methods -----------------------------------------------------------------------------------------------
	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const {usernameRules, usernameConstraints} = this.props;
		const dataValidation = new DataValueValidation();
		const dataToValidate = this.getData();

		dataValidation.addRule('username', 'required');
		if (Array.isArray(usernameRules) && usernameRules.length) {
			dataValidation.addRule('username', ...usernameRules);
		}
		if (Array.isArray(usernameConstraints) && usernameConstraints.length) {
			dataValidation.addConstraint('username', ...usernameConstraints);
		}
		
		dataValidation.addRule('password', 'required');

		const validationErrors = dataValidation.run(dataToValidate);
		if (validationErrors) this.setValidationErrors('', validationErrors);
		return !validationErrors;
	}
	

	// Custom component methods -----------------------------------------------------------------------------------------
	/**
	 * Login method
	 * @note This method will execute a function defined in the 'action' prop.
	 */
	login() {
		const username = this.getValue('username');
		const password = this.getValue('password');
		
		this.clearValidationErrors()
			.then(() => this.clearErrorMessage())
			.then(() => { 
				if (this.validate()) {
					return executeComponentCallback(this.props.action, username, password);
				} else if (!username || !password) {
					return this.setState({error: this.t('Please enter username and password.')});
				}
			});
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			styleName, className, cardClassName, cardHeaderClassName, cardContentClassName, cardActionsClassName, 
			labelPosition, labelIcon, labelElement, labelElementProps, loginButtonProps, registerAction, 
			registerButtonProps, changePasswordAction, changePasswordButtonProps, logo, backAction, 
		} = this.props;
		const error = this.getErrorMessage();
		
		return (
			<div id={this.getDomId()} className={`login-component ${styleName}-style ${className}`}>
				<div className={cardClassName}>
					<div className={cardHeaderClassName}>
						{
							logo ?
								<div className="card-header-icon">
									<img src={logo} alt={this.t('title', 'App')}/>
								</div>
								:
								<Icon symbol={labelIcon} className="card-header-icon" />
						}
						<Label 
							element="div"
							elementProps={{className: 'app-name'}}
							content={this.t('title', 'App')}
						/>
						<Label 
							element={labelElement}
							elementProps={labelElementProps}
							content={this.t('User login')}
							supportHtml={true}
						/>
					</div>
					<div className={`login-content ${cardContentClassName}`}>
						<FormWrapper>
							<FormField 
								required={true}
								label={this.t("Username")} 
								labelPosition={labelPosition}
								labelClassName={`login-label`}
								errorMessages={this.getValidationErrors('username')}
							>
								<TextInput
									isFast={false}
									className={`login-input`}
									name="username"
									value={this.getValue('username')}
									onChange={this.handleInputChange}
									onEnterKey={this.login}
									placeholder={this.t('Enter your username')}
								/>
							</FormField>
							
							<FormField
								required={true}	
								label={this.t("Password")}
								labelPosition={labelPosition}
								labelClassName={`login-label`}
								errorMessages={this.getValidationErrors('password')}
							>
								<TextInput
									isFast={false}
									className={`login-input`}
									type="password"
									name="password"
									value={this.getValue('password')}
									onChange={this.handleInputChange}
									onEnterKey={this.login}
									placeholder={this.t('Enter your password')}
								/>
							</FormField>

							{
								changePasswordAction ?
									<Button 
										className={
											`change-password-btn ${getString(changePasswordButtonProps, 'className')}`
										}
										label={this.t('Change password')} 
										onClick={changePasswordAction}
										{...Login.defaultProps.changePasswordButtonProps}
										{...omit(changePasswordButtonProps, ['className'])}
									/>
									: null
							}
						</FormWrapper>
					</div>
					<div className={cardActionsClassName}>
						{
							backAction ?
								<Button
									big={true}
									icon="chevron-left"
									label={this.t('Back', 'general')}
									displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
									onClick={backAction}
								/>
								: null
						}
						{
							registerAction ? 
								<Button 
									label={this.t('Register')} 
									onClick={registerAction}
									{...Login.defaultProps.registerButtonProps}
									{...registerButtonProps} 
								/>
								: null
						}
						<Button 
							label={this.t('Login')} 
							onClick={this.login}
							{...Login.defaultProps.loginButtonProps}
							{...loginButtonProps} 
						/>
					</div>
					{error ? <p className={`component-error error-color`}>{error}</p> : null}
				</div>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
Login.propTypes = {
	// Component style name
	// @description Component style name is a name of the style that will be used to determine the CSS used to style the
	// component.
	styleName: PropTypes.string,
	// Login component wrapper element ID class attribute
	id: PropTypes.string,
	// Login component wrapper element class attribute
	className: PropTypes.string,
	// Login component internal element card class name
	cardClassName: PropTypes.string,
	// Login component internal element card header class name
	cardHeaderClassName: PropTypes.string,
	// Login component internal element card content class name
	cardContentClassName: PropTypes.string,
	// Login component internal element card actions class name
	cardActionsClassName: PropTypes.string,
	// Login component input fields label position
	// @see FORM_FIELD_LABEL_POSITION const.
	labelPosition: PropTypes.string,
	// Login component input fields label icon
	labelIcon: PropTypes.string,
	// Login component input fields label wrapper element name
	// @note If empty, no wrapper element will be rendered.
	labelElement: PropTypes.string,
	// Login component input fields label wrapper element props
	labelElementProps: PropTypes.object,
	// Login form submit function
	action: PropTypes.func, // Arguments: username, password
	// Login form back action
	backAction: PropTypes.func,
	// Login button props
	// @see Button component props.
	loginButtonProps: PropTypes.object,
	// Register button action
	// @note Register button will only render if this action is specified.
	registerAction: PropTypes.func, // Arguments: button click event.
	// Register button props
	// @see Button component props.
	registerButtonProps: PropTypes.object,
	// Change password action
	// @note Change password button will only render if this action is specified.
	changePasswordAction: PropTypes.func, // Arguments: button click event.
	// Change password button props
	// @see Button component props.
	changePasswordButtonProps: PropTypes.object,
	// Array of username validation rules
	usernameRules: PropTypes.array,
	// Array of username validation constraints
	usernameConstraints: PropTypes.array,
	// Logo to render
	logo: PropTypes.any,
};

/**
 * Define component default values for own props
 */
Login.defaultProps = {
	styleName: 'default',
	className: '',
	cardClassName: 'card icon-card',
	cardHeaderClassName: 'card-header',
	cardContentClassName: 'card-content',
	cardActionsClassName: 'card-actions',
	labelPosition: FORM_FIELD_LABEL_POSITION.STACKED,
	labelIcon: 'lock',
	labelElement: '',
	loginButtonProps: {
		displayStyle: BUTTON_STYLE.ACTION,
		displayType: BUTTON_DISPLAY_TYPE.SOLID,
		icon: 'unlock-alt',
		big: true
	},
	registerButtonProps: {
		displayStyle: BUTTON_STYLE.ACTION,
		displayType: BUTTON_DISPLAY_TYPE.TRANSPARENT,
		icon: 'user-plus',
		big: true
	},
	changePasswordButtonProps: {
		displayType: BUTTON_DISPLAY_TYPE.NONE,
		displayStyle: BUTTON_STYLE.DEFAULT
	},
	logo: null,
};

export default Login;