import {ioJsonAction} from "../../core/store/actions/io";

/**
 * Fetch all available data sources
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @return {function(*): function(*): *}
 */
export const fetchDataSourcesAction = abortCallback => dispatch => {
	return ioJsonAction(abortCallback, 'defaultAuthorizedApi', 'data-source/fetch-all', null)(dispatch);
}