import {ioJsonAction, ioJsonFetchAction, ioJsonFetchItemAction, ioJsonSaveAction} from "../../core/store/actions/io";
import {hideLoading, hideLoadingFunction, showLoading} from "../../core/helpers/loading";
import {actionCreators} from "../../core/store/reducers";
import * as accountRoleListItemDataMap from "./dataMap/accountRoleListItem";
import * as accountRoleDataMap from "./dataMap/accountRole";
import {getArray, getString} from "../../core/helpers/data";
import * as accountPermissionDataMap from "../accountPermissions/dataMap/accountPermission";
import {AccountPermissionDataObject} from "../accountPermissions/dataObjects";

/**
 * Fetch the list of account roles
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchAccountRoleListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'role/fetch-all',
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy,
		sortDir
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => ({
			...responseData,
			data: getArray(responseData, 'data').map(i => accountRoleListItemDataMap.input(i))
		}));
};

/**
 * Load the list of account roles into Redux store
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const loadAccountRoleListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {
	const loading = showLoading("#main-page-table");
	return fetchAccountRoleListAction(abortCallback, filter, pageNo, perPage, sortBy, sortDir)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators.accountRoles.setAccountRoleListData(responseData));
			hideLoading(loading);
			return responseData;
		});
};

/**
 * Clear account role list form Redux store
 * 
 * @return {(function(*): void)|*}
 */
export const clearAccountRoleListAction = () => dispatch => {
	actionCreators.accountRoles.clearAccountRoleListData();
}


/**
 * Fetch account role
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - DB ID of the account role to fetch.
 * @return {function(*=): Promise<AccountRoleDataObject>}
 */
export const fetchAccountRoleAction = (abortCallback, id) => dispatch => {
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'role/fetch-by-id',
		id,
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => accountRoleDataMap.input(responseData?.data));
};

/**
 * Load account role into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - DB ID of the account role to load.
 * @return {function(*=): Promise<AccountRoleDataObject>}
 */
export const loadAccountRoleAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#account-role-popup');
	return fetchAccountRoleAction(abortCallback, id)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators.accountRoles.setAccountRole(responseData));
			hideLoading(loading);
			return responseData;
		});
}

/**
 * Clear account role from Redux store
 * @return {(function(*=): void)|*}
 */
export const clearAccountRoleAction = () => dispatch => {
	dispatch(actionCreators.accountRoles.clearAccountRole());
};

/**
 * Create account role
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {AccountRoleDataObject} accountRole - Account role to save.
 * @return {function(*=): Promise<AccountRoleDataObject>} Promise that will resolve with the created account
 * role received from IO.
 */
export const createAccountRoleAction = (abortCallback, accountRole) => dispatch => {
	const loading = showLoading('#account-role-popup', true, true);
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'role/create',
		{
			id: '',
			data: accountRoleDataMap.output(accountRole)
		},
		null,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => accountRoleDataMap.input(responseData?.data))
		// Add permissions to the newly created account role
		// @note API uses a separate endpoint to add permissions to an account role.
		.then(createdAccountRole => {
			// If account role was successfully created it will have an ID so this is checking for it because it should 
			// only add permissions if account role was actually created
			if (createdAccountRole.id) {
				return accountRoleAddPermissionsAction(
					undefined, 
					createdAccountRole.id, 
					accountRole.permissions
				)(dispatch)
					.then(() => createdAccountRole);
			}
			return Promise.resolve(createdAccountRole);
		});
};

/**
 * Update account role
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} accountRoleId - DB ID of the account role to update.
 * @param {AccountRoleDataObject} accountRole - Account role to save.
 * @param {AccountPermissionDataObject[]} originalPermissions - List of account permission assigned to the role before 
 * the update.
 * @return {function(*=): Promise<AccountRoleDataObject>} Promise that wil resolve with the updated account 
 * role received from IO.
 */
export const updateAccountRoleAction = (abortCallback, accountRoleId, accountRole, originalPermissions) => dispatch => {
	const originalPermissionIds = getArray(originalPermissions).map(p => getString(p, 'id'));
	const permissionIds = getArray(accountRole, 'permissions').map(p => getString(p, 'id'));
	
	const loading = showLoading('#account-role-popup', true, true);
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'role/update-by-id',
		{
			id: accountRoleId,
			data: accountRoleDataMap.output(accountRole)
		},
		null,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => accountRoleDataMap.input(responseData?.data))
		// Remove permissions from the updated account role
		// @note API uses a separate endpoint to add permissions to an account role.
		.then(updatedAccountRole => {
			// Get IDs of the account permissions that should be removed from the account role
			// @description Permissions that were in the original permissions list but are not in the updated account role 
			// permission list should be removed.
			const permissionIdsToRemove = originalPermissionIds.filter(pid => !permissionIds.includes(pid));
			if (permissionIdsToRemove.length > 0) {
				// If account role was successfully updated it will have an ID so this is checking for it because it should 
				// only remove permissions if account role was successfully updated
				if (updatedAccountRole.id) {
					return accountRoleRemovePermissionsAction(
						undefined,
						// This action receives a 'accountRoleId' so it should always be used for IO calls even though it will 
						// receive an account role ID from the update response.
						accountRoleId,
						permissionIdsToRemove
					)(dispatch)
						.then(() => updatedAccountRole);
				}
			}
			return Promise.resolve(updatedAccountRole);
		})
		// Add permissions to the updated account role
		// @note API uses a separate endpoint to add permissions to an account role.
		.then(updatedAccountRole => {
			// Get IDs of the account permissions that should be added to the account role
			// @description Permissions that are in the updated account role permission list but are not in the original 
			// permissions list should be added.
			const permissionIdsToAdd = permissionIds.filter(pid => !originalPermissionIds.includes(pid));
			if (permissionIdsToAdd.length > 0) {
				// If account role was successfully updated it will have an ID so this is checking for it because it should 
				// only add permissions if account role was successfully updated
				if (updatedAccountRole.id) {
					return accountRoleAddPermissionsAction(
						undefined,
						// This action receives a 'accountRoleId' so it should always be used for IO calls even though it will 
						// receive an account role ID from the update response.
						accountRoleId,
						permissionIdsToAdd
					)(dispatch)
						.then(() => updatedAccountRole);
				}
			}
			return Promise.resolve(updatedAccountRole);
		});
};

/**
 * Load the list of all account permissions
 * @note This will be used in the 'PermissionsTab' of the 'AccountRolePopup' to manage role permissions.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @return {function(*=): Promise<AccountPermissionDataObject[]>}
 */
export const loadAllPermissionsAction = abortCallback => dispatch => {
	return ioJsonAction(abortCallback, 'defaultAuthorizedApi', 'permission/fetch-all', null)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			const allPermissions = getArray(responseData, 'data').map(i => accountPermissionDataMap.input(i));
			if (responseData) dispatch(actionCreators.accountRoles.setAllPermissions(allPermissions));
			return allPermissions;
		});
}

/**
 * Add permissions to account role
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} roleId - Account role ID to add permissions to.
 * @param {AccountPermissionDataObject[]|number[]|string[]} permissions - List of permissions or permission IDs to add.
 * @returns {function(*=): *}
 */
export const accountRoleAddPermissionsAction = (abortCallback, roleId, permissions) => dispatch => {
	const loading = showLoading('#account-role-popup', true, true);
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'role/add-permissions',
		{
			id: roleId, 
			data: {permissionIds: getArray(permissions).map(p =>
				p instanceof AccountPermissionDataObject ? getString(p, 'id') : p
			)}
		},
		undefined, 
		true,
		hideLoadingFunction(loading)
	)(dispatch);
};

/**
 * Remove permissions form account role
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} roleId - Account role ID to remove permissions from.
 * @param {AccountPermissionDataObject[]|number[]|string[]} permissions - List of permissions or permission IDs to 
 * remove.
 * @returns {function(*=): *}
 */
export const accountRoleRemovePermissionsAction = (abortCallback, roleId, permissions) => dispatch => {
	const loading = showLoading('#account-role-popup', true, true);
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'role/remove-permissions',
		{
			id: roleId,
			data: {
				permissionIds: getArray(permissions).map(p => (
					p instanceof AccountPermissionDataObject ? getString(p, 'id') : p
				))
			}
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch);
};