import {get} from 'lodash';
import {getBoolean, getInteger, getString} from "../../core/helpers/data";
import {SORT_ORDER} from "../../core/const/global";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'accounts';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@accounts/reset',
	SET_ACCOUNT_LIST_DATA: '@accounts/set_account_list_data',
	CLEAR_ACCOUNT_LIST_DATA: '@accounts/clear_account_list_data',
	SET_ACCOUNT: '@accounts/set_account',
	CLEAR_ACCOUNT: '@accounts/clear_account',
};

// define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	/**
	 * @param {IoJsonFetchResponseObject} accountListData
	 * @return {{type: string, accountListData: IoJsonFetchResponseObject}}
	 */
	setAccountListData: accountListData => ({
		type: REDUCER_TYPES.SET_ACCOUNT_LIST_DATA, 
		accountListData
	}),
	clearAccountListData: () => ({type: REDUCER_TYPES.CLEAR_ACCOUNT_LIST_DATA}),
	/**
	 * @param {AccountDataObject} account
	 * @return {{type: string, account: AccountDataObject}}
	 */
	setAccount: account => ({type: REDUCER_TYPES.SET_ACCOUNT, account }),
	clearAccount: () => ({type: REDUCER_TYPES.CLEAR_ACCOUNT }),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/** @type {IoJsonFetchResponseObject} */
	accountListData: null,

	/**
	 * @note Value is null to differentiate between unloaded and new items (new items should be a data object with
	 * default values).
	 * @type {AccountDataObject} 
	 */
	account: null,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET:
			return { ...initialState };

		case REDUCER_TYPES.SET_ACCOUNT_LIST_DATA: 
			return {...state, accountListData: action.accountListData};
		case REDUCER_TYPES.CLEAR_ACCOUNT_LIST_DATA:
			return {...state, accountListData: initialState.accountListData};

		case REDUCER_TYPES.SET_ACCOUNT: return {...state, account: action.account};
		case REDUCER_TYPES.CLEAR_ACCOUNT: return {...state, account: initialState.account};

		default:
			return state;
	}
};

// Selectors
export const selectors = {
	getAccountList: state => 
		get(state, [reducerStoreKey, 'accountListData', 'data']),
	getAccountListPagination: state => ({
		perPage: getInteger(state, [reducerStoreKey, 'accountListData', 'perPage']),
		pageNo: getInteger(state, [reducerStoreKey, 'accountListData', 'pageNo']),
		isLastPage: getBoolean(state, [reducerStoreKey, 'accountListData', 'isLastPage']) 
	}),
	getAccountListSort: state => ({
		sortBy: getString(state, [reducerStoreKey, 'accountListData', 'sortBy']),
		sortDir: getString(state, [reducerStoreKey, 'accountListData', 'sortDir'], SORT_ORDER.ASC)
	}),
	getAccountListFilter: state => 
		get(state, [reducerStoreKey, 'accountListData', 'filter'], null),

	getAccount: state => get(state, [reducerStoreKey, 'account']),
};

export default reducer;