import {AUTH_TOKEN_LOCATION} from "../core/auth/const";
import {STORAGE_TYPE} from "../core/storage/const";
import {REQUEST_TYPE, RESPONSE_DATA_TYPE, LOGIN_TYPE} from "../core/io/const";
import {app_id} from "./app";

/**
 * Specify the login type that will be used by the app
 * @type {LoginType}
 */
export const auth_login_type = LOGIN_TYPE.OAUTH2_NO_REDIRECT;

/**
 * Flag that specifies if auth tokens will be used for IO by default
 * @description IO functions and actions have an optional 'autoToken' flag for sending access token with each request.
 * Default value of this 'autoToken' will be set to the value of this flag.
 * @type {boolean}
 */
export const auth_default_auto_token = true;

// Auth API ------------------------------------------------------------------------------------------------------------
/**
 * Router path in the app used to handle auth API redirects
 * @description Auth API will sometimes redirect to the app and this is the page what will handle all those redirects.
 * @type {string}
 */
export const auth_api_handler_router_path = '/auth';
/**
 * Auth API name
 * @note This corresponds to the 'io_base_urls' io config object key.
 * @type {string}
 */
export const auth_api = 'authApi';
/**
 * Request type used for all auth API requests.
 * @see REQUEST_TYPE global const for available values.
 * @type {string}
 */
export const auth_api_request_type = REQUEST_TYPE.JSON;
/**
 * Response type use for all auth API requests.
 * @see RESPONSE_DATA_TYPE global const for available values.
 * @type {string}
 */
export const auth_api_response_type = RESPONSE_DATA_TYPE.JSON;
/**
 * Auth API endpoints
 * @note See auth.js.example for standard endpoints used by the framework.
 * @type {Object<{[string]: {[api]: string, method: string, path: string, params: Object}}>}
 */
export const auth_api_endpoints = {
	authorized_ping: {
		api: 'defaultApi',
		method: 'post',
		path: 'authorized/ping',
		params: {}
	},
	authorize: {
		api: 'authApi',
		method: 'post',
		path: 'authorize',
		params: {
			response_type: 'code',
			scope: ''
		}
	},
	generate_authorization_code: {
		api: 'authApi',
		method: 'post',
		path: 'authorized/authorization-code/generate',
		params: {}
	},
	refresh_tokens: {
		api: 'authApi',
		method: 'post',
		path: 'token',
		params: {
			grant_type: 'refresh_token',
			redirect_uri: '',
			code_verifier: '',
			client_secret: ''
		}
	},
	refresh_tokens_with_authorization_code: {
		api: 'authApi',
		method: 'post',
		path: 'token',
		params: {
			grant_type: 'authorization_code',
			client_secret: ''
		}
	},
	security_init: {
		api: 'defaultApi',
		method: 'post',
		path: 'security/init',
		params: {}
	},
	login: {
		api: 'authApi',
		method: 'post',
		path: 'login',
		params: {}
	},
	logout: {
		api: 'authApi',
		method: 'post',
		path: 'logout',
		params: {}
	},
	password_reset: {
		api: 'authApi',
		method: 'post',
		path: 'password/reset',
		params: {}
	},
	password_reset_request: {
		api: 'authApi',
		method: 'post',
		path: 'password/forgot',
		params: {}
	}
};
/**
 * Storage field name used for auth code verifier
 * @type {string}
 */
export const auth_code_verifier_storage_field_name = `${app_id}_authCodeVerifier`;
/**
 * SHA function used to generate the auth code challenge hash
 * @note Uses "jssha" package from https://github.com/Caligatio/jsSHA
 * @type {"SHA-1" | "SHA-224" | "SHA-256" | "SHA-384" | "SHA-512" | "SHA3-224" | "SHA3-256" | "SHA3-384" | "SHA3-512"}
 */
export const auth_code_challenge_method = 'SHA-256';
/**
 * Output format for the auth code challenge
 * @note Uses "jssha" package from https://github.com/Caligatio/jsSHA
 * @type {"B64" | "HEX" | "BYTES" | "ARRAYBUFFER" | "UINT8ARRAY"}
 */
export const auth_code_challenge_output_format = 'HEX';
/**
 * Storage field name used for auth state
 * @type {string}
 */
export const auth_state_storage_field_name = `${app_id}_authState`;

// Access token --------------------------------------------------------------------------------------------------------
/**
 * Storage type for saving access token
 * @see STORAGE_TYPE for available types.
 * @type {string}
 */
export const access_token_storage_type = STORAGE_TYPE.LOCAL;
/**
 * Storage field name used for temporary storage of the access token
 * @type {string}
 */
export const temporary_access_token_storage_field_name = `${app_id}_tempAccessToken`;
/**
 * Storage field name used for access token
 * @type {string}
 */
export const access_token_storage_field_name = `${app_id}_authToken`;
/**
 * Storage field name used for decoded header and payload sections of the access token
 * @type {string}
 */
export const decoded_access_token_storage_field_name = `${app_id}_authTokenData`;
/**
 * Location of access token in requests
 * @see AUTH_TOKEN_LOCATION for available locations.
 * @type {string}
 */
export const add_access_token_to = AUTH_TOKEN_LOCATION.HEADER;
/**
 * Access token request field name if access token is added to request body.
 * @type {string}
 */
export const access_token_body_field_name = 'token';
/**
 * Access token request field name if access token is added to request header.
 * @type {string}
 */
export const access_token_header_field_name = 'Authorization';
/**
 * Access token request field value prefix if access token is added to request header.
 * @type {string}
 */
export const access_token_header_value_prefix = 'Bearer ';

// Refresh token -------------------------------------------------------------------------------------------------------
/**
 * Storage type for saving refresh token
 * @see STORAGE_TYPE for available types.
 * @type {string}
 */
export const refresh_token_storage_type = STORAGE_TYPE.LOCAL;
/**
 * Storage field name used for refresh token
 * @type {string}
 */
export const refresh_token_storage_field_name = `${app_id}_refreshToken`;

// Current user --------------------------------------------------------------------------------------------------------
/**
 * Storage field name used for current user data
 * @note Current user data is stored in both local storage and Redux storage, so it can be loaded from local storage
 * into Redux storage on page refresh. Redux storage is used so that components will be able to subscribe to it and
 * update accordingly.
 * @type {string}
 */
export const current_user_storage_field_name = `${app_id}_currentUser`;