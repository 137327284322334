import {v4} from "uuid";
import {LOGICAL_OPERATOR} from "../core/const/global";
import {REPORT_FILTER_COMPARATOR, REPORT_OUTPUT_TYPE} from "../const/report";
import {getString, isset} from "../core/helpers/data";
import {get} from "lodash";

/**
 * Report data data source object
 */
export class ReportDataSourceObject {
	/**
	 * @param {string|number} id - ID of data source used for the report.
	 * @param {string} name - Name of the data source used for the report.
	 */
	constructor(id = '', name = '') {
		this.id = id;
		this.name = name;
	}
}

/**
 * Report column data object
 * @note This item is used both as select column and filter field.
 */
export class ReportColumnDataObject {
	/**
	 * @param {DynamicValueDataObject} [dynamicValue=null] - Dynamic value.
	 * @param {boolean} [customHeadings=false] - Flag that determines if custom headings should be used in the report.
	 * @param {string} [groupHeading=''] - Custom group heading that will be used in the report if 'customHeadings' is
	 * set to true.
	 * @param {string} [columnHeading=''] - Custom column heading that will be used in the report if 'customHeadings' is
	 * set to true.
	 * @param {boolean} [htmlAllowedInHeadings=false] - Flag that determines if custom headings (both group and column)
	 * should allow HTML tags.
	 * @param {DynamicValueAggregation|''} [aggregateFunction] - Aggregation function.
	 * @param {FormulaEditorItemDataObject[]} [formula=[]] - Formula to use for the item instead of single dynamic value.
	 * @param {DynamicValueDataFormatDataObject} [dataFormat=null] - Data format used for rendering the column in the 
	 * final report.
	 * @param {DynamicValueDisplayType} [displayType] - Display type of the select column. If not specified, dynamic 
	 * value display type will be used.
	 * @param {string} [GUIID] - GUI ID of the column. If not specified unique guid will be used.
	 */
	constructor(
		dynamicValue = null, customHeadings = false, groupHeading = '', columnHeading = '', htmlAllowedInHeadings = false,
		aggregateFunction, formula = [], dataFormat = null, displayType = '', GUIID = ''
	) {
		this.GUIID = (GUIID ? GUIID : v4());
		this.dynamicValue = dynamicValue;
		this.customHeadings = customHeadings;
		this.groupHeading = groupHeading;
		this.columnHeading = columnHeading;
		this.htmlAllowedInHeadings = htmlAllowedInHeadings;
		this.aggregateFunction = (
			isset(aggregateFunction) ? aggregateFunction : getString(dynamicValue, 'aggregateFunction')
		);
		this.formula = formula;
		this.displayType = (displayType ? displayType : get(dynamicValue, 'displayType'));
		this.dataFormat = (dataFormat ? dataFormat : get(dynamicValue, 'defaultDataFormat'));
	}
}

/**
 * Report column sort data object
 */
export class ReportColumnSortDataObject {
	/**
	 * @param {ReportColumnDataObject} column - Report column used for sort.
	 * @param {SortOrder} direction - Sort direction.
	 * @param {string} [GUIID] - GUI ID of the sort column. If not specified unique guid will be used.
	 */
	constructor(column, direction, GUIID) {
		this.GUIID = (GUIID ? GUIID : v4());
		this.column = column;
		this.direction = direction;
	}
}

/**
 * Report filter item data object
 */
export class ReportFilterItemDataObject {
	/**
	 * @param {ReportColumnDataObject} [column] - Report column used as a basis for the filter item.
	 * @param {LogicalOperator|''} [logicalOperator=''] - Filter item logical operator. First filter item in the list
	 * does not have this. See 'LOGICAL_OPERATOR' global const.
	 * @param {ReportFilterComparator} [comparator='EQ'] - Filter item comparator. See 'COMPARATOR' global const.
	 * @param {string|number} [value] - Filter item value.
	 * @param {string} [valueLabel] - Filter item value label for async select filters.
	 * @param {string} [GUIID] - GUI ID. If not specified or empty, unique GUID will be used.
	 */
	constructor(
		column, logicalOperator = LOGICAL_OPERATOR.AND, comparator = REPORT_FILTER_COMPARATOR.EQ, value, valueLabel, GUIID
	) {
		this.GUIID = (GUIID ? GUIID : v4());
		this.column = (column ? column : new ReportColumnDataObject());
		this.logicalOperator = logicalOperator;
		this.comparator = comparator;
		this.value = value;
		this.valueLabel = valueLabel;
	}
}

/**
 * Chart series data object
 */
export class ReportChartSeriesDataObject {
	/**
	 * @param {string} [name='Series 1'] - The name of this series.
	 * @param {string} [axisColumnX=''] - The name of the column that values for X axis should be read from.
	 * @param {string} [axisColumnY=''] -The name of the column that values for Y axis should be read from.
	 * @param {ReportFilterItemDataObject[]} [filter=[]] - Filters that should be applied additionally to the report 
	 * filters in order to get the data for this chart series.
	 * @param {string} [GUIID] - GUI ID. If not specified or empty, unique GUID will be used.
	 */
	constructor(name = 'Series 1', axisColumnX = '', axisColumnY = '', filter = [], GUIID) {
		this.GUIID = (GUIID ? GUIID : v4());
		this.name = name;
		this.axisColumnX = axisColumnX;
		this.axisColumnY = axisColumnY;
		this.filter = filter;
	}
}

/**
 * Chart data object
 */
export class ReportChartDataObject {
	/**
	 * @param {ReportChartType | ''} [chartType=''] - Type of the chart to draw.
	 * @param {string} [name=''] - Chart name (title).
	 * @param {string} [axisNameX=''] - Name of the X axis that will be printed on the chart.
	 * @param {string} [axisNameY=''] - Name of the Y axis that will be printed on the chart.
	 * @param {number} [imageWidth=800] - Width of the chart image that should be generated (in pixels). This is not 
	 * applicable to the vector graphic images (PDF, SVG) - only to PNG, JPG, BMP and similar.
	 * @param {number} [imageHeight=600] - Height of the chart image that should be generated (in pixels). This is not 
	 * applicable to the vector graphic images (PDF, SVG) - only to PNG, JPG, BMP and similar.
	 * @param {ReportChartSeriesDataObject[]} [seriesList=[]] - List of chat series to draw. Note that in case of 
	 * pie/donut charts there should be a single series.
	 * @param {string} [GUIID] - GUI ID. If not specified or empty, unique GUID will be used.
	 */
	constructor(
		chartType = '', name = '', axisNameX = '', axisNameY = '', imageWidth = 800, imageHeight = 600, 
		seriesList = [], GUIID
	) {
		this.GUIID = (GUIID ? GUIID : v4());
		this.chartType = chartType;
		this.name = name;
		this.axisNameX = axisNameX;
		this.axisNameY = axisNameY;
		this.imageWidth = imageWidth;
		this.imageHeight = imageHeight;
		this.seriesList = seriesList;
	}
}

/**
 * Report data object
 * @description This is a complete report data object. It is used in IO and to load a specific report into the report
 * builder.
 */
export class ReportDataObject {
	/**
	 * @param {ReportDataSourceObject} [dataSource=null] - Data source used for the report. It will determine which 
	 * dynamic values can be used.
	 * @param {ReportOutputType} [outputType='DATA_TABLE'] - Report output type.
	 * @param {ReportFileType|''} [fileType=''] - Report file type (depends on 'outputType').
	 * @param {string} [title=''] - Report title.
	 * @param {ReportColumnDataObject[]} [selectColumns=[]] - Report select columns.
	 * @param {ReportFilterItemDataObject[]} [filter=[]] - Report filter.
	 * @param {ReportColumnSortDataObject[]} [sort=[]] - Report sort columns.
	 * @param {ReportChartDataObject} [chart=null] - Chart data if report is a chart.
	 */
	constructor(
		dataSource = null, outputType = REPORT_OUTPUT_TYPE.DATA_TABLE, fileType = '', title = '', selectColumns = [], 
		filter = [], sort = [], chart = null
	) {
		this.dataSource = dataSource;
		this.outputType = outputType;
		this.fileType = fileType;
		this.title = title;
		this.selectColumns = selectColumns;
		this.filter = filter;
		this.sort = sort;
		this.chart = chart;
	}
}