import styles from "./index.module.css";

import React from "react";
import BaseComponent, {executeComponentCallback} from "../../BaseComponent";
import PropTypes from "prop-types";
import {Tooltip, TooltipProps} from "react-tippy";
import {getSkin} from "../../../helpers/skin";
import {SKIN_MODE} from "../../../const/global";
import Spinner from "../Spinner";
import {getBoolean, getString} from "../../../helpers/data";
import {vertScrollbarVisible} from "../../../helpers/dom";
import {waitingFunction} from "../../../helpers/function";
import {main_layout_element} from "../../../../config";

class TooltipDropdown extends BaseComponent {
	/**
	 * Child element click timeoutID
	 * @type {number}
	 */
	childClickTimeout;
	
	constructor(props) {
		super(props, {
			domPrefix: 'tooltip-dropdown-component',
			updateOnSkinChange: true,
			domManipulationIntervalTimeout: 100,
		});

		// Refs
		this.tooltipRef = null;
		this.innerContentRef = null;
		
		// Action methods
		this.closeTooltip = this.closeTooltip.bind(this);

		// Render methods
		this.renderChildren = this.renderChildren.bind(this);
	}
	
	componentWillUnmount() {
		if (this.childClickTimeout > 0) clearTimeout(this.childClickTimeout);
		super.componentWillUnmount();
	}


	// DOM manipulation interval methods --------------------------------------------------------------------------------
	/**
	 * Method called on each DOM manipulation interval
	 *
	 * @param {HTMLElement|Element|null} element - Component's main DOM element or null if component's main DOM element
	 * is not set.
	 */
	domManipulations(element) {
		// Fix overflow auto issue where scrollbar is render on top of the content 
		const tooltipId = element.querySelector('[aria-describedby]').getAttribute('aria-describedby');
		const tooltipElement = document.getElementById(tooltipId);
		if (tooltipElement) {
			const contentElement = tooltipElement.querySelector(`.${styles['content']}`);
			if (contentElement) contentElement.style.overflowY = (vertScrollbarVisible(contentElement) ? 'scroll' : '');
		}
	}
	
	
	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Close tooltip
	 */
	closeTooltip() {
		this.childClickTimeout = setTimeout(() => {
			if (this.tooltipRef) this.tooltipRef.hideTooltip();
		}, 100);
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Return child elements (dropdown content) to render
	 * @return {JSX.Element|Array<Exclude<React.DetailedReactHTMLElement<{onMouseUp: onMouseUp}, HTMLElement>|null, boolean | null | undefined>>}
	 */
	renderChildren() {
		const {showLoading, loading, disabled, children} = this.props;
		
		// Do not render children if component is disabled
		if (disabled) return null;
		
		// Render loading spinner
		if (loading) {
			return (showLoading ? <div className={styles['loading']}><Spinner /></div> : null);
		}
		// Render tooltip child elements
		else {
			return React.Children.map(children, child =>
				child ?
					React.cloneElement(child, {
						className: `${getString(child.props, 'className')} ${styles['item']}`,
						onClick: e => {
							// Do not trigger the 'onClick' event if child is disabled
							// @note Child is disabled if it has a 'disabled' attribute set to 'true'.
							if (e.target.dataset.disabled !== 'true') executeComponentCallback(child.props.onClick, e);
						},
						onMouseUp: e => {
							// Hide tooltip if child element is clicked
							// @note Tooltip will not hide if disabled or inactive is clicked.
							if (
								e.target.classList.contains(styles['item']) && 
								e.target.dataset.disabled !== 'true' &&
								e.target.dataset.inactive !== 'true'
							) {
								this.closeTooltip();
							}
							// Trigger child's own 'onMouseUp' event if it is defined.
							executeComponentCallback(child.props.onMouseUp);
						},
						style: {
							// Set disabled child element style
							color: getBoolean(child, 'props["data-disabled"]') ? 'var(--dropdown-text-faded)' : undefined,
						}
					})
					: null
			);
		}
	}
	
	render() {
		const {
			element, label, className, contentClassName, position, dropdownWidth, dropdownMaxHeight, dropdownOffset, 
			disabled, showLoading, loading, ...otherProps 
		} = this.props;
		const skin = getSkin();

		// Convert camelCase (in this case lowercase) prop name into PascalCase
		// @note This is done because react component props use camelCase (JSX attributes use camelCase) by convention, 
		// but rendered React JSX component must be capitalized (PascalCase).
		const Element = element;
		
		return (
			<Element 
				id={this.getDomId()} 
				className={`${this.getOption('domPrefix')} ${styles['wrapper']} ${className}`}
			>
				<Tooltip
					tag="span"
					trigger={!disabled && (!loading || showLoading) ? 'click' : 'manual'}
					offset={dropdownOffset}
					size="small"
					arrow={false}
					position={position}
					interactive={true}
					theme={skin === SKIN_MODE.DARK ? 'dark' : 'light'}
					hideDelay={0}
					hideDuration={0}
					html={
						<div
							className={`dropdown-component ${styles['content']} ${contentClassName}`}
							style={{
								width: (!loading && dropdownWidth ? dropdownWidth : undefined),
								maxHeight: (!loading && dropdownMaxHeight ? dropdownMaxHeight : undefined)
							}}
						>
							<div className={`dropdown-content-inner ${styles['contentInner']}`}>
								{this.renderChildren()}
							</div>
						</div>
					}
					{...otherProps}
					onShow={(...props) => {
						// Trigger the 'onShow' event if it was specified
						executeComponentCallback(this.props.onShow, ...props);

						// Clear the tooltip content's max width
						// @note Timeout is added to make sure that tooltip content element exists in the DOM.
						setTimeout(() => {
							const element = this.getDomElement();
							const tooltipId = element.querySelector('[aria-describedby]').getAttribute('aria-describedby');
							const tooltipElement = document.getElementById(tooltipId);
							if (tooltipElement) tooltipElement.style.maxWidth = 'none';
						});
					}}
					ref={node => {
						this.tooltipRef = node;

						waitingFunction(() => {
							const appendToElement = document.querySelector(main_layout_element);
							if (appendToElement && node && node.tippy) {
								node.tippy.settings.appendTo = appendToElement;
								return true;
							}
						}, 1, 60000).then();
					}}
				>{label}</Tooltip>
			</Element>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
TooltipDropdown.propTypes = {
	...TooltipProps,
	
	// Name of the wrapper HTML element (like 'i', 'span', ...) used to render the label
	// @note If not specified, wrapper will not be rendered.
	element: PropTypes.string,
	// Dropdown trigger label
	label: PropTypes.element,
	// Dropdown component's CSS class
	className: PropTypes.string,
	// Dropdown content CSS class
	contentClassName: PropTypes.string,
	// Dropdown content position (see react-tippy tooltip position options)
	position: PropTypes.string,
	// Dropdown content width in pixels
	dropdownWidth: PropTypes.number,
	// Dropdown content max height in pixels
	dropdownMaxHeight: PropTypes.number,
	// Dropdown top position offset in pixels
	dropdownOffset: PropTypes.number,
	// Flag that determines if dropdown will be disabled
	// @description Disabled dropdown will not show its content on label click and will not trigger 'onClick' event.
	disabled: PropTypes.bool,
	// Flag that specifies if loading spinner will be shown
	showLoading: PropTypes.bool,
	// Flag that specifies if dropdown items are loading
	// @note If dropdown items are loading, no item will be rendered. Only the loading animation will be rendered.
	loading: PropTypes.bool,

	// Events
	onShow: PropTypes.func,
	onShown: PropTypes.func,
	onHide: PropTypes.func,
	onHidden: PropTypes.func,
};

/**
 * Define component default values for own props
 */
TooltipDropdown.defaultProps = {
	element: 'span',
	className: '',
	contentClassName: '',
	position: 'bottom-start',
	dropdownOffset: 0,
	disabled: false,
	showLoading: true,
	loading: false,
};

export default TooltipDropdown;