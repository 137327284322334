/** @typedef {string} CardsSelectInputDisplayType */
export const CARDS_SELECT_INPUT_DISPLAY_TYPE_GRID = 'GRID';
export const CARDS_SELECT_INPUT_DISPLAY_TYPE_LIST = 'LIST';
/** @enum {CardsSelectInputDisplayType} */
export const CARDS_SELECT_INPUT_DISPLAY_TYPE = {
	GRID: CARDS_SELECT_INPUT_DISPLAY_TYPE_GRID,
	LIST: CARDS_SELECT_INPUT_DISPLAY_TYPE_LIST,
}
/** @type {CardsSelectInputDisplayType[]} */
export const CARDS_SELECT_INPUT_DISPLAY_TYPES = [
	CARDS_SELECT_INPUT_DISPLAY_TYPE_GRID,
	CARDS_SELECT_INPUT_DISPLAY_TYPE_LIST,
];

/** @typedef {string} CardsSelectInputContentDisplayType */
export const CARDS_SELECT_INPUT_CONTENT_DISPLAY_TYPE_STACKED = 'STACKED';
export const CARDS_SELECT_INPUT_CONTENT_DISPLAY_TYPE_ALIGNED = 'ALIGNED';
/** @enum {CardsSelectInputContentDisplayType} */
export const CARDS_SELECT_INPUT_CONTENT_DISPLAY_TYPE = {
	STACKED: CARDS_SELECT_INPUT_CONTENT_DISPLAY_TYPE_STACKED,
	ALIGNED: CARDS_SELECT_INPUT_CONTENT_DISPLAY_TYPE_ALIGNED,
}
/** @type {CardsSelectInputContentDisplayType[]} */
export const CARDS_SELECT_INPUT_CONTENT_DISPLAY_TYPES = [
	CARDS_SELECT_INPUT_CONTENT_DISPLAY_TYPE_STACKED,
	CARDS_SELECT_INPUT_CONTENT_DISPLAY_TYPE_ALIGNED,
];