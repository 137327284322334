import {ACCOUNT_USER_TYPE} from "../../const/account";

/**
 * Account permission list item data object
 * @note This is usually used in data table as a row.
 */
export class AccountListItemDataObject {
	/**
	 * @param {string|number|null} [id] - DB ID of the account.
	 * @param {string} [userName=''] - Account username.
	 * @param {AccountUserType|''} [userType=''] - Account user type.
	 * @param {string} [email=''] - Account user email.
	 * @param {string} [firstName=''] - Account user first name.
	 * @param {string} [lastName=''] - Account user last name.
	 * @param {boolean} [active=true] - Flag that specifies if account is active.
	 */
	constructor(id = null, userName = '', userType = '', email = '', firstName = '', lastName = '', active = true) {
		this.id = id;
		this.userName = userName;
		this.userType = userType;
		this.email = email;
		this.firstName = firstName;
		this.lastName = lastName;
		this.active = active;
	}
}

/**
 * Account data object
 * @note This is usually used when creating or updating data table item using a popup or a dialog.
 */
export class AccountDataObject {
	/**
	 * @param {string|number|null} [id] - DB ID of the account.
	 * @param {string} [userName=''] - Account username.
	 * @param {AccountUserType|''} [userType=''] - Account user type.
	 * @param {string} [email=''] - Account user email.
	 * @param {string} [firstName=''] - Account user first name.
	 * @param {string} [lastName=''] - Account user last name.
	 * @param {AccountRoleDataObject[]} [roles=[]] - List of roles assigned to the user account.
	 * @param {boolean} [active=true] - Flag that specifies if account is active.
	 */
	constructor(
		id = null, userName = '', userType = ACCOUNT_USER_TYPE.OPERATOR, email = '', firstName = '', lastName = '', 
		roles = [], active = true
	) {
		this.id = id;
		this.userName = userName;
		this.userType = userType;
		this.email = email;
		this.firstName = firstName;
		this.lastName = lastName;
		this.roles = roles;
		this.active = active;
	}
}