import {hideLoading, hideLoadingFunction, showLoading} from "../../../../core/helpers/loading";
import {ioJsonDeleteItemAction, ioJsonFetchAction, ioJsonSaveItemAction} from "../../../../core/store/actions/io";
import {actionCreators} from "../../../../core/store/reducers";
import * as filterDataMap from "../../dataMap/filter";
import {clearMessagesAction} from "../../../../core/components/global/Message";
import {CookieData} from "../../../../core/dataProtection/objects/cookie";
import {setStorageValue, STORAGE_TYPE} from "../../../../core/storage";
import CookieConsent from "../../../../core/dataProtection/cookieConsent";
import {app_id} from "../../../../config";
import {uppercaseGUIID} from "../../../../helpers/guiid";
import * as reportDataMap from "../../../builder/dataMap/report";

/**
 * Load generated reports into redux store
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Reports filter plane object.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): *}
 */
export const loadGeneratedReportsAction = (
	abortCallback, filter, pageNo, perPage, sortBy = 'creationDate', sortDir = 'DESC'
) => dispatch => {
	const loading = showLoading("#main-page-table");
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'report/search',
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy,
		sortDir
	)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) {
				dispatch(actionCreators.generatedReports.setReportsData({
					...responseData,
					filter: filterDataMap.input(responseData.filter)
				}));
			}
			hideLoading(loading);
			return responseData;
		});
};

/**
 * Load generated reports on interval into redux store
 * @note Sets only reports list and not pagination, sort and filter data.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Reports filter plane object.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): *}
 */
export const loadGeneratedReportsIntervalAction = (
	abortCallback, filter, pageNo, perPage, sortBy, sortDir
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'report/search',
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy,
		sortDir
	)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators.generatedReports.setReports(responseData.data));
			return responseData;
		});
};

/**
 * Clear generated reports data from redux store
 * @return {(function(*=): void)|*}
 */
export const clearGeneratedReportsAction = () => dispatch => {
	clearMessagesAction()(dispatch);
	dispatch(actionCreators.generatedReports.clearReportsData());
};

/**
 * Clone generated report into a report builder
 * @param {ReportDataObject} reportData - Report data to clone.
 * @return {(function(*): void)|*}
 */
export const cloneGeneratedReportAction = reportData => dispatch => {
	// Convert 'guiid' properties to 'GUIID'
	// @note This is done because JSON parser used by the API does not work with uppercase field names.
	uppercaseGUIID(reportData);
	
	// Map report data using data map 'input' function
	const mappedReportData = reportDataMap.input(reportData);
	
	dispatch(actionCreators.builder.setReport(mappedReportData));
	const builderCookie = new CookieData('necessary', 'builder', STORAGE_TYPE.SESSION);
	if (CookieConsent.isAllowed(builderCookie)) {
		try {
			setStorageValue(`${app_id}-builder`, mappedReportData, STORAGE_TYPE.SESSION, {}, true);
		} catch (e) {
			console.error('Could not store report builder data into the cookie!', e);
		}
	}
};

/**
 * Delete generated report action
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - ID of the report that will be deleted.
 */
export const deleteGeneratedReportAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#report-delete-dialog .dialog-content-component .buttons');
	return ioJsonDeleteItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'report/delete',
		id,
		{},
		hideLoadingFunction(loading)
	)(dispatch);
};

/**
 * Update generated report favourite status
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} report - Whole report object.
 * @param {boolean} isFavourite - New favourite status for the report.
 * @return {function(*=): *}
 */
export const updateGeneratedReportFavouriteAction = (abortCallback, report, isFavourite) => dispatch => {
	const loading = showLoading("#main-page-table");
	return ioJsonSaveItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'report/update-favourite',
		report.id,
		{isFavourite},
		null,
		false,
		hideLoadingFunction(loading)
	)(dispatch);
};