import "./index.css";
import styles from "./index.module.css";

import React from "react";
import {connect} from "react-redux";
import PageComponent from "../../core/components/PageComponent";
import {getGlobalActions} from "../../core/helpers/redux";
import auth from "../../auth";
import {app_id, auth_api, auth_api_endpoints, auth_code_challenge_method} from "../../config";
import {getIOUrl} from "../../core/io/helper";
import {encodeURLParams} from "../../core/helpers/string";
import Button, {BUTTON_STYLE} from "../../core/components/display/Button";
import Label from "../../core/components/display/Label";
import {getString} from "../../core/helpers/data";
import {appHasLogin, isLoginWithoutRedirect, isLoginWithRedirect} from "../../core/helpers/login";

// Import page configs
import * as builderPageConfig from "../builder/config";
import * as schedulePageConfig from "../schedule/config";
import * as reportsPageConfig from "../reports/config";
import * as reportTemplatesPageConfig from "../reportTemplates/config";

/**
 * Imported page config if pages that will be rendered in main navigation as links in the order they are specified in
 * this array.
 * @type {{translationPath?: string, iconElement?: JSX.Element, permissions?: string[], routerPath?: string}[]}
 */
const pages = [
	builderPageConfig, schedulePageConfig, reportsPageConfig, reportTemplatesPageConfig
];

class LandingPage extends PageComponent {
	constructor(props) {
		super(props, { 
			layout: 'blank', 
			translationPath: 'LandingPage'
		}, null);
		
		// Custom component methods
		this.redirectToAuthorizeUrl = this.redirectToAuthorizeUrl.bind(this);
		this.handleLoginButtonClick = this.handleLoginButtonClick.bind(this);
	}


	// Custom component methods -----------------------------------------------------------------------------------------
	/**
	 * Redirect to authorize API
	 */
	redirectToAuthorizeUrl() {
		const codeVerifier = auth.generateCodeVerifier();
		auth.storeCodeVerifier(codeVerifier);
		const codeChallenge = auth.calculateCodeChallenge(codeVerifier);
		const state = auth.generateState();
		auth.storeState(state);

		const params = {
			...auth_api_endpoints.authorize.params,
			client_id: app_id,
			redirect_uri: auth.getRedirectUri(),
			code_challenge: codeChallenge,
			code_challenge_method: auth_code_challenge_method,
			state
		};

		window.location.href = `${getIOUrl(auth_api, auth_api_endpoints.authorize.path)}?${encodeURLParams(params)}`;
	}
	
	/**
	 * Handle clicking on the login button
	 * @param {MouseEvent} event - Button's mouse click event.
	 */
	handleLoginButtonClick(event) {
		if (isLoginWithRedirect()) this.redirectToAuthorizeUrl();
		else if (isLoginWithoutRedirect()) this.redirectTo('login');
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		return this.renderLayout((
			<div className={`${styles['wrapper']}`}>
				<div className={`card ${styles['card']}`}>
					<div className="card-header">{this.t('title')}!</div>
					<div className={`card-content`}>{this.t('content')}</div>
					<div className="card-actions">
					{
						appHasLogin() ?
							<Button
								displayStyle={BUTTON_STYLE.ACTION}
								big={true}
								label={this.t('Login', 'Login')}
								icon="unlock-alt"
								onClick={this.handleLoginButtonClick}
							/>
						:
							<Button
								displayStyle={BUTTON_STYLE.ACTION}
								big={true}
								label={this.t('page_title', 'HomePage')}
								icon="home"
								onClick={() => this.redirectToHome()}
							/>
					}
					</div>
				</div>

				<Label element="h2" elementProps={{className: styles['featuresTitle']}} content={this.t('Features')} />
				<div className={`card-grid per-row-2 ${styles['features']}`}>
					{pages.map((page, index) =>
						<div key={index} className="card action-card no-action">
							<div className="card-content">
								{page.hasOwnProperty('iconElement') ? page.iconElement : null}
								<Label
									content={this.t('page_title', getString(page, 'translationPath'))}
									element="p"
								/>
								{
									this.t('page_short_description', getString(page, 'translationPath')) ?
										<Label
											content={
												this.t('page_short_description', getString(page, 'translationPath'))
											}
											element="p"
											elementProps={{className: 'description'}}
											supportHtml={true}
										/>
										: null
								}
							</div>
						</div>
					)}
				</div>
			</div>
		), 'background-image');
	}
}

export const landingPageRouterPath = '/';
export default connect(null, getGlobalActions()) (LandingPage);
