import React from "react";
import PageComponent from "../../core/components/PageComponent";
import {connect} from "react-redux";
import auth from "../../auth";
import {hideLoading, showPageLoading} from "../../core/helpers/loading";
import {Link} from "react-router-dom";
import AclVisible from "../../core/acl/components/AclVisible";
import Label from "../../core/components/display/Label";
import {getArray, getString} from "../../core/helpers/data";
import * as pageConfig from "./config";
import {appHasLogin} from "../../core/helpers/login";
import {getPageActions} from "../../core/helpers/redux";
import {selectors} from "../../core/store/reducers";
import {getMenuSidebarShrankFromStorage} from "../../layout/elements/MainSidebar/helpers";

// Import page configs
import * as builderPageConfig from "../builder/config";
import * as reportTemplatesPageConfig from "../reportTemplates/config";
import * as schedulePageConfig from "../schedule/config";
import * as reportsPageConfig from "../reports/config";

/**
 * Imported page config if pages that will be rendered in main navigation as links in the order they are specified in
 * this array.
 * @type {{translationPath?: string, iconElement?: JSX.Element, permissions?: string[], routerPath?: string}[]}
 */
const pages = [
	builderPageConfig, reportTemplatesPageConfig, schedulePageConfig, reportsPageConfig
];

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
});

class HomePage extends PageComponent {
	constructor(props) {
		super(props, {
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath
		}, 'page_title');
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return 'home-page'; }


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 */
	loadPageData() {
		// Check if user is logged in by calling an auth ping API
		// @note This is done on each individual section app page because page components (child components) are mounted 
		// before app and section components (parent components) and we need to display the loading overlay as soon as 
		// possible.
		if (appHasLogin()) {
			// Redirect to landing page if user is not logged in
			const loading = showPageLoading(false);
			return this.executeAbortableAction(auth.authorizedPing)
				// Only hide loading if ping was successful because if it was not it should redirect user to the login page
				.then(() => hideLoading(loading))
				// Redirect to login page if pin was not successful
				.catch(() => {
					hideLoading(loading);
					this.redirectTo('login');
				});
		}
	}

	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {mainSidebarShrank, toggleMainSidebarSizeAction} = this.props;
		
		return this.renderLayout((
			<div id={this.getDomId()}>
				<div className="card-grid per-row-2">
					{pages.map((page, index) =>
						<AclVisible key={index} permissions={getArray(page, 'permissions')}>
							<Link to={page.routerPath} className="card action-card">
								<div className="card-content">
									{page.hasOwnProperty('iconElement') ? page.iconElement : null}
									<Label
										content={this.t('page_title', getString(page, 'translationPath'))}
										element="p"
									/>
									{
										this.t('page_short_description', getString(page, 'translationPath')) ?
											<Label
												content={
													this.t('page_short_description', getString(page, 'translationPath'))
												}
												element="p"
												elementProps={{className: 'description'}}
												supportHtml={true}
											/>
											: null
									}
								</div>
							</Link>
						</AclVisible>
					)}
				</div>
			</div>
		), undefined, undefined, {
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default connect(mapStateToProps, getPageActions())(HomePage);