import {ioJsonAction, ioJsonFetchAction, ioJsonFetchItemAction, ioJsonSaveAction} from "../../core/store/actions/io";
import {hideLoading, hideLoadingFunction, showLoading} from "../../core/helpers/loading";
import {actionCreators} from "../../core/store/reducers";
import * as accountListItemDataMap from "./dataMap/accountListItem";
import * as accountDataMap from "./dataMap/account";
import * as accountRoleDataMap from "../accountRoles/dataMap/accountRole";
import {getArray} from "../../core/helpers/data";

/**
 * Fetch the list of accounts
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchAccountListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'user/search',
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy,
		sortDir
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => ({
			...responseData,
			data: getArray(responseData, 'data').map(i => accountListItemDataMap.input(i))
		}));
};

/**
 * Load the list of accounts into Redux store
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const loadAccountListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {
	const loading = showLoading("#main-page-table");
	return fetchAccountListAction(abortCallback, filter, pageNo, perPage, sortBy, sortDir)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators.accounts.setAccountListData(responseData));
			hideLoading(loading);
			return responseData;
		});
};

/**
 * Clear account list form Redux store
 * 
 * @return {(function(*): void)|*}
 */
export const clearAccountListAction = () => dispatch => {
	actionCreators.accounts.clearAccountListData();
}


/**
 * Fetch account
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - DB ID of the account to fetch.
 * @return {function(*=): Promise<AccountDataObject>}
 */
export const fetchAccountAction = (abortCallback, id) => dispatch => {
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'user/fetch-by-id',
		id,
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => accountDataMap.input(responseData?.data));
};

/**
 * Load account into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - DB ID of the account to load.
 * @return {function(*=): Promise<AccountDataObject>}
 */
export const loadAccountAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#account-popup');
	return fetchAccountAction(abortCallback, id)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators.accounts.setAccount(responseData));
			hideLoading(loading);
			return responseData;
		});
}

/**
 * Clear account from Redux store
 * @return {(function(*=): void)|*}
 */
export const clearAccountAction = () => dispatch => {
	dispatch(actionCreators.accounts.clearAccount());
};

/**
 * Create account
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {AccountDataObject} account - Account to save.
 * @return {function(*=): Promise<AccountDataObject>} Promise that wil resolve with the created account
 * received from IO.
 */
export const createAccountAction = (abortCallback, account) => dispatch => {
	const loading = showLoading('#account-popup', true, true);
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'user/create',
		{
			id: '',
			data: accountDataMap.output(account)
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => accountDataMap.input(responseData?.data));
};

/**
 * Update account
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} accountId - DB ID of the account to update.
 * @param {AccountDataObject} account - Account to save.
 * @return {function(*=): Promise<AccountDataObject>} Promise that wil resolve with the updated account 
 * received from IO.
 */
export const updateAccountAction = (abortCallback, accountId, account) => dispatch => {
	const loading = showLoading('#account-popup', true, true);
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'user/update-by-id',
		{
			id: accountId,
			data: accountDataMap.output(account)
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => accountDataMap.input(responseData?.data));
};

/**
 * Fetch the list of all account roles
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @return {function(*): Promise<AccountRoleDataObject[]>}
 */
export const fetchAllRolesAction = abortCallback => dispatch => {
	const loading = showLoading('#account-popup');
	return ioJsonAction(
		abortCallback, 
		'defaultAuthorizedApi', 
		'role/fetch-all',
		undefined,
		hideLoadingFunction(loading),
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => getArray(responseData, 'data').map(i => accountRoleDataMap.input(i)));
};