import {ioJsonFindAction} from "../../core/store/actions/io";

/**
 * Fetch usernames list based on query string
 * @note This action is perfect for use in async select components.
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [query] - Query string to search for.
 * @return {function(*): function(*=): *}
 */
export const fetchUsernamesAction = (abortCallback, query) => dispatch => {
	return ioJsonFindAction(
		abortCallback,
		'defaultAuthorizedApi',
		'report/creators-search',
		query
	)(dispatch);
};
