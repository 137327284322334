import {get} from "lodash";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'mainSidebar';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@mainSidebar/reset',
	SHOW: '@mainSidebar/show',
	HIDE: '@mainSidebar/hide',
	TOGGLE: '@mainSidebar/toggle',
	SHRINK: '@mainSidebar/shrink',
	ENLARGE: '@mainSidebar/enlarge',
	TOGGLE_SIZE: '@mainSidebar/toggle_size',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	show: () => ({type: REDUCER_TYPES.SHOW}),
	hide: () => ({type: REDUCER_TYPES.HIDE}),
	toggle: () => ({type: REDUCER_TYPES.TOGGLE}),
	shrink: () => ({type: REDUCER_TYPES.SHRINK}),
	enlarge: () => ({type: REDUCER_TYPES.ENLARGE}),
	toggleSize: () => ({type: REDUCER_TYPES.TOGGLE_SIZE}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	visible: null,
	shrank: false,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET:
			return {...initialState};

		case REDUCER_TYPES.SHOW:
			return {...state, visible: true};

		case REDUCER_TYPES.HIDE:
			return {...state, visible: false};

		case REDUCER_TYPES.TOGGLE:
			return {...state, visible: !state.visible};
			
		case REDUCER_TYPES.SHRINK:
			return {...state, shrank: true};

		case REDUCER_TYPES.ENLARGE:
			return {...state, shrank: false};
			
		case REDUCER_TYPES.TOGGLE_SIZE:
			return {...state, shrank: !state.shrank};

		default:
			return state;
	}
};

// Selectors
export const selectors = {
	visible: state => get(state, [reducerStoreKey, 'visible'], true),
	shrank: state => get(state, [reducerStoreKey, 'shrank'], false),
	enlarged: state => !get(state, [reducerStoreKey, 'shrank'], false),
};

export default reducer;