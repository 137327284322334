export const DATA_TABLE_CELL_TYPE_TEXT = 'text';
export const DATA_TABLE_CELL_TYPE_NUMBER = 'number';
export const DATA_TABLE_CELL_TYPE_DATE = 'date';
export const DATA_TABLE_CELL_TYPE_BOOL = 'bool';
export const DATA_TABLE_CELL_TYPE_BOOL_INVERTED = 'bool_inverted';
export const DATA_TABLE_CELL_TYPE_ACTION = 'action';
export const DATA_TABLE_CELL_TYPE_ACTIONS = 'actions';
export const DATA_TABLE_CELL_TYPE_TEMPLATE = 'template';
export const DATA_TABLE_CELL_TYPE_ANY = 'any';
export const DATA_TABLE_CELL_TYPE = {
	TEXT: DATA_TABLE_CELL_TYPE_TEXT,
	NUMBER: DATA_TABLE_CELL_TYPE_NUMBER,
	DATE: DATA_TABLE_CELL_TYPE_DATE,
	BOOL: DATA_TABLE_CELL_TYPE_BOOL,
	BOOL_INVERTED: DATA_TABLE_CELL_TYPE_BOOL_INVERTED,
	ACTION: DATA_TABLE_CELL_TYPE_ACTION,
	ACTIONS: DATA_TABLE_CELL_TYPE_ACTIONS,
	TEMPLATE: DATA_TABLE_CELL_TYPE_TEMPLATE,
	ANY: DATA_TABLE_CELL_TYPE_ANY,
};
export const DATA_TABLE_CELL_TYPES = [
	DATA_TABLE_CELL_TYPE_TEXT, DATA_TABLE_CELL_TYPE_NUMBER, DATA_TABLE_CELL_TYPE_DATE, DATA_TABLE_CELL_TYPE_BOOL, 
	DATA_TABLE_CELL_TYPE_BOOL_INVERTED, DATA_TABLE_CELL_TYPE_ACTION, DATA_TABLE_CELL_TYPE_ACTIONS, 
	DATA_TABLE_CELL_TYPE_TEMPLATE, DATA_TABLE_CELL_TYPE_ANY
];