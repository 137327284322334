import "./index.css";

import React from "react";
import DataComponent, {executeComponentCallback} from "../../DataComponent";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {getDate, STANDARD_DATE_TIME_FORMAT} from "../../../helpers/datetime";
import {getDateLocaleByCode, getLocaleCode, getLocaleDateFormat, getLocaleTimeFormat} from "../../../helpers/locale";
import {selectors} from "../../../store/reducers";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import {LOCALE_DATE_FORMAT_NAME, LOCALE_DATE_FORMAT_NAMES, LOCALE_TIME_FORMAT_NAMES} from "../../../const/locale";
import Icon from "../../display/Icon";
import {icon_font_close_symbol} from "../../../../config";
import DateLabel from "../../display/DateLabel";
import {isValid, startOfMonth} from "date-fns";
import {isEqual, each, get} from "lodash";
import {getArray} from "../../../helpers/data";
import {areElementsSiblings} from "../../../helpers/dom";
import {isAnyInputInvalid} from "./helper";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	appLocale: selectors.i18n.getLocale(state)
});

/**
 * Date range picker input component
 * @description Input that allows selection of a date range using a date-picker. 
 * @note This is a controlled component which means it does not maintain it's own state and value is controlled by the
 * parent component.
 * 
 * This component uses '@wojtekmaj/react-daterange-picker' component and 'date-fns' library.
 */
class DateRangeInput extends DataComponent {
	/**
	 * Calendar wrapper element ref
	 * @type {null}
	 */
	calendarRef = null;

	/**
	 * Calendar component ref
	 * @type {null}
	 */
	calendarComponentRef = null;

	constructor(props) {
		super(props, {
			/**
			 * Currently selected date range
			 * @type {Date[]}
			 */
			data: [],

			/**
			 * Calendar will open to this date if no date was selected
			 * @note If not specified, today will be used.
			 */
			openToDate: startOfMonth(new Date()),
		}, {
			domPrefix: 'date-range-input-component',
			enableLoadOnDataPropChange: true,
			dataPropAlias: 'value'
		});

		// Custom component methods
		this.getValue = this.getValue.bind(this);
		this.getValueLocale = this.getValueLocale.bind(this);
		this.getValueFormat = this.getValueFormat.bind(this);
		this.getRenderLocale = this.getRenderLocale.bind(this);
		this.getRenderFormat = this.getRenderFormat.bind(this);
		this.getMinValue = this.getMinValue.bind(this);
		this.getMaxValue = this.getMaxValue.bind(this);

		// GUI methods
		this.handleBlur = this.handleBlur.bind(this);
	}

	componentDidMount(override = false) {
		return super.componentDidMount(override)
			.then(() => {
				// Prevent scroll events in calendar inputs
				// @note This is done for performance reasons because date and time picker component triggers onChange every
				// time any value change and values change on mouse wheel event. Third party date picker component used does 
				// not have an option to disable mouse wheel events so it is done manually here. This is however not a 
				// perfect solution because mouse wheel event will be completely disabled when the mouse if over the 
				// calendar input fields.
				if (this.calendarRef && this.getProp('preventInputScroll') === true) {
					const inputs = getArray(this.calendarRef.querySelectorAll('input'));
					inputs.forEach(input => {
						input.addEventListener("wheel", e => e.preventDefault(), { passive:false });
					});
				}

				if (this.calendarRef && this.getProp('preventInput') === true) {
					const inputs = getArray(this.calendarRef.querySelectorAll('input'));
					inputs.forEach(input => {
						input.setAttribute("readOnly", true);
					});
				}
				
				// Handle DatePicker input element focus to allow 'blur' detection of the whole DateInput component
				if (this.calendarRef) {
					each(this.calendarRef.querySelectorAll('.react-daterange-picker__inputGroup input'),
						/** @param {Element|HTMLElement} input */
						input => {
							input.addEventListener('blur', event => {
								const target = event.target;
								const nextTarget = event.relatedTarget;

								// Only trigger component blur event if the next target (the next element receiving the focus) 
								// is not a sibling of the target element or if it is, it is not an input element. This is done
								// because all DatePicker component inputs (year, month, day, ...) are siblings and we don't 
								// want to trigger the components global 'blur' event while any of those inputs are still in 
								// focus.
								if (!areElementsSiblings(target, nextTarget) || nextTarget.tagName.toLowerCase() !== 'input') {
									this.handleBlur();
								}
							});

							input.addEventListener('keydown', event => {
								// Handle Escape key press
								if(event.key === 'Escape') {
									// Call blur handler, blur inputs and close the calendar
									// @note Blur handler is called to reset invalid values if necessary.
									this.handleBlur().then(() => {
										input.blur();
										if (this.calendarComponentRef) this.calendarComponentRef.closeCalendar();
									});
								}
								// Handle Enter key press
								else if(event.key === 'Enter') {
									// Call blur handler, close the calendar and trigger 'onEnterKey' event
									// @note Blur handler is called to reset invalid values if necessary.
									this.handleBlur().then(() => {
										if (this.calendarComponentRef) this.calendarComponentRef.closeCalendar();
										executeComponentCallback(this.props.onEnterKey, event);
									});
								}
							});
						});
				}
			});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {openToDate} = this.props;

		// Handle 'openToDate' prop changes
		if (!isEqual(openToDate, prevProps.openToDate)) {
			let newOpenToDate = this.getDataToLoad(openToDate);
			if (!isValid(newOpenToDate)) newOpenToDate = this.getMaxValue();
			if (!isValid(newOpenToDate)) newOpenToDate = startOfMonth(new Date());
			this.setState({openToDate: newOpenToDate});
		}

		// Load data
		if (this.getOption('enableLoadOnDataPropChange', false)) {
			const dataPropName = this.getDataPropName();
			const data = (dataPropName ? this.props[this.getDataPropName()] : this.props);
			const prevData = (dataPropName ? prevProps[this.getDataPropName()] : prevProps);

			// If prop data changes load it into local component's state
			// NOTE: Only crude comparison is done (no custom data class support) because load method will check that 
			// before changing local state. This simple check is done just to prevent calling load method on every prop
			// change for optimization.
			if(!isEqual(data, prevData)) {
				return this.load(data)
					.then(() => {
						const valueDate = this.getData();
						if (isValid(get(valueDate, '[0]'))) this.setState({openToDate: valueDate[0]});
						else if (isValid(get(valueDate, '[1]'))) this.setState({openToDate: valueDate[1]});
					});
			}
		}
		return Promise.resolve(this.state);
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Get data to load into local component's state
	 * @description Create and return data that can be loaded directly into local component's state based on the raw
	 * external data (usually sent through props). In some sense this is a method that maps external data into format
	 * that component can use in its local state. This method should return data in the same format as 'getData' method.
	 * @note This method will not mutate the passed data.
	 *
	 * @param {any} rawData - External data that will be used to create local component's state compatible data.
	 * @return {any|null} Local component's state compatible data or null if data could not be loaded.
	 */
	getDataToLoad(rawData) {
		if (Array.isArray(rawData) && rawData.length) {
			if (rawData.length === 1) {
				return [getDate(rawData[0], this.getValueFormat(), this.getValueLocale())];
			} else {
				return [
					getDate(rawData[0], this.getValueFormat(), this.getValueLocale()),
					getDate(rawData[1], this.getValueFormat(), this.getValueLocale())
				];
			}
		} else {
			return [null, null];
		}
	}


	// Custom component methods -----------------------------------------------------------------------------------------
	/**
	 * Get locale used for converting received value date string into Date
	 * @return {string} Locale code (IETF).
	 */
	getValueLocale() {
		const {useAppLocale, valueLocale, appLocale} = this.props;

		let result = '';
		if (valueLocale) result = valueLocale;
		else if (useAppLocale) result = getLocaleCode(appLocale);
		return result;
	}

	/**
	 * Get value format used for converting received value date string into Date
	 * @return {string}
	 */
	getValueFormat() {
		const {valueFormat, appLocale} = this.props;

		if (Array.isArray(valueFormat)) {
			if (valueFormat.length === 3) {
				const dateFormat = (
					LOCALE_DATE_FORMAT_NAMES.includes(valueFormat[0]) ?
						getLocaleDateFormat(appLocale, valueFormat[0]) : valueFormat[0]
				);
				const dateTimeSeparator = valueFormat[1];
				const timeFormat = (
					LOCALE_TIME_FORMAT_NAMES.includes(valueFormat[2]) ?
						getLocaleTimeFormat(appLocale, valueFormat[2]) : valueFormat[2]
				);
				return dateFormat + dateTimeSeparator + timeFormat;
			} else {
				return '';
			}
		} else {
			return (
				LOCALE_DATE_FORMAT_NAMES.includes(valueFormat) ?
					getLocaleDateFormat(appLocale, valueFormat) : valueFormat
			);
		}
	}

	/**
	 * Get locale used for rendering the date input element
	 * @return {string} Locale code (IETF).
	 */
	getRenderLocale() {
		const {useAppLocale, renderLocale, appLocale} = this.props;

		let result = '';
		if (renderLocale) result = renderLocale;
		else if (useAppLocale) result = getLocaleCode(appLocale);
		return result;
	}

	/**
	 * Get date format used to render the date input element
	 * @return {string}
	 */
	getRenderFormat() {
		const {renderFormat, useAppLocale, outputLocale, appLocale} = this.props;
		const defaultFormat = (!outputLocale && useAppLocale ?
				getLocaleDateFormat(appLocale, LOCALE_DATE_FORMAT_NAME.SHORT) : STANDARD_DATE_TIME_FORMAT.MYSQL_DATE
		);

		if (Array.isArray(renderFormat)) {
			if (renderFormat.length === 3) {
				const dateFormat = (
					LOCALE_DATE_FORMAT_NAMES.includes(renderFormat[0]) ?
						getLocaleDateFormat(appLocale, renderFormat[0]) : renderFormat[0]
				);
				const dateTimeSeparator = renderFormat[1];
				const timeFormat = (
					LOCALE_TIME_FORMAT_NAMES.includes(renderFormat[2]) ?
						getLocaleTimeFormat(appLocale, renderFormat[2]) : renderFormat[2]
				);
				return dateFormat + dateTimeSeparator + timeFormat;
			} else {
				return defaultFormat;
			}
		} else {
			return (
				typeof renderFormat !== 'undefined' ?
					(
						LOCALE_DATE_FORMAT_NAMES.includes(renderFormat) ?
							getLocaleDateFormat(appLocale, renderFormat) : renderFormat
					) :
					defaultFormat
			);
		}
	}

	/**
	 * Get minimal allowed date to select
	 * @return {Date}
	 */
	getMinValue() {
		const {minValue} = this.props;
		return getDate(minValue, this.getValueFormat(), this.getValueLocale());
	}

	/**
	 * Get maximal allowed date to select
	 * @return {Date}
	 */
	getMaxValue() {
		const {maxValue} = this.props;
		return getDate(maxValue, this.getValueFormat(), this.getValueLocale());
	}

	/**
	 * Get open to date
	 * @return {Date} It will always return the start of the month do display. This is done because the third-party
	 * datepicker component expects the start of the month.
	 */
	getOpenToDate() {
		const {openToDate} = this.state;
		return startOfMonth(getDate(openToDate, this.getValueFormat(), this.getValueLocale()));
	}


	// GUI methods ------------------------------------------------------------------------------------------------------
	/**
	 * Handle component's 'blur' event
	 * @note This component manually detects 'blur' event when all internal input elements loose focus.
	 * @return {Promise<*>}
	 */
	handleBlur() {
		// Reset invalid values
		if (isAnyInputInvalid(this.calendarRef, true)) {
			const currentDataRange = this.getData();

			// Reset to the previous valid date range
			if (get(currentDataRange, '[0]') instanceof Date && get(currentDataRange, '[1]') instanceof Date) {
				return this.setData([null, null]).then(() => this.setData(currentDataRange));
			}
			// Clear the value if there was no previous valid date
			else {
				return this.setData([null, null])
					.then(() => executeComponentCallback(this.props.onChange, null));
			}
		}
		return Promise.resolve();
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			className, calendarClassName, name, readOnly, disabled, disableCalendar, formControlStyle, closeOnSelect,
			showLeadingZeros, rangeDivider, maxDetail, align, onCalendarOpen, onCalendarClose
		} = this.props;
		
		return (
			!readOnly ?
				<DateRangePicker
					maxDetail={maxDetail}
					calendarClassName={`calendar ${calendarClassName}`}
					calendarIcon={<Icon symbol="calendar" />}
					clearIcon={<Icon symbol={icon_font_close_symbol} />}
					className={
						`${this.getOption('domPrefix')} datepicker ${formControlStyle ? 'form-control' : ''} ` +
						`align-${(align ? align : 'initial')} ${className}`
					}
					closeCalendar={closeOnSelect}
					disabled={disabled}
					disableCalendar={disableCalendar}
					locale={this.getRenderLocale()}
					maxDate={this.getMaxValue()}
					minDate={this.getMinValue()}
					format={this.getRenderFormat()}
					name={name}
					value={this.getData()}
					activeStartDate={this.getOpenToDate()}
					showLeadingZeros={showLeadingZeros}
					rangeDivider={rangeDivider}
					onCalendarOpen={onCalendarOpen}
					onCalendarClose={onCalendarClose}
					onChange={v => executeComponentCallback(this.props.onChange, v)}
					ref={node => {
						if (node) {
							this.calendarRef = node.wrapper;
							this.calendarComponentRef = node;
						}
					}}
				/>
				:
				<div
					className={
						`${this.getOption('domPrefix')} datepicker readonly ${className} ` + 
						`${formControlStyle ? ' form-control ' : ''}`
					}
				>
					<DateLabel
						inputDate={this.getData()[0]}
						outputFormat={this.getRenderFormat()}
						outputLocale={getDateLocaleByCode(this.getRenderLocale())}
					/>
					&nbsp;{rangeDivider}&nbsp;
					<DateLabel
						inputDate={this.getData()[1]}
						outputFormat={this.getRenderFormat()}
						outputLocale={getDateLocaleByCode(this.getRenderLocale())}
					/>
				</div>
				
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
DateRangeInput.propTypes = {
	// Datepicker id attribute
	id: PropTypes.string,
	// Datepicker class attribute
	className: PropTypes.string,
	// Calendar class attribute
	calendarClassName: PropTypes.string,
	// Input name attribute
	name: PropTypes.string,
	// Input value
	value: PropTypes.arrayOf(PropTypes.any),
	// Min. allowed value that can be selected
	minValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
	// Max. allowed value that can be selected
	maxValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
	// Flag specifying if datepicker should be read only
	readOnly: PropTypes.bool,
	// Flag that determines if the input should be disabled
	disabled: PropTypes.bool,
	// When set to true, will remove the calendar and the button toggling its visibility
	disableCalendar: PropTypes.bool,
	// Flag that determines if input will have a standard form control style
	formControlStyle: PropTypes.bool,
	// When true, once the day has been selected, the datepicker will be automatically closed
	closeOnSelect: PropTypes.bool,
	// Flag that determines if current app locale will be used for both input and output
	useAppLocale: PropTypes.bool,
	// Date label value date format
	// @note This supports LOCALE_DATE_FORMAT_NAMES and LOCALE_TIME_FORMAT_NAMES strings that use current app locale 
	// formats (@see app/i18n/locale.js) or any custom format (like 'MM/dd/yyyy'). If array, first item should be date 
	// format, second item should be date-time separator and third item should be time format.
	valueFormat: PropTypes.string,
	// Locale code (IETF) to use for converting received value date string into Date
	// @note If specified, 'useAppLocale' prop will be ignored.
	valueLocale: PropTypes.string,
	// Date format used to render the date input element
	renderFormat: PropTypes.string,
	// Locale code (IETF) to use for rendering the date input element
	// @note If specified, 'useAppLocale' prop will be ignored.
	renderLocale: PropTypes.string,
	// Whether leading zeros should be rendered in date inputs
	showLeadingZeros: PropTypes.bool,
	// Divider between date inputs
	rangeDivider: PropTypes.string,
	// The most detailed calendar view that the user shall see
	maxDetail: PropTypes.oneOf(["month", "year", "decade", "century"]),
	// Input text align
	align: PropTypes.oneOf(['left', 'right', 'center', 'initial', 'inherit']),
	// Prevent input scroll event form triggering
	// @note This can be useful if component onChange event triggers some IO action or other expensive operation to 
	// prevent it from triggering when date input is in focus. Default functionality of third-party date picker component
	// used as a basis for this component, changes the value of the focused date input section (day, month, ...) on mouse 
	// wheel scroll event.
	preventInputScroll: PropTypes.bool,
	// Prevent text input into the input fields by making them read-only (setting 'readOnly' attribute)
	preventInput: PropTypes.bool,

	// Events
	onChange: PropTypes.func, // Arguments: {Date[]} - New value
	onCalendarOpen: PropTypes.func, // Arguments: no arguments
	onCalendarClose: PropTypes.func, // Arguments: no arguments
	onEnterKey: PropTypes.func, // Arguments: keypress event
};

/**
 * Define component default values for own props
 */
DateRangeInput.defaultProps = {
	id: '',
	className: '',
	calendarClassName: '',
	name: '',
	value: null,
	minValue: null,
	maxValue: null,
	readOnly: false,
	disabled: false,
	disableCalendar: false,
	formControlStyle: true,
	closeOnSelect: true,
	useAppLocale: true,
	valueFormat: STANDARD_DATE_TIME_FORMAT.MYSQL_DATE,
	showLeadingZeros: false,
	rangeDivider: '-',
	maxDetail: 'month',
	align: 'initial',
	preventInputScroll: true,
	preventInput: false,
};

export default connect(mapStateToProps, null, null, {forwardRef: true})(DateRangeInput);