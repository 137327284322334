/**
 * Skin resource
 * This resource will handle the skin.
 *
 * NOTE: Resources must default export a function that return a Promise resolved with an object where key is the
 * RESOURCE_KEY and value is loaded resource data. Resources can be used to get any resource but are most useful for
 * loading global app resources needed by the app to work properly.
 */

import {skin_use_system_mode, skin_default_mode} from "../../config";
import {SKIN_MODE} from "../const/global";
import {getStorageValue, STORAGE_TYPE} from "../storage";
import CookieConsent from "../dataProtection/cookieConsent";
import {CookieData} from "../dataProtection/objects/cookie";
import {onSkinModeChange} from "../helpers/skin";

export const RESOURCE_KEY = 'skin';

function isSystemDarkMode() {
	return !!(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
}

/**
 * Resource function
 * @return {Promise<Object<string, any>>} Promise that will resolve with an object where key is the RESOURCE_KEY and
 * value is loaded resource data.
 */
function resourceFunction() {
	// Get skin mode cookie settings
	const skinModeCookie = new CookieData('preferences', 'skin_mode', STORAGE_TYPE.LOCAL);
	
	// Try to get saved skin mode
	const savedSkinMode = (
		CookieConsent.isAllowed(skinModeCookie) ? 
			getStorageValue('skin_mode', STORAGE_TYPE.LOCAL) : 
			undefined
	);
	
	if (savedSkinMode) document.body.classList.add(savedSkinMode);
	else {
		const skinMode = (
			skin_use_system_mode ? (isSystemDarkMode() ? SKIN_MODE.DARK : SKIN_MODE.LIGHT) : skin_default_mode
		);
		document.body.classList.add(skinMode);
	}
	
	// Create event for skin mode change
	// @note Event name is 'onSkinModeChange' and it gets 'newSkinMode' and 'oldSkinMode' in the custom event 'detail'.
	onSkinModeChange();
	
	return Promise.resolve({
		[RESOURCE_KEY]: {
			skinMode: skin_default_mode,
		}
	});
}

export default resourceFunction;