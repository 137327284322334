import React from "react";
import Icon from "../../core/components/display/Icon";

/**
 * Rendered icon element used as a page icon
 * @description Page icons can be used in main navigation, page links and buttons or any other place.
 * @type {JSX.Element}
 */
export const iconElement = (<Icon symbol="people" symbolPrefix="icofont-" />);

/**
 * Permissions required to access the page
 * @type {string[]}
 */
export const permissions = ["USER_READ"];

/**
 * Page router path relative to the base path of the app
 * @note Use '/' at the beginning (for example '/home').
 * @type {string}
 */
export const routerPath = '/accounts';

/**
 * Page translation path
 * @type {string}
 */
export const translationPath = 'AccountsPage';