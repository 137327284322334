import styles from "./index.module.css";
import logoLight from "../../../skin/images/logo_small_light.png";
import logoDark from "../../../skin/images/logo_small_dark.png";

import React from "react";
import PageComponent from "../../components/PageComponent";
import {connect} from "react-redux";
import {get} from "lodash";
import {getIOUrl} from "../../io/helper";
import auth from "../../../auth";
import {app_id, auth_api, auth_api_endpoints, auth_code_challenge_method} from "../../../config";
import {encodeURLParams} from "../../helpers/string";
import {hideLoading, showPageLoading} from "../../helpers/loading";
import {getGlobalActions} from "../../helpers/redux";
import Button, {BUTTON_STYLE} from "../../components/display/Button";
import Label from "../../components/display/Label";
import Login from "../../components/advanced/Login";
import {getString} from "../../helpers/data";
import {appHasLogin, isLoginWithoutRedirect, isLoginWithRedirect} from "../../helpers/login";
import {getSkin} from "../../helpers/skin";
import {SKIN_MODE} from "../../const/global";
import {passwordResetRequestPageRouterPath} from "../passwordResetRequest";
import * as pageConfig from "./config";

/**
 * This page handles user login
 * @note It supports both direct login and redirect to authorization API by using the 'directLogin' option. 
 * Authorization API is usually used when single-sign-on (SSO) is used to authorize users.
 */
class CoreLoginPage extends PageComponent {
	constructor(props) {
		super(props, {
			layout: 'blank',
			routerPath: pageConfig.routerPath,
			domPrefix: 'login-page',
			translationPath: pageConfig.translationPath,
			
			/**
			 * If true, login form will be rendered if. Otherwise, login button that redirects to the authorization API 
			 * (OAuth 2) will be rendered instead.
			 */
			directLogin: isLoginWithoutRedirect()
		});

		this.setBrowserTitle('User login');
		
		// Login form methods
		this.login = this.login.bind(this);
		this.renderLoginForm = this.renderLoginForm.bind(this);
		
		// Login button methods
		this.redirectToAuthorizeUrl = this.redirectToAuthorizeUrl.bind(this);
		this.renderLoginButton = this.renderLoginButton.bind(this);
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 */
	loadPageData() {
		if (appHasLogin()) {
			// Redirect to home page (defined in app config) if user is already logged in
			// @note This is added in case someone opens this page directly.
			auth.authorizedPing()
				.then(() => this.redirectToHome())
				.catch(() => { /* Do nothing */ });
		} else {
			this.redirectToHome();
		}
	}


	// Login form methods -----------------------------------------------------------------------------------------------
	/**
	 * Login user with credentials from login form
	 * @param {string} username - Username from login form.
	 * @param {string} password - Password from login form.
	 */
	login(username, password) {
		const {showErrorMessageAction} = this.props;

		const loading = showPageLoading();
		this.executeAbortableAction(auth.login, username, password, '')
			// Store newly created tokens
			.then(response => {
				if (response) {
					auth.storeAccessToken(get(response, 'data.access_token'));
					auth.storeRefreshToken(get(response, 'data.refresh_token'));
				}
				return response;
			})

			// Call security init API to handle all server-side generated cookies
			.then(response => (response ? this.executeAbortableAction(auth.securityInit) : response))

			// Try to get the authorization code with the newly created access token if login type uses redirects
			// @note If it is successful, user will be redirected to the app, otherwise login form will be shown.
			.then(() => {
				if (isLoginWithRedirect()) {
					return this.executeAbortableAction(auth.generateAuthorizationCode, auth.getAccessToken())
				}
				return Promise.resolve();
			})
			
			// Go to home page if login was successful and login type does not use redirects
			.then(() => {
				if (isLoginWithoutRedirect()) {
					hideLoading(loading);
					this.redirectToHome();
				}
			})

			.catch(error => {
				hideLoading(loading);
				showErrorMessageAction(error.message); 
			});
	}

	/**
	 * Render login form
	 * @return {JSX.Element|null}
	 */
	renderLoginForm() {
		return this.renderLayout((
			<Login
				logo={getSkin() === SKIN_MODE.DARK ? logoDark : logoLight}
				className={`${styles['wrapper']}`}
				cardHeaderClassName={`card-header ${styles['header']}`}
				cardContentClassName={`card-content ${styles['content']}`}
				action={this.login}
				changePasswordAction={() => this.redirectTo(passwordResetRequestPageRouterPath)}
				backAction={() => this.redirectTo('/')}
			/>
		), 'background-image');
	}


	// Login button methods ---------------------------------------------------------------------------------------------
	/**
	 * Redirect to authorize API
	 */
	redirectToAuthorizeUrl() {
		const codeVerifier = auth.generateCodeVerifier();
		auth.storeCodeVerifier(codeVerifier);
		const codeChallenge = auth.calculateCodeChallenge(codeVerifier);
		const state = auth.generateState();
		auth.storeState(state);

		const params = {
			...auth_api_endpoints.authorize.params,
			client_id: app_id,
			redirect_uri: auth.getRedirectUri(),
			code_challenge: codeChallenge,
			code_challenge_method: auth_code_challenge_method,
			state
		};
		
		// Get API to use
		const api = (
			getString(auth_api_endpoints.authorize, 'api') ?
				auth_api_endpoints.authorize.api :
				auth_api
		);
		
		window.location.href = `${getIOUrl(api, auth_api_endpoints.authorize.path)}?${encodeURLParams(params)}`;
	}

	/**
	 * Render login button that will redirect to the authentication page
	 * @return {JSX.Element|null}
	 */
	renderLoginButton() {
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']}`}>
				<div className="card icon-card">
					<div className={`card-header ${styles['header']}`}>
						<Label content={this.t('Locked')} icon="lock" iconClassName='card-header-icon' />
					</div>
					<div className={`card-content ${styles['content']}`}>
						{this.t('You must be logged in to access this page')}
					</div>
					<div className="card-actions">
						<Button
							displayStyle={BUTTON_STYLE.ACTION}
							big={true}
							label={this.t('Login')}
							icon="unlock-alt"
							onClick={() => this.redirectToAuthorizeUrl()}
						/>
					</div>
				</div>
			</div>
		), 'background-image');
	}

	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		return (this.getOption('directLogin') ? this.renderLoginForm() : this.renderLoginButton());
	}
}

export const loginPageRouterPath = pageConfig.routerPath;
export default connect(null, getGlobalActions())(CoreLoginPage);