import styles from "./index.module.css";
import "./index.css";

import React from "react";
import DataComponent, {executeComponentCallback} from "../../../../core/components/DataComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getString} from "../../../../core/helpers/data";
import {getPageActions} from "../../../../core/helpers/redux";
import * as actions from "./actions";
import Separator from "../../../../core/components/display/Separator";
import FormWrapper, {FormField} from "../../../../core/components/advanced/FormWrapper";
import Label from "../../../../core/components/display/Label";
import {FORM_FIELD_LABEL_POSITION} from "../../../../core/components/advanced/FormWrapper/FormField";
import SelectAsyncInput from "../../../../core/components/input/SelectAsyncInput";
import {Tooltip} from "react-tippy";
import Button, {BUTTON_STYLE} from "../../../../core/components/display/Button";
import {REPORT_VALUE_PREFIX, REPORT_VALUE_SUFFIX} from "./const";
import {selectors} from "../../../../core/store/reducers";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	dataSource: selectors.builder.getReportValue(state, 'dataSource'),
});

/**
 * Report and report template section for InsertValueDialog component
 */
class InsertValueDialogReportSection extends DataComponent {
	constructor(props) {
		super(props, {
			data: {
				/**
				 * Selected option object
				 * @type {Object}
				 */
				selectedOption: null
			},
		}, {
			domPrefix: 'insert-value-dialog-reports-section',
			translationPath: 'InsertValueDialogReportSection',
			disableLoad: true,
		});

		// Data loading methods
		this.loadOptions = this.loadOptions.bind(this);

		// Action methods
		this.insert = this.insert.bind(this);
	}


	// Data loading methods ---------------------------------------------------------------------------------------------
	/**
	 * Load autosuggest options
	 * @param {string} [query=''] - Set this to a query string if autosuggest is used.
	 * @return {Promise<Object>}
	 */
	loadOptions(query = '') {
		const {dataSource, searchReportForInsertAction} = this.props;
		if (query.length > 1) return this.executeAbortableAction(
			searchReportForInsertAction, 
			query, 
			{dataSourceId: getString(dataSource, 'id', null)}
		);
		else return Promise.resolve([]);
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Get data that will be returned to the parent component
	 * @description Create and return data that will be returned to paren component (usually through onChange event)
	 * based on the local component's raw state data
	 * @note This method will not mutate the passed data.
	 *
	 * @return {any} Data that will be returned to the parent component.
	 */
	getDataToReturn() {
		const selectedOption = this.getValue('selectedOption');
		if (selectedOption) {
			return `${REPORT_VALUE_PREFIX}${selectedOption.__GROUP__}-${selectedOption.id}${REPORT_VALUE_SUFFIX}`;
		} else {
			return '';
		}
	}
	

	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method to insert tha value
	 * @note This is a required function that must be implemented in every insert value section component created by
	 * extending this abstract component.
	 */
	insert() {
		if (this.validate()) executeComponentCallback(this.props.onInsert, this.getDataToReturn());
	}

	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {className, titleIcon, titleIconStackClass, titleIconSymbolPrefix} = this.props;
		const title = getString(this.props, 'title', this.t('default_title'));

		return (
			<div
				id={this.getDomId()}
				className={
					`${this.getOption('domPrefix')} ${className} ${styles['wrapper']} ` +
					`${title !== '' ? styles['withTitle'] : ''}`
				}
			>
				<div className={`${styles['title']}`}>
					{
						title !== '' ? 
							<Separator
								iconSymbolPrefix={titleIconSymbolPrefix}
								iconStackClass={titleIconStackClass}
								icon={titleIcon}
								content={title} 
								className={`${styles['separator']}`}
							/>
							: null
					}
				</div>
				<Label 
					element="div"
					elementProps={{className: `${styles['description']}`}} 
					content={this.t('description')}
				/>

				<FormWrapper className={`${styles['form']}`}>
					<FormField
						labelPosition={FORM_FIELD_LABEL_POSITION.NONE}
						className={styles['field']}
					>
						<SelectAsyncInput
							isClearable={true}
							getOptionLabel={o => o.fileName}
							getOptionValue={o => o.id}
							value={this.getValue('selectedOption')}
							loadOptions={this.loadOptions}
							onChange={v => this.handleValueChange('selectedOption', v)}
							onEnterKey={this.insert}
						/>
					</FormField>

					<div className={`${styles['formSidebar']}`}>
						<Tooltip
							tag="div"
							title={this.t('Insert', 'general')}
							size="small"
							position="left"
							arrow={true}
							interactive={false}
						>
							<Button
								className={`${styles['insertBtn']}`}
								icon="level-down"
								displayStyle={BUTTON_STYLE.ACTION}
								onClick={this.insert}
								disabled={!this.getValue('selectedOption')}
							/>
						</Tooltip>
					</div>
				</FormWrapper>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
InsertValueDialogReportSection.propTypes = {
	// Component's wrapper element id attribute
	id: PropTypes.string,
	// Component's wrapper element class attribute
	className: PropTypes.string,
	// Section title
	// @note If not defined default title from translation will be used.
	title: PropTypes.string,
	// Section title icon
	titleIcon: PropTypes.string,
	// Section title icon additional Icon component's stack class name
	titleIconStackClass: PropTypes.string,
	// Section title icon font icon symbol prefix
	titleIconSymbolPrefix: PropTypes.string,
	
	// Events
	onInsert: PropTypes.func, // Arguments: insert value
};

/**
 * Define component default values for own props
 */
InsertValueDialogReportSection.defaultProps = {
	id: '',
	className: '',
	titleIcon: '',
	titleIconProps: {},
};

export * from "./regex";
export default connect(mapStateToProps, getPageActions(actions))(InsertValueDialogReportSection);