import PropTypes from "prop-types";
import PopupComponent, {PopupActionDataObject, PopupTabDataObject} from "../../../../core/components/PopupComponent";
import {connect} from "react-redux";
import * as pageConfig from "../../config";
import {getPageActions} from "../../../../core/helpers/redux";
import * as actions from "../../actions";
import {cloneDeep, orderBy, get, omit} from "lodash";
import {icon_font_close_symbol, icon_font_create_symbol, icon_font_save_symbol} from "../../../../config";
import {BUTTON_STYLE} from "../../../../core/components/display/Button";
import {getBool, getString, isset} from "../../../../core/helpers/data";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	
});

class AccountPermissionPopup extends PopupComponent {
	/**
	 * IMPORTANT! Must be defined in components that extend this abstract component like this:
	 * dirname = __dirname;
	 *
	 * @note This is done in order for automatic tab component loading to work properly.
	 */
	dirname = __dirname;

	constructor(props) {
		super(props, {
			translationPath: `${pageConfig.translationPath}.AccountPermissionPopup`,
			domPrefix: 'account-permission-popup',
			hideSingleTab: true,
		});

		this.initialState = {
			/**
			 * List of all popup tabs
			 * @type {PopupTabDataObject[]}
			 */
			tabs: [],

			/**
			 * List of all popup actions
			 * @type {PopupActionDataObject[]}
			 */
			actions: [],

			/**
			 * ID of the currently opened tab
			 * @type {string}
			 */
			currentTabId: '',
		};

		this.state = cloneDeep(this.initialState);

		// Tab methods
		this.dynamicActionButtons = this.dynamicActionButtons.bind(this);
		this.dynamicTabs = this.dynamicTabs.bind(this);
		this.updateDynamics = this.updateDynamics.bind(this);

		// Action methods
		this.saveAccountPermission = this.saveAccountPermission.bind(this);
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Tab methods ------------------------------------------------------------------------------------------------------
	/**
	 * Update dynamic action buttons that depend on current state and props
	 * @param {boolean} [isNew] - Flag that specifies if this popup is for a new item.
	 * @return {Promise<void>}
	 */
	async dynamicActionButtons({isNew}) {
		// Remove actions before deciding which ones to display
		await this.removeActions(['create', 'update']);

		// Add save related global action
		await this.addAction(
			new PopupActionDataObject({
				id: isNew ? 'create' : 'update',
				action: this.saveAccountPermission,
				buttonProps: {
					label: `${pageConfig.translationPath}.${isNew ? 'create_account_permission' : 'save_account_permission'}`,
					icon: isNew ? icon_font_create_symbol : icon_font_save_symbol,
					displayStyle: BUTTON_STYLE.ACTION
				},
				ordinal: 1
			})
		);
	}

	/**
	 * Update dynamic tabs that depend on current state and props
	 * @param {boolean} [isNew] - Flag that specifies if this popup is for a new item.
	 * @return {Promise<void>}
	 */
	dynamicTabs({isNew}) {
		return this.updateTab({
			...this.getTab('MainTab'),
			componentProps: {
				...this.getTab('MainTab').componentProps,
				isNew,
			}
		});
	}

	/**
	 * Update dynamic component items (tabs, action buttons, ...) that depend on state or prop changes
	 * @param {boolean} [isNew] - Flag that specifies if this popup is for a new item.
	 * @return {Promise<void>}
	 */
	updateDynamics({isNew}) {
		// Update dynamic tabs that depend on the new status
		return this.dynamicTabs({isNew})
			// Update dynamic action buttons that depend on the new status
			.then(() => this.dynamicActionButtons({isNew}));
	}

	/**
	 * Initialize popup by specifying initial tabs, actions and current tab
	 * @note If current tab is not set it will default to the first visible and valid tab. Valid tab is tab that has
	 * 'component' property specified (manually or automatically loaded).
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	async init() {
		const isNew = getBool(this.props, 'isNew');

		// Add static actions that don't depend on current state or props
		await this.addAction(
			new PopupActionDataObject({
				id: 'close',
				action: this.close,
				buttonProps: {
					label: 'general.Close',
					icon: icon_font_close_symbol
				},
				ordinal: 0
			}),
		);
		// Add dynamic actions that depend on current state or props
		await this.dynamicActionButtons({isNew});

		// Add tabs
		await this.setTabs([
			new PopupTabDataObject({
				id: 'MainTab',
			}),
		]).then(this.importTabComponents)
		// Update dynamic tabs that depend on current state or props
		await this.dynamicTabs({isNew});

		return Promise.resolve(this.state);
	}

	/**
	 * Try to automatically load tab components from standard location for tabs that don't have components defined
	 * @note To automatically load tab components the need to be located in a 'tabs' subdirectory either as a component
	 * file (like ./tabs/InfoTab.js) or subdirectory with index file (./tabs/InfoTab/index.js) where directory name or
	 * filename must be the tab ID.
	 *
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	importTabComponents() {
		const tabs = orderBy(this.getSortedTabs(), ['preloadPriority'], ['desc']);
		return Promise.all(tabs.map(tab => {
			if (!isset(tab.component)) return this.handleTabComponentImport(tab, import(`./tabs/${tab.id}`));
			else return Promise.resolve(this.state);
		}));
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Create account permission
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'create'|'update'} actionId - ID of the clicked action.
	 * @return {Promise<*>}
	 */
	saveAccountPermission(allTabsData, event, actionId) {
		const {
			redirectToItem, createAccountPermissionAction, updateAccountPermissionAction, loadAccountPermissionListAction, 
			addSuccessMessageAction
		} = this.props;

		return this.getTabRef('MainTab').validateTab()
			.then(valid => {
				if (valid) {
					/** @type {AccountPermissionDataObject} */
					const accountPermission = get(allTabsData, 'MainTab');

					// Create account permission
					if (actionId === 'create') {
						return this.executeAbortableAction(createAccountPermissionAction, accountPermission)
							.then(createdAccountPermission => {
								if (createdAccountPermission.id) {
									// Reload account permission list (data table)
									return this.executeAbortableAction(loadAccountPermissionListAction)
										// Redirect to item URL if item was created successfully and show success message
										.then(() => {
											redirectToItem(createdAccountPermission.id);
											addSuccessMessageAction(this.t('create_account_permission_success_msg'));
										});
								}
							});
					}
					// Update account permission
					else if (actionId === 'update') {
						return this.executeAbortableAction(
							updateAccountPermissionAction,
							getString(accountPermission, 'id', '', true),
							omit(accountPermission, ['id'])
						)
							.then(() => addSuccessMessageAction(this.t('update_account_permission_success_msg')));
					} else {
						console.error(`Invalid account permission popup save method: ${actionId}`);
					}
				}
			});
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
AccountPermissionPopup.propTypes = {
	// Router history objects
	// @note Can be used to redirect to any route. 
	history: PropTypes.object,
	// Flag that specifies if this popup is for creating a new item
	isNew: PropTypes.bool,
	// Function that will redirect to item URL
	redirectToItem: PropTypes.func, // Arguments: {string} - Item id to redirect to.

	// Events
	onClose: PropTypes.func,
	onGlobalAction: PropTypes.func,
	onTabAction: PropTypes.func,
};

export default connect(
	mapStateToProps, getPageActions(actions), null, {forwardRef: true}
)(AccountPermissionPopup);