import {actionCreators} from "../../store/reducers";
import {
	ReportColumnDataObject,
	ReportColumnSortDataObject,
	ReportFilterItemDataObject
} from "../../dataObjects/report";
import {ioJsonAction, ioJsonFetchItemAction, ioJsonFindAction, ioManualJsonAction} from "../../core/store/actions/io";
import {CookieData} from "../../core/dataProtection/objects/cookie";
import {deleteStorageKey, getStorageValue, STORAGE_TYPE} from "../../core/storage";
import CookieConsent from "../../core/dataProtection/cookieConsent";
import {app_id, messages_default_auto_hide_after} from "../../config";
import {hideLoading, hideLoadingFunction, showContentLoading} from "../../core/helpers/loading";
import {isSuccessful} from "../../core/helpers/io";
import {cloneDeep, get} from "lodash";
import {lowercaseGUIID, uppercaseGUIID} from "../../helpers/guiid";
import {StandardJsonResponseError} from "../../core/errors";
import {getString} from "../../core/helpers/data";
import {closeDialogAction, openDialogAction} from "../../core/components/global/Dialog";
import ConfirmDialog from "../../core/components/dialogs/ConfirmDialog";
import {translate} from "../../core/i18n";
import {addErrorMessageAction} from "../../core/components/global/Message";
import * as reportDataMap from "./dataMap/report";

/**
 * Set the report in Redux store
 * @param {ReportDataObject} report
 * @return {(function(*): void)|*}
 */
export const setReportAction = report => dispatch => {
	dispatch(actionCreators.builder.setReport(report));
};

/**
 * Clear report in Redux store
 * @return {(function(*): void)|*}
 */
export const clearReportAction = () => dispatch => {
	dispatch(actionCreators.builder.clearReport());
	dispatch(actionCreators.builder.clearChartOptionsValidationErrors());
	dispatch(actionCreators.builder.clearChartSeriesValidationErrors());
};

/**
 * Set a specific report value in Redux store
 * @note If report is not defined in Redux store, a new empty one will be crated and value will be set.
 * 
 * @param {any} data - Any data to set the report value to. 
 * @param {string|string[]} path - Path to the report value.
 * @return {(function(*): void)|*}
 */
export const setReportValueAction = (data, path) => dispatch => {
	dispatch(actionCreators.builder.setReportValue(data, path));
}

/**
 * Clear a specific report value in Redux store
 * @note If report is not defined in Redux store, a new empty one will be crated and value will be cleared.
 *
 * @param {string|string[]} path - Path to the report value.
 * @return {(function(*): void)|*}
 */
export const clearReportValueAction = path => dispatch => {
	dispatch(actionCreators.builder.clearReportValue(path));
};

/**
 * Save (create new or update existing) a report value item
 * @note This will only work with array report values. 
 * 
 * @param {ReportColumnDataObject|ReportFilterItemDataObject|ReportColumnSortDataObject} item - Item to save.
 * @param {string|string[]} path - Path to the report value.
 * @return {(function(*): void)|*}
 */
export const saveReportValueItemAction = (item, path) => dispatch => {
	dispatch(actionCreators.builder.saveReportValueItem(item, path));
};

/**
 * Remove a report value item
 * @note This will only work with array report values.
 * 
 * @param {string} itemGUIID - GUI ID of the item.
 * @param {string|string[]} path - Path to the report value.
 * @return {(function(*): void)|*}
 */
export const removeReportValueItemAction = (itemGUIID, path) => dispatch => {
	dispatch(actionCreators.builder.removeReportValueItem(itemGUIID, path));
};


// Select columns ------------------------------------------------------------------------------------------------------
/**
 * Add a column to the select list
 * @param {DynamicValueDataObject} dynamicValue - Dynamic value used for adding new select column.
 * @return {(function(*): void)|*}
 */
export const addSelectColumnAction = dynamicValue => dispatch => {
	const selectColumn = new ReportColumnDataObject(dynamicValue);
	saveReportValueItemAction(selectColumn, 'selectColumns')(dispatch);
	return selectColumn.GUIID;
}


// Sort ----------------------------------------------------------------------------------------------------------------
/**
 * Add a column to the sort list
 * @param {ReportColumnDataObject} selectColumn - Report select column.
 * @param {SortOrder} direction - Sort direction.
 * @return {function(*): *|string|string}
 */
export const addSortColumnAction = (selectColumn, direction) => dispatch => {
	const sortColumn = new ReportColumnSortDataObject(selectColumn, direction);
	saveReportValueItemAction(sortColumn, 'sort')(dispatch);
	return sortColumn.GUIID;
};


// Filter --------------------------------------------------------------------------------------------------------------
/**
 * Add a filter item to filter
 * @param {DynamicValueDataObject} dynamicValue - Dynamic value used for adding new select column.
 * @return {(function(*): void)|*}
 */
export const addFilterItemAction = (dynamicValue) => dispatch => {
	const builderFilterItem = new ReportFilterItemDataObject(new ReportColumnDataObject(dynamicValue))
	saveReportValueItemAction(builderFilterItem, 'filter')(dispatch);
	return builderFilterItem.GUIID;
}

/**
 * Fetch options for a specific dynamic value
 * @note This is used for filter items that use select or autosuggest for value input.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} dynamicValueId - Dynamic value ID for witch to get the options.
 * @param {string} [query=''] - Query to filter the results by. This is only used if filter item uses an autosuggest.
 * @param {string[]|number[]} [exclude=[]] - Already selected items that should be excluded from the result.
 * @return {(function(*))|*}
 */
export const fetchFilterOptionsAction = (abortCallback, dynamicValueId, query = '', exclude = []) => dispatch => {
	return ioJsonFindAction(
		abortCallback,
		'defaultAuthorizedApi',
		'dimension-field/find-select-values',
		query,
		100,
		'',
		'asc',
		{
			id: dynamicValueId,
			exclude
		}
	)(dispatch);
};


// Storage -------------------------------------------------------------------------------------------------------------
/**
 * Clear report builder data
 * @note Both Redux store and cookie data will be cleared.
 * @return {(function(*): void)|*}
 */
export const clearReportBuilderAction = () => dispatch => {
	// Delete cookie data
	const builderCookie = new CookieData('necessary', 'builder', STORAGE_TYPE.SESSION);
	if (CookieConsent.isAllowed(builderCookie)) deleteStorageKey(`${app_id}-builder`, STORAGE_TYPE.SESSION);
	
	// Clear Redux store
	dispatch(actionCreators.builder.reset());
}

/**
 * Reload report builder data from storage (cookies) into Redux store
 * @return {(function(*): void)|*}
 */
export const reloadReportFromStorageAction = () => dispatch => {
	const builderCookie = new CookieData('necessary', 'builder', STORAGE_TYPE.SESSION);
	if (CookieConsent.isAllowed(builderCookie)) {
		try {
			const report = getStorageValue(`${app_id}-builder`, STORAGE_TYPE.SESSION, true);
			if (report) dispatch(actionCreators.builder.setReport(report));
		} catch (e) {
			console.error('Could not load report data from cookie!', e);
		}
	}

	// Clear file type because it is not stored in the storage (cookies) and is used only while report builder is in 
	// report template editor mode
	dispatch(actionCreators.builder.setReportValue('', 'fileType'));
	
	// Clear chart validation errors from Redux store
	dispatch(actionCreators.builder.clearChartOptionsValidationErrors());
	dispatch(actionCreators.builder.clearChartSeriesValidationErrors());
}


// Report --------------------------------------------------------------------------------------------------------------
/**
 * Start report building process
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} data - Report data.
 * @param {boolean} overwrite - Flag that specifies if report file will be overwritten if a new report with the same
 * name already exists.
 * @return {function(*): Promise<void>}
 */
export const generateReportAction = (abortCallback, data, overwrite = false) => dispatch => {
	const DUPLICATE_FILE_NAME_ERROR_CODE = '102010005';

	const loading = showContentLoading();
	return ioManualJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'report/create-report-request',
		// @note 'GUIID' fields are changed to 'guiid' because JSON parser used by the API does not work with uppercase 
		// field names.
		{
			...lowercaseGUIID(cloneDeep(data)),
			overwrite
		},
		hideLoadingFunction(loading)
	)(dispatch)
		.catch(error => {
			// Handle standard JSON response error code for duplicate file name
			if (
				error instanceof StandardJsonResponseError &&
				getString(error.response, 'errorCode') === DUPLICATE_FILE_NAME_ERROR_CODE
			) {
				return new Promise(resolve => {
					openDialogAction('', ConfirmDialog, {
						message: translate('file_exists_confirmation_message', 'BuilderPage'),
						onYes: dialogGUIID => {
							hideLoading(loading);
							closeDialogAction(dialogGUIID)(dispatch);
							resolve(generateReportAction(abortCallback, data, true)(dispatch));
						},
						onNo: dialogGUIID => {
							hideLoading(loading);
							closeDialogAction(dialogGUIID)(dispatch);
						}
					}, {
						closeOnEscape: true,
						closeOnClickOutside: false,
						hideCloseBtn: true,
						maxWidth: 520
					})(dispatch);
				})
			}
			// Render error if it is not 'AbortError'
			// @note Error should be an object with 'message' field already translated and ready for display.
			else if (error.name !== 'AbortError') {
				dispatch(addErrorMessageAction(error.message, messages_default_auto_hide_after));
			}
			// Hide loading overlay
			hideLoading(loading);
		});
};


// Report template -----------------------------------------------------------------------------------------------------
/**
 * Fetch report template date
 * @note Report template is not saved in cookie because the current report builder data is stored there and there is no
 * need to save the template since it can always be loaded from the IO.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - Report template item ID.
 * @return {function(*=): *}
 */
export const fetchReportTemplateAction = (abortCallback, id) => dispatch => {
	const loading = showContentLoading(false);
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'report-template/fetch-by-id',
		id,
	)(dispatch)
		.then(response => {
			if (response) {
				let reportData = get(response, 'data.reportRequest');
				
				// Convert 'guiid' properties to 'GUIID'
				// @note This is done because JSON parser used by the API does not work with uppercase field names.
				uppercaseGUIID(reportData);

				// Map report data using data map 'input' function
				const mappedReportData = reportDataMap.input(reportData);
				
				hideLoading(loading);
				return mappedReportData;
			}
			hideLoading(loading);
			return response;
		});
}

/**
 * Create report template for scheduled reports
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} data - Report template data (same as report data when generating the report).
 * @param {boolean} overwrite - Flag that specifies if report file will be overwritten if a new report with the same
 * name already exists.
 * @return {function(*): Promise<void>}
 */
export const createReportTemplateAction = (abortCallback, data, overwrite = false) => dispatch => {
	const loading = showContentLoading();
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'report-template/create',
		// @note 'GUIID' fields are changed to 'guiid' because JSON parser used by the API does not work with uppercase 
		// field names.
		{
			id: '',
			data: {
				...lowercaseGUIID(cloneDeep(data)),
				overwrite
			},
			requestSavedData: false
		},
		hideLoadingFunction(loading)
	)(dispatch);
};

/**
 * Update report template for scheduled reports
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - ID of the report template to update.
 * @param {Object} data - Report template data (same as report data when generating the report).
 * @param {boolean} overwrite - Flag that specifies if report file will be overwritten if a new report with the same
 * name already exists.
 * @return {function(*): Promise<void>}
 */
export const updateReportTemplateAction = (abortCallback, id, data, overwrite = false) => dispatch => {
	const loading = showContentLoading();
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'report-template/update-by-id',
		// @note 'GUIID' fields are changed to 'guiid' because JSON parser used by the API does not work with uppercase 
		// field names.
		{
			id,
			data: {
				...lowercaseGUIID(cloneDeep(data)),
				overwrite
			},
			requestSavedData: true
		},
		hideLoadingFunction(loading)
	)(dispatch)
		.then(response => {
			if (isSuccessful(response)) {
				let reportData = get(response, 'data.reportRequest', {});

				// Convert 'guiid' properties to 'GUIID'
				// @note This is done because JSON parser used by the API does not work with uppercase field names.
				uppercaseGUIID(reportData);

				// Map report data using data map 'input' function
				const mappedReportData = reportDataMap.input(reportData);

				dispatch(actionCreators.builder.setReport(mappedReportData));
			}
			return response;
		});
}

/**
 * Set chart options validation errors in Redux store
 * @param {Object} errors - Validation errors object generated when validation has finished running.
 * @return {(function(*): void)|*}
 */
export const setChartOptionsValidationErrorsAction = errors => dispatch => {
	dispatch(actionCreators.builder.setChartOptionsValidationErrors(errors))
};
/**
 * Clear chart options validation errors in Redux store
 * @return {(function(*): void)|*}
 */
export const clearChartOptionsValidationErrorsAction = () => dispatch => {
	dispatch(actionCreators.builder.clearChartOptionsValidationErrors())
};

/**
 * Set chart series validation errors in Redux store
 * @param {Object} errors - Validation errors object generated when validation has finished running.
 * @return {(function(*): void)|*}
 */
export const setChartSeriesValidationErrorsAction = errors => dispatch => {
	dispatch(actionCreators.builder.setChartSeriesValidationErrors(errors))
};
/**
 * Clear chart series validation errors in Redux store
 * @return {(function(*): void)|*}
 */
export const clearChartSeriesValidationErrorsAction = () => dispatch => {
	dispatch(actionCreators.builder.clearChartSeriesValidationErrors())
};