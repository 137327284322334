import React from "react";
import {isString} from "lodash";

import styles from "./index.module.css";

const Spinner = ({id = '', className = '', size = 16, weight = 2, ...otherProps}) => {
	return (
		<span
			id={id}
			className={`spinner ${className} ${styles['spinner']}`}
			style={{
				height: isString(size) ? size : `${size.toString()}px`,
				width: isString(size) ? size : `${size.toString()}px`,
				borderWidth: isString(weight) ? weight : `${weight.toString()}px`
			}}
			{...otherProps}
		/>
	);
};

export default Spinner;