import styles from "./index.module.css";
import "./index.css";

import React from "react";
import DataComponent from "../../../../core/components/DataComponent";
import PropTypes from "prop-types";
import * as pageConfig from "../../config";
import {selectors} from "../../../../core/store/reducers";
import * as actions from "./actions";
import * as reportsPageActions from "../../actions";
import {areAllObjectPropsEmpty, getArray, getNumber, getString, isset} from "../../../../core/helpers/data";
import * as filterDataMap from "../../dataMap/filter";
import {scrollToSelector} from "../../../../core/helpers/dom";
import ConfirmDialog from "../../../../core/components/dialogs/ConfirmDialog";
import {DATETIME_ROUND_TO, STANDARD_DATE_TIME_FORMAT} from "../../../../core/helpers/datetime";
import {getLocaleDatetimeSeparator} from "../../../../core/helpers/locale";
import {Tooltip} from "react-tippy";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../../../core/components/display/Button";
import Label from "../../../../core/components/display/Label";
import ACL from "../../../../acl";
import {icon_font_delete_symbol} from "../../../../config";
import {connect} from "react-redux";
import {getPageActions} from "../../../../core/helpers/redux";
import SimpleStaticSearch, {
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE,
	SIMPLE_STATIC_SEARCH_LAYOUT,
	SimpleStaticSearchOptionObject
} from "../../../../core/components/advanced/SimpleStaticSearch";
import DataTable, {DATA_TABLE_CELL_TYPE} from "../../../../core/components/advanced/DataTable";
import {LOCALE_DATE_FORMAT_NAME, LOCALE_TIME_FORMAT_NAME} from "../../../../core/const/locale";
import {PAGINATION_TYPE} from "../../../../core/components/action/Pagination";
import {redirectToPath} from "../../../../core/helpers/url";
import {routerPath as schedulePageRouterPath} from "../../../schedule/config";
import {map} from "lodash";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	appLocale: selectors.i18n.getLocale(state),
	reports: selectors.scheduledReports.getReports(state),
	reportsPagination: selectors.scheduledReports.getReportsPagination(state),
	reportsSort: selectors.scheduledReports.getReportsSort(state),
	reportsFilter: selectors.scheduledReports.getReportsFilter(state),
});

class ScheduledReports extends DataComponent {
	/**
	 * Loading intervalID for page data
	 * @description Interval ID of the interval that should run continuously while the component is mounted.
	 */
	pageDataLoadingIntervalId;

	/**
	 * Loading interval (in milliseconds) for page data
	 * @type {number}
	 */
	pageDataLoadingInterval = 7000;

	/**
	 * Flag specifying that regular page data (not interval loading page data) is loading
	 * @type {boolean}
	 */
	pageDataLoading = false;

	constructor(props) {
		super(props, {
			data: {
				/**
				 * Currently selected search filter
				 */
				filter: {},
				/**
				 * Flag showing if filter is loading
				 */
				filterLoading: false,

				/**
				 * List of selected report IDs
				 * @type {string[]|number[]}
				 */
				selectedReportIds: [],

				/** List of data sources loaded from the codebook */
				dataSources: [],
				dataSourcesLoading: false,
			},

			/**
			 * Flag that specifies if main data table height will be limited to the available space
			 */
			limitToAvailableSpace: true,
		}, {
			translationPath: pageConfig.translationPath,
			domPrefix: 'scheduled-reports',
			disableLoad: true,
		});
		
		// Refs
		/** @type {DataTable} */
		this.mainTableRef = null;

		// Data loading methods
		this.getUsernames = this.getUsernames.bind(this);

		// Data methods
		this.loadData = this.loadData.bind(this);
		this.intervalReloadReports = this.intervalReloadReports.bind(this);
		this.reloadReports = this.reloadReports.bind(this);
		this.loadReportsPage = this.loadReportsPage.bind(this);
		this.sortReports = this.sortReports.bind(this);
		this.filterReports = this.filterReports.bind(this);
		this.removeReportsFilter = this.removeReportsFilter.bind(this);
		this.isReportsFilterEmpty = this.isReportsFilterEmpty.bind(this);

		// Action methods
		this.editSchedule = this.editSchedule.bind(this);
		this.deleteReport = this.deleteReport.bind(this);

		// Render methods
		this.renderActions = this.renderActions.bind(this);
	}

	componentDidMount(override = false) {
		return super.componentDidMount(override)
			.then(this.loadData);
	}

	componentWillUnmount() {
		super.componentWillUnmount();

		// Clear page data loading interval
		if (this.pageDataLoadingIntervalId) clearInterval(this.pageDataLoadingIntervalId);
	}

	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return 'reports-page-scheduled-reports'; }


	// Data loading methods ---------------------------------------------------------------------------------------------
	/**
	 * Fetch usernames list based on query string
	 * @note This action is perfect for use in async select components.
	 *
	 * @param {string} [query=''] - Query string to search for.
	 * @return {Promise<[]>}
	 */
	getUsernames(query = '') {
		const {fetchUsernamesAction} = this.props;

		return new Promise(resolve => {
			if (query.length > 2) {
				this.executeAbortableAction(fetchUsernamesAction, query)
					.then(responseData => {resolve(getArray(responseData, 'data'))})
					.catch(() => {resolve([])});
			} else {
				resolve([]);
			}
		});
	}

	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {Promise<*>}
	 */
	loadData() {
		const {reports, loadScheduledReportsAction, fetchDataSourcesAction} = this.props;

		// Load data sources used in filters
		this.setValue('dataSourcesLoading', true)
			.then(() => this.executeAbortableAction(fetchDataSourcesAction))
			.then(response => {
				if (response) {
					return this.setValue('dataSources', getArray(response, 'data'))
						.then(() => this.setValue('dataSourcesLoading', false))
				}
				return Promise.resolve(this.state);
			});

		// Set interval that will reload page data
		if (this.pageDataLoadingInterval > 0) {
			this.pageDataLoadingIntervalId = setInterval(() => {
				// Reload page data only if it is not currently loading
				if (!this.pageDataLoading) this.intervalReloadReports().then();
			}, this.pageDataLoadingInterval);
		}

		// Reload reports if they are not already loaded
		// @note This will load reports using current options (filter, pagination, ...)
		if (isset(reports)) {
			// Open filter if it is not empty
			if (!this.isReportsFilterEmpty() && this.reportsFilterRef) this.reportsFilterRef.open();
			return this.reloadReports();
		}
		// Load reports if they are not already loaded
		else {
			return this.setValue('loading', true)
				.then(() => this.executeAbortableAction(loadScheduledReportsAction))
				.then(() => this.setValue('loading', false));
		}
	}

	/**
	 * Reload reports using current options (page, sort, ...) on interval
	 * @return {Promise<*>}
	 */
	intervalReloadReports() {
		const {
			loadScheduledReportsIntervalAction, reportsPagination, reportsSort, reportsFilter
		} = this.props;
		const {pageNo, perPage} = reportsPagination;
		const {sortBy, sortDir} = reportsSort;
		const oFilter = filterDataMap.output(reportsFilter);

		this.pageDataLoading = true;
		return this.executeAbortableAction(loadScheduledReportsIntervalAction, oFilter, pageNo, perPage, sortBy, sortDir)
			.then(() => this.pageDataLoading = false);
	}

	/**
	 * Reload reports using current options (page, sort, ...)
	 * @return {Promise<*>}
	 */
	reloadReports() {
		const {loadScheduledReportsAction, reportsPagination, reportsSort, reportsFilter} = this.props;
		const {pageNo, perPage} = reportsPagination;
		const {sortBy, sortDir} = reportsSort;
		const oFilter = filterDataMap.output(reportsFilter);

		this.pageDataLoading = true;
		return this.executeAbortableAction(loadScheduledReportsAction, oFilter, pageNo, perPage, sortBy, sortDir)
			.then(() => this.reportsFilterRef?.reload())
			.then(() => this.pageDataLoading = false);
	}

	/**
	 * Load reports page
	 * @param {number} [pageNo=1] - Page number to load (starts with 1).
	 * @return {Promise<*>}
	 */
	loadReportsPage(pageNo = 1) {
		const {loadScheduledReportsAction, reportsPagination, reportsSort, reportsFilter} = this.props;
		const {perPage} = reportsPagination;
		const {sortBy, sortDir} = reportsSort;
		const oFilter = filterDataMap.output(reportsFilter);

		this.pageDataLoading = true;
		return this.executeAbortableAction(loadScheduledReportsAction, oFilter, pageNo, perPage, sortBy, sortDir)
			.then(() => this.pageDataLoading = false);
	}

	/**
	 * Sort reports
	 * @param {string} sortBy - Name of the sort column.
	 * @param {string} sortDir - Direction of the sort.
	 * @return {Promise<*>}
	 */
	sortReports(sortBy, sortDir) {
		const {loadScheduledReportsAction, reportsPagination, reportsFilter} = this.props;
		const {pageNo, perPage} = reportsPagination;
		const oFilter = filterDataMap.output(reportsFilter);

		this.pageDataLoading = true;
		return this.executeAbortableAction(loadScheduledReportsAction, oFilter, pageNo, perPage, sortBy, sortDir)
			.then(() => this.pageDataLoading = false);
	}

	/**
	 * Filter reports
	 * @param {Object} filter - Filter object where keys are filter field names and values are filter values.
	 * @return {Promise<*>}
	 */
	filterReports(filter) {
		const {loadScheduledReportsAction, reportsPagination, reportsSort} = this.props;
		const {perPage} = reportsPagination;
		const {sortBy, sortDir} = reportsSort;
		const oFilter = filterDataMap.output(filter);

		this.pageDataLoading = true;
		return this.setValue('filterLoading', true)
			.then(() => this.executeAbortableAction(loadScheduledReportsAction, oFilter, 1, perPage, sortBy, sortDir))
			.then(() => this.setValue('filterLoading', false))
			.then(() => this.pageDataLoading = false)
			.then(() => {
				if (areAllObjectPropsEmpty(filter, true, false)) {
					if (this.reportsFilterRef) this.reportsFilterRef.close();
				} else {
					scrollToSelector('#main-page-table', false, 80);
				}
			});
	}

	/**
	 * Remove reports filter
	 * @return {Promise<*>}
	 */
	removeReportsFilter() {
		return this.filterReports(null);
	}

	/**
	 * Check if reports filter is applied
	 * @return {Boolean}
	 */
	isReportsFilterEmpty() {
		return areAllObjectPropsEmpty(this.getProp('reportsFilter'), true, false);
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Edit report schedule
	 * @param {Object} data - Report data.
	 */
	editSchedule(data) {
		redirectToPath(schedulePageRouterPath);
	}

	/**
	 * Delete report
	 * @param {Object} data - Report data of the report that will be deleted.
	 */
	deleteReport(data) {
		const {openDialogAction, closeDialogAction, deleteScheduledReportAction} = this.props;

		const dialogGUIID = openDialogAction('', ConfirmDialog, {
			message: this.t('confirm_delete'),
			supportHtml: true,
			onYes: () => {
				this.executeAbortableAction(deleteScheduledReportAction, data.id)
					.then(() => this.reloadReports())
					// Go to the previous page if there are no table rows after one has been deleted
					.then(() => {
						const reports = getArray(this.props, 'reports');
						const pageNo = getNumber(this.props, 'reportsPagination.pageNo', 1);
						if (reports.length === 0 && pageNo > 1) return this.loadReportsPage(pageNo - 1);
					})
					.then(() => closeDialogAction(dialogGUIID));
			},
			onNo: () => closeDialogAction(dialogGUIID)
		}, {
			id: 'report-delete-dialog',
			closeOnEscape: true,
			closeOnClickOutside: true,
			hideCloseBtn: true,
			maxWidth: 500
		});
	}

	/**
	 * Delete multiple reports
	 * @param {string[]|number[]} ids - List of report IDs to delete.
	 */
	deleteReports(ids) {
		const {openDialogAction, closeDialogAction, deleteScheduledReportAction} = this.props;
		const dialogGUIID = openDialogAction('', ConfirmDialog, {
			message: this.t('confirm_delete_multiple'),
			supportHtml: true,
			onYes: () => {
				this.executeAbortableAction(deleteScheduledReportAction, ids)
					.then(() => this.reloadReports())
					// Go to the previous page if there are no table rows after one has been deleted
					.then(() => {
						const reports = getArray(this.props, 'reports');
						const pageNo = getNumber(this.props, 'reportsPagination.pageNo', 1);
						if (reports.length === 0 && pageNo > 1) return this.loadReportsPage(pageNo - 1);
					})
					// Unselect deleted ids
					.then(() => { if (this.mainTableRef) this.mainTableRef.clearSelection(); })
					.then(() => closeDialogAction(dialogGUIID));
			},
			onNo: () => closeDialogAction(dialogGUIID)
		}, {
			id: 'report-delete-dialog',
			closeOnEscape: true,
			closeOnClickOutside: true,
			hideCloseBtn: true,
			maxWidth: 500
		});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render data table actions cell
	 * @param {Object} report - Report data object (one data table row).
	 * @return {JSX.Element}
	 */
	renderActions(report) {
		return (
			<div className="actions">
				<Tooltip
					tag="div"
					title={`${this.t('Delete', 'general')} ...`}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
				>
					<Button
						className="action-btn"
						displayStyle={BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon={icon_font_delete_symbol}
						hide={!ACL.checkPermission(['REPORTING_DELETE'])}
						onClick={() => this.deleteReport(report)}
					/>
				</Tooltip>
			</div>
		);
	}
	
	render() {
		const {appLocale, reports, reportsPagination, reportsSort, reportsFilter} = this.props;
		const {limitToAvailableSpace} = this.state;

		return (
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']} `}>
				<SimpleStaticSearch
					className="main-search"
					defaultCollapse={true}
					layout={SIMPLE_STATIC_SEARCH_LAYOUT.STACKED}
					buttonProps={{
						displayStyle: BUTTON_STYLE.DEFAULT
					}}
					options={[
						new SimpleStaticSearchOptionObject('fileName', this.t('Name')),
						new SimpleStaticSearchOptionObject(
							'dataSourceId',
							this.t('Data source'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: this.getValue('dataSources').map(i => ({label: i.name, value: i.id})),
							}
						),
						new SimpleStaticSearchOptionObject(
							'username',
							this.t('Creator'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT_ASYNC,
							{
								isClearable: true,
								cacheOptions: false,
								loadOptions: this.getUsernames,
							}
						),
						new SimpleStaticSearchOptionObject(
							'dateFrom',
							this.t('Scheduled from'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
							{
								valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
								roundTo: DATETIME_ROUND_TO.START
							}
						),
						new SimpleStaticSearchOptionObject(
							'dateTo',
							this.t('Scheduled to'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
							{
								valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
								roundTo: DATETIME_ROUND_TO.END
							}
						),
					]}
					value={reportsFilter}
					title={(<Label icon="search" content={this.t('Search', 'general')} />)}
					applied={!this.isReportsFilterEmpty()}
					enableToolbar={true}
					enableResetButton={false}
					onChange={this.filterReports}
					onRemove={this.removeReportsFilter}
					onToggle={visible => this.setState({limitToAvailableSpace: visible})}
					ref={node => { this.reportsFilterRef = node; }}
				/>

				<div className="toolbar standard for-table-with-filter">
					<Tooltip
						tag="div"
						title={`${this.t('Delete selected', 'general')} ...`}
						size="small"
						position="top-center"
						arrow={true}
						interactive={false}
					>
						<Button
							displayStyle={BUTTON_STYLE.SUBTLE}
							displayType={BUTTON_DISPLAY_TYPE.NONE}
							icon={icon_font_delete_symbol}
							onClick={() => this.deleteReports(this.getValue('selectedReportIds'))}
							disabled={this.getValue('selectedReportIds').length === 0}
						/>
					</Tooltip>
					<Tooltip
						tag="div"
						title={this.t('Reload data', 'general')}
						size="small"
						position="top-center"
						arrow={true}
						interactive={false}
					>
						<Button
							big={true}
							icon="refresh"
							displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
							displayStyle={BUTTON_STYLE.SUBTLE}
							onClick={this.reloadReports}
						/>
					</Tooltip>
				</div>

				<DataTable
					id="main-page-table"
					limitToAvailableSpace={limitToAvailableSpace && !this.getProp('isMobileBreakpoint')}
					highlightOnHover={true}
					columns={[
						/*{
							name: 'creationDate',
							sortName: 'creationDate',
							label: this.t('Creation date'),
							dataType: DATA_TABLE_CELL_TYPE.DATE,
							dataTypeOptions: {
								inputFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
								outputFormat: [
									LOCALE_DATE_FORMAT_NAME.SHORT,
									getLocaleDatetimeSeparator(appLocale),
									LOCALE_TIME_FORMAT_NAME.STANDARD
								]
							},
							width: 1,
							minWidth: 180
						},*/
						{
							name: 'scheduledTime',
							sortName: 'scheduledTime',
							label: this.t('Scheduled date'),
							dataType: DATA_TABLE_CELL_TYPE.DATE,
							dataTypeOptions: {
								inputFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
								outputFormat: [
									LOCALE_DATE_FORMAT_NAME.SHORT,
									getLocaleDatetimeSeparator(appLocale),
									LOCALE_TIME_FORMAT_NAME.STANDARD
								]
							},
							width: 1,
							minWidth: 180
						},
						{
							name: 'creator',
							label: this.t('Creator'),
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: {
								content: report =>
									<div className="no-wrap">
										{getString(report, 'creator')}
									</div>
							},
							width: 1,
						},
						{
							name: 'fileName',
							label: this.t('Name'),
						},
						{
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: {
								content: this.renderActions,
								standardWrapper: false
							},
							width: 1,
							stopPropagation: true,
						},
					]}
					primaryKeyColumn="id"
					selectable={true}
					onSelect={selRows => this.setValue('selectedReportIds', map(selRows, 'id'))}
					data={reports}
					paginationType={PAGINATION_TYPE.STATIC}
					onSortByColumn={this.sortReports}
					onPaginationClick={this.loadReportsPage}
					{...reportsPagination}
					{...reportsSort}
					ref={node => { this.mainTableRef = node; }}
				/>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ScheduledReports.propTypes = {
	// Function that renders report page title
	// @note This is added so each report type can specify its own reload method for the reload button in page title.
	renderPageTitle: PropTypes.func,
};

export default connect(
	mapStateToProps, 
	getPageActions(actions, reportsPageActions),
	null,
	{forwardRef: true}
)(ScheduledReports);