import styles from "./index.module.css";
import "./index.css";

import React from "react";
import DataComponent from "../../../../core/components/DataComponent";
import PropTypes from "prop-types";
import * as pageConfig from "../../config";
import {selectors} from "../../../../core/store/reducers";
import * as actions from "./actions";
import * as reportsPageActions from "../../actions";
import {areAllObjectPropsEmpty, getArray, getBoolean, getNumber, getString, isset} from "../../../../core/helpers/data";
import * as filterDataMap from "../../dataMap/filter";
import {scrollToSelector} from "../../../../core/helpers/dom";
import ConfirmDialog from "../../../../core/components/dialogs/ConfirmDialog";
import {get, map} from "lodash";
import {routerPath as builderPageRouterPath} from "../../../builder";
import StacktraceDialog, {stacktraceDialogClassName} from "../../../../core/components/dialogs/StacktraceDialog";
import {REPORT_STATUS, REPORT_STATUSES} from "../../const";
import Label from "../../../../core/components/display/Label";
import {DATETIME_ROUND_TO, getDate, getDateString, STANDARD_DATE_TIME_FORMAT} from "../../../../core/helpers/datetime";
import {getDateLocale, getLocaleDatetimeFormat, getLocaleDatetimeSeparator} from "../../../../core/helpers/locale";
import Icon from "../../../../core/components/display/Icon";
import {
	icon_font_delete_symbol,
	icon_font_error_symbol,
	icon_font_stack_class,
	icon_font_success_symbol
} from "../../../../config";
import {getIOUrl} from "../../../../core/io/helper";
import {Tooltip} from "react-tippy";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../../../core/components/display/Button";
import DownloadButton from "../../../../core/components/action/DownloadButton";
import ACL from "../../../../acl";
import SimpleStaticSearch, {
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE,
	SIMPLE_STATIC_SEARCH_LAYOUT,
	SimpleStaticSearchOptionObject
} from "../../../../core/components/advanced/SimpleStaticSearch";
import DataTable, {DATA_TABLE_CELL_TYPE} from "../../../../core/components/advanced/DataTable";
import {LOCALE_DATE_FORMAT_NAME, LOCALE_TIME_FORMAT_NAME} from "../../../../core/const/locale";
import {PAGINATION_TYPE} from "../../../../core/components/action/Pagination";
import {connect} from "react-redux";
import {getPageActions} from "../../../../core/helpers/redux";
import {redirectToPath} from "../../../../core/helpers/url";
import {formatNumber, getAppNumberLocaleCode, getParsedElapsedTime} from "../../../../core/helpers/number";
import {validateReport} from "../../../../helpers/report";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	appLocale: selectors.i18n.getLocale(state),
	reports: selectors.generatedReports.getReports(state),
	reportsPagination: selectors.generatedReports.getReportsPagination(state),
	reportsSort: selectors.generatedReports.getReportsSort(state),
	reportsFilter: selectors.generatedReports.getReportsFilter(state),
});

class GeneratedReports extends DataComponent {
	/**
	 * Loading intervalID for page data
	 * @description Interval ID of the interval that should run continuously while the component is mounted.
	 */
	pageDataLoadingIntervalId;

	/**
	 * Loading interval (in milliseconds) for page data
	 * @type {number}
	 */
	pageDataLoadingInterval = 7000;

	/**
	 * Flag specifying that regular page data (not interval loading page data) is loading
	 * @type {boolean}
	 */
	pageDataLoading = false;
	
	constructor(props) {
		super(props, {
			data: {
				/**
				 * Currently selected search filter
				 */
				filter: {},
				/**
				 * Flag showing if filter is loading
				 */
				filterLoading: false,

				/**
				 * List of selected report IDs
				 * @type {string[]|number[]}
				 */
				selectedReportIds: [],

				/** List of data sources loaded from the codebook */
				dataSources: [],
				dataSourcesLoading: false,
			},

			/**
			 * Flag that specifies if main data table height will be limited to the available space
			 */
			limitToAvailableSpace: true,
		}, {
			translationPath: pageConfig.translationPath,
			domPrefix: 'generated-reports',
			disableLoad: true,
		});

		// Refs
		/** @type {DataTable} */
		this.mainTableRef = null;

		// Data loading methods
		this.getUsernames = this.getUsernames.bind(this);

		// Data methods
		this.loadData = this.loadData.bind(this);
		this.intervalReloadReports = this.intervalReloadReports.bind(this);
		this.reloadReports = this.reloadReports.bind(this);
		this.loadReportsPage = this.loadReportsPage.bind(this);
		this.sortReports = this.sortReports.bind(this);
		this.filterReports = this.filterReports.bind(this);
		this.removeReportsFilter = this.removeReportsFilter.bind(this);
		this.isReportsFilterEmpty = this.isReportsFilterEmpty.bind(this);

		// Action methods
		this.cloneReport = this.cloneReport.bind(this);
		this.deleteReport = this.deleteReport.bind(this);
		this.deleteReports = this.deleteReports.bind(this);
		this.toggleFavourite = this.toggleFavourite.bind(this);

		// Dialog methods
		this.openDebugDialog = this.openDebugDialog.bind(this);

		// Render methods
		this.renderReportStatus = this.renderReportStatus.bind(this);
		this.renderActions = this.renderActions.bind(this);
	}

	componentDidMount(override = false) {
		return super.componentDidMount(override)
			.then(this.loadData);
	}
	
	componentWillUnmount() {
		super.componentWillUnmount();

		// Clear page data loading interval
		if (this.pageDataLoadingIntervalId) clearInterval(this.pageDataLoadingIntervalId);
	}

	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return 'reports-page-generated-reports'; }


	// Data loading methods ---------------------------------------------------------------------------------------------
	/**
	 * Fetch usernames list based on query string
	 * @note This action is perfect for use in async select components.
	 *
	 * @param {string} [query=''] - Query string to search for.
	 * @return {Promise<[]>}
	 */
	getUsernames(query = '') {
		const {fetchUsernamesAction} = this.props;

		return new Promise(resolve => {
			if (query.length > 2) {
				this.executeAbortableAction(fetchUsernamesAction, query)
					.then(responseData => {resolve(getArray(responseData, 'data'))})
					.catch(() => {resolve([])});
			} else {
				resolve([]);
			}
		});
	}

	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {Promise<*>}
	 */
	loadData() {
		const {reports, loadGeneratedReportsAction, fetchDataSourcesAction} = this.props;

		// Load data sources used in filters
		this.setValue('dataSourcesLoading', true)
			.then(() => this.executeAbortableAction(fetchDataSourcesAction))
			.then(response => {
				if (response) {
					return this.setValue('dataSources', getArray(response, 'data'))
						.then(() => this.setValue('dataSourcesLoading', false))
				}
				return Promise.resolve(this.state);
			});

		// Set interval that will reload page data
		if (this.pageDataLoadingInterval > 0) {
			this.pageDataLoadingIntervalId = setInterval(() => {
				// Reload page data only if it is not currently loading
				if (!this.pageDataLoading) this.intervalReloadReports().then();
			}, this.pageDataLoadingInterval);
		}

		// Reload reports if they are not already loaded
		// @note This will load reports using current options (filter, pagination, ...)
		if (isset(reports)) {
			// Open filter if it is not empty
			if (!this.isReportsFilterEmpty() && this.reportsFilterRef) this.reportsFilterRef.open();
			return this.reloadReports();
		}
		// Load reports if they are not already loaded
		else {
			return this.setValue('loading', true)
				.then(() => this.executeAbortableAction(loadGeneratedReportsAction))
				.then(() => this.setValue('loading', false));
		}
	}

	/**
	 * Reload reports using current options (page, sort, ...) on interval
	 * @return {Promise<*>}
	 */
	intervalReloadReports() {
		const {
			loadGeneratedReportsIntervalAction, reportsPagination, reportsSort, reportsFilter
		} = this.props;
		const {pageNo, perPage} = reportsPagination;
		const {sortBy, sortDir} = reportsSort;
		const oFilter = filterDataMap.output(reportsFilter);

		this.pageDataLoading = true;
		return this.executeAbortableAction(loadGeneratedReportsIntervalAction, oFilter, pageNo, perPage, sortBy, sortDir)
			.then(() => this.pageDataLoading = false);
	}

	/**
	 * Reload reports using current options (page, sort, ...)
	 * @return {Promise<*>}
	 */
	reloadReports() {
		const {loadGeneratedReportsAction, reportsPagination, reportsSort, reportsFilter} = this.props;
		const {pageNo, perPage} = reportsPagination;
		const {sortBy, sortDir} = reportsSort;
		const oFilter = filterDataMap.output(reportsFilter);

		this.pageDataLoading = true;
		return this.executeAbortableAction(loadGeneratedReportsAction, oFilter, pageNo, perPage, sortBy, sortDir)
			.then(() => this.reportsFilterRef?.reload())
			.then(() => this.pageDataLoading = false);
	}

	/**
	 * Load reports page
	 * @param {number} [pageNo=1] - Page number to load (starts with 1).
	 * @return {Promise<*>}
	 */
	loadReportsPage(pageNo = 1) {
		const {loadGeneratedReportsAction, reportsPagination, reportsSort, reportsFilter} = this.props;
		const {perPage} = reportsPagination;
		const {sortBy, sortDir} = reportsSort;
		const oFilter = filterDataMap.output(reportsFilter);

		this.pageDataLoading = true;
		return this.executeAbortableAction(loadGeneratedReportsAction, oFilter, pageNo, perPage, sortBy, sortDir)
			.then(() => this.pageDataLoading = false);
	}

	/**
	 * Sort reports
	 * @param {string} sortBy - Name of the sort column.
	 * @param {string} sortDir - Direction of the sort.
	 * @return {Promise<*>}
	 */
	sortReports(sortBy, sortDir) {
		const {loadGeneratedReportsAction, reportsPagination, reportsFilter} = this.props;
		const {pageNo, perPage} = reportsPagination;
		const oFilter = filterDataMap.output(reportsFilter);

		this.pageDataLoading = true;
		return this.executeAbortableAction(loadGeneratedReportsAction, oFilter, pageNo, perPage, sortBy, sortDir)
			.then(() => this.pageDataLoading = false);
	}

	/**
	 * Filter reports
	 * @param {Object} filter - Filter object where keys are filter field names and values are filter values.
	 * @return {Promise<*>}
	 */
	filterReports(filter) {
		const {loadGeneratedReportsAction, reportsPagination, reportsSort} = this.props;
		const {perPage} = reportsPagination;
		const {sortBy, sortDir} = reportsSort;
		const oFilter = filterDataMap.output(filter);

		this.pageDataLoading = true;
		return this.setValue('filterLoading', true)
			.then(() => this.executeAbortableAction(loadGeneratedReportsAction, oFilter, 1, perPage, sortBy, sortDir))
			.then(() => this.setValue('filterLoading', false))
			.then(() => this.pageDataLoading = false)
			.then(() => {
				if (areAllObjectPropsEmpty(filter, true, false)) {
					if (this.reportsFilterRef) this.reportsFilterRef.close();
				} else {
					scrollToSelector('#main-page-table', false, 80);
				}
			});
	}

	/**
	 * Remove reports filter
	 * @return {Promise<*>}
	 */
	removeReportsFilter() {
		return this.filterReports(null);
	}

	/**
	 * Check if reports filter is applied
	 * @return {Boolean}
	 */
	isReportsFilterEmpty() {
		return areAllObjectPropsEmpty(this.getProp('reportsFilter'), true, false);
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Clone report
	 * @param {Object} data - Report data to clone.
	 */
	cloneReport(data) {
		const {openDialogAction, closeDialogAction, addErrorMessageAction, cloneGeneratedReportAction} = this.props;

		const dialogGUIID = openDialogAction('', ConfirmDialog, {
			message: this.t('clone_info'),
			supportHtml: true,
			onYes: () => {
				/** @type {ReportDataObject} */
				const reportBuilderData = get(data, 'reportRequest');
				const validationCode = validateReport(reportBuilderData);
				if (validationCode === 0) {
					cloneGeneratedReportAction(reportBuilderData);
					closeDialogAction(dialogGUIID);
					redirectToPath(builderPageRouterPath);
				} else {
					addErrorMessageAction(
						this.t(
							'clone_error', 
							'', 
							'', 
							{name: getString(reportBuilderData, 'title')}
						) + ' ' + this.t(`${validationCode}`, 'constants.report_validation_errors'),
						undefined, undefined, undefined, undefined, undefined, true
					);
					closeDialogAction(dialogGUIID);
				}
			},
			onNo: () => closeDialogAction(dialogGUIID)
		}, {
			closeOnEscape: true,
			closeOnClickOutside: true,
			hideCloseBtn: true,
			maxWidth: 500
		});
	}

	/**
	 * Delete report
	 * @param {Object} data - Report data of the report that will be deleted.
	 */
	deleteReport(data) {
		const {openDialogAction, closeDialogAction, deleteGeneratedReportAction} = this.props;

		const dialogGUIID = openDialogAction('', ConfirmDialog, {
			message: this.t('confirm_delete'),
			supportHtml: true,
			onYes: () => {
				this.executeAbortableAction(deleteGeneratedReportAction, data.id)
					.then(() => this.reloadReports())
					// Go to the previous page if there are no table rows after one has been deleted
					.then(() => {
						const reports = getArray(this.props, 'reports');
						const pageNo = getNumber(this.props, 'reportsPagination.pageNo', 1);
						if (reports.length === 0 && pageNo > 1) return this.loadReportsPage(pageNo - 1);
					})
					.then(() => closeDialogAction(dialogGUIID));
			},
			onNo: () => closeDialogAction(dialogGUIID)
		}, {
			id: 'report-delete-dialog',
			closeOnEscape: true,
			closeOnClickOutside: true,
			hideCloseBtn: true,
			maxWidth: 500
		});
	}
	
	/**
	 * Delete multiple reports
	 * @param {string[]|number[]} ids - List of report IDs to delete.
	 */
	deleteReports(ids) {
		const {openDialogAction, closeDialogAction, deleteGeneratedReportAction} = this.props;
		const dialogGUIID = openDialogAction('', ConfirmDialog, {
			message: this.t('confirm_delete_multiple'),
			supportHtml: true,
			onYes: () => {
				this.executeAbortableAction(deleteGeneratedReportAction, ids)
					.then(() => this.reloadReports())
					// Go to the previous page if there are no table rows after one has been deleted
					.then(() => {
						const reports = getArray(this.props, 'reports');
						const pageNo = getNumber(this.props, 'reportsPagination.pageNo', 1);
						if (reports.length === 0 && pageNo > 1) return this.loadReportsPage(pageNo - 1);
					})
					// Unselect deleted ids
					.then(() => { if (this.mainTableRef) this.mainTableRef.clearSelection(); })
					.then(() => closeDialogAction(dialogGUIID));
			},
			onNo: () => closeDialogAction(dialogGUIID)
		}, {
			id: 'report-delete-dialog',
			closeOnEscape: true,
			closeOnClickOutside: true,
			hideCloseBtn: true,
			maxWidth: 500
		});
	}

	/**
	 * Toggle report favourite status
	 * @param {Object} report - Whole report object.
	 * @return {Promise<*>}
	 */
	toggleFavourite(report) {
		const {updateGeneratedReportFavouriteAction} = this.props;
		return this.executeAbortableAction(
			updateGeneratedReportFavouriteAction, report, !getBoolean(report, 'favourite')
		)
			.then(response => {
				if (response) return this.reloadReports();
				return response;
			});
	}


	// Dialog methods ---------------------------------------------------------------------------------------------------
	/**
	 * Open debug dialog for reports with error status
	 * @param {Object} report - Report data object (one data table row).
	 */
	openDebugDialog(report) {
		const {errorCode, errorMessage, errorStacktrace} = report;
		const {openDialogAction} = this.props;

		openDialogAction(
			'',
			StacktraceDialog, {
				errorCode,
				errorMessage,
				errorStacktrace
			}, {
				className: `message-dialog ${stacktraceDialogClassName}`,
				closeOnEscape: true,
				closeOnClickOutside: true,
				hideCloseBtn: true,
				maxWidth: 1200
			}
		)
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render report status data table cell for a specific report (data table row)
	 * @param {Object} report - Report data object (one data table row).
	 * @return {JSX.Element}
	 */
	renderReportStatus(report) {
		const {appLocale} = this.props;
		const {status, scheduledTime, errorCode, errorMessage} = report;

		switch (status) {
			case REPORT_STATUS.PENDING:
				return (
					<div className={`${styles['statusCell']} ${styles['statusPending']}`}>
						<Label
							icon="calendar"
							iconClassName={styles['pendingIcon']}
							content={this.tt(status, 'status')}
							help={scheduledTime ? getDateString(
								getDate(scheduledTime, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
								getLocaleDatetimeFormat(appLocale),
								getDateLocale(appLocale)
							) : ''}
						/>
					</div>
				);
			case REPORT_STATUS.PROCESSING:
				return (
					<div className={`${styles['statusCell']} ${styles['statusProcessing']}`}>
						<Icon symbol={['coffee', 'fire']} stackClassName={`${icon_font_stack_class} steaming-coffee`} />
						<Label content={this.tt(status, 'status')} />&nbsp;...
					</div>
				);
			case REPORT_STATUS.COMPLETED:
				return (
					<div className={`${styles['statusCell']} ${styles['statusCompleted']}`}>
						<Label icon={icon_font_success_symbol} content={this.tt(status, 'status')} />
					</div>
				);
			case REPORT_STATUS.ERROR:
				// Handle dynamic value context warnings
				if (errorCode === '105001008') {
					return (
						<div className={`status-cell ${styles['statusCell']} ${styles['statusWarning']}`}>
							<Label
								icon={icon_font_error_symbol}
								content={`${this.tt('dynamic_value_context_warning', 'status')}: ${errorMessage}`}
							/>
						</div>
					);
				} else {
					return (
						<div className={`${styles['statusCell']} ${styles['statusError']}`}>
							<Label icon={icon_font_error_symbol} content={this.tt('system_error', 'status')} />
						</div>
					);
				}
			default:
				return null;
		}
	}

	/**
	 * Render data table actions cell
	 * @param {Object} report - Report data object (one data table row).
	 * @return {JSX.Element}
	 */
	renderActions(report) {
		const {appLocale, openDialogAction, closeDialogAction} = this.props;
		const {status} = report;
		const downloadLink = getIOUrl('defaultAuthorizedApi', 'report/download-report');

		// Create prefix string for file name for download
		const fileNamePrefix = getDateString(
			getDate(getString(report, 'scheduledTime'), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
			"yyyy-MM-dd_HH-mm-ss",
			getDateLocale(appLocale)
		);
		const fileNameForDownload = `${fileNamePrefix} ${report.fileName}`;

		return (
			<div className="actions">
				{
					status === REPORT_STATUS.ERROR ?
						<React.Fragment>
							<Tooltip
								tag="div"
								title={this.t('Error details')}
								size="small"
								position="top-center"
								arrow={true}
								interactive={false}
							>
								<Button
									className="action-btn"
									displayStyle={BUTTON_STYLE.ACTION}
									displayType={BUTTON_DISPLAY_TYPE.NONE}
									icon="bug"
									onClick={() => this.openDebugDialog(report)}
								/>
							</Tooltip>
						</React.Fragment>
						:
						null
				}
				{
					status === REPORT_STATUS.COMPLETED ?
						<React.Fragment>
							<Tooltip
								tag="div"
								title={`${this.t('Download', 'general')} ...`}
								size="small"
								position="top-center"
								arrow={true}
								interactive={false}
							>
								<DownloadButton
									className="action-btn"
									displayStyle={BUTTON_STYLE.ACTION}
									displayType={BUTTON_DISPLAY_TYPE.NONE}
									url={downloadLink}
									filename={fileNameForDownload}
									data={{id: report.id}}
									label=""
									icon=""
								>
									<Label icon="download" />
								</DownloadButton>
							</Tooltip>


							<Tooltip
								tag="div"
								title={`${this.t('Download and delete')} ...`}
								size="small"
								position="top-center"
								arrow={true}
								interactive={false}
							>
								<DownloadButton
									className="action-btn"
									displayStyle={BUTTON_STYLE.ACTION}
									displayType={BUTTON_DISPLAY_TYPE.NONE}
									url={downloadLink}
									filename={fileNameForDownload}
									label=""
									icon=""
									data={{id: report.id, delete: true}}
									hide={!ACL.checkPermission(['REPORTING_DELETE'])}
									onClick={() => {
										return new Promise(resolve => {
											const dialogGUIID = openDialogAction('', ConfirmDialog, {
												message: this.t('confirm_download_and_delete'),
												supportHtml: true,
												onYes: () => {
													resolve(true);
													closeDialogAction(dialogGUIID);
												},
												onNo: () => {
													resolve(false);
													closeDialogAction(dialogGUIID);
												}
											}, {
												closeOnEscape: true,
												closeOnClickOutside: true,
												hideCloseBtn: true,
												maxWidth: 600
											});
										});
									}}
									onDownload={this.reloadReports}
								>
									<Icon
										symbol={['download', 'trash']}
										stackClassName={`${icon_font_stack_class} download-delete`}
									/>
								</DownloadButton>
							</Tooltip>
						</React.Fragment>
						: null
				}

				<Tooltip
					tag="div"
					title={this.t('clone_tooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
				>
					<Button
						className="action-btn"
						displayStyle={BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon="clone"
						onClick={() => this.cloneReport(report)}
					/>
				</Tooltip>

				<Tooltip
					tag="div"
					title={`${this.t('Delete', 'general')} ...`}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
				>
					<Button
						className="action-btn"
						displayStyle={BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon={icon_font_delete_symbol}
						hide={!ACL.checkPermission(['REPORTING_DELETE'])}
						onClick={() => this.deleteReport(report)}
					/>
				</Tooltip>
			</div>
		);
	}

	render() {
		const {appLocale, reports, reportsPagination, reportsSort, reportsFilter} = this.props;
		const {limitToAvailableSpace} = this.state;
		
		return (
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']} `}>
				<SimpleStaticSearch
					className="main-search"
					defaultCollapse={true}
					layout={SIMPLE_STATIC_SEARCH_LAYOUT.STACKED}
					buttonProps={{
						displayStyle: BUTTON_STYLE.DEFAULT
					}}
					options={[
						new SimpleStaticSearchOptionObject('fileName', this.t('Name')),
						new SimpleStaticSearchOptionObject(
							'dataSourceId',
							this.t('Data source'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: this.getValue('dataSources').map(i => ({label: i.name, value: i.id})),
							}
						),
						new SimpleStaticSearchOptionObject(
							'username',
							this.t('Creator'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT_ASYNC,
							{
								isClearable: true,
								cacheOptions: false,
								loadOptions: this.getUsernames,
							}
						),
						new SimpleStaticSearchOptionObject(
							'status',
							this.t('Status'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: REPORT_STATUSES.map(s => ({
									label: this.tt(s, 'status', 'c'),
									value: s
								}))
							}
						),
						new SimpleStaticSearchOptionObject(
							'favourite',
							this.t('Favourite'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: [
									{label: this.t('Yes', 'general'), value: '1'},
									{label: this.t('No', 'general'), value: '0'}
								],
							}
						),
						new SimpleStaticSearchOptionObject(
							'dateFrom',
							this.t('Date from'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
							{
								valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
								roundTo: DATETIME_ROUND_TO.START
							}
						),
						new SimpleStaticSearchOptionObject(
							'dateTo',
							this.t('Date to'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
							{
								valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
								roundTo: DATETIME_ROUND_TO.END
							}
						),
					]}
					value={reportsFilter}
					title={(<Label icon="search" content={this.t('Search', 'general')} />)}
					applied={!this.isReportsFilterEmpty()}
					enableToolbar={true}
					enableResetButton={false}
					onChange={this.filterReports}
					onRemove={this.removeReportsFilter}
					onToggle={visible => this.setState({limitToAvailableSpace: visible})}
					ref={node => { this.reportsFilterRef = node; }}
				/>
				
				<div className="toolbar standard for-table-with-filter">
					<Tooltip
						tag="div"
						title={`${this.t('Delete selected', 'general')} ...`}
						size="small"
						position="top-center"
						arrow={true}
						interactive={false}
					>
						<Button
							displayStyle={BUTTON_STYLE.SUBTLE}
							displayType={BUTTON_DISPLAY_TYPE.NONE}
							icon={icon_font_delete_symbol}
							onClick={() => this.deleteReports(this.getValue('selectedReportIds'))}
							disabled={this.getValue('selectedReportIds').length === 0}
						/>
					</Tooltip>
					<Tooltip
						tag="div"
						title={this.t('Reload data', 'general')}
						size="small"
						position="top-center"
						arrow={true}
						interactive={false}
					>
						<Button
							big={true}
							icon="refresh"
							displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
							displayStyle={BUTTON_STYLE.SUBTLE}
							onClick={this.reloadReports}
						/>
					</Tooltip>
				</div>

				<DataTable
					id="main-page-table"
					limitToAvailableSpace={limitToAvailableSpace && !this.getProp('isMobileBreakpoint')}
					highlightOnHover={true}
					columns={[
						{
							name: 'favourite',
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: {
								content: report => (
									<Icon
										className={
											styles['favouriteBtn'] + ' ' +
											(getBoolean(report, 'favourite') ? styles['true'] : styles['false']) + ' '
										}
										symbol={getBoolean(report, 'favourite') ? 'star' : 'star-o'}
										onClick={() => this.toggleFavourite(report)}
									/>
								),
								standardWrapper: false
							},
							width: 1,
						},
						{
							name: 'creationDate',
							sortName: 'creationDate',
							label: this.t('Creation date'),
							dataType: DATA_TABLE_CELL_TYPE.DATE,
							dataTypeOptions: {
								inputFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
								outputFormat: [
									LOCALE_DATE_FORMAT_NAME.SHORT,
									getLocaleDatetimeSeparator(appLocale),
									LOCALE_TIME_FORMAT_NAME.STANDARD
								]
							},
							width: 1,
							minWidth: 170
						},


						{
							name: 'processingStartTime',
							label: this.t('Processing started'),
							dataType: DATA_TABLE_CELL_TYPE.DATE,
							dataTypeOptions: {
								inputFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
								outputFormat: [
									LOCALE_DATE_FORMAT_NAME.SHORT,
									getLocaleDatetimeSeparator(appLocale),
									LOCALE_TIME_FORMAT_NAME.STANDARD
								]
							},
							width: 1,
							minWidth: 170
						},
						{
							name: 'processingEndTime',
							label: this.t('Processing ended'),
							dataType: DATA_TABLE_CELL_TYPE.DATE,
							dataTypeOptions: {
								inputFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
								outputFormat: [
									LOCALE_DATE_FORMAT_NAME.SHORT,
									getLocaleDatetimeSeparator(appLocale),
									LOCALE_TIME_FORMAT_NAME.STANDARD
								]
							},
							width: 1,
							minWidth: 170
						},
						{
							name: 'processingTimeSeconds',
							label: this.t('Processing duration'),
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: {
								content: row => {
									const processingTimeSeconds = row.processingTimeSeconds;
									if (processingTimeSeconds < 1 && processingTimeSeconds > 0) {
										return (
											<Label 
												content={
													`${formatNumber(processingTimeSeconds, '0.000', getAppNumberLocaleCode())}`
													+ this.translatePath('time.sec')
												} 
											/>
										);
									} else {
										const elapsedTime = getParsedElapsedTime(Math.ceil(processingTimeSeconds));
										const hoursString = (
											elapsedTime[0] ? `${elapsedTime[0]}${this.translatePath('time.h')} ` : ''
										);
										const minutesString = (
											elapsedTime[1] ? `${elapsedTime[1]}${this.translatePath('time.min')} ` : ''
										);
										const secondsString = (
											elapsedTime[2] ? elapsedTime[2] + this.translatePath('time.sec') : ''
										);
										return (
											<Label content={hoursString + minutesString + secondsString} />
										);
									}
								},
								whiteSpace: 'nowrap'
							},
							minWidth: 1,
						},
						
						
						{
							name: 'creator',
							label: this.t('Creator'),
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: {
								content: report =>
									<div className="no-wrap">
										{getString(report, 'creator')}
									</div>
							},
							width: 1,
						},
						{
							name: 'status',
							label: this.t('Status'),
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: {
								content: this.renderReportStatus
							},
							width: 1,
							widthLessThanLabel: true,
						},
						{
							name: 'fileName',
							label: this.t('Name'),
							dataType: DATA_TABLE_CELL_TYPE.TEMPLATE,
							dataTypeOptions: {
								template: report =>
									report.status === REPORT_STATUS.PENDING ?
										`<span class="${styles['statusPending']}">{$fileName}</span>`
										: report.status === REPORT_STATUS.PROCESSING ?
											`<span class="${styles['statusProcessing']}">{$fileName}</span>`
											: report.status === REPORT_STATUS.COMPLETED ?
												`<span class="${styles['statusCompleted']}">{$fileName}</span>`
												: report.status === REPORT_STATUS.ERROR ?
													(
														report.errorCode === '105001008' ?
															`<span class="${styles['statusWarning']}">{$fileName}</span>` :
															`<span class="${styles['statusError']}">{$fileName}</span>`
													)
													: '{$fileName}',
								supportHtml: true
							}
						},
						{
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: {
								content: this.renderActions,
								standardWrapper: false
							},
							width: 1,
							stopPropagation: true,
						},
					]}
					primaryKeyColumn="id"
					selectable={true}
					onSelect={selRows => this.setValue('selectedReportIds', map(selRows, 'id'))}
					data={reports}
					paginationType={PAGINATION_TYPE.STATIC}
					onSortByColumn={this.sortReports}
					onPaginationClick={this.loadReportsPage}
					{...reportsPagination}
					{...reportsSort}
					ref={node => { this.mainTableRef = node; }}
				/>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
GeneratedReports.propTypes = {
	// Function that renders report page title
	// @note This is added so each report type can specify its own reload method for the reload button in page title.
	renderPageTitle: PropTypes.func,
};

export default connect(
	mapStateToProps, 
	getPageActions(actions, reportsPageActions), 
	null, 
	{forwardRef: true}
)(GeneratedReports);