import React from "react";
import Icon from "../../core/components/display/Icon";
import {icon_font_stack_class} from "../../config";

/**
 * Rendered icon element used as a page icon
 * @description Page icons can be used in main navigation, page links and buttons or any other place.
 * @type {JSX.Element}
 */
export const iconElement = (
	<Icon
		symbolPrefix="icofont-"	
		symbol={['file-file', 'help-robot']} 
		stackClassName={`${icon_font_stack_class} file-template`}
	/>
);


/**
 * Permissions required to access the page
 * @type {string[]}
 */
export const permissions = ['REPORT_TEMPLATE_VIEW'];

/**
 * Page router path relative to the base path of the app
 * @note Use '/' at the beginning (for example '/home').
 * @type {string}
 */
export const routerPath = '/report-templates';

/**
 * Page translation path
 * @type {string}
 */
export const translationPath = 'ReportTemplatesPage';