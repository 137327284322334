/**
 * Account permission list item data object
 * @note This is usually used in data table as a row.
 */
export class AccountRoleListItemDataObject {
	/**
	 * @param {string|number|null} [id] - DB ID of the account role.
	 * @param {string} [code=''] - Account role code.
	 * @param {string} [name=''] - Account role name.
	 * @param {string} [description=''] - Account role description.
	 * @param {AccountPermissionDataObject[]} [permissions=[]] - List of permissions assigned to the role.
	 */
	constructor(id = null, code = '', name = '', description = '', permissions = []) {
		this.id = id;
		this.code = code;
		this.name = name;
		this.description = description;
		this.permissions = permissions;
	}
}

/**
 * Account role data object
 * @note This is usually used when creating or updating data table item using a popup or a dialog.
 */
export class AccountRoleDataObject {
	/**
	 * @param {string|number|null} [id] - DB ID of the account role.
	 * @param {string} [code=''] - Account role code.
	 * @param {string} [name=''] - Account role name.
	 * @param {string} [description=''] - Account role description.
	 * @param {AccountPermissionDataObject[]} [permissions=[]] - List of permissions assigned to the role.
	 */
	constructor(id = null, code = '', name = '', description = '', permissions = []) {
		this.id = id;
		this.code = code;
		this.name = name;
		this.description = description;
		this.permissions = permissions;
	}
}