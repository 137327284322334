/**
 * Calculate and apply proper position of the dropdown content
 * @param {string} GUIID - Dropdown GUI ID.
 * @param {number} [offset=0] - Offset in pixels.
 */
export const calculateDropdownPosition = (GUIID, offset = 0) => {
	const dropdownElement = document.querySelector('#dropdown-' + GUIID);
	if (dropdownElement) {
		const dropdownLabelElement = dropdownElement.querySelector('.dropdown-label');
		const dropdownContentElement = dropdownElement.querySelector('.dropdown-content');

		if (dropdownContentElement && dropdownLabelElement) {
			dropdownContentElement.style.top = `${dropdownLabelElement.offsetHeight + offset}px`;
		}
	}
};