import React from 'react';
import AppComponent, {AppPageRoute} from "./core/App";
import {Route, withRouter} from "react-router-dom";

/**
 * Import required resources
 */
import cookieConsentResource from "./core/resources/cookieConsentResource";
import i18nResource from "./core/resources/i18nResource";
import skinResource from "./core/resources/skinResource";
import currentUserResource from "./core/resources/currentUserResource";

/**
 * Import app top-level pages
 * @note Child pages will be loaded by top level pages themselves.
 */
import LandingPage, {landingPageRouterPath} from "./pages/landing";
import AuthPage, {authPageRouterPath} from "./core/pages/auth";
import LoginPage, {loginPageRouterPath} from "./core/pages/login";
import PasswordResetPage, {passwordResetPageRouterPath} from "./core/pages/passwordReset";
import PasswordResetRequestPage, {passwordResetRequestPageRouterPath} from "./core/pages/passwordResetRequest";
import * as homePage from "./pages/home";
import * as builderPage from  "./pages/builder";
import * as reportsPage from  "./pages/reports";
import * as reportTemplatesPage from "./pages/reportTemplates";
import * as scheduleReportsPage from "./pages/schedule";
import * as accountPermissionsPage from "./pages/accountPermissions";
import * as accountRolesPage from "./pages/accountRoles";
import * as accountsPage from "./pages/accounts";

/**
 * Main app component
 */
class App extends AppComponent {
	/**
	 * List of imported resources required before app can be rendered
	 * @type {(function(): Promise<Object<string, *>>)[]}
	 */
	requiredResources = [cookieConsentResource, i18nResource, skinResource, currentUserResource];
	
	render() {
		return this.renderApp((
			<>
				<Route path={landingPageRouterPath} exact={true} component={LandingPage} />
			</>
		), (
			<>
				<Route path={authPageRouterPath} exact={true} component={AuthPage} />
				<Route path={loginPageRouterPath} exact={true} component={LoginPage} />
				<Route path={passwordResetPageRouterPath} exact={true} component={PasswordResetPage} />
				<Route path={passwordResetRequestPageRouterPath} exact={true} component={PasswordResetRequestPage} />
				
				<AppPageRoute page={homePage} exact={true} />
				<AppPageRoute page={builderPage} />
				<AppPageRoute page={reportsPage} />
				<AppPageRoute page={reportTemplatesPage} />
				<AppPageRoute page={scheduleReportsPage} />
				<AppPageRoute page={accountPermissionsPage} />
				<AppPageRoute page={accountRolesPage} />
				<AppPageRoute page={accountsPage} />
			</>
		));
	}
}

export default withRouter(App);