import styles from "./index.module.css";

import React from "react";
import PageComponent from "../../../components/PageComponent";
import {icon_font_close_symbol, icon_font_error_symbol} from "../../../../config";
import Label from "../../../components/display/Label";
import Button from "../../../components/display/Button";
import Auth from "../../../../auth";

/**
 * Default access control (ACL) error page
 */
class AclErrorPage extends PageComponent {
	constructor(props) {
		super(props, {
			layout: 'blank',
			domPrefix: 'acl-error-page',
			translationPath: 'ACL.pages.AclErrorPage',
			
			// @note This page does not have 'routerPath' because it will be used instead of another page if access to that
			// page is forbidden thus using the path of that page. 
		});
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		this.setBrowserTitle(this.t('page_title'));
		
		return this.renderLayout(
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']}`}>
				<div className="card icon-card">
					<div className={`card-header ${styles['header']}`}>
						<Label
							content={this.t('page_title')}
							icon={icon_font_error_symbol}
							iconClassName='card-header-icon error-color'
						/>
					</div>
					<div className={`card-content ${styles['content']}`}>
						{this.t('error_message')}
					</div>
					<div className="card-actions">
						<Button
							big={true}
							label={this.t('Close', 'general')}
							icon={icon_font_close_symbol}
							onClick={() => {
								if (Auth.hasTokens()) this.redirectToHome();
								else this.redirectTo('/');
							}}
						/>
					</div>
				</div>
			</div>
			, 'background-image-error'
		);
	}
}

export default AclErrorPage;