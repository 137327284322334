import {icon_font_symbol_class_prefix} from "../../../../config";

/**
 * Cards select input item object
 */
export class CardsSelectInputItemDataObject {
	/**
	 * @param {string|number} [value=''] - Item's value.
	 * @param {string } [label=''] - Item's label.
	 * @param {string} [description=''] - Item's description.
	 * @param {string} [icon=''] - Item's icon symbol.
	 * @param {string} [iconSymbolPrefix] - Item's icon symbol prefix. If not define, default symbol prefix defined as 
	 * 'icon_font_symbol_class_prefix' app config will be used.
	 */
	constructor(value = '', label = '', description = '', icon = '', iconSymbolPrefix = icon_font_symbol_class_prefix) {
		this.value = value;
		this.label = label;
		this.description = description;
		this.icon = icon;
		this.iconSymbolPrefix = iconSymbolPrefix;
	}
}