import {hideLoadingFunction, showPageLoading} from "../../helpers/loading";
import {ioJsonAction} from "../../store/actions/io";
import {auth_api, auth_api_endpoints} from "../../../config";

/**
 * Reset user's password
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} hash - Reset password hash received through URL from reset password email.
 * @param {string} password - Password typed by the user.
 * @param {string} confirmPassword - Confirm password typed by the user.
 * @return {function(*)}
 */
export const resetPasswordAction = (abortCallback, hash, password, confirmPassword) => dispatch => {
	const loading = showPageLoading();
	return ioJsonAction(
		abortCallback,
		auth_api_endpoints.password_reset?.api ? auth_api_endpoints.password_reset?.api : auth_api,
		auth_api_endpoints.password_reset.path,
		{
			resetPasswordHash: hash,
			newPassword: password,
			repeatedPassword: confirmPassword
		},
		hideLoadingFunction(loading),
		false, 
		undefined,
		auth_api_endpoints.password_reset?.method ? auth_api_endpoints.password_reset?.method : undefined
	)(dispatch);
};