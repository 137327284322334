import {get} from "lodash";
import {getBoolean, getInteger} from "../../core/helpers/data";
import {pagination_default_per_page} from "../../config";
import {getString} from "../../core/components/global/Message/reducer";
import {SORT_ORDER} from "../../core/const/global";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'reportTemplates';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@reportTemplates/reset',
	SET_REPORT_TEMPLATES_DATA: '@reportTemplates/set_report_templates_data',
	CLEAR_REPORT_TEMPLATES_DATA: '@reportTemplates/clear_report_templates_data',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	setReportTemplatesData: reportTemplatesData => ({type: REDUCER_TYPES.SET_REPORT_TEMPLATES_DATA,reportTemplatesData}),
	clearReportTemplatesData: () => ({type: REDUCER_TYPES.CLEAR_REPORT_TEMPLATES_DATA}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	// Report templates data retrieved from API (including filter, pagination, sort and other data)
	reportTemplatesData: null,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET:
			return {...initialState};

		case REDUCER_TYPES.SET_REPORT_TEMPLATES_DATA:
			return {...state, reportTemplatesData: action.reportTemplatesData};
		case REDUCER_TYPES.CLEAR_REPORT_TEMPLATES_DATA:
			return {...state, reportTemplatesData: initialState.reportTemplatesData};
			
		default:
			return state;
	}
};

// Selectors
export const selectors = {
	getReportTemplates: state => get(state, [reducerStoreKey, 'reportTemplatesData', 'data']),
	getReportTemplatesPagination: state => ({
		pageNo: getInteger(state, [reducerStoreKey, 'reportTemplatesData', 'pageNo'], 1),
		perPage: getInteger(state, [reducerStoreKey, 'reportTemplatesData', 'perPage'], pagination_default_per_page),
		isLastPage: getBoolean(state, [reducerStoreKey, 'reportTemplatesData', 'isLastPage'])
	}),
	getReportTemplatesSort: state => ({
		sortBy: getString(state, [reducerStoreKey, "reportTemplatesData", "sortBy"]),
		sortDir: getString(state, [reducerStoreKey, "reportTemplatesData", "sortDir"], SORT_ORDER.DESC)
	}),
	getReportTemplatesFilter: state => 
		get(state, [reducerStoreKey, "reportTemplatesData", "filter"], null),
};

export default reducer;