import AbstractIoError from "./AbstractIoError";

/**
 * Standard JSON request error class
 */
class StandardJsonResponseError extends AbstractIoError {
	/**
	 * Constructor
	 * @param {string} message - The status message corresponding to the status code.
	 * @param {any} response - Full response revived directly form fetch or other response processor executed before this 
	 * one.
	 * @param {string} url - Request's full url.
	 * @param {string} api - Request's API (see 'io_base_urls' io config value for available APIs).
	 * @param {string} endpoint - Request's API endpoint (see 'io_base_urls' io config value for available APIs).
	 * @param {Object} request - Request object containing at least 'method', 'headers' and 'body' properties.
	 * @param {string} responseType - Response data type (see RESPONSE_DATA_TYPE const).
	 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as
	 * an argument.
	 */
	constructor(message, response, url, api, endpoint, request, responseType, abortCallback = (abortController) => {}) {
		super(message, url, api, endpoint, request, responseType, abortCallback);
		this.name = 'StandardJsonResponseError';
		this.response = response;
	}
}

export default StandardJsonResponseError;