import styles from "./index.module.css";
import "./index.css";

import React from "react";
import PageDataComponent from "../../core/components/PageDataComponent";
import {connect} from "react-redux";
import * as pageConfig from "./config";
import * as actions from "./actions";
import {selectors} from "../../core/store/reducers";
import {getPageActions} from "../../core/helpers/redux";
import DataTable, {DATA_TABLE_CELL_TYPE} from "../../core/components/advanced/DataTable";
import {areAllObjectPropsEmpty, getArray, getNumber, getString, isset} from "../../core/helpers/data";
import * as filterDataMap from "../schedule/dataMap/filter";
import {scrollToSelector} from "../../core/helpers/dom";
import SimpleStaticSearch, {
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE,
	SIMPLE_STATIC_SEARCH_LAYOUT,
	SimpleStaticSearchOptionObject
} from "../../core/components/advanced/SimpleStaticSearch";
import {STANDARD_DATE_TIME_FORMAT} from "../../core/const/datetime";
import {DATETIME_ROUND_TO} from "../../core/components/input/DateInput/const";
import Label from "../../core/components/display/Label";
import {PAGINATION_TYPE} from "../../core/components/action/Pagination";
import {Link} from "react-router-dom";
import {SCHEDULE_INTERVAL_TYPES} from "./const";
import SchedulePopup from "./popups/schedulePopup";
import {LOCALE_DATE_FORMAT_NAME} from "../../core/const/locale";
import {getScheduleIntervalDescription} from "../../helpers/schedule";
import {REPORT_FILE_TYPE} from "../../const/report";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../core/components/display/Button";
import {Tooltip} from "react-tippy";
import ACL from "../../acl";
import {icon_font_delete_symbol, icon_font_edit_symbol} from "../../config";
import ConfirmDialog from "../../core/components/dialogs/ConfirmDialog";
import {getMenuSidebarShrankFromStorage} from "../../layout/elements/MainSidebar/helpers";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	schedule: selectors.schedule.getSchedule(state),
	schedulePagination: selectors.schedule.getSchedulePagination(state),
	scheduleSort: selectors.schedule.getScheduleSort(state),
	scheduleFilter: selectors.schedule.getScheduleFilter(state),
});

class SchedulePage extends PageDataComponent {
	constructor(props) {
		super(props, {
			data: {
				/**
				 * Currently selected search filter
				 */
				filter: {},
				/**
				 * Flag showing if filter is loading
				 */
				filterLoading: false,
			},

			/**
			 * Flag that specifies if main data table height will be limited to the available space
			 */
			limitToAvailableSpace: true
		}, {
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			disableLoad: true,
		}, 'page_title');
		
		// Data methods
		this.reloadSchedule = this.reloadSchedule.bind(this);
		this.loadSchedulePage = this.loadSchedulePage.bind(this);
		this.sortSchedule = this.sortSchedule.bind(this);
		this.filterSchedule = this.filterSchedule.bind(this);
		this.removeScheduleFilter = this.removeScheduleFilter.bind(this);
		this.isScheduleFilterEmpty = this.isScheduleFilterEmpty.bind(this);

		// Popup methods
		this.openScheduleItemPopup = this.openScheduleItemPopup.bind(this);
		this.closeScheduleItemPopup = this.closeScheduleItemPopup.bind(this);
		this.handleCloseScheduleItemPopup = this.handleCloseScheduleItemPopup.bind(this);
		
		// Action methods
		this.deleteScheduleItem = this.deleteScheduleItem.bind(this);

		// Render methods
		this.renderActions = this.renderActions.bind(this);
		this.renderPageTitle = this.renderPageTitle.bind(this);
	}

	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return 'schedule-page'; }


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {Promise<*>}
	 */
	loadPageData() {
		const {schedule, loadScheduleAction} = this.props;

		// Do not load schedule if they are already loaded
		if (isset(schedule)) {
			// Open filter if it is not empty
			if (!this.isScheduleFilterEmpty() && this.scheduleFilterRef) this.scheduleFilterRef.open();
			return this.reloadSchedule();
		}
		// Load schedule if they are not already loaded
		else {
			return this.setValue('loading', true)
				.then(() => this.executeAbortableAction(loadScheduleAction))
				.then(() => this.setValue('loading', false));
		}
	}

	/**
	 * Reload schedule using current options (page, sort, ...)
	 * @return {Promise<*>}
	 */
	reloadSchedule() {
		const {loadScheduleAction, schedulePagination, scheduleSort, scheduleFilter} = this.props;
		const {pageNo, perPage} = schedulePagination;
		const {sortBy, sortDir} = scheduleSort;
		const oFilter = filterDataMap.output(scheduleFilter);
		
		return this.executeAbortableAction(loadScheduleAction, oFilter, pageNo, perPage, sortBy, sortDir)
			.then(() => this.scheduleFilterRef?.reload());
	}
	
	/**
	 * Load schedule page
	 * @param {number} [pageNo=1] - Page number to load (starts with 1).
	 * @return {Promise<*>}
	 */
	loadSchedulePage(pageNo = 1) {
		const {loadScheduleAction, schedulePagination, scheduleSort, scheduleFilter} = this.props;
		const {perPage} = schedulePagination;
		const {sortBy, sortDir} = scheduleSort;
		const oFilter = filterDataMap.output(scheduleFilter);
		
		return this.executeAbortableAction(loadScheduleAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Sort schedule
	 * @param {string} sortBy - Name of the sort column.
	 * @param {string} sortDir - Direction of the sort.
	 * @return {Promise<*>}
	 */
	sortSchedule(sortBy, sortDir) {
		const {loadScheduleAction, schedulePagination, scheduleFilter} = this.props;
		const {pageNo, perPage} = schedulePagination;
		const oFilter = filterDataMap.output(scheduleFilter);
		
		return this.executeAbortableAction(loadScheduleAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Filter schedule
	 * @param {Object} filter - Filter object where keys are filter field names and values are filter values.
	 * @return {Promise<*>}
	 */
	filterSchedule(filter) {
		const {loadScheduleAction, schedulePagination, scheduleSort} = this.props;
		const {perPage} = schedulePagination;
		const {sortBy, sortDir} = scheduleSort;
		const oFilter = filterDataMap.output(filter);
		
		return this.setValue('filterLoading', true)
			.then(() => this.executeAbortableAction(loadScheduleAction, oFilter, 1, perPage, sortBy, sortDir))
			.then(() => this.setValue('filterLoading', false))
			.then(() => {
				if (areAllObjectPropsEmpty(filter, true, false)) {
					if (this.scheduleFilterRef) this.scheduleFilterRef.close();
				} else {
					scrollToSelector('#main-page-table', false, 80);
				}
			});
	}

	/**
	 * Remove schedule filter
	 * @return {Promise<*>}
	 */
	removeScheduleFilter() {
		return this.filterSchedule(null);
	}

	/**
	 * Check if schedule filter is applied
	 * @return {Boolean}
	 */
	isScheduleFilterEmpty() {
		return areAllObjectPropsEmpty(this.getProp('scheduleFilter'), true, false);
	}


	// Router methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called if current URL matches the 'create' sub-url of the page
	 * @note Create sub-url uses '/new' router path relative to the router path of the page (see 'options.routerPath').
	 *
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on 
	 * 'create' sub-url if such component exists.
	 */
	handleCreateUrl() {
		if (ACL.checkPermission(['AUTOMATIC_REPORT_SCHEDULE_CREATE'])) return this.openScheduleItemPopup();
		else { this.redirectToBase(); return ''; }
	}

	/**
	 * Method that will be called if current URL matches the 'item' sub-url of the page
	 * @note Item sub-url uses '/item' router path and 'id' as router path param ('/item/:id') on top of to the router
	 * path of the page (see 'options.routerPath').
	 *
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on 
	 * 'item' sub-url if such component exists.
	 */
	handleItemUrl(id) { 
		const {loadScheduleItemAction} = this.props;

		// Open item popup if it is not already opened
		// @note This is done to ensure that create dialog does not open another dialog after creating the item 
		// or to avoid opening another dialog if item ID in the URL changes programmatically. Dialog data will 
		// change because Redux store is used.
		if (!this.urlComponentGUIID) this.urlComponentGUIID = this.openScheduleItemPopup();
		
		// Try to load schedule item
		return new Promise(resolve =>
			// Timeout is added to allow for the popup open animation to finish 
			setTimeout(() => resolve(
				this.executeAbortableAction(loadScheduleItemAction, id)
					.then(responseData => {
						// If schedule item is successfully loaded
						if (responseData) {
							return this.urlComponentGUIID;
						}
						// If schedule item could not be loaded (usually if item with ID from URL does not exist)
						else {
							// Close item popup if it is opened
							if (this.urlComponentGUIID) this.closeUrlComponent();
							// Redirect to page base url (removes item ID from URL if it exists)
							this.redirectToBase();
							return '';
						}
					})
			))
		);
	}

	/**
	 * Method that will be called if current URL matches the base URL of the page
	 * 
	 * @return {string} GUI ID of the component (popup, dialog, ...) that is rendered when page is on its base URL if
	 * such component exists.
	 */
	handleBaseUrl() { this.closeScheduleItemPopup(); return ''; }

	/**
	 * Method that will be called when page component unmounts and should handle closing of any page url or sub-url
	 * component if it exists.
	 */
	closeUrlComponent() { this.closeScheduleItemPopup(); }
	

	// Popup methods ----------------------------------------------------------------------------------------------------
	/**
	 * Open schedule item popup
	 */
	openScheduleItemPopup() {
		const {openPopupAction} = this.props;
		return openPopupAction(SchedulePopup, {
			onClose: this.handleCloseScheduleItemPopup,
			redirectToItem: this.redirectToItem,
			deleteScheduleItem: this.deleteScheduleItem
		});
	}

	/**
	 * Close schedule popup
	 */
	closeScheduleItemPopup() {
		const {closePopupAction, clearScheduleItemPopupDataAction} = this.props;
		
		// Close item popup
		closePopupAction(this.getUrlComponentGUIID());
		this.clearUrlComponentGUIID();
		
		// Clear popup Redux data
		clearScheduleItemPopupDataAction();
	}

	/**
	 * Handle schedule popup 'onClose' event
	 * @return {Promise<*>}
	 */
	handleCloseScheduleItemPopup() {
		this.redirectToBase();
		
		return new Promise(resolve =>
			// Timeout is added to allow for the popup close animation to finish 
			setTimeout(() => resolve(this.reloadSchedule()))
		);
	}
	
	
	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Delete schedule item
	 * @param {Object} scheduleItemId - ID of the schedule item that will be deleted.
	 */
	deleteScheduleItem(scheduleItemId) {
		const {openDialogAction, closeDialogAction, deleteScheduleItemAction} = this.props;

		const dialogGUIID = openDialogAction('', ConfirmDialog, {
			message: this.t('confirm_delete'),
			supportHtml: true,
			onYes: () => {
				this.executeAbortableAction(deleteScheduleItemAction, scheduleItemId)
					.then(() => this.reloadSchedule())
					// Go to the previous page if there are no table rows after one has been deleted
					.then(() => {
						const schedule = getArray(this.props, 'schedule');
						const pageNo = getNumber(this.props, 'schedulePagination.pageNo', 1);
						if (schedule.length === 0 && pageNo > 1) return this.loadSchedulePage(pageNo-1);
					})
					.then(() => closeDialogAction(dialogGUIID));
			},
			onNo: () => closeDialogAction(dialogGUIID)
		}, {
			id: 'schedule-item-delete-dialog',
			closeOnEscape: true,
			closeOnClickOutside: true,
			hideCloseBtn: true,
			maxWidth: 500
		});
	}
	

	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render data table actions cell
	 * @param {Object} scheduleItem - Schedule item data object (one data table row).
	 * @return {JSX.Element}
	 */
	renderActions(scheduleItem) {
		return (
			<div className="actions">
				{
					ACL.checkPermission(['AUTOMATIC_REPORT_SCHEDULE_CREATE']) ?
						<Tooltip
							tag="div"
							title={this.t('edit_tooltip')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<Button
								className="action-btn"
								displayStyle={BUTTON_STYLE.ACTION}
								displayType={BUTTON_DISPLAY_TYPE.NONE}
								icon={icon_font_edit_symbol}
								onClick={() => this.redirectToItem(scheduleItem.id)}
							/>
						</Tooltip>
						: null
				}

				{
					ACL.checkPermission(['AUTOMATIC_REPORT_SCHEDULE_DELETE']) ?
						<Tooltip
							tag="div"
							title={this.t('delete_tooltip')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<Button
								className="action-btn"
								displayStyle={BUTTON_STYLE.ACTION}
								displayType={BUTTON_DISPLAY_TYPE.NONE}
								icon={icon_font_delete_symbol}
								onClick={() => this.deleteScheduleItem(scheduleItem.id)}
							/>
						</Tooltip>
						: null
				}
			</div>
		);
	}
	
	/**
	 * Render page title
	 * @description This method specifies how page title will be rendered if page title should be rendered. It does not
	 * determine if page title should be rendered.
	 * @return {JSX.Element}
	 */
	renderPageTitle() {
		const {title} = this.state;

		return (
			<h1 className="page-title with-actions">
				<div className="content">{title ? this.translate(title, this.titlePathPrefix) : ''}</div>
				<div className="actions">
					{
						ACL.checkPermission(['AUTOMATIC_REPORT_SCHEDULE_CREATE']) ?
							<div className="action-button">
								<Link to={this.getCreateRedirectTo()} className="button action solid big">
									<Label icon="calendar-plus-o" content={this.t('create_new')} />
								</Link>
							</div>
							: null
					}
					
					<div className="action-button">
						<Tooltip
							tag="div"
							title={this.t('Reload data', 'general')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<Button
								big={true}
								icon="refresh"
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								displayStyle={BUTTON_STYLE.SUBTLE}
								onClick={this.reloadSchedule}
							/>
						</Tooltip>
					</div>
				</div>
			</h1>
		);
	}
	
	render() {
		const {
			schedule, schedulePagination, scheduleSort, scheduleFilter, mainSidebarShrank, toggleMainSidebarSizeAction
		} = this.props;
		const {limitToAvailableSpace} = this.state;

		return (
			this.renderLayout((
				<div id={this.getDomId()} className={styles['wrapper']}>
					<div className="simple-page-description">
						<Label content={this.t('page_short_description')} supportHtml={true} />
					</div>
					
					<SimpleStaticSearch
						className="main-search"
						defaultCollapse={true}
						layout={SIMPLE_STATIC_SEARCH_LAYOUT.STACKED}
						buttonProps={{
							displayStyle: BUTTON_STYLE.DEFAULT
						}}
						options={[
							new SimpleStaticSearchOptionObject(
								'name',
								this.t('Name')
							),
							new SimpleStaticSearchOptionObject(
								'scheduleIntervalType', 
								this.t('Interval type'),
								SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
								{
									isClearable: true,
									options: SCHEDULE_INTERVAL_TYPES.map(intervalType => ({
										value: intervalType, 
										label: this.t(intervalType, 'constants.schedule.interval_type')
									})),
								}
							),
							new SimpleStaticSearchOptionObject(
								'dateFrom',
								this.t('Date from'),
								SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
								{
									valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
									roundTo: DATETIME_ROUND_TO.START
								}
							),
							new SimpleStaticSearchOptionObject(
								'dateTo',
								this.t('Date to'),
								SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
								{
									valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
									roundTo: DATETIME_ROUND_TO.END
								}
							),
						]}
						value={scheduleFilter}
						title={(<Label icon="search" content={this.t('Search', 'general')} />)}
						applied={!this.isScheduleFilterEmpty()}
						enableToolbar={true}
						enableResetButton={false}
						onChange={this.filterSchedule}
						onRemove={this.removeScheduleFilter}
						onToggle={visible => this.setState({limitToAvailableSpace: visible})}
						ref={node => { this.scheduleFilterRef = node; }}
					/>

					<DataTable
						id="main-page-table"
						limitToAvailableSpace={limitToAvailableSpace && !this.getProp('isMobileBreakpoint')}
						highlightOnHover={true}
						columns={[
							{
								name: 'dateFrom',
								label: this.t('Date from'),
								dataType: DATA_TABLE_CELL_TYPE.DATE,
								dataTypeOptions: {
									inputFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
									outputFormat: LOCALE_DATE_FORMAT_NAME.SHORT,
								},
								width: 1,
							},
							{
								name: 'dateTo',
								label: this.t('Date to'),
								dataType: DATA_TABLE_CELL_TYPE.DATE,
								dataTypeOptions: {
									inputFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
									outputFormat: LOCALE_DATE_FORMAT_NAME.SHORT,
								},
								width: 1,
							},
							{
								name: 'scheduleIntervalType',
								label: this.t('Interval type'),
								dataTypeOptions: {
									translatePath: 'constants.schedule.interval_type'
								},
								width: 1,
							},
							{
								label: this.t('Interval'),
								dataType: DATA_TABLE_CELL_TYPE.TEMPLATE,
								dataTypeOptions: {
									template: row => getScheduleIntervalDescription(
										row.scheduleIntervalType,
										row.scheduleDays,
										row.reportGenerationTime
									)
								},
								minWidth: 250,
							},
							{
								name: 'reportTemplateDataList',
								label: this.t('Report templates'),
								dataType: DATA_TABLE_CELL_TYPE.ANY,
								dataTypeOptions: {
									content: row => (
										<div className={styles['reportTemplatesCell']}>
											{getArray(row, 'reportTemplateDataList').map(t => {
												const fileType = getString(t, 'fileName').split('.').pop().toUpperCase();
												return (
													<Label
														key={t.id}
														element="span"
														iconSymbolPrefix="icofont-"
														icon={(fileType === REPORT_FILE_TYPE.CSV ? 'file-text' : 'file-excel')}
														iconClassName={styles['reportTemplatesCellItemIcon']}
														tooltip={fileType}
														tooltipOptions={{
															className: `${styles['reportTemplatesCellItem']} tag`
														}}
														content={getString(t, 'fileName')}
														style={{cursor: 'help'}}
													/>
												);
											})}
										</div>
									),
								}
							},
							{
								name: 'name',
								label: this.t('Name'),
								minWidth: 200,
							},
							{
								dataType: DATA_TABLE_CELL_TYPE.ANY,
								dataTypeOptions: {
									content: this.renderActions,
									standardWrapper: false
								},
								width: 1,
								stopPropagation: true,
							},
						]}
						onRowClick={data => this.redirectToItem(data.id)}
						data={schedule}
						paginationType={PAGINATION_TYPE.STATIC}
						onSortByColumn={this.sortSchedule}
						onPaginationClick={this.loadSchedulePage}
						{...schedulePagination}
						{...scheduleSort}
					/>
				</div>
			), undefined, undefined, {
				mainSidebarShrank,
				toggleMainSidebarSizeAction,
			})
		);
	}
}

export * from "./config";
export default connect(mapStateToProps, getPageActions(actions))(SchedulePage);