import {get} from "lodash";
import {getBoolean, getInteger} from "../../../../core/helpers/data";
import {pagination_default_per_page} from "../../../../config";
import {getString} from "../../../../core/components/global/Message/reducer";
import {SORT_ORDER} from "../../../../core/const/global";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'scheduledReports';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@scheduledReports/reset',
	SET_REPORTS_DATA: '@scheduledReports/set_reports_data',
	CLEAR_REPORTS_DATA: '@scheduledReports/clear_reports_data',
	SET_REPORTS: '@scheduledReports/set_reports',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	setReportsData: reportsData => ({type: REDUCER_TYPES.SET_REPORTS_DATA, reportsData}),
	clearReportsData: () => ({type: REDUCER_TYPES.CLEAR_REPORTS_DATA}),
	/**
	 * Set only reports list and don't change pagination, sort and filter
	 * @param reports
	 * @return {{reports, type: string}}
	 */
	setReports: reports => ({type: REDUCER_TYPES.SET_REPORTS, reports}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	// Reports data retrieved from API (including filter, pagination, sort and other data)
	reportsData: null,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET:
			return {...initialState};

		case REDUCER_TYPES.SET_REPORTS_DATA:
			return {...state, reportsData: action.reportsData};

		case REDUCER_TYPES.CLEAR_REPORTS_DATA:
			return {...state, reportsData: initialState.reportsData};

		case REDUCER_TYPES.SET_REPORTS:
			if (state.reportsData) return {...state, reportsData: {...state.reportsData, data: action.reports}};
			else return state;

		default:
			return state;
	}
};

// Selectors
export const selectors = {
	getReports: state => get(state, [reducerStoreKey, 'reportsData', 'data']),
	getReportsPagination: state => ({
		pageNo: getInteger(state, [reducerStoreKey, 'reportsData', 'pageNo'], 1),
		perPage: getInteger(state, [reducerStoreKey, 'reportsData', 'perPage'], pagination_default_per_page),
		isLastPage: getBoolean(state, [reducerStoreKey, 'reportsData', 'isLastPage'])
	}),
	getReportsSort: state => ({
		sortBy: getString(state, [reducerStoreKey, "reportsData", "sortBy"]),
		sortDir: getString(state, [reducerStoreKey, "reportsData", "sortDir"], SORT_ORDER.DESC)
	}),
	getReportsFilter: state => get(state, [reducerStoreKey, "reportsData", "filter"], null),
};

export default reducer;