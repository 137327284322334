import styles from "./index.module.css";
import "./index.css";

import React from "react";
import DialogDataComponent from "../../../../../../../core/components/DialogDataComponent";
import PropTypes from "prop-types";
import {omit, cloneDeep, set} from "lodash";
import * as pageConfig from "../../../../../config";
import FormWrapper, {FormField} from "../../../../../../../core/components/advanced/FormWrapper";
import TextInput from "../../../../../../../core/components/input/TextInput";
import ToggleInput from "../../../../../../../core/components/input/ToggleInput";
import {getArray, getBoolean, getString} from "../../../../../../../core/helpers/data";
import Separator from "../../../../../../../core/components/display/Separator";
import SelectInput from "../../../../../../../core/components/input/SelectInput";
import FormulaEditor from "../../../../../../../components/advanced/FormulaEditor";
import {DYNAMIC_VALUE_AGGREGATIONS} from "../../../../../../../components/advanced/DynamicValues";


class FormulaDialog extends DialogDataComponent {
	/**
	 * Formula editor components ref
	 */
	formulaEditorRef = null;
	
	constructor(props) {
		super(props, {
			/** @type {ReportColumnDataObject} */
			data: null,
		}, {
			domPrefix: 'select-column-formula-dialog-component',
			translationPath: `${pageConfig.translationPath}.select_section.item.formula_dialog`,
		});
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Get data to load into local component's state
	 * @description Create and return data that can be loaded directly into local component's state based on the raw
	 * external data (usually sent through props). In some sense this is a method that maps external data into format
	 * that component can use in its local state. This method should return data in the same format as 'getData' method.
	 * @note This method will not mutate the passed data.
	 *
	 * @param {any} rawData - External data that will be used to create local component's state compatible data.
	 * @return {any|null} Local component's state compatible data or null if data could not be loaded.
	 */
	getDataToLoad(rawData) {
		let dataToLoad = cloneDeep(rawData);
		
		// Set group heading to group name if 'groupHeading' is empty
		if (!getString(dataToLoad, 'groupHeading')) {
			set(dataToLoad, 'groupHeading', getString(dataToLoad, 'dynamicValue.group.name'));
		}
		// Set column heading to column name if 'columnHeading' is empty
		if (!getString(dataToLoad, 'columnHeading')) {
			set(dataToLoad, 'columnHeading', getString(dataToLoad, 'dynamicValue.name'));
		}
		
		return dataToLoad;
	}

	/**
	 * Get data that will be returned to the parent component
	 * @description Create and return data that will be returned to paren component (usually through onChange event)
	 * based on the local component's raw state data
	 * @note This method will not mutate the passed data.
	 *
	 * @return {any} Data that will be returned to the parent component.
	 */
	getDataToReturn() {
		let dataToReturn;
		
		// Filter out properties added by ReactSortable (react-sortablejs) component.
		dataToReturn = omit(this.getData(), ['chosen', 'selected', 'filtered']);

		// Set group heading to group name if 'groupHeading' is empty
		if (!getString(dataToReturn, 'groupHeading')) {
			set(dataToReturn, 'groupHeading', getString(dataToReturn, 'dynamicValue.group.name'));
		}
		// Set column heading to column name if 'columnHeading' is empty
		if (!getString(dataToReturn, 'columnHeading')) {
			set(dataToReturn, 'columnHeading', getString(dataToReturn, 'dynamicValue.name'));
		}
		
		// Get formula from formula editor component ref
		if (this.formulaEditorRef) set(dataToReturn, 'formula', this.formulaEditorRef.getDataToReturn());
		
		return dataToReturn;
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that should return true if component can be rendered or false otherwise
	 * @return {boolean} True if component can be rendered or false otherwise.
	 */
	canRender() { return (this.getData() !== null) }

	render() {
		if (!this.canRender()) return null;
		
		return this.renderDialog(
			this.renderTitle(this.t('title')), (
				<FormWrapper className={`${styles['wrapper']}`}>
					<FormField
						label={this.t('Custom headings')}
					>
						<ToggleInput
							name="customHeadings"
							checked={this.getValue('customHeadings')}
							onChange={this.handleInputChange}
						/>
					</FormField>
					
					<FormField
						label={this.t('Group heading')}
					>
						<TextInput 
							name="groupHeading"
							value={
								this.getValue('groupHeading') ? 
									this.getValue('groupHeading') : 
									this.getValue('dynamicValue.group.name')
							}
							onChange={this.handleInputChange}
							disabled={!getBoolean(this.getValue('customHeadings'))}
							placeholder={this.getValue('dynamicValue.group.name')}
						/>
					</FormField>

					<FormField
						label={this.t('Column heading')}
					>
						<TextInput
							name="columnHeading"
							value={
								this.getValue('columnHeading') ?
									this.getValue('columnHeading') :
									this.getValue('dynamicValue.name')
							}
							onChange={this.handleInputChange}
							disabled={!getBoolean(this.getValue('customHeadings'))}
							placeholder={this.getValue('dynamicValue.name')}
						/>
					</FormField>
					
					<FormField
						label={this.t('Contains HTML markup')}
					>
						<ToggleInput
							name="htmlAllowedInHeadings"
							checked={this.getValue('htmlAllowedInHeadings')}
							onChange={this.handleInputChange}
							disabled={!getBoolean(this.getValue('customHeadings'))}
						/>
					</FormField>
					
					<FormField
						label={this.t('Aggregation function')}
					>
						<SelectInput
							simpleValue={true}
							isClearable={true}
							value={this.getValue('aggregateFunction')}
							options={
								DYNAMIC_VALUE_AGGREGATIONS.map(a => ({
									label: this.tt(a, 'aggregation_functions'),
									value: a
								}))
							}
							onChange={v => this.handleValueChange('aggregateFunction', v)}
						/>
					</FormField>
					
					<Separator content={this.t('Formula editor')} />
					<FormulaEditor
						className={styles['formulaEditor']}
						data={getArray(this.getValue('formula'))}
						ref={node => {this.formulaEditorRef = node}}
					/>
				</FormWrapper>
			)
		)
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
FormulaDialog.propTypes = {
	...DialogDataComponent.propTypes,

	// Main dialog data
	// @type {ReportColumnDataObject}
	data: PropTypes.object,
};

export default FormulaDialog;