import {ioJsonFindAction} from "../../../../core/store/actions/io";
import {getArray} from "../../../../core/helpers/data";
import {translate} from "../../../../core/i18n";

export const searchReportForInsertAction = (abortCallback, query, filter = null) => dispatch => {
	return ioJsonFindAction(
		abortCallback,
		'defaultAuthorizedApi',
		'report-and-template/search',
		query,
		undefined,
		undefined,
		undefined,
		{filter}
	)(dispatch)
		// Get array of type groups
		.then(response => getArray(response, 'data'))
		// Translate item labels and add "__GROUP__" property to each item
		.then(data => data.map(g => ({
			type: translate(g.type, 'InsertValueDialogReportSection.group'),
			options: g.options.map(o => ({...o, __GROUP__: g.type}))
		})));
}