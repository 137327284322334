/**
 * This file contains all global app constants
 */

// Skin modes
/** @typedef {string} SkinMode */
export const SKIN_MODE_LIGHT = 'skin-light';
export const SKIN_MODE_DARK = 'skin-dark';
/** @enum {SkinMode} */
export const SKIN_MODE = {
	LIGHT: SKIN_MODE_LIGHT,
	DARK: SKIN_MODE_DARK
};
/** @type {SkinMode[]} */
export const SKIN_MODES = [
	SKIN_MODE_LIGHT,
	SKIN_MODE_DARK
];

// GUI status
export const GUI_STATUS_PRISTINE = 'pristine';
export const GUI_STATUS_NEW = 'new';
export const GUI_STATUS_CHANGED = 'changed';
export const GUI_STATUS_DELETED = 'deleted';
export const GUI_STATUS = {
	PRISTINE: GUI_STATUS_PRISTINE,
	NEW: GUI_STATUS_NEW,
	CHANGED: GUI_STATUS_CHANGED,
	DELETED: GUI_STATUS_DELETED	
}
export const GUI_STATUSES = [GUI_STATUS_PRISTINE, GUI_STATUS_NEW, GUI_STATUS_CHANGED, GUI_STATUS_DELETED];

/**
 * Sort orders
 * @typedef {string} SortOrder
 */
export const SORT_ORDER_ASC = 'ASC';
export const SORT_ORDER_DESC = 'DESC';
/** @enum {SortOrder} */
export const SORT_ORDER = {
	ASC: SORT_ORDER_ASC,
	DESC: SORT_ORDER_DESC
};
/** @type {SortOrder[]} */
export const SORT_ORDERS = [SORT_ORDER_ASC, SORT_ORDER_DESC];

// Logical operators
/**
 * @typedef {string} LogicalOperator
 */
export const LOGICAL_OPERATOR_AND = 'AND';
export const LOGICAL_OPERATOR_OR = 'OR';
/**
 * @enum {LogicalOperator}
 */
export const LOGICAL_OPERATOR = {
	AND: LOGICAL_OPERATOR_AND,
	OR: LOGICAL_OPERATOR_OR
};
export const LOGICAL_OPERATORS = [LOGICAL_OPERATOR_AND, LOGICAL_OPERATOR_OR];

// Comparators
/**
 * @typedef {string} ComparatorSymbol
 */
export const COMPARATOR_EQ = 'EQ';
export const COMPARATOR_NEQ = 'NEQ';
export const COMPARATOR_GT = 'GT';
export const COMPARATOR_GTE = 'GTE';
export const COMPARATOR_LT = 'LT';
export const COMPARATOR_LTE = 'LTE';
export const COMPARATOR_LIKE = 'LIKE';
export const COMPARATOR_IN = 'LIKE';
export const COMPARATOR_NULL = 'NULL';
export const COMPARATOR_NOT_NULL = 'NOT_NULL';
/**
 * @enum {ComparatorSymbol}
 */
export const COMPARATOR = {
	EQ: COMPARATOR_EQ,
	NEQ: COMPARATOR_NEQ,
	GT: COMPARATOR_GT,
	GTE: COMPARATOR_GTE,
	LT: COMPARATOR_LT,
	LTE: COMPARATOR_LTE,
	LIKE: COMPARATOR_LIKE,
	IN: COMPARATOR_IN,
	NULL: COMPARATOR_NULL,
	NOT_NULL: COMPARATOR_NOT_NULL
};
export const COMPARATORS = [
	COMPARATOR_EQ, COMPARATOR_NEQ, COMPARATOR_GT, COMPARATOR_GTE, COMPARATOR_LT, COMPARATOR_LTE, COMPARATOR_LIKE, 
	COMPARATOR_IN, COMPARATOR_NULL, COMPARATOR_NOT_NULL
];

// Flag display types
export const FLAG_SHAPE_DEFAULT = 'DEFAULT';
export const FLAG_SHAPE_SQUARE = 'SQUARE';
export const FLAG_SHAPE_CIRCLE = 'CIRCLE';
export const FLAG_SHAPE_ELLIPSE = 'ELLIPSE';
export const FLAG_SHAPE = {
	DEFAULT: FLAG_SHAPE_DEFAULT,
	SQUARE: FLAG_SHAPE_SQUARE,
	CIRCLE: FLAG_SHAPE_CIRCLE,
	ELLIPSE: FLAG_SHAPE_ELLIPSE
};

// Event command codes
export const COMMAND_CODES = [
	'Backspace', 'Tab', 'Enter', 'ShiftLeft', 'ShiftRight', 'ControlLeft', 'ControlRight', 'AltLeft', 'AltRight', 
	'Pause', 'CapsLock', 'Escape', 'Space', 'PageUp', 'PageDown', 'End', 'Home', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 
	'ArrowDown', 'PrintScreen', 'Insert', 'Delete', 'MetaLeft', 'MetaRight', 'ContextMenu', 'F1', 'F2', 'F3', 'F4', 'F5',
	'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12', 'NumLock', 'ScrollLock', 'NumpadEnter'
];

// CSS units
export const CSS_UNITS = ['px', 'ch', 'em', 'ex', 'rem', 'vh', 'vw', 'vmin', 'vmax'];