import React from "react";
import BaseComponent, {executeComponentCallbackPromise} from "../../BaseComponent";
import PropTypes from "prop-types";
import {
	find, get, has, forOwn, isString, isEmpty, clone, cloneDeep, difference, map, isFunction, reject, isObject, 
	isPlainObject
} from "lodash";
import {icon_font_stack_class, icon_font_stacked_item_class, pagination_default_per_page} from "../../../../config";
import {SORT_ORDER} from "../../../const/global";
import {DATA_TABLE_CELL_TYPE} from "./const";
import {getArray, getBoolean, getNumber, getString, isset} from "../../../helpers/data";
import {safeCssName, trimChar, cssStyleStringToObject} from "../../../helpers/string";
import {getElementAbsolutePos, horScrollbarVisible, vertScrollbarVisible} from "../../../helpers/dom";

import NumberLabel from "../../display/NumberLabel";
import DateLabel from "../../display/DateLabel";
import Label, {LABEL_ICON_POSITION} from "../../display/Label";
import Pagination, {PAGINATION_TYPE, PAGINATION_TYPES, PaginationStatic} from "../../action/Pagination";
import Icon from "../../display/Icon";
import Html from "../../display/Html";
import CheckboxInput from "../../input/CheckboxInput";

import styles from "./index.module.css";
import {Tooltip} from "react-tippy";

class DataTable extends BaseComponent {
	constructor(props) {
		super(props, { 
			translationPath: 'DataTable',
			domManipulationIntervalTimeout: 50,
		});

		// Initialize initial state
		this.initialState = {
			/**
			 * Selected data table rows
			 * @type {Object[]}
			 */
			selectedRows: [],
		}

		// Set initial component's internal state
		this.state = cloneDeep(this.initialState);
		
		// Action handle methods
		this.handleSortClick = this.handleSortClick.bind(this);
		this.handleRowClick = this.handleRowClick.bind(this);
		this.handlePaginationClick = this.handlePaginationClick.bind(this);

		// Select methods
		this.clearSelection = this.clearSelection.bind(this);
		this.isRowSelected = this.isRowSelected.bind(this);
		this.areAllRowsOnPageSelected = this.areAllRowsOnPageSelected.bind(this);
		this.handleRowSelect = this.handleRowSelect.bind(this);
		this.handlePageSelect = this.handlePageSelect.bind(this);
		this.updateSelection = this.updateSelection.bind(this);
		this.addToSelection = this.addToSelection.bind(this);
		this.removeFromSelection = this.removeFromSelection.bind(this);
		
		// Render methods
		this.isSelectable = this.isSelectable.bind(this);
		this.getRowHighlightClassName = this.getRowHighlightClassName.bind(this);
		this.getRowHighlightStyle = this.getRowHighlightStyle.bind(this);
		this.getColSpan = this.getColSpan.bind(this);
		this.renderCell = this.renderCell.bind(this);
		this.renderColumns = this.renderColumns.bind(this);
		this.renderRows = this.renderRows.bind(this);
		this.hasPagination = this.hasPagination.bind(this);
		this.renderPagination = this.renderPagination.bind(this);
		this.hasItems = this.hasItems.bind(this);
	}

	/**
	 * Get component's main DOM element
	 * @note Component's main DOM element is usually the wrapper <div> or other wrapper DOM element.
	 * @return {HTMLElement}
	 */
	getDomElement() { return document.getElementById(this.getProp('id', `data-table-${this.getId()}`)); }

	/**
	 * Method called on each DOM manipulation interval if component has a defined DOM element (see 'getDomElement'
	 * method).
	 * @param {HTMLElement|Element|null} element - Component's main DOM element or null if component's main DOM element is
	 * not set.
	 */
	domManipulations(element) {
		if (element) {
			// Handle clear selection row top position
			const clearSelectionElement = element.querySelector('.clear-selection');
			if (clearSelectionElement) {
				clearSelectionElement.childNodes[0].style.top = `${clearSelectionElement.previousSibling.offsetHeight}px`;
			}

			// Handle data table height (see 'limitToAvailableSpace' prop)
			if (this.getProp('limitToAvailableSpace') === true) {
				let maxHeight;
				
				// If table is located inside a popup tab component and there are global action buttons at the bottom
				const popupTabContent = element.closest('.popup-tab-content');
				if (popupTabContent) {
					maxHeight =
						popupTabContent.offsetHeight - getElementAbsolutePos(element, popupTabContent).top -
						getNumber(window.getComputedStyle(popupTabContent, ':after').height) -
						getNumber(window.getComputedStyle(popupTabContent).paddingBottom)
				} else {
					let layoutPageElem = document.querySelector('.layout-page');
					if (!layoutPageElem) layoutPageElem = document.querySelector('.layout-content');
					const layoutFooter = document.querySelector('.layout-footer');
					maxHeight =
						window.innerHeight - getElementAbsolutePos(element).top -
						(layoutPageElem ? getNumber(window.getComputedStyle(layoutPageElem, ':after').height) : 0) -
						(layoutPageElem ? getNumber(window.getComputedStyle(layoutPageElem).paddingBottom) : 0) -
						(layoutFooter ? getNumber(window.getComputedStyle(layoutFooter).height) : 0)
					;
				}
				
				element.style.maxHeight = `${maxHeight}px`;
			} else {
				element.style.maxHeight = '';
			}

			// Handle scrollbar visibility CSS class
			if (vertScrollbarVisible(element)) element.classList.add('has-vert-scrollbar', styles['v-scroll']);
			else element.classList.remove('has-vert-scrollbar', styles['v-scroll']);
			if (horScrollbarVisible(element)) element.classList.add('has-hor-scrollbar', styles['h-scroll']);
			else element.classList.remove('has-hor-scrollbar', styles['h-scroll']);
		}
	}

	// Action handle methods --------------------------------------------------------------------------------------------
	/**
	 * Sort column click handle method
	 * @note Data is sorted by calling an external sort function defined in component props as "onSortByColumn" if it 
	 * exists.
	 *
	 * @param {string} column - Column name to sort data by.
	 * @return {Promise<any>} Promise that resolves to the result of the executed event callback function or null if
	 * function does not exist or no data if sort event callback function was not celled at all.
	 */
	handleSortClick(column) {
		// TODO: Reset sort after third click
		const { sortBy, sortDir, totalRows, paginationType } = this.props;

		if(paginationType === PAGINATION_TYPE.STATIC || totalRows > 0){
			// If sorting by another column or sorting for the first time, use ascending order because it's the default 
			// direction
			let newSortDir = SORT_ORDER.ASC;
			
			// If same column is sorted again, just flip the direction
			if(sortBy === column) newSortDir = (sortDir === SORT_ORDER.ASC) ? SORT_ORDER.DESC : SORT_ORDER.ASC;

			// Trigger 'onSortByColumn' event
			return executeComponentCallbackPromise(this.props.onSortByColumn, column, newSortDir);
		}
		return Promise.resolve();
	}

	/**
	 * Row click handle method
	 * @note This method calls an external function defined in props as "onRowClick" if it exists.
	 *
	 * @param {Object} row - Clicked row.
	 * @param {number} index - Index of the clicked row in table.
	 * @return {Promise<any>} Promise that resolves to the result of the executed event callback function or null if 
	 * function does not exist.
	 */
	handleRowClick(row, index) { return executeComponentCallbackPromise(this.props.onRowClick, row, index); }

	/**
	 * Pagination button click handle method
	 * @param {number} pageNo - Page number of the clicked pagination button.
	 * @return {Promise<any>} Promise that resolves to the result of the executed event callback function or null if
	 * function does not exist.
	 */
	handlePaginationClick(pageNo) { return executeComponentCallbackPromise(this.props.onPaginationClick, pageNo); }


	// Select methods ---------------------------------------------------------------------------------------------------
	/**
	 * Clear row selection on all pages
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	clearSelection() { 
		return this.setState({selectedRows: []})
			.then(state => executeComponentCallbackPromise(this.props.onSelect, state.selectedRows));
	}

	/**
	 * Check if row is selected
	 * @param {Object} row - Row data object.
	 * @return {boolean}
	 */
	isRowSelected(row) { 
		const {primaryKeyColumn} = this.props;
		const {selectedRows} = this.state;
		return selectedRows.map(i => get(i, primaryKeyColumn)).includes(get(row, primaryKeyColumn));
	}

	/**
	 * Check if all rows on the current page are selected
	 * @return {boolean} True if all rows on the current page are selected, false otherwise.
	 */
	areAllRowsOnPageSelected() {
		const {primaryKeyColumn} = this.props;
		const {selectedRows} = this.state;
		const selectedRowIds = selectedRows.map(i => get(i, primaryKeyColumn));
		const pageRowIds = map(getArray(this.props, 'data'), primaryKeyColumn);
		return (pageRowIds.length > 0 && difference(pageRowIds, selectedRowIds).length === 0);
	}

	/**
	 * Handle row select checkbox change
	 * 
	 * @param {boolean} checked - CheckboxInput component check value after click.
	 * @param {Object} row - Row data object. 
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	handleRowSelect(checked, row) {
		return this.setState((state, props) => {
			const {primaryKeyColumn} = props;
			if (checked) return { selectedRows: [...state.selectedRows, row] };
			else return { selectedRows: reject(state.selectedRows, {[primaryKeyColumn]: get(row, primaryKeyColumn)}) };
		}).then(state => executeComponentCallbackPromise(this.props.onSelect, state.selectedRows));
	}

	/**
	 * Handle page select checkbox change
	 * 
	 * @param {boolean} checked - CheckboxInput component check value after click.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	handlePageSelect(checked) {
		return this.setState((state, props) => {
			const {primaryKeyColumn, data} = props;
			const {selectedRows} = state;
			const pageRows = getArray(data);
			let updatedSelectedRows = cloneDeep(selectedRows);
			
			// Add all page rows to selected list
			if (checked) {
				pageRows.forEach(pageRow => {
					if (!find(selectedRows, {[primaryKeyColumn]: get(pageRow, primaryKeyColumn)})) {
						updatedSelectedRows = [...updatedSelectedRows, pageRow];
					}
				});
			}
			// Remove all page rows from selected list
			else {
				pageRows.forEach(pageRow => {
					if (find(selectedRows, {[primaryKeyColumn]: get(pageRow, primaryKeyColumn)})) {
						updatedSelectedRows = reject(updatedSelectedRows, {
							[primaryKeyColumn]: get(pageRow, primaryKeyColumn)
						});
					}
				});
			}
			
			return {selectedRows: updatedSelectedRows};
		}).then(state => executeComponentCallbackPromise(this.props.onSelect, state.selectedRows));
	}

	/**
	 * Update selected rows list
	 * 
	 * @param {Object[]} selectedRows - Selected rows.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	updateSelection(selectedRows) { return this.setState({selectedRows}); }

	/**
	 * Add row to selected rows list
	 * 
	 * @param {Object} row - Row to add to selection.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	addToSelection(row) {
		return this.setState({selectedRows: [...this.state.selectedRows, row]});
	}

	/**
	 * Remove row from selected rows list
	 * 
	 * @param {Object|string|number} row - Row object or primary key column value of the row to remove from selection.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	removeFromSelection(row) {
		const {primaryKeyColumn} = this.props;
		const rowPrimaryKey = (isPlainObject(row) ? get(row, primaryKeyColumn) : row);
		
		return this.setState({
			selectedRows: reject(this.state.selectedRows, {[primaryKeyColumn]: rowPrimaryKey})
		});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Check if data table rows are selectable
	 * @return {boolean}
	 */
	isSelectable() { return this.props.selectable; }
	
	/**
	 * Get a row highlight CSS class name for rows that should be highlighted
	 * @note This function should be called for each main data row.
	 *
	 * @param {Object} row - Main data row to get the highlight CSS class for.
	 * @param {{className: string, style: Object|string, rows: Object[]}[]} highlightsArray - Row highlight data.
	 * @return {string} Highlight CSS class name for the specified row.
	 */
	getRowHighlightClassName(row, highlightsArray) {
		let result = '';
		if(Array.isArray(highlightsArray) && highlightsArray.length > 0){
			highlightsArray.forEach(item => {
				const isHighlightRowAnObject = isObject(get(item, 'rows[0]'));
				if ((
					isHighlightRowAnObject &&
					(isEmpty(result) && find(item.rows, row))
				) || (
					!isHighlightRowAnObject &&
					(isEmpty(result) && item.rows.includes(get(row, this.props.primaryKeyColumn)))
				))
				{
					result = get(item, 'className', '');
				}
			});
		}
		return result;
	}

	/**
	 * Get a row highlight CSS style for rows that should be highlighted
	 * @note This function should be called for each main data row.
	 *
	 * @param {Object} row - Main data row to get the highlight CSS style for.
	 * @param {{className: string, style: Object|string, rows: Object[]}[]} highlightsArray - Row highlight data.
	 * @return {Object} Highlight CSS style JSX object for the specified row.
	 */
	getRowHighlightStyle(row, highlightsArray) {
		let result = {};
		if(Array.isArray(highlightsArray) && highlightsArray.length > 0){
			highlightsArray.forEach(item => {
				const isHighlightRowAnObject = isObject(get(item, 'rows[0]'));
				if ((
						isHighlightRowAnObject && 
						(isEmpty(result) && find(item.rows, row))
					) || (
						!isHighlightRowAnObject && 
						(isEmpty(result) && item.rows.includes(get(row, this.props.primaryKeyColumn)))
					)) 
				{
					let style = get(item, 'style');
					if (isString(style)) style = cssStyleStringToObject(style);
					if (!isEmpty(style)) result = clone(style);
				}
			});
		}
		return result;
	}

	/**
	 * Get the colSpan value that can be used to span the whole table columns
	 * @return {number}
	 */
	getColSpan() {
		return (
			reject( getArray(this.props, 'columns'), {hide: true}).length + 
			(getBoolean(this.props, 'showRowNumber') ? 1 : 0) +
			(this.isSelectable() ? 1 : 0)
		);
	}

	/**
	 * Render table cell value based on specified type and type options
	 * Supported types:
	 * 	- text: This will render a trimmed string value.
	 * 		- typeOptions:
	 * 		   {string} translatePath - If set, string value will be translated using this translation path.
	 * 		   {string} trimChar - Custom character to use for trimming the rendered value instead of the default trim.
	 * 			{boolean} supportHtml - If true, rendered value will support HTML. WARNING: Be careful when using this
	 * 			flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' to allow HTML content.
	 * 			{string} alignContent - If set, cell content will be aligned using this value. Can be any CSS text-align.
	 * 			{string} whiteSpace - CSS white-space.
	 * 		   
	 * 	- number: This will render a number with optional formatting rules based on NumberLabel component.
	 * 		- typeOptions: 
	 * 			@see NumberLabel component for type options.
	 * 			{string} alignContent - If set, cell content will be aligned using this value. Can be any CSS text-align.
	 * 			{string} whiteSpace - CSS white-space.	
	 * 	
	 * 	- date: This will render a number with optional formatting rules based on DateLabel component. Format of the 
	 * 	string is based on Unicode Technical Standard #35 with a few additions (date-fns library):
	 * 	https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
	 * 		- typeOptions: 
	 * 			@see DateLabel component for type options.
	 * 			{string} alignContent - If set, cell content will be aligned using this value. Can be any CSS text-align.
	 * 			{string} whiteSpace - CSS white-space.	
	 * 			  
	 * 	- bool: This will render true or false value. Default values are loaded form translation file ("general.Yes" 
	 * 	and "general.No").
	 * 		- typeOptions:
	 * 			{string} translatePath - If set, value will be translated using this translation path.
	 * 			{string} trueLabel - If set, this will be the label rendered as true value instead of the default one.
	 * 			{string} falseLabel - If set, this will be the label rendered as false value instead of the default one.
	 * 			{boolean} supportHtml - If true, rendered value will support HTML. WARNING: Be careful when using this
	 * 			flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' to allow HTML content.
	 * 			{string} alignContent - If set, cell content will be aligned using this value. Can be any CSS text-align.
	 * 			{string} whiteSpace - CSS white-space.	
	 * 			  
	 * 	- bool-inverted: This will render bool with inverted values.
	 * 	
	 * 	- action: This will render a clickable text label, icon or both.
	 * 		- typeOptions:	
	 * 			{string} label - Label to render.
	 * 			{string} icon - Icon symbol name.
	 * 			{Function<Object, Event>} onClick - Function to trigger on click. Function will receive row data object 
	 * 			and the click Event.
	 * 			{string} tooltip - Tooltip text for rendered content.
	 * 			{boolean} supportHtml - If true, rendered value will support HTML. WARNING: Be careful when using this
	 * 			flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' to allow HTML content.	
	 * 			@note 'label' or 'icon' or both must be set in order for cell content to be rendered.
	 * 			{Function} condition	- Condition function to determine if action button will be rendered. It receives the
	 * 			whole row data object.
	 * 			{string} alignContent - If set, cell action content will be aligned using this value. Can be any CSS 
	 * 			text-align.
	 * 			{string} whiteSpace - CSS white-space for the action content.	
 	 * 	- actions: This is a list of actions
	 * 		- typeOptions: 
	 * 			{Array} actions - Array of action typeOptions.
	 * 				 
	 * 	- template: This will render a string containing any number of row fields (columns) where fields are 
	 * 	specified like {$FIELD_NAME|[params]} where 'params' is a list of space-separated modifiers that can be applied
	 * 	to the field value.
	 * 		- typeOptions:
	 * 			{boolean} supportHtml - If true, rendered value will support HTML. WARNING: Be careful when using this
	 * 			flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' to allow HTML content.
	 * 			{string} alignContent - If set, cell content will be aligned using this value. Can be any CSS text-align.
	 * 			{string} whiteSpace - CSS white-space.
	 * 			{boolean} nullAsEmpty - Flag that determines if null values will be rendered as empty strings.	
	 * 			{string|Function} template - Template used to render the cell containing one or more row fields and any 
	 * 			other string. If function is passed it will be called with the row data and should return a string that 
	 * 			will be rendered in the cell.
	 * 		- Available field value modifier params:
	 * 				- l: lowercase (example: {$name|l})
	 * 			  	- u: uppercase (example: {$name|u})
	 * 			  	- t: trim (example: {$name|t})
	 * 			  Examples:
	 * 			  	- Simple replacement (no params): {$name}
	 * 			  	- Replace with lowercase and trim: {$name|l t}
	 * 	- any: This will render any content
	 * 		- typeOptions:
	 * 			{any} content - Any content to render. If function is passed it will be called with the row data and 
	 * 			should return content that will be rendered in the cell.
	 * 			{boolean} [standardWrapper=true] - Flag that determines if the standard cell wrapper will be used to 
	 * 			render the content.
	 * 
	 * @param {Object} row - Row data object.
	 * @param {string|null} value - Row cell raw value. 
	 * @param {string} [type='text'] - Cell type (see 'Supported types' section above and DATA_TABLE_CELL_TYPES const).
	 * @param {Object} [typeOptions={}] - Optional type options object with type specific options (see 'Supported types'
	 * section above).
	 */
	renderCell(row, value, type = DATA_TABLE_CELL_TYPE.TEXT, typeOptions = {}) {
		if(
			(isset(value) && value !== null) || 
			type === DATA_TABLE_CELL_TYPE.ACTION || type === DATA_TABLE_CELL_TYPE.ACTIONS || 
			type === DATA_TABLE_CELL_TYPE.TEMPLATE || type === DATA_TABLE_CELL_TYPE.ANY
		) {
			const supportHtml = getBoolean(typeOptions, 'supportHtml');
			
			switch (type) {
				case DATA_TABLE_CELL_TYPE.TEXT:
					if(has(typeOptions, 'trimChar')){
						value = (
							getString(typeOptions, 'trimChar') !== ' ' ?
								trimChar(value, getString(typeOptions, 'trimChar')) :
								value = (value ? value.trim() : value)
						);
					}
					const text_content = (
						get(typeOptions, 'translatePath') ? this.t(value, typeOptions.translatePath) : value
					);
					return (
						supportHtml ? 
							<Html 
								content={text_content} 
								className={`content ${styles['content']}`}
								element="div" 
								elementProps={{
									style: {
										textAlign: getString(typeOptions, 'alignContent', 'unset'),
										whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
									}
								}}
							/>
							:
							<div
								className={`content ${styles['content']}`}
								style={{
									textAlign: getString(typeOptions, 'alignContent', 'unset'),
									whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
								}}
							>{text_content}</div>
					);
					
				case DATA_TABLE_CELL_TYPE.NUMBER:
					return (
						<NumberLabel 
							number={value}
							defaultNumber={get(typeOptions, 'defaultNumber')}
							prefix={get(typeOptions, 'prefix', '')}
							suffix={get(typeOptions, 'suffix', '')}
							icon={get(typeOptions, 'icon')}
							iconPosition={get(typeOptions, 'iconPosition')}
							iconSpin={get(typeOptions, 'iconSpin')}
							tooltip={get(typeOptions, 'tooltip')}
							useAppLocale={get(typeOptions, 'useAppLocale')}
							useAppLocaleCurrency={get(typeOptions, 'useAppLocaleCurrency')}
							locale={get(typeOptions, 'locale')}
							format={get(typeOptions, 'format')}
							element="div"
							elementProps={{
								className: `content ${styles['content']}`,
								style: {
									textAlign: getString(typeOptions, 'alignContent', 'unset'),
									whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
								}
							}}
						/>
					);
					
				case DATA_TABLE_CELL_TYPE.DATE:
					return (
						<DateLabel
							inputDate={value}
							inputFormat={get(typeOptions, 'inputFormat')}
							outputFormat={get(typeOptions, 'outputFormat')}
							defaultDate={get(typeOptions, 'defaultDate')}
							defaultOutput={get(typeOptions, 'defaultOutput')}
							prefix={get(typeOptions, 'prefix', '')}
							suffix={get(typeOptions, 'suffix', '')}
							icon={get(typeOptions, 'icon')}
							iconPosition={get(typeOptions, 'iconPosition')}
							iconSpin={get(typeOptions, 'iconSpin')}
							tooltip={get(typeOptions, 'tooltip')}
							useAppLocale={get(typeOptions, 'useAppLocale')}
							locale={get(typeOptions, 'locale')}
							element="div"
							elementProps={{
								className: `content ${styles['content']}`,
								style: {
									textAlign: getString(typeOptions, 'alignContent', 'unset'),
									whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
								}
							}}
						/>
					);
					
				case DATA_TABLE_CELL_TYPE.BOOL:
					let bool_content = '';
					// If value is defined
					if (isset(value)) {
						// True
						if (getBoolean(value)) {
							const label = getString(typeOptions, 'trueLabel');
							const translatePath = getString(typeOptions, 'translatePath');
							if (label) bool_content = (translatePath ? this.t(label, translatePath) : label);
							else bool_content = this.t('Yes', 'general');
						}
						// False
						else {
							const label = getString(typeOptions, 'falseLabel');
							const translatePath = getString(typeOptions, 'translatePath');
							if (label) bool_content = (translatePath ? this.t(label, translatePath) : label);
							else bool_content = this.t('No', 'general');
						}
					}
					return (
						supportHtml ?
							<Html 
								content={bool_content} 
								className={`content ${styles['content']}`} 
								element="div"
								elementProps={{
									style: {
										textAlign: getString(typeOptions, 'alignContent', 'unset'),
										whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
									}
								}}
							/>
							:
							<div 
								className={`content ${styles['content']}`}
								style={{
									textAlign: getString(typeOptions, 'alignContent', 'unset'),
									whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
								}}
							>{bool_content}</div>
					);

				case DATA_TABLE_CELL_TYPE.BOOL_INVERTED:
					let inverse_bool_content = '';
					// If value is defined
					if (isset(value)) {
						// True
						if (getBoolean(value)) {
							const label = getString(typeOptions, 'falseLabel');
							const translatePath = getString(typeOptions, 'translatePath');
							if (label) inverse_bool_content = (translatePath ? this.t(label, translatePath) : label);
							else inverse_bool_content = this.t('No', 'general');
						}
						// False
						else {
							const label = getString(typeOptions, 'trueLabel');
							const translatePath = getString(typeOptions, 'translatePath');
							if (label) inverse_bool_content = (translatePath ? this.t(label, translatePath) : label);
							else inverse_bool_content = this.t('Yes', 'general');
						}
					}
					return (
						supportHtml ?
							<Html 
								content={inverse_bool_content} 
								className={`content ${styles['content']}`} 
								element="div"
								elementProps={{
									style: {
										textAlign: getString(typeOptions, 'alignContent', 'unset'),
										whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
									}
								}}
							/>
							:
							<div 
								className={`content ${styles['content']}`}
								style={{
									textAlign: getString(typeOptions, 'alignContent', 'unset'),
									whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
								}}
							>{inverse_bool_content}</div>
					);
					
				case DATA_TABLE_CELL_TYPE.ACTION:
					const onClick = get(typeOptions, 'onClick');
					const icon = getString(typeOptions, 'icon');
					const label = get(typeOptions, 'label');
					const className = getString(typeOptions, 'className', '', true);
					const tooltip = getString(typeOptions, 'tooltip');
					const conditionFunc = get(typeOptions, 'condition');
					const conditionMet = (conditionFunc ? conditionFunc(row) : true);
					
					return (
						<div className={`content ${styles['content']}`}>
							{
								conditionMet && (icon || label) ?
									tooltip ?
										<Tooltip
											tag="span"
											title={tooltip}
											size="small"
											position="top-center"
											arrow={true}
											interactive={false}
										>
											<div
												className={`action-btn ${styles['action-btn']} ${className}`}
												style={{
													textAlign: getString(typeOptions, 'alignContent', 'unset'),
													whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
												}}
												onClick={(onClick ? e => onClick(row, e) : null)}
											>
												<Label
													content={label}
													icon={icon}
													supportHtml={supportHtml}
												/>
											</div>
										</Tooltip>
										:
										<div 
											className={`action-btn ${styles['action-btn']} ${className}`}
											style={{
												textAlign: getString(typeOptions, 'alignContent', 'unset'),
												whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
											}}
											onClick={(onClick ? e => onClick(row, e) : null)}
										>
											<Label
												content={label}
												icon={icon}
												supportHtml={supportHtml}
											/>
										</div>
									: ''
							}
						</div>
					);
					
				case DATA_TABLE_CELL_TYPE.ACTIONS:
					const actions = getArray(typeOptions, 'actions');
					return actions.map((action, idx) => {
						const onClick = get(action, 'onClick');
						const icon = getString(action, 'icon');
						const label = get(action, 'label');
						const className = getString(action, 'className', '', true);
						const tooltip = getString(action, 'tooltip');
						const conditionFunc = get(action, 'condition');
						const conditionMet = (conditionFunc ? conditionFunc(row) : true);

						return (
							<div key={idx} className={`content ${styles['content']}`}>
								{
									conditionMet && (icon || label) ?
										tooltip ?
											<Tooltip
												tag="span"
												title={tooltip}
												size="small"
												position="top-center"
												arrow={true}
												interactive={false}
											>
												<div
													className={`action-btn ${styles['action-btn']} ${className}`}
													style={{
														textAlign: getString(typeOptions, 'alignContent', 'unset'),
														whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
													}}
													onClick={(onClick ? e => onClick(row, e) : null)}
												>
													<Label
														content={label}
														icon={icon}
														supportHtml={supportHtml}
													/>
												</div>
											</Tooltip>
											:
											<div
												className={`action-btn ${styles['action-btn']}`}
												style={{
													textAlign: getString(typeOptions, 'alignContent', 'unset'),
													whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
												}}
												onClick={(onClick ? e => onClick(row, e) : null)}
											>
												<Label
													content={label}
													icon={icon}
													supportHtml={supportHtml}
												/>
											</div>
										: ''
								}
							</div>
						);
					});
					
				case DATA_TABLE_CELL_TYPE.TEMPLATE:
					const rawTemplate = get(typeOptions, 'template');
					const nullAsEmpty = getBoolean(typeOptions, 'nullAsEmpty');
					const template = (isFunction(rawTemplate) ? rawTemplate(row) : getString(rawTemplate));
					let template_content = template;
					if (template) {
						forOwn(row, (fieldValue, field) => {
							const regexPattern = '{\\$' + field + '\\|?[^}]*}';
							const regex = new RegExp(regexPattern, 'gim');

							template_content = template_content.replace(regex, match => {
								// Remove "{$" from the beginning and "}" from the end of matched item
								match = match.replace(/^{\$|}$/g, '');

								// Trim matched item
								match = match.trim();

								// Replacement params
								// Every match item can have one or more params that can alter the replacement value in some way
								// (for example converting replacement value to lowercase or uppercase).
								const paramsSplit = match.split('|');
								if (paramsSplit.length === 2) {
									let alteredValue = (
										nullAsEmpty ? getString(fieldValue, '', '', true) : fieldValue
									);
									const params = paramsSplit[1].split(' ');
									for (let param of params) {
										switch (param.trim()) {
											case 'l': alteredValue = alteredValue.toLowerCase(); break; // Lowercase
											case 'u': alteredValue = alteredValue.toUpperCase(); break; // Uppercase
											case 't': alteredValue = alteredValue.trim(); break; // Trim
											// no default
										}
									}
									return alteredValue;
								}
								// If there are no altering params use the default replacement value (rowFieldValue)
								else return (
									nullAsEmpty ? getString(fieldValue, '', '', true) : fieldValue
								);
							});
						});
						return (
							supportHtml ?
								<Html 
									content={template_content} 
									className={`content ${styles['content']}`} 
									element="div"
									elementProps={{
										style: {
											textAlign: getString(typeOptions, 'alignContent', 'unset'),
											whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
										}
									}}
								/>
								:
								<div 
									className={`content ${styles['content']}`}
									style={{
										textAlign: getString(typeOptions, 'alignContent', 'unset'),
										whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
									}}
								>{template_content}</div>
						);
					}
					else return <div className={`content ${styles['content']}`} />;
					
				case DATA_TABLE_CELL_TYPE.ANY:
					const rawContent = get(typeOptions, 'content');
					const content = (isFunction(rawContent) ? rawContent(row) : rawContent);
					return (
						getBoolean(typeOptions, 'standardWrapper', true) ?
							<div className={`content ${styles['content']}`}>{content}</div>
							:
							content
					);
					
				default:
					return <div className={`content ${styles['content']}`} />;
			}
		}
	}
	
	/**
	 * Render data table columns (table header)
	 */
	renderColumns() {
		const {sortBy, sortDir} = this.props;
		const columns = getArray(this.props, 'columns');
		
		// Extract single column data used for rendering
		const columnSortName = column => getString(column, 'sortName');
		const isColumnSortable = column => !!columnSortName(column);
		const isActionColumn = column => (getString(column, 'dataType') === DATA_TABLE_CELL_TYPE.ACTION);
		const isActionsColumn = column => (getString(column, 'dataType') === DATA_TABLE_CELL_TYPE.ACTIONS);
		const isColumnHidden = column => getBoolean(column, 'hide');
		
		return (
			columns.map((column, index) =>
				!isColumnHidden(column) ?
					<th
						key={index}
						className={
							`column ${styles['column']}` +
							` column-${safeCssName(column.name)} ` +
							(isColumnSortable(column) ? ` sortable ${styles['sortable']}` : '') +
							(isColumnSortable(column) && sortBy === columnSortName(column) ? ` sort` : '') +
							(isActionColumn(column) ? ` action` : '') +
							(isActionsColumn(column) ? ` actions` : '')
						}
						style={{
							width: (has(column, 'width') ? column.width : ''),
							minWidth: (has(column, 'minWidth') ? column.minWidth : ''),
							whiteSpace: (getBoolean(column, 'widthLessThanLabel') ? 'normal' : 'nowrap')
						}}
						onClick={isColumnSortable(column) ? () => this.handleSortClick(column.sortName) : null}
					>
						<Label
							content={column.label}
							tooltip={column.tooltip}
							icon={column.tooltip ? `question-circle help ${styles['column-help-icon']}` : ''}
							iconPosition={LABEL_ICON_POSITION.RIGHT}
							prefix={(
								isColumnSortable(column) ?
									<span className={icon_font_stack_class}>
										<Icon 
											symbol="sort" 
											className={`sort-icon-main ${styles['sort-icon-main']} ${icon_font_stacked_item_class}`} 
										/>
										{sortBy === columnSortName(column) && sortDir === SORT_ORDER.DESC ?
											<Icon 
												symbol="sort-desc" 
												className={
													`sort-icon-active ${styles['sort-icon-active']} ${icon_font_stacked_item_class}`
												} 
											/> 
												: null
										}
										{sortBy === columnSortName(column) && sortDir === SORT_ORDER.ASC ?
											<Icon 
												symbol="sort-asc" 
												className={
													`sort-icon-active ${styles['sort-icon-active']} ${icon_font_stacked_item_class}`
												} 
											/> 
											: null
										}
									</span>
									: null
							)}
							element="div"
							elementProps={{
								className: `content ${styles['content']}`
							}}
						/>
					</th>
					: null
			)
		);
	}

	/**
	 * Render data table rows (table body)
	 */
	renderRows() {
		const {
			highlightOnHover, highlightedRows, showRowNumber, perPage, pageNo, onRowClick, primaryKeyColumn
		} = this.props;
		const selectable = this.isSelectable();
		const data = getArray(this.props, 'data');
		const columns = getArray(this.props, 'columns');

		// Extract single row data used for rendering
		const isActionCell = column => (getString(column, 'dataType') === DATA_TABLE_CELL_TYPE.ACTION);
		const isActionsCell = column => (getString(column, 'dataType') === DATA_TABLE_CELL_TYPE.ACTIONS);
		const isColumnHidden = column => getBoolean(column, 'hide');
		const columnSortName = column => getString(column, 'sortName');
		const isColumnSortable = column => !!columnSortName(column);
		
		return (
			<tbody className={
				(highlightOnHover || onRowClick ? ` highlighted-hover ${styles['hover']} ` : '') +
				(onRowClick ? ` clickable ${styles['clickable']} ` : '')
			}>
				{
					data.length > 0 ?
						data.map((row, index) =>
							<tr 
								key={index} 
								id={`data-table-row-${primaryKeyColumn ? get(row, primaryKeyColumn) : index}`}
								className={(this.isRowSelected(row) ? 'selected' : '')}
								onClick={() => this.handleRowClick(row, index)}
							>
								{selectable ?
									<td
										className={
											`select ${styles['select']} ` + this.getRowHighlightClassName(row, highlightedRows)
										}
										style={this.getRowHighlightStyle(row, highlightedRows)}
										onClick={e => { e.stopPropagation() }}
									>
										<div className={`content ${styles['content']}`}>
											<CheckboxInput
												className={`select-checkbox ${styles['select-checkbox']}`}
												size={18}
												checked={this.isRowSelected(row)}
												onChange={checked => this.handleRowSelect(checked, row)}
											/>
										</div>
									</td> : null
								}
								
								{
									showRowNumber ? 
										<td
											className={this.getRowHighlightClassName(row, highlightedRows)}
											style={this.getRowHighlightStyle(row, highlightedRows)}
										>
											<div className={`content ${styles['content']}`}>{(perPage * (pageNo - 1)) + index + 1}</div>
										</td> 
									: null
								}
								
								{columns.map((column, columnIndex) =>
									!isColumnHidden(column) ?
										<td
											key={columnIndex}
											className={
												(isActionCell(column) ? ` action ${styles['action-cell']}` : '') +
												(isActionsCell(column) ? ` actions ${styles['actions-cell']}` : '') +
												(isColumnSortable(column) ? ` sortable ${styles['sortable']}` : '') +
												' ' + this.getRowHighlightClassName(row, highlightedRows)
											}
											style={this.getRowHighlightStyle(row, highlightedRows)}
											onClick={
												isActionCell(column) || isActionsCell(column) ? e => { 
													e.stopPropagation(); e.nativeEvent.stopImmediatePropagation(); 
												} : e => {
													if(getBoolean(column, 'stopPropagation') === true) { 
														e.stopPropagation(); e.nativeEvent.stopImmediatePropagation(); 
													}
												}
											}
										>
											{
												this.renderCell(
													row, 
													get(row, column.name), 
													get(column, 'dataType'), 
													get(column, 'dataTypeOptions'
												)
											)}
										</td>
										: null
								)}
							</tr>
						)
						: 
						<tr className={`no-data ${styles['noData']}`}>
							<td 
								colSpan={this.getColSpan()}
								className={`content ${styles['content']}`}
							>{this.t('No data', 'general')}</td>
						</tr>
				}
			</tbody>
		);
	}

	/**
	 * Check if data table has pagination
	 * @return {boolean}
	 */
	hasPagination() {
		const {paginationType, totalRows, perPage} = this.props;
		return !(
			!this.hasItems() || perPage < 1 || !paginationType || 
			(paginationType === PAGINATION_TYPE.DYNAMIC && totalRows < 1)
		);
	}

	/**
	 * Render data table pagination
	 */
	renderPagination() {
		const {paginationType, isLastPage, totalRows, pageNo, perPage, paginationOptions} = this.props;
		
		// Don't render pagination if it there is not need for it
		if (!this.hasPagination()) return null;
		
		return (
			<div className={`data-table-pagination ${styles['pagination']}`}>
				{
					paginationType === PAGINATION_TYPE.STATIC ?
						<PaginationStatic
							pageNo={pageNo}
							perPage={perPage}
							isLastPage={isLastPage}
							pageItemsCount={getArray(this.props, 'data').length}
							onPageSelect={this.handlePaginationClick}
							{...paginationOptions}
						/>
					: paginationType === PAGINATION_TYPE.DYNAMIC ?
						<Pagination
							pageNo={pageNo}
							perPage={perPage}
							totalResults={totalRows}
							onPageSelect={this.handlePaginationClick}
							{...paginationOptions}
						/>
					: null
				}
			</div>
		);
	}

	/**
	 * Check if data table has any items
	 * @return {boolean}
	 */
	hasItems() { return (getArray(this.props, 'data').length > 0); }
	
	render() {
		const {className, showRowNumber, minWidth} = this.props;
		const selectable = this.isSelectable();
		const {selectedRows} = this.state;
		
		return (
			<div
				id={this.getProp('id', `data-table-${this.getId()}`)}
				className={
					`data-table-component ${className} ${styles['wrapper']}` + 
					(!this.hasItems() ? ` empty ${styles['empty']} ` : '')
				}
			>
				<table 
					className={
						`data-table ${styles['table']} ` +
						(this.hasPagination() ? 
							` with-pagination ${styles['withPagination']} ` : 
							` without-pagination ${styles['withoutPagination']} `
						)
					} 
					style={{minWidth: getNumber(minWidth)}}
				>
					<thead>
						<tr>
							{selectable ?
								<th className={`column select ${styles['column']} ${styles['select-row']}`}>
									<div className={`content ${styles['content']}`}>
										<CheckboxInput
											className={`select-checkbox ${styles['select-checkbox']}`}
											size={18}
											checked={this.areAllRowsOnPageSelected()} 
											onChange={this.handlePageSelect} 
										/>
									</div>
								</th> : null
							}
							
							{showRowNumber ? 
								<th className={`column num-row ${styles['column']} ${styles['num-row']}`}>
									<span className={`content ${styles['content']}`}>#</span>
								</th> : null
							}
							{this.renderColumns()}
						</tr>
						{
							selectedRows.length > 0 ?
								<tr className={`clear-selection ${styles['clear-selection']}`}>
									<th colSpan={this.getColSpan()}>
										<Label
											content={this.t('Clear selection')}
											element="div"
											elementProps={{
												className: `content ${styles['content']}`,
												onClick: this.clearSelection
											}}
										/>
									</th>
								</tr>
								: null
						}
					</thead>

					{this.renderRows()}
				</table>
				
				{this.renderPagination()}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
DataTable.propTypes = {
	// Data table wrapper element id attribute
	id: PropTypes.string,
	// Data table wrapper element class attribute
	className: PropTypes.string,
	// Data table main data (rows)
	data: PropTypes.array,
	// Data table columns
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			// Column name
			name: PropTypes.string,
			// Column label to display as table column name (in thead)
			label: PropTypes.string,
			// Tooltip to display next to column label
			tooltip: PropTypes.string,
			// Column sort name used for sort IO (sort name can be different from column name)
			sortName: PropTypes.string,
			// Table column width in pixels (ex: "100px", 100)
			// @note Use 1 or '1px' for auto width column.
			width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			// Table column min-width in pixels (ex: "100px", 100)
			// @note Use 1 or '1px' for auto width column.
			minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			// Flag that determines if column width can be smaller that the rendered label width
			// @note If true, column might not have the exact with as specified by 'width' property.
			widthLessThanLabel: PropTypes.bool,
			// Flag to stop the click propagation for columns that contain links when table rows are clickable
			stopPropagation: PropTypes.bool,
			// Column data type (see "Supported types" section in "renderCell" component method)
			dataType: PropTypes.string,
			// Data type specific options (see "renderCell" component method)
			dataTypeOptions: PropTypes.object,
			// Flag that determines if column should be hidden/not rendered
			hide: PropTypes.bool,
		})
	),
	// Name of the primary key column
	// @note This is required if rows need to be selected ('selectable' prop is true). Column must exist in 'columns' 
	// prop.
	primaryKeyColumn: PropTypes.string,
	// Flag that determines if rows can be selectable
	// @note CheckboxInput will be displayed as a first table column. If 'primaryKeyColumn' is not set this will be ignored 
	// and rows will not be selectable.
	selectable: PropTypes.bool,
	// Flag that determines if rows will be highlighted on mouse hover
	// @note If not defined and rows are clickable ('onRowClick' prop is defined) this will be true by default.
	highlightOnHover: PropTypes.bool,
	// Row highlight specification 
	highlightedRows: PropTypes.arrayOf(
		PropTypes.shape({
			// Class name that will be added to all rows in the 'rows' property
			// @note This class name represents the highlight which means that skin should already have CSS styles for it.
			className: PropTypes.string,
			// Style object that will be added to all rows in the 'rows' property
			style: PropTypes.oneOfType([
				// Style object that cna be used in JSX (camelCase keys)
				// @example {backgroundColor: '#ccc', color: '#333'}
				PropTypes.object, 
				// String CSS style declaration that will be converted to the object
				// @example 'background-color: #ccc; color: #333;'
				PropTypes.string
			]),
			// Rows to get the 'className' property
			// @note This is an array of row objects from 'data' prop.
			rows: PropTypes.oneOfType([
				PropTypes.arrayOf(PropTypes.object), 
				PropTypes.arrayOf(PropTypes.string), 
				PropTypes.arrayOf(PropTypes.number)
			])
		})
	),
	// If true, a new first column will be rendered with row numbers
	// @note Row numbers start from 1.
	showRowNumber: PropTypes.bool,
	// Flag that specifies if table height will be limited to the space available
	// @description If true, data table will have a max. height according to the space available between it and the 
	// bottom of the page.
	limitToAvailableSpace: PropTypes.bool,
	// Minimal data table width
	minWidth: PropTypes.number,
	
	// Pagination
	paginationType: PropTypes.oneOf(PAGINATION_TYPES),
	totalRows: PropTypes.number, // Used ony for PAGINATION_TYPE.DYNAMIC
	isLastPage: PropTypes.bool, // Used ony for PAGINATION_TYPE.STATIC
	pageNo: PropTypes.number,
	perPage: PropTypes.number,
	paginationOptions: PropTypes.object, // Pagination component options/props

	// Sort
	sortBy: PropTypes.string,
	sortDir: PropTypes.string,

	// Events
	onRowClick: PropTypes.func, // Arguments: row, index
	onSortByColumn: PropTypes.func, // Arguments: column, newSortDir
	onPaginationClick: PropTypes.func, // Arguments: pageNo
	onSelect: PropTypes.func, // Arguments: {Object[]} selected rows
};

/**
 * Define component default values for own props
 */
DataTable.defaultProps = {
	className: 'standard',
	showRowNumber: false,
	paginationType: PAGINATION_TYPE.STATIC,
	pageNo: 1,
	perPage: pagination_default_per_page,
};

export default DataTable;
export * from "./const";

// TODO: Add support for nested column type (similar to 'template' type but instead of using raw column values as
//  string this type will support nested column definitions that can have any type).
// TODO: Dynamic column with async value loading. Loading the value by using an async function that returns a promise.
// TODO: Implement tfoot as fixed columns that can be used, for example, for column totals.