/**
 * Abstract main app component
 */
import React from "react";
import PropTypes from "prop-types";
import DataComponent from "./components/DataComponent";
import {Redirect, Route, Switch} from "react-router-dom";
import {cloneDeep, findIndex, map, sortBy} from "lodash";
import {addErrorMessageAction} from "./components/global/Message";
import {setBreakpointAction} from "./store/actions/breakpoint";
import {responsive_breakpoints, responsive_default_breakpoints_name, responsive_mobile_breakpoint_name} from "../config";
import {calculateCurrentBreakpointName} from "./helpers/dom";
import Messages from "./components/global/Messages";
import Overlay from "./components/global/Overlay";
import {LoadingOverlayObject} from "./objects";
import ACL from "../acl";
import AclErrorPage from "./pages/error/acl";
import {getArray} from "./helpers/data";

/**
 * Main app component
 */
class AppComponent extends DataComponent {
	/**
	 * List of imported resources required before app can be rendered
	 * @type {(function(): Promise<Object<string, *>>)[]}
	 */
	requiredResources = [];
	
	constructor(props, initialState = {}, options = {}) {
		const _options = {
			/**
			 * If true, all resources will be loaded into component's local state
			 * @note Resources can be big so be careful when enabling this. Resources are defined in 'requiredResources'.
			 * @type {boolean}
			 */
			loadResourcesIntoLocalState: false,

			translationPath: 'App',
			disableLoad: true,
			domManipulationIntervalTimeout: 10,
			
			...(options ? cloneDeep(options) : {})
		};

		const _initialState = {
			data: {
				resourcesLoading: true,
				resourcesLoaded: false,
				resources: {},
				resourceRedirect: undefined,
			},

			...(initialState ? cloneDeep(initialState) : {})
		};

		super(props, _initialState, _options);

		// Bind methods
		this.updateResponsiveBreakpoints = this.updateResponsiveBreakpoints.bind(this);
		this.renderApp = this.renderApp.bind(this);

		// Register event listeners
		this.registerEventListener('resize', this.updateResponsiveBreakpoints);
	}

	componentDidMount(override = false) {
		return super.componentDidMount(override).then(state => {
			// Load all required resources
			// This will load all the required global resources that need to be loaded before the app is rendered. For 
			// example i18n translation files need to be loaded before app is rendered.
			return Promise.allSettled(
				this.requiredResources.map(resourceFunction => resourceFunction(this.props.location))
			)
				// Return a promise that resolves to an array of loaded resources or rejects on a first failed resource.
				// @note Resources will be checked in the order they are declared in 'this.requiredResources'.
				.then(results => {
					return new Promise((resolve, reject) => {
						let loadedRes = [];
						results.forEach(result => {
							if (result.status === 'fulfilled') loadedRes.push(result.value);
							else if (result.status === 'rejected') reject(result.reason);
						});
						resolve(loadedRes);
					});
				})
				// If all resources were loaded successfully and 'loadResourcesIntoLocalState' flag is true, load all 
				// resources into local state
				.then(loadedRes => {
					if (this.getOption('loadResourcesIntoLocalState')) {
						return new Promise(resolve => {
							if (loadedRes) this.setValue('resources', loadedRes).then(() => resolve(loadedRes));
							else resolve();
						})
					} else {
						return loadedRes;
					}
				})
				.then(() => this.setValue('resourcesLoaded', true)
				)
				.then(() => { this.updateResponsiveBreakpoints(); return this.state; })
				.catch(error => {
					console.error(this.t('Resource loading error'), error.message);
					return new Promise((resolve => {
						if (error.hasOwnProperty('redirect')) {
							this.setValue('resourcesLoaded', false)
								.then(() => this.setValue('resourceRedirect', error.redirect))
								.then(() => resolve());
						} else {
							this.props.store.dispatch(addErrorMessageAction(this.t('Could not load app resource!')));
							this.setValue('resourcesLoaded', false).then(() => resolve());
						}
					}));
				})
				.then(() => this.setValue('resourcesLoading', false))
		});
	}

	/**
	 * Method called on each DOM manipulation interval
	 *
	 * @param {HTMLElement|Element|null} element - Component's main DOM element or null if component's main DOM element
	 * is not set.
	 */
	domManipulations(element) {
		// Handle overlay parent elements
		document.querySelectorAll('.overlay-component').forEach(overlay => {
			const overlayParent = overlay.parentElement;
			if (overlayParent) {
				overlayParent.classList.add('has-overlay-component');
				if (!overlayParent.style.position || overlayParent.style.position === 'static') {
					overlayParent.classList.add('has-overlay-component-position');
				}
				overlayParent.classList.add('has-overlay-component-overlay');
				if (overlay.classList.contains('overlay-component-blur')) {
					overlayParent.classList.add('has-overlay-component-blur');
				}
			}
		});
		document.querySelectorAll('.has-overlay-component-blur').forEach(overlayParent => {
			if (!overlayParent.querySelector('.overlay-component-blur')) {
				overlayParent.classList.remove('has-overlay-component-blur');
			}
		});
		document.querySelectorAll('.has-overlay-component').forEach(overlayParent => {
			if (!overlayParent.querySelector('.overlay-component')) {
				overlayParent.classList.remove(
					'has-overlay-component', 'has-overlay-component-position', 'has-overlay-component-overlay',
					'has-overlay-component-blur'
				)
			}
		});
	}

	/**
	 * Update responsive breakpoints based on window width
	 */
	updateResponsiveBreakpoints() {
		// Calculate current breakpoint base on window width
		const currentBreakpointName = calculateCurrentBreakpointName();

		// Set calculated current breakpoint in Redux store so that other components can use it
		this.props.store.dispatch(setBreakpointAction(currentBreakpointName));

		// Clear all breakpoint CSS classes from body tag 
		document.body.classList.remove(
			...map(responsive_breakpoints, 'name'), responsive_default_breakpoints_name
		);

		// Set current breakpoint CSS class to body tag
		document.body.classList.add(currentBreakpointName);

		// Set mobile breakpoint CSS class to body tag
		const sortedResponsiveBreakpoints = sortBy(responsive_breakpoints, ['maxWidth']);
		const currentBpIndex = findIndex(sortedResponsiveBreakpoints, {name: currentBreakpointName});
		const mobileBpIndex = findIndex(sortedResponsiveBreakpoints, {name: responsive_mobile_breakpoint_name});
		if (currentBpIndex <= mobileBpIndex) document.body.classList.add('mobile');
		else document.body.classList.remove('mobile');
	}

	/**
	 * Method used to render the main app
	 * 
	 * @param {JSX.Element} [routs=null] - Routes that will be available even if resources are not loaded or some 
	 * resource fails to load. All resources before the failed one will still be available to those routes (pages).
	 * @param {JSX.Element} [resourceRoutes=null] - Routes that will be available if all resources are loaded.
	 * @return {JSX.Element}
	 */
	renderApp(routs = null, resourceRoutes = null) {
		return (
			<>
				<Messages />
				{
					this.getValue('resourcesLoading') ?
						<Overlay 
							data={
								new LoadingOverlayObject('#root', false, false, '3rem', 3)
							} 
							element={document.getElementById('root')} 
						/> 
						:
						<Switch>{routs}</Switch>
				}
				{
					this.getValue('resourcesLoaded') ? 
						<Switch>{resourceRoutes}</Switch> 
						: 
						null
				}
				{
					!this.getValue('resourcesLoaded') && typeof this.getValue('resourceRedirect') !== 'undefined'?
						<Redirect to={this.getValue('resourceRedirect')} /> 
						:
						null
				}
			</>
		);
	}
}

/**
 * Standard page route component
 * @description Use this component as a helper to render standard app pages in main 'App.js' file. It handles ACL and 
 * can handle other core functionality in the future so you don't have to.
 * 
 * @param {Object} page - Page import object containing all exports from the page file.
 * @param {boolean} [ignorePermissions=false] - If true, permissions check will be skipped and page will be render 
 * regardless of ACL settings.   
 * @param {PageComponent|PageDataComponent} [errorPage=null] - Custom ACL error page component. If null or not specified
 * default AclErrorPage component will be used.
 * @param {boolean} [exact=false] - When true, will only match if the path matches the location.pathname exactly. This 
 * is a prop from react-router-dom Route component.
 * @param {boolean} [strict=false] - When true, a path that has a trailing slash will only match a location.pathname 
 * with a trailing slash. This has no effect when there are additional URL segments in the location.pathname. This is a 
 * prop from react-router-dom Route component.
 * @param {boolean} [sensitive=false] - When true, will match if the path is case sensitive. This is a prop from 
 * react-router-dom Route component.
 * @param {any} [routeProps] - Any other react-router-dom Route component prop.
 * @return {JSX.Element}
 * @constructor
 */
export function AppPageRoute({
	page, 
	ignorePermissions = false, 
	errorPage = null, 
	exact = false, 
	strict = false, 
	sensitive = false, 
	...routeProps
}) {
	const ErrorPage = (errorPage ? errorPage : AclErrorPage);
	return (
		<Route
			path={page.routerPath}
			component={(
				ignorePermissions ? 
					page.default :
					(
						(ACL.getPermissions().length > 0 && ACL.checkPermission(page.permissions)) || 
						getArray(page, 'permissions').length === 0
					) ? 
						page.default : 
						ErrorPage
			)}
			exact={exact}
			strict={strict}
			sensitive={sensitive}
			{...routeProps}
		/>
	);
}

/**
 * Define component's own props that can be passed to it by parent components
 */
AppComponent.propTypes = {
	// Redux store
	store: PropTypes.object.isRequired
};

export default AppComponent;