import {forOwn} from "lodash";

/**
 * Prepare 
 * @param {File|File[]} files - File or array of file objects.
 * @param {Object} [additionalFormData={}] - Additional FormData values that will be added.
 * @param {string} [formDataFieldName='file'] - Field name in FormData where selected file(s) will be stored.
 * @return {FormData}
 */
export const prepareFilesForUpload = (files, additionalFormData = {}, formDataFieldName = 'files') => {
	// Generate FormData from selected files
	let formData = new FormData();
	
	// Add file(s) to form data
	if (Array.isArray(files)) files.forEach(f => formData.append(`${formDataFieldName}[]`, f));
	else if (files) formData.append(formDataFieldName, files);

	// Add additional FormData fields
	if (additionalFormData) {
		forOwn(additionalFormData, (value, field) => formData.append(field, value));
	}
	
	return formData;
};

/**
 * Convert JSON object to FormData
 * @note This function only works with one-dimensional JSON objects with simple values and arrays. Arrays form items 
 * will have '[]' suffix added.
 * 
 * @param {Object} json - One-dimensional JSON data object.
 * @return {FormData}
 */
export const jsonToFormData = json => {
	let result = new FormData();
	
	if (json) {
		forOwn(json, (value, key) => {
			if (Array.isArray(value)) value.forEach(v => result.append(`${key}[]`, v));
			else result.append(key, value);
		});
	}
	
	return result;
};