import {getBoolean, getString} from "../../core/helpers/data";

/**
 * Get report column name
 * @note This method will use custom column name if it is specified by the user.
 *
 * @param {ReportColumnDataObject} column - Report column.
 * @return {string}
 */
export const getColumnName = column => {
	return (getBoolean(column, 'customHeadings') ?
			getString(column, 'columnHeading') :
			getString(column, 'dynamicValue.name')
	);
}

/**
 * Get column object by name
 *
 * @param {string} name - Column name (default or custom if user specified a custom headings).
 * @param {ReportColumnDataObject[]} selectColumns - Report select columns.
 * @return {ReportColumnDataObject | null}
 */
export const getColumnByName = (name, selectColumns) => {
	let result = null;

	selectColumns.forEach(column => {
		const columnName = getColumnName(column);
		if (columnName === name) result = column;
	});

	return result;
}