export const REPORT_VALUE_PREFIX = '##';
export const REPORT_VALUE_SUFFIX = '##';

/**
 * @typedef {Object} ReportValue
 * @property {string} code - Report value code used as the body of the value.
 */
/** @type {ReportValue[]} */
export const REPORT_VALUES = [
	{code: 'REPORT'},
	{code: 'REPORT_TEMPLATE'},
];