import styles from "../index.module.css";

import React from "react";
import DataComponent from "../../../core/components/DataComponent";
import {connect} from "react-redux";
import * as pageConfig from "../config";
import * as builderActions from "../actions";
import {selectors} from "../../../core/store/reducers";
import {getPageActions} from "../../../core/helpers/redux";
import ReportBuilderFilter from "../../../components/advanced/ReportBuilderFilter";
import Label from "../../../core/components/display/Label";
import {CookieData} from "../../../core/dataProtection/objects/cookie";
import {getStorageValue, setStorageValue, STORAGE_TYPE} from "../../../core/storage";
import {app_id, icon_font_delete_symbol} from "../../../config";
import CookieConsent from "../../../core/dataProtection/cookieConsent";
import Button, {BUTTON_DISPLAY_TYPE} from "../../../core/components/display/Button";
import {getBoolean, getBooleanFromTinyInt, getTinyIntFormBoolean} from "../../../core/helpers/data";
import {Tooltip} from "react-tippy";
import ConfirmDialog from "../../../core/components/dialogs/ConfirmDialog";
import PropTypes from "prop-types";

/**
 * Section collapsed cookie
 * @type {CookieData}
 */
const collapsedCookie = new CookieData(
	'necessary', `${app_id}-builder_filter_section_collapsed`, STORAGE_TYPE.SESSION
);

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	filter: selectors.builder.getReportValue(state, 'filter'),
});

class BuilderFilterSection extends DataComponent {
	/**
	 * Filter component (ReportBuilderFilter) ref
	 * @note This is primarily used for validation.
	 */
	filterRef = null;
	
	constructor(props) {
		super(props, {
			data: {
				collapsed: (
					!getBoolean(props, 'isReportTemplate') && CookieConsent.isAllowed(collapsedCookie) ?
						getBooleanFromTinyInt(
							getStorageValue(`${app_id}-builder_filter_section_collapsed`, STORAGE_TYPE.SESSION)
						)
						:
						false
				),
			}
		}, {
			translationPath: `${pageConfig.translationPath}.filter_section`,
			disableLoad: true,
			domManipulationIntervalTimeout: 1,
		});

		// Action methods
		this.clearItems = this.clearItems.bind(this);
	}


	// DOM manipulation interval methods --------------------------------------------------------------------------------
	/**
	 * Method called on each DOM manipulation interval
	 * @param {HTMLElement|Element|null} element - Component's main DOM element or null if component's main DOM element
	 * is not set.
	 */
	domManipulations(element) {
		// Add error flag to filter section if any filter item did not pass the validation
		const filterError = element.querySelector('.report-builder-filter-item-component .value-wrapper.error');
		if (filterError) element.dataset.error = 'true';
		else delete element.dataset.error;
	}


	// Validation and error methods -------------------------------------------------------------------------------------
	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() { return (this.filterRef ? this.filterRef.validate() : true); }


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Clear filter
	 */
	clearItems() {
		const {openDialogAction, closeDialogAction, clearReportValueAction} = this.props;

		const dialogGUIID = openDialogAction('', ConfirmDialog, {
			message: this.t('clear_confirmation'),
			onYes: () => {
				clearReportValueAction('filter');
				closeDialogAction(dialogGUIID);
			},
			onNo: () => closeDialogAction(dialogGUIID)
		}, {
			closeOnEscape: true,
			closeOnClickOutside: true,
			hideCloseBtn: true,
			maxWidth: 400
		});
	}

	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {filter, fetchFilterOptionsAction} = this.props;
		
		return (
			<div 
				id={this.getDomId()}
				className={
					`card ${styles['section']} ${styles['filter']}` +
					` ${this.getValue('collapsed') ? styles['collapsed'] : ''}`
				}
			>
				<div className={`card-header ${styles['filterHeader']}`}>
					<Label
						element="div"
						elementProps={{
							className: `card-title ${styles['title']}`,
							onClick: () => this.invertBoolValue('collapsed')
								.then(() => {
									if (CookieConsent.isAllowed(collapsedCookie)) {
										setStorageValue(
											`${app_id}-builder_filter_section_collapsed`,
											getTinyIntFormBoolean(this.getValue('collapsed')),
											STORAGE_TYPE.SESSION
										);
									}
								}),
						}}
						icon="filter"
						iconSymbolPrefix="icofont-"
						content={this.t('title')}
					/>
					<div className={`toolbar ${styles['toolbar']}`}>
						{
							filter.length ?
								<Tooltip
									tag="div"
									title={this.t('Clear', 'general') + ' ...'}
									size="small"
									position="top-center"
									arrow={true}
									interactive={false}
								>
									<Button
										className={styles['button']}
										icon={icon_font_delete_symbol}
										displayType={BUTTON_DISPLAY_TYPE.NONE}
										onClick={this.clearItems}
									/>
								</Tooltip>
								: null
						}
						<Button
							className={`${styles['button']} ${styles['sizeBtn']}`}
							icon={getBoolean(this.getValue('collapsed')) ? 'caret-down' : 'caret-up'}
							displayType={BUTTON_DISPLAY_TYPE.NONE}
							onClick={() => this.invertBoolValue('collapsed')
								.then(() => {
									if (CookieConsent.isAllowed(collapsedCookie)) {
										setStorageValue(
											`${app_id}-builder_filter_section_collapsed`,
											getTinyIntFormBoolean(this.getValue('collapsed')),
											STORAGE_TYPE.SESSION
										);
									}
								})
							}
						/>
					</div>
				</div>
				
				<div 
					className={
						`card-content ${styles['filterContent']} ${this.getValue('collapsed') ? 'no-display' : ''}`
					}
				>
					<ReportBuilderFilter 
						loadOptionsAction={fetchFilterOptionsAction}
						ref={node => { this.filterRef = node; }}
					/>
				</div>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
BuilderFilterSection.propTypes = {
	isReportTemplate: PropTypes.bool,
};

/**
 * Define component default values for own props
 */
BuilderFilterSection.defaultProps = {
	isReportTemplate: false,
};

export default connect(
	mapStateToProps, getPageActions(builderActions), null, {forwardRef: true}
)(BuilderFilterSection);