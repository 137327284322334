import {hideLoading, hideLoadingFunction, showLoading} from "../../core/helpers/loading";
import {
	ioJsonDeleteItemAction,
	ioJsonFetchAction,
	ioJsonFetchItemAction,
	ioJsonSaveAction
} from "../../core/store/actions/io";
import {actionCreators} from "../../core/store/reducers";
import * as filterDataMap from "../schedule/dataMap/filter";
import {get} from "lodash";
import {isSuccessful} from "../../core/helpers/io";
import {getArray} from "../../core/helpers/data";
import {SORT_ORDER} from "../../core/const/global";

/**
 * Load schedule into Redux store
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Schedule filter plane object.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): *}
 */
export const loadScheduleAction = (
	abortCallback, filter, pageNo, perPage, sortBy = 'creationDate', sortDir = 'DESC'
) => dispatch => {
	const loading = showLoading("#main-page-table");
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'automatic-report-schedule/search',
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy,
		sortDir
	)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) {
				dispatch(actionCreators.schedule.setScheduleData({
					...responseData,
					filter: filterDataMap.input(responseData.filter)
				}));
			}
			hideLoading(loading);
			return responseData;
		});
};

/**
 * Load schedule item into Redux store
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - Schedule item ID.
 * @return {function(*=): *}
 */
export const loadScheduleItemAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#schedule-page-schedule-popup', true, true);
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'automatic-report-schedule/fetch-by-id',
		id,
		{},
	)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) {
				dispatch(actionCreators.schedule.setScheduleItem(get(responseData, 'data')));
			}
			hideLoading(loading);
			return responseData;
		});
};

/**
 * Create schedule item and load saved data into Redux store 
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {object} data - Schedule item data. Data should already be in the proper format expected by the API.
 * @returns {function(*)}
 */
export const createScheduleItemAction = (abortCallback, data) => dispatch => {
	const loading = showLoading('#schedule-page-schedule-popup');
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'automatic-report-schedule/create',
		{data},
		{id: ''},
		true,
	)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators.schedule.setScheduleItem(get(responseData, 'data')));
			hideLoading(loading);
			return responseData;
		});
};

/**
 * Update schedule item and load saved data into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - Schedule item ID.
 * @param {object} data - Updated schedule item data. Data should already be in the proper format expected by the API.
 * @returns {function(*)}
 */
export const updateScheduleItemAction = (abortCallback, id, data) => dispatch => {
	const loading = showLoading('#schedule-page-schedule-popup');
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'automatic-report-schedule/update-by-id',
		{data},
		{id},
		true,
	)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators.schedule.setScheduleItem(get(responseData, 'data')));
			hideLoading(loading);
			return responseData;
		});
};

/**
 * // TODO: check if this should exist considering that the API does not exist
 * Delete schedule item
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - Schedule item ID.
 * @return {function(*=): *}
 */
export const deleteScheduleItemAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#schedule-item-delete-dialog .dialog-content-component .buttons');
	return ioJsonDeleteItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'automatic-report-schedule/delete',
		id,
		{},
		hideLoadingFunction(loading)
	)(dispatch);
};

/**
 * Find report templates
 * @note Usually used by the SelectAsync core component.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} query - Query string for search. 
 * @param {string[]|number[]} [exclude=[]] - Already selected items that should be excluded from the result.
 * @return {function(*=): *}
 */
export const findReportTemplatesAction = (abortCallback, query, exclude = []) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'report-template/search',
		query,
		null,
		null,
		1,
		40,
		'fileName',
		SORT_ORDER.ASC,
		{
			exclude
		}
	)(dispatch)
		// Return data response data
		.then(response => isSuccessful(response) ? getArray(response, 'data') : []);
};

/**
 * Clear Redux data used by the schedule item popup
 * @return {(function(*): void)|*}
 */
export const clearScheduleItemPopupDataAction = () => dispatch => {
	dispatch(actionCreators.schedule.clearScheduleItem());
};