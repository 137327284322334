import styles from "../../../index.module.css";
import "./index.css";

import React from "react";
import BaseComponent from "../../../../../core/components/BaseComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as pageConfig from "../../../config";
import * as builderActions from "../../../actions";
import {selectors} from "../../../../../core/store/reducers";
import {getPageActions} from "../../../../../core/helpers/redux";
import Label, {LABEL_ICON_POSITION} from "../../../../../core/components/display/Label";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../../../../core/components/display/Button";
import ConfirmDialog from "../../../../../core/components/dialogs/ConfirmDialog";
import {Tooltip} from "react-tippy";
import Dropdown, {DROPDOWN_POSITION} from "../../../../../core/components/display/Dropdown";
import FormulaDialog from "./dialogs/FormulaDialog";
import {getArray, getBoolean, getString} from "../../../../../core/helpers/data";
import PropertiesDialog from "./dialogs/PropertiesDialog";
import {ReportColumnSortDataObject, ReportFilterItemDataObject} from "../../../../../dataObjects/report";
import {SORT_ORDER} from "../../../../../core/const/global";
import {get, find, findIndex} from "lodash";
import {icon_font_symbol_class_prefix} from "../../../../../config";
import {scrollToSelector} from "../../../../../core/helpers/dom";
import {blinkBackgroundSlowAnimation} from "../../../../../core/helpers/animation";
import {waitingFunction} from "../../../../../core/helpers/function";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @param {object} ownProps - Components own props.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = (state, ownProps) => ({
	selectColumn: selectors.builder.getReportValueItem(state, 'selectColumns', getString(ownProps, 'GUIID')),
	sort: selectors.builder.getReportValue(state, 'sort'),
});

class SelectItem extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: `${pageConfig.translationPath}.select_section.item`,
			domPrefix: 'select-column-item'
		});
		
		// Action methods
		this.remove = this.remove.bind(this);
		this.setSort = this.setSort.bind(this);
		this.clearSort = this.clearSort.bind(this);
		this.selectColumnInSort = this.selectColumnInSort.bind(this);
		this.selectColumnSortOrdinal = this.selectColumnSortOrdinal.bind(this);
		this.selectColumnSortDir = this.selectColumnSortDir.bind(this);
		this.addColumnToSort = this.addColumnToSort.bind(this);
		this.updateSortColumnDir = this.updateSortColumnDir.bind(this);
		this.removeColumnFromSort = this.removeColumnFromSort.bind(this);
		
		// Dialog methods
		this.openFormulaDialog = this.openFormulaDialog.bind(this);
		this.openPropertiesDialog = this.openPropertiesDialog.bind(this);
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Get component's main data from local state
	 * @return {ReportColumnDataObject} Cloned component's main data from local state.
	 */
	getData() { return this.getProp('selectColumn'); }


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Remove select item
	 * @param {string} GUIID - Select items GUI ID.
	 */
	remove(GUIID) {
		const {openDialogAction, closeDialogAction, removeReportValueItemAction} = this.props;
		
		const dialogGUIID = openDialogAction('', ConfirmDialog, {
			message: this.t('remove_confirmation'),
			onYes: () => {
				removeReportValueItemAction(GUIID, 'selectColumns');
				closeDialogAction(dialogGUIID);
			},
			onNo: () => closeDialogAction(dialogGUIID)
		}, {
			closeOnEscape: true,
			closeOnClickOutside: true,
			hideCloseBtn: true,
			maxWidth: 450
		});
	}

	/**
	 * Set whole sort list
	 * @param {ReportColumnSortDataObject[]} sort - List of all sort columns.
	 */
	setSort(sort) {
		const {setReportValueAction} = this.props;
		setReportValueAction(sort, 'sort');
	}

	/**
	 * Clear whole sort list
	 */
	clearSort() {
		const {clearReportValueAction} = this.props;
		clearReportValueAction('sort');
	}

	/**
	 * Check if select column is used as a sort column
	 * @param {ReportColumnDataObject} selectColumn - Report select column to check.
	 * @return {ReportColumnSortDataObject|undefined}
	 */
	selectColumnInSort(selectColumn) {
		const sort = getArray(this.props, 'sort');
		return find(sort, i => get(i, 'column.GUIID') === get(selectColumn, 'GUIID'));
	}

	/**
	 * Get select columns sort index
	 * @note If select column is not included in sort, this method will return -1.
	 * @param {ReportColumnDataObject} selectColumn - Report select column to check.
	 * @return {number}
	 */
	selectColumnSortOrdinal(selectColumn) {
		const sort = getArray(this.props, 'sort');
		return findIndex(sort, i => get(i, 'column.GUIID') === get(selectColumn, 'GUIID'));
	}

	/**
	 * Get select column sort direction
	 * @note If select column is not included in sort, this method will return an empty string.
	 * @param {ReportColumnDataObject} selectColumn - Report select column to check.
	 * @return {SortOrder|''}
	 */
	selectColumnSortDir(selectColumn) {
		const sortColumn = this.selectColumnInSort(selectColumn);
		return (sortColumn ? sortColumn.direction : '');
	}

	/**
	 * Add select column as a new sort column
	 * @param {ReportColumnDataObject} selectColumn - Report select column.
	 * @param {SortOrder} direction - Sort direction.
	 */
	addColumnToSort(selectColumn, direction) {
		const {addSortColumnAction} = this.props;
		const sortColumn = this.selectColumnInSort(selectColumn);
		if (typeof sortColumn === 'undefined') addSortColumnAction(selectColumn, direction);
		else this.updateSortColumnDir(sortColumn, direction);
	}

	/**
	 * Update sort column direction
	 * @param {ReportColumnSortDataObject} sortColumn - Sort column to update.
	 * @param {SortOrder} newDirection - New sort direction to set.
	 */
	updateSortColumnDir(sortColumn, newDirection) {
		const {saveReportValueItemAction} = this.props;
		saveReportValueItemAction({...sortColumn, direction: newDirection}, 'sort');
	}

	/**
	 * Remove column from sort
	 * @param {string} sortColumnGUIID - GUI ID of the sort column to remove. 
	 */
	removeColumnFromSort(sortColumnGUIID) {
		const {removeReportValueItemAction} = this.props;
		removeReportValueItemAction(sortColumnGUIID, 'sort');
	}


	// Dialog methods ---------------------------------------------------------------------------------------------------
	/**
	 * Open select column formula dialog
	 */
	openFormulaDialog() {
		const {openDialogAction, closeDialogAction, saveReportValueItemAction} = this.props;
		
		const dialogGUIID = openDialogAction('', FormulaDialog, {
			data: this.getData(),
			onSave: data => {
				saveReportValueItemAction(data, 'selectColumns');
				closeDialogAction(dialogGUIID);
			},
			onClose: () => closeDialogAction(dialogGUIID),
		}, {
			id: 'SelectColumnFormulaDialog',
			className: 'bordered-title',
			closeOnEscape: false,
			closeOnClickOutside: false,
			hideCloseBtn: false,
			maxWidth: 1200
		});
	}

	/**
	 * Open select column properties dialog
	 */
	openPropertiesDialog() {
		const {openDialogAction, closeDialogAction, saveReportValueItemAction} = this.props;

		const dialogGUIID = openDialogAction('', PropertiesDialog, {
			data: this.getData(),
			onSave: data => {
				saveReportValueItemAction(data, 'selectColumns');
				closeDialogAction(dialogGUIID);
			},
			onClose: () => closeDialogAction(dialogGUIID),
		}, {
			id: 'SelectColumnPropertiesDialog',
			className: 'bordered-title',
			closeOnEscape: false,
			closeOnClickOutside: false,
			hideCloseBtn: false,
			maxWidth: 1200
		});
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {className, GUIID, saveReportValueItemAction} = this.props;
		const data = this.getData();
		
		return (
			<div id={this.getDomId()} className={`card ${this.getOption('domPrefix')} ${className}`}>
				<Tooltip
					tag="div"
					className="remove-btn-dropdown"
					title={this.t('remove_btn')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
				>
					<Button
						className="remove-btn"
						icon="ui-delete"
						iconProps={{symbolPrefix: 'icofont-'}}
						displayStyle={BUTTON_STYLE.SUBTLE}
						displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
						onClick={() => this.remove(GUIID)}
					/>
				</Tooltip>
				<div className="handle">
					<div className="card-header">
						<Label 
							icon="folder" 
							content={
								getBoolean(data, 'customHeadings') ?
									getString(data, 'groupHeading') :
									getString(data, 'dynamicValue.group.name')
							}
							supportHtml={getBoolean(data, 'htmlAllowedInHeadings')}
						/>
					</div>
					<div className="card-content">
						<Label
							icon={data.aggregateFunction ? 'ruler-alt-2' : 'hashtag'}
							iconSymbolPrefix={data.aggregateFunction ? 'icofont-' : icon_font_symbol_class_prefix}
							content={
								getBoolean(data, 'customHeadings') ?
									getString(data, 'columnHeading') :
									getString(data, 'dynamicValue.name')
							}
							supportHtml={getBoolean(data, 'htmlAllowedInHeadings')}
						/>
					</div>
				</div>
				<div className="card-actions">
					<Tooltip
						tag="div"
						title={this.t('sort_btn')}
						size="small"
						position="top-center"
						arrow={true}
						interactive={false}
					>
						<Dropdown
							className="sort-dropdown"
							label={
								<Button
									className="sort-btn"
									icon="sort"
									iconProps={{symbolPrefix: 'icofont-'}}
									displayStyle={BUTTON_STYLE.SUBTLE}
									displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								>
									{
										this.selectColumnSortOrdinal(data) !== -1 ?
											<Label
												element="div"
												elementProps={{className: 'sort-ordinal'}}
												content={this.selectColumnSortOrdinal(data) + 1}
												icon={
													this.selectColumnSortDir(data) === SORT_ORDER.ASC ? 'arrow-up' : 
													this.selectColumnSortDir(data) === SORT_ORDER.DESC ? 'arrow-down' :
													''
												}
												iconPosition={LABEL_ICON_POSITION.RIGHT}
											/>
											: null
									}
								</Button>
							}
							position={DROPDOWN_POSITION.LEFT}
						>
							<div
								className="dropdown-item"
								onClick={() => this.setSort([new ReportColumnSortDataObject(data, SORT_ORDER.ASC)])}
							>
								<Label content={this.t('Sort ascending')} />
							</div>

							<div
								className="dropdown-item"
								onClick={() => this.setSort([new ReportColumnSortDataObject(data, SORT_ORDER.DESC)])}
							>
								<Label content={this.t('Sort descending')} />
							</div>

							<div
								className="dropdown-item"
								onClick={() => this.addColumnToSort(data, SORT_ORDER.ASC)}
							>
								<Label content={this.t('Add ascending sort')} />
							</div>

							<div
								className="dropdown-item"
								onClick={() => this.addColumnToSort(data, SORT_ORDER.DESC)}
							>
								<Label content={this.t('Add descending sort')} />
							</div>

							<div
								className="dropdown-item"
								onClick={() => 
									this.removeColumnFromSort(getString(this.selectColumnInSort(data), 'GUIID'))
								}
							>
								<Label content={this.t('Clear sort')} />
							</div>

							<div
								className="dropdown-item"
								onClick={() => this.clearSort()}
							>
								<Label content={this.t('Clear all sort in all columns')} />
							</div>
						</Dropdown>
					</Tooltip>
					<Tooltip
						tag="div"
						title={this.t('formula_btn')}
						size="small"
						position="top-center"
						arrow={true}
						interactive={false}
					>
						<Button
							className={`formula-btn ${getArray(data, 'formula').length > 0 ? 'applied' : ''}`}
							displayStyle={BUTTON_STYLE.SUBTLE}
							displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
							onClick={this.openFormulaDialog}
						><span className="icon-function icon" /></Button>
					</Tooltip>
					<Tooltip
						tag="div"
						title={this.t('properties_btn')}
						size="small"
						position="top-center"
						arrow={true}
						interactive={false}
					>
						<Button
							className="properties-btn"
							icon="settings" 
							iconProps={{symbolPrefix: 'icofont-'}}
							displayStyle={BUTTON_STYLE.SUBTLE}
							displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
							onClick={this.openPropertiesDialog}
						/>
					</Tooltip>
					<Tooltip
						tag="div"
						title={this.t('filter_btn')}
						size="small"
						position="top-center"
						arrow={true}
						interactive={false}
					>
						<Button
							className="filter-btn"
							icon="filter"
							iconProps={{symbolPrefix: 'icofont-'}}
							displayStyle={BUTTON_STYLE.SUBTLE}
							displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
							onClick={() => {
								const newFilterItem = new ReportFilterItemDataObject(this.getData());
								saveReportValueItemAction(newFilterItem, 'filter');
								waitingFunction(() => {
									const selector = `#report-builder-filter-item-${newFilterItem.GUIID}`;
									const element = document.querySelector(selector);
									if (element) {
										scrollToSelector(
											selector,
											true,
											'20',
											`.${styles['filterContent']}`
										);
										blinkBackgroundSlowAnimation(element);
										return true;
									}
								}, 10, 500).then();
							}}
						/>
					</Tooltip>
				</div>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
SelectItem.propTypes = {
	...BaseComponent.propTypes,

	// Items GUI ID
	GUIID: PropTypes.string,
};

export default connect(mapStateToProps, getPageActions(builderActions))(SelectItem);