// Import locale policies
import CookiePolicyEnUs from "./en-US";
import CookiePolicySrLatnRS from "./sr-Latn-RS";
import CookiePolicySrCyrlRS from "./sr-Cyrl-RS";

import React from "react";
import {useSelector} from "react-redux";
import {selectors} from "../../../core/store/reducers";

/**
 * Component that renders cookie policy based on the current locale/language
 * @note Locale cookie policies are define in files with locale filename (for example: sr-Latn-RS.js).
 * 
 * @param {any} props - Any props that will be passed down to locale cookie policy component.
 * @return {JSX.Element|null}
 * @constructor
 */
function CookiePolicy(props) {
	const locale = useSelector(selectors.i18n.getLocaleCode);
	
	return (
		locale === 'en-US' ? <CookiePolicyEnUs {...props} /> :
		locale === 'sr-Latn-RS' ? <CookiePolicySrLatnRS {...props} /> :
		locale === 'sr-Cyrl-RS' ? <CookiePolicySrCyrlRS {...props} /> : 
		null
	);
}

export default CookiePolicy;