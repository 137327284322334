import styles from "./index.module.css";

import React from "react";
import BaseComponent, {executeComponentCallback} from "../../BaseComponent";
import PropTypes from "prop-types";
import {getArray} from "../../../helpers/data";
import Icon from "../../display/Icon";
import Label from "../../display/Label";
import {CardsSelectInputItemDataObject} from "./dataObjects";
import {
	CARDS_SELECT_INPUT_CONTENT_DISPLAY_TYPE,
	CARDS_SELECT_INPUT_CONTENT_DISPLAY_TYPES,
	CARDS_SELECT_INPUT_DISPLAY_TYPE,
	CARDS_SELECT_INPUT_DISPLAY_TYPES
} from "./const";
import {getCssSizeString} from "../../../helpers/dom";

class CardsSelectInput extends BaseComponent {
	constructor(props) {
		super(props, {
			domPrefix: 'cards-select-input-component'
		});

		// Render methods
		this.isSelected = this.isSelected.bind(this);
	}
	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Check if a specified item is selected
	 * 
	 * @param {CardsSelectInputItemDataObject} item - Item to check.
	 * @return {boolean}
	 */
	isSelected(item) {
		const {value} = this.props;
		if (value instanceof CardsSelectInputItemDataObject) return item.value === value.value;
		else if (typeof value === 'number' || typeof value === 'string') return item.value === value;
		else return false;
	}
	
	render() {
		const {
			className, itemClassName, itemContentClassName, itemIconClassName, itemLabelClassName, 
			itemDescriptionClassName, displayType, simpleValue, gridStyles, listStyles, size, contentDisplayType, 
		} = this.props;

		/** @type {CardsSelectInputItemDataObject[]} */
		const options = getArray(this.props, 'options');
		
		return (
			<div 
				id={this.getDomId()} 
				className={
					`${this.getOption('domPrefix')} ${styles['wrapper']} ${className} ` +
					`type-${displayType} ${styles[`type${displayType}`]} `
				}
				style={
					displayType === CARDS_SELECT_INPUT_DISPLAY_TYPE.GRID ? {
							gridTemplateColumns: 'repeat(3, 1fr)',
							gridColumnGap: '20px',
							gridRowGap: '20px',
							...gridStyles
					} :
					displayType === CARDS_SELECT_INPUT_DISPLAY_TYPE.LIST ? listStyles :
					{}
				}
			>
				{options.map(i =>
					<div 
						key={i.value}
						tabIndex={0}
						className={
							`${this.getOption('domPrefix')}-item ${styles['item']} ${itemClassName} no-select` + 
							`${this.isSelected(i) ? ` selected ${styles['selected']}` : ''} ` + 
							`item-type-${contentDisplayType} ${styles[`itemType${contentDisplayType}`]} ` +
							`${i.icon ? `with-icon ${styles['withIcon']}` : `without-icon ${styles['withoutIcon']}`}`
						}
						onClick={e => {
							// Deselect on Ctrl+Click or if already selected
							if (e.ctrlKey || this.isSelected(i)) {
								executeComponentCallback(this.props.onChange, (simpleValue ? '' : null));
							}
							// Select on Click if not already selected
							else {
								executeComponentCallback(this.props.onChange, (simpleValue ? i.value : i));
							}
						}}
						onKeyDown={e => {
							if (e.nativeEvent.code === 'Enter' || e.nativeEvent.code === 'Space') {
								// Deselect if already selected
								if (this.isSelected(i)) {
									executeComponentCallback(this.props.onChange, (simpleValue ? '' : null));
								}
								// Select if not already selected
								else {
									executeComponentCallback(this.props.onChange, (simpleValue ? i.value : i));
								}
							}
						}}
						style={{fontSize: getCssSizeString(size)}}
					>
						<div 
							className={
								`${this.getOption('domPrefix')}-item-content ${styles['itemContent']} ` +
								`${itemContentClassName} `
							}
						>
							{
								i.icon ? 
									<Icon 
										className={
											`${this.getOption('domPrefix')}-item-icon ${styles['itemIcon']} ` +
											`${itemIconClassName} `
										}
										symbol={i.icon} 
										symbolPrefix={i.iconSymbolPrefix}
									/>
									: null
							}
							
							<div className={styles['text']}>
								<Label
									element="div"
									elementProps={{
										className: `${this.getOption('domPrefix')}-item-label ${styles['itemLabel']} ` +
											`${itemLabelClassName} `
									}}
									content={i.label}
								/>

								{
									i.description ?
										<Label
											element="div"
											elementProps={{
												className: `${this.getOption('domPrefix')}-item-desc ${styles['itemDesc']} ` +
													`${itemDescriptionClassName} `
											}}
											content={i.description}
										/>
										: null
								}
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
CardsSelectInput.propTypes = {
	// Wrapper element's id attribute
	id: PropTypes.string,
	// Wrapper element's class attribute
	className: PropTypes.string,
	// Item element's class attribute
	itemClassName: PropTypes.string,
	// Item's content element's class attribute
	itemContentClassName: PropTypes.string,
	// Item's icon's class attribute
	itemIconClassName: PropTypes.string,
	// Item's label's class attribute
	itemLabelClassName: PropTypes.string,
	// Item's description's class attribute
	itemDescriptionClassName: PropTypes.string,
	// Card select input display type
	displayType: PropTypes.oneOf(CARDS_SELECT_INPUT_DISPLAY_TYPES),
	// Grid CSS styles object
	gridStyles: PropTypes.object,
	// List CSS styles object
	listStyles: PropTypes.object,
	// Item size
	size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	// Display type of the item content
	contentDisplayType: PropTypes.oneOf(CARDS_SELECT_INPUT_CONTENT_DISPLAY_TYPES),

	// Flag that specifies if component will use a simple value (not an object) to load and return data through the 
	// 'onChange' event. Component will always use a CardsSelectInputItemDataObject data object internally.
	simpleValue: PropTypes.bool,
	// Select option items
	// @type {CardsSelectInputItemDataObject[]}
	options: PropTypes.arrayOf(PropTypes.object),
	// Current item
	value: PropTypes.oneOfType([
		// Item object used only if 'simpleValue' is false
		// @type {CardsSelectInputItemDataObject}
		PropTypes.object, 
		// Item id used only if 'simpleValue' is true
		PropTypes.string,
		// Item id used only if 'simpleValue' is true
		PropTypes.number
	]),

	// Events
	onChange: PropTypes.func, // Arguments: {CardsSelectInputItemDataObject|string|number} selected option depending on 'simpleValue'
};

/**
 * Define component default values for own props
 */
CardsSelectInput.defaultProps = {
	id: '',
	className: '',
	itemClassName: 'card',
	itemContentClassName: 'card-content',
	itemIconClassName: '',
	itemLabelClassName: '',
	itemDescriptionClassName: '',
	displayType: CARDS_SELECT_INPUT_DISPLAY_TYPE.GRID,
	gridStyles: {
		gridTemplateColumns: 'repeat(3, 1fr)',
		gap: '20px',
	},
	listStyles: {
		
	},
	size: '1em',
	contentDisplayType: CARDS_SELECT_INPUT_CONTENT_DISPLAY_TYPE.STACKED,
	simpleValue: true,
	options: [],
	value: '',
};

export default CardsSelectInput;