import {get} from 'lodash';
import {getBoolean, getInteger, getString} from "../../core/helpers/data";
import {SORT_ORDER} from "../../core/const/global";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'accountRoles';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@accountRoles/reset',
	SET_ACCOUNT_ROLE_LIST_DATA: '@accountRoles/set_account_role_list_data',
	CLEAR_ACCOUNT_ROLE_LIST_DATA: '@accountRoles/clear_account_role_list_data',
	SET_ACCOUNT_ROLE: '@accountRoles/set_account_role',
	CLEAR_ACCOUNT_ROLE: '@accountRoles/clear_account_role',
	
	SET_ALL_PERMISSIONS: '@accountRoles/set_all_permissions',
	CLEAR_ALL_PERMISSIONS: '@accountRoles/clear_all_permissions',
};

// define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	/**
	 * @param {IoJsonFetchResponseObject} accountRoleListData
	 * @return {{type: string, accountRoleListData: IoJsonFetchResponseObject}}
	 */
	setAccountRoleListData: accountRoleListData => ({
		type: REDUCER_TYPES.SET_ACCOUNT_ROLE_LIST_DATA, 
		accountRoleListData
	}),
	clearAccountRoleListData: () => ({type: REDUCER_TYPES.CLEAR_ACCOUNT_ROLE_LIST_DATA}),
	/**
	 * @param {AccountRoleDataObject} accountRole
	 * @return {{type: string, accountRole: AccountRoleDataObject}}
	 */
	setAccountRole: accountRole => ({type: REDUCER_TYPES.SET_ACCOUNT_ROLE, accountRole }),
	clearAccountRole: () => ({type: REDUCER_TYPES.CLEAR_ACCOUNT_ROLE }),
	
	/**
	 * @param {AccountPermissionDataObject[]} allPermissions
	 * @return {{type: string, allPermissions: AccountPermissionDataObject[]}}
	 */
	setAllPermissions: allPermissions => ({type: REDUCER_TYPES.SET_ALL_PERMISSIONS, allPermissions}),
	clearAllPermissions: () => ({type: REDUCER_TYPES.CLEAR_ALL_PERMISSIONS}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/** @type {IoJsonFetchResponseObject} */
	accountRoleListData: null,

	/**
	 * @note Value is null to differentiate between unloaded and new items (new items should be a data object with
	 * default values).
	 * @type {AccountRoleDataObject} 
	 */
	accountRole: null,

	/**
	 * @note Value is null to specify that the list is not loaded. List of all permissions will be cached here once it 
	 * has been loaded for the first time.
	 * @type {AccountPermissionDataObject[]|null}
	 */
	allPermissions: null,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET:
			return { ...initialState };

		case REDUCER_TYPES.SET_ACCOUNT_ROLE_LIST_DATA: 
			return {...state, accountRoleListData: action.accountRoleListData};
		case REDUCER_TYPES.CLEAR_ACCOUNT_ROLE_LIST_DATA:
			return {...state, accountRoleListData: initialState.accountRoleListData};

		case REDUCER_TYPES.SET_ACCOUNT_ROLE: return {...state, accountRole: action.accountRole};
		case REDUCER_TYPES.CLEAR_ACCOUNT_ROLE: return {...state, accountRole: initialState.accountRole};
		
		case REDUCER_TYPES.SET_ALL_PERMISSIONS: return {...state, allPermissions: action.allPermissions};
		case REDUCER_TYPES.CLEAR_ALL_PERMISSIONS: return {...state, allPermissions: initialState.allPermissions};

		default:
			return state;
	}
};

// Selectors
export const selectors = {
	getAccountRoleList: state => 
		get(state, [reducerStoreKey, 'accountRoleListData', 'data']),
	getAccountRoleListPagination: state => ({
		perPage: getInteger(state, [reducerStoreKey, 'accountRoleListData', 'perPage']),
		pageNo: getInteger(state, [reducerStoreKey, 'accountRoleListData', 'pageNo']),
		isLastPage: getBoolean(state, [reducerStoreKey, 'accountRoleListData', 'isLastPage']) 
	}),
	getAccountRoleListSort: state => ({
		sortBy: getString(state, [reducerStoreKey, 'accountRoleListData', 'sortBy']),
		sortDir: getString(state, [reducerStoreKey, 'accountRoleListData', 'sortDir'], SORT_ORDER.ASC)
	}),
	getAccountRoleListFilter: state => 
		get(state, [reducerStoreKey, 'accountRoleListData', 'filter'], null),

	getAccountRole: state => get(state, [reducerStoreKey, 'accountRole']),
	
	getAllPermissions: state => get(state, [reducerStoreKey, 'allPermissions'], initialState.allPermissions),
};

export default reducer;