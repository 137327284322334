import {find, get} from "lodash";
import countryList from "../../country/countries";
import {default_country} from "../../config";

/**
 * Country class
 */
class Country {
	/**
	 * Find country by country code
	 * @param {string} countryCode - ISO 3166-1 alpha-3 three-letter uppercase country code (ex: 'SRB').
	 * @return {CountryObj|undefined}
	 */
	static findCountryByCode(countryCode) { return find(countryList, {code: countryCode.toUpperCase()}); }
	
	/**
	 * Get application's country
	 * @return {CountryObj}
	 */
	static getAppCountry() {
		// Try to detect the country using the current URL
		const country = find(countryList, c => new RegExp(c.urlRegExp, 'gmi').test(window.location.href));
		
		// Return the detected country or a default country from app config if it was not detected
		return (country ? country : default_country)
	}

	/**
	 * Get the public base URL of the application based on the country
	 * @return {string}
	 */
	static getAppCountryUrl() { return this.getAppCountry().url; }

	/**
	 * Get the list of all countries
	 * @return {CountryObj[]}
	 */
	static getAppCountries() { return countryList; }

	/**
	 * Get the default application locale
	 * @return {LocaleObj}
	 */
	static getAppDefaultLocale() { return this.getAppCountry().defaultLocale; }
	
	/**
	 * Get the list of allowed locales for the application
	 * @return {LocaleObj[]}
	 */
	static getAppLocaleList() { return this.getAppCountry().allowedLocales; }

	/**
	 * Get custom country value
	 *
	 * @param {string|string[]} path - Custom value path string or an array of path segments in the options object. If 
	 * empty or not specified all custom values will be returned.
	 * @param {any} [defaultValue] - Default value to return if custom value is not defined.
	 * @return {any} Component's option value.
	 */
	static getCustomValue(path, defaultValue) {
		let customValues = get(this.getAppCountry(), 'customValues');
		if (!customValues) customValues = {};
		return get(customValues, path, defaultValue);
	}
}

export default Country;