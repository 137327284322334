import {
	REPORT_CHART_CATEGORY_OUTPUT_TYPES,
	REPORT_FILE_TYPE_FOR_OUTPUT, 
	REPORT_OUTPUT_CHART_TYPES,
	REPORT_OUTPUT_TYPES
} from "../const/report";
import {arrayIncludes, getArray, getString} from "../core/helpers/data";
import {forOwn, isPlainObject} from "lodash";
import {ReportDataObject} from "../dataObjects/report";

/**
 * Validate a report data object
 * @note This function does not validate every report property in detail. It performs a basic check required for the app 
 * to detect some issues and work properly. To better understand what is being checked see the list of error codes it 
 * can return.
 * 
 * @param {any} report - Report to validate.
 * @return {number} Returns a number representing error code or 0 if validation passed.
 * Error codes:
 * 	- 100: Report not specified
 * 	- 101: Specified report is not an instance of ReportDataObject
 * 	- 200: Report's data source not specified
 * 	- 201: Report's data source is not an instance of ReportDataSourceObject
 * 	- 202: Report's data source ID not specified
 * 	- 300: Report's output type not specified
 * 	- 301: Unknown report's output type (must be one of REPORT_OUTPUT_TYPES)
 * 	- 400: Report's file type is not supported for the specified output type	
 */
export const validateReport = report => {
	/**
	 * Define report object properties
	 * @type {string[]}
	 */
	const props = [
		"dataSource",
		"outputType",
		"fileType",
		"title",
		"selectColumns",
		"filter",
		"sort",
	];
	
	// Check if report is specified (required)
	if (!report) return 100;
	// Check if report object has all the necessary properties
	if (typeof report !== 'object' || !arrayIncludes(Object.getOwnPropertyNames(report), props)) return 101;
	
	// Check data source (required)
	if (!report.dataSource) return 200;
	if (typeof report.dataSource !== 'object') return 201;
	if (!getString(report, 'dataSource.id')) return 202;
	
	// Check output type (required)
	if (!report.outputType) return 300;
	if (!REPORT_OUTPUT_TYPES.includes(report.outputType)) return 301;

	// Check file type (not required)
	const outputFileTypes = getArray(REPORT_FILE_TYPE_FOR_OUTPUT, report.outputType);
	if (report.fileType && !outputFileTypes.includes(report.fileType)) return 400;
	
	// Validation passed
	return 0;
};

/**
 * Check if report is a chart report 
 * @param {ReportDataObject | ReportOutputType} report - Report object or output type.
 * @return {boolean}
 */
export const isChartReport = report => REPORT_OUTPUT_CHART_TYPES.includes(
	report instanceof ReportDataObject || isPlainObject(report) ? report.outputType : report
);

/**
 * Get report chart category
 * 
 * @param {ReportDataObject | ReportOutputType} report - Report object or output type.
 * @return {ReportChartCategory|''}
 */
export const getChartCategory = report => {
	let result = '';
	forOwn(REPORT_CHART_CATEGORY_OUTPUT_TYPES, (outputTypes, category) => {
		if (result === '' && outputTypes.includes(
			report instanceof ReportDataObject || isPlainObject(report) ? report.outputType : report)
		) {
			result = category;
		}
	});
	return result;
}