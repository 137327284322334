// Dynamic date values
/** @typedef {string} RelativeDateValueUnit */
export const RELATIVE_DATE_VALUE_UNIT_DAYS = 'DAYS';
export const RELATIVE_DATE_VALUE_UNIT_MONTHS = 'MONTHS';
export const RELATIVE_DATE_VALUE_UNIT_YEARS = 'YEARS';
/** @enum {RelativeDateValueUnit} */
export const RELATIVE_DATE_VALUE_UNIT = {
	DAYS: RELATIVE_DATE_VALUE_UNIT_DAYS,
	MONTHS: RELATIVE_DATE_VALUE_UNIT_MONTHS,
	YEARS: RELATIVE_DATE_VALUE_UNIT_YEARS
};
/** @type {RelativeDateValueUnit[]} */
export const RELATIVE_DATE_VALUE_UNITS = [
	RELATIVE_DATE_VALUE_UNIT_DAYS,
	RELATIVE_DATE_VALUE_UNIT_MONTHS,
	RELATIVE_DATE_VALUE_UNIT_YEARS
];
export const RELATIVE_DATE_VALUE_PREFIX = '##';
export const RELATIVE_DATE_VALUE_SUFFIX = '##';
/**
 * @typedef {Object} RelativeDateValue
 * @property {string} code - Relative date value code used as the body of the value.
 * @property {boolean} [modifiable] - Flag that determines if value can be modified by adding or subtracting something.
 * @property {RelativeDateValueUnit[]} [units] - If value is modifiable these are teh available modifiers.
 * @property {boolean} [isNumeric] - Flag that determines if value can be parsed as a number.
 */
/** @type {RelativeDateValue[]} */
export const RELATIVE_DATE_VALUES = [
	// Fully modifiable with units
	// @example '##TODAY-5DAYS##'
	{code: 'TODAY', modifiable: true, units: RELATIVE_DATE_VALUE_UNITS},
	{code: 'YESTERDAY', modifiable: true, units: RELATIVE_DATE_VALUE_UNITS},
	{code: 'FIRST_OF_CURRENT_MONTH', modifiable: true, units: RELATIVE_DATE_VALUE_UNITS},
	{code: 'LAST_OF_CURRENT_MONTH', modifiable: true, units: RELATIVE_DATE_VALUE_UNITS},

	// Modifiable
	// @example '##CURRENT_YEAR+2##'
	{code: 'CURRENT_MONTH_OF_YEAR', modifiable: true},
	{code: 'CURRENT_QUARTER_OF_YEAR', modifiable: true},
	{code: 'CURRENT_YEAR', modifiable: true, isNumeric: true},

	// Simple
	// @example '##CURRENT_MONTH##'
	{code: 'CURRENT_MONTH', isNumeric: true},
	{code: 'CURRENT_QUARTER', isNumeric: true}
];