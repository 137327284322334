import {cloneDeep} from "lodash";
import {getBoolean, getString, resetObjectValues} from "../../../core/helpers/data";
import {AccountListItemDataObject} from "../dataObjects";


/**
 * Get input data from raw data
 * @param {{}} rawData - Raw data.
 * @return {AccountListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new AccountListItemDataObject(
		getString(result, 'id'),
		getString(result, 'userName'),
		getString(result, 'userType'),
		getString(result, 'email'),
		getString(result, 'firstName'),
		getString(result, 'lastName'),
		getBoolean(result, 'active'),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {AccountListItemDataObject} inputData - Input data.
 * @return {{}}
 */
export const output = inputData =>  {
	let result = cloneDeep(inputData);

	result = {
		id: getString(result, 'id'),
		userName: getString(result, 'userName'),
		userType: getString(result, 'userType'),
		email: getString(result, 'email'),
		firstName: getString(result, 'firstName'),
		lastName: getString(result, 'lastName'),
		active: getBoolean(result, 'active'),
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}