import {get} from "lodash";
import {getBoolean, getInteger} from "../../core/helpers/data";
import {pagination_default_per_page} from "../../config";
import {getString} from "../../core/components/global/Message/reducer";
import {SORT_ORDER} from "../../core/const/global";
import {ScheduleItemDataObject} from "./dataObjects";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'schedule';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@schedule/reset',
	SET_SCHEDULE_DATA: '@schedule/set_schedule_data',
	CLEAR_SCHEDULE_DATA: '@schedule/clear_schedule_data',
	SET_SCHEDULE: '@schedule/set_schedule',
	SET_SCHEDULE_ITEM: '@schedule/set_schedule_item',
	CLEAR_SCHEDULE_ITEM: '@schedule/clear_schedule_item',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	setScheduleData: scheduleData => ({type: REDUCER_TYPES.SET_SCHEDULE_DATA, scheduleData}),
	clearScheduleData: () => ({type: REDUCER_TYPES.CLEAR_SCHEDULE_DATA}),
	setScheduleItem: scheduleItem => ({type: REDUCER_TYPES.SET_SCHEDULE_ITEM, scheduleItem}),
	clearScheduleItem: () => ({type: REDUCER_TYPES.CLEAR_SCHEDULE_ITEM}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	// Schedule data retrieved from API (including filter, pagination, sort and other data)
	scheduleData: null,
	// Selected schedule item used by the schedule item popup
	scheduleItem: new ScheduleItemDataObject(),
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET:
			return {...initialState};
			
		case REDUCER_TYPES.SET_SCHEDULE_DATA:
			return {...state, scheduleData: action.scheduleData};
		case REDUCER_TYPES.CLEAR_SCHEDULE_DATA:
			return {...state, scheduleData: initialState.scheduleData};
			
		case REDUCER_TYPES.SET_SCHEDULE_ITEM:
			return {...state, scheduleItem: action.scheduleItem};
		case REDUCER_TYPES.CLEAR_SCHEDULE_ITEM:
			return {...state, scheduleItem: initialState.scheduleItem};

		default:
			return state;
	}
};

// Selectors
export const selectors = {
	getSchedule: state => get(state, [reducerStoreKey, 'scheduleData', 'data']),
	getSchedulePagination: state => ({
		pageNo: getInteger(state, [reducerStoreKey, 'scheduleData', 'pageNo'], 1),
		perPage: getInteger(state, [reducerStoreKey, 'scheduleData', 'perPage'], pagination_default_per_page),
		isLastPage: getBoolean(state, [reducerStoreKey, 'scheduleData', 'isLastPage'])
	}),
	getScheduleSort: state => ({
		sortBy: getString(state, [reducerStoreKey, "scheduleData", "sortBy"]),
		sortDir: getString(state, [reducerStoreKey, "scheduleData", "sortDir"], SORT_ORDER.DESC)
	}),
	getScheduleFilter: state => get(state, [reducerStoreKey, "scheduleData", "filter"], null),
	
	getScheduleItem: state => get(state, [reducerStoreKey, 'scheduleItem']),
};

export default reducer;