export const DATE_LABEL_ICON_POSITION_NONE = '';
export const DATE_LABEL_ICON_POSITION_LEFT = 'left';
export const DATE_LABEL_ICON_POSITION_RIGHT = 'right';
export const DATE_LABEL_ICON_POSITION = {
	NONE: DATE_LABEL_ICON_POSITION_NONE,
	LEFT: DATE_LABEL_ICON_POSITION_LEFT,
	RIGHT: DATE_LABEL_ICON_POSITION_RIGHT
};
export const DATE_LABEL_ICON_POSITIONS = [
	DATE_LABEL_ICON_POSITION_NONE, DATE_LABEL_ICON_POSITION_LEFT, DATE_LABEL_ICON_POSITION_RIGHT
];