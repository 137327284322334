import './config/icons';
import './config/flags';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import reduxStore from './core/store';
import {BrowserRouter as Router, HashRouter as StandaloneRouter} from "react-router-dom";

ReactDOM.render(
	<React.StrictMode>
		<Provider store={reduxStore}>
			{
				process.env.PUBLIC_URL === '.' ?
					<StandaloneRouter basename={process.env.REACT_APP_BROWSER_ROUTER_BASE_NAME}>
						<App store={reduxStore} />
					</StandaloneRouter>
					:
					<Router basename={process.env.REACT_APP_BROWSER_ROUTER_BASE_NAME}>
						<App store={reduxStore} />
					</Router>
			}
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
