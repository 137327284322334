/**
 * @note This component uses a third-party library 'date-fns' (https://date-fns.org/) for managing and rendering dates.
 */
import React from "react";
import BaseComponent from "../../BaseComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {format} from "date-fns";
import {selectors} from "../../../store/reducers";
import {DATE_LABEL_ICON_POSITION} from "./const";
import {LOCALE_DATE_FORMAT_NAME, LOCALE_DATE_FORMAT_NAMES, LOCALE_TIME_FORMAT_NAMES} from "../../../const/locale";
import {getDate} from "../../../helpers/datetime";
import {getDateLocale, getLocaleDateFormat, getLocaleTimeFormat} from "../../../helpers/locale";

import Icon from "../Icon";

import {Tooltip} from "react-tippy";
import 'react-tippy/dist/tippy.css'
import {STANDARD_DATE_TIME_FORMAT} from "../../../const/datetime";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	appLocale: selectors.i18n.getLocale(state)
});

class DateLabel extends BaseComponent {
	constructor(props) {
		super(props);
		
		// Custom component methods
		this.getInputLocale = this.getInputLocale.bind(this);
		this.getOutputLocale = this.getOutputLocale.bind(this);
		this.getInputFormat = this.getInputFormat.bind(this);
		this.getOutputFormat = this.getOutputFormat.bind(this);
		this.getDefault = this.getDefault.bind(this);
		this.getContent = this.getContent.bind(this);
		
		// Render methods
		this.renderLabelContent = this.renderLabelContent.bind(this);
	}

	/**
	 * Get locale used for converting input date string into date
	 * @return {Locale|null} Locale to use in 'date-fns' functions or null if locale should not be used or it cannot be
	 * found.
	 */
	getInputLocale() {
		const {useAppLocale, inputLocale, appLocale} = this.props;

		let result = null;
		if (inputLocale) result = inputLocale;
		else if (useAppLocale) result = getDateLocale(appLocale);
		return result;
	}

	/**
	 * Get locale used for rendering the data label based on specified props
	 * @return {Locale|null} Locale to use in 'date-fns' functions or null if locale should not be used or it cannot be
	 * found.
	 */
	getOutputLocale() {
		const {useAppLocale, outputLocale, appLocale} = this.props;

		let result = null;
		if (outputLocale) result = outputLocale;
		else if (useAppLocale) result = getDateLocale(appLocale);
		return result;
	}

	/**
	 * Get input format used for rendering the data label based on specified props 
	 * @return {string}
	 */
	getInputFormat() {
		const {inputFormat, appLocale} = this.props;
		
		if (Array.isArray(inputFormat)) {
			if (inputFormat.length === 3) {
				const dateFormat = (
					LOCALE_DATE_FORMAT_NAMES.includes(inputFormat[0]) ?
						getLocaleDateFormat(appLocale, inputFormat[0]) : inputFormat[0]
				);
				const dateTimeSeparator = inputFormat[1];
				const timeFormat = (
					LOCALE_TIME_FORMAT_NAMES.includes(inputFormat[2]) ?
						getLocaleTimeFormat(appLocale, inputFormat[2]) : inputFormat[2]
				);
				return dateFormat + dateTimeSeparator + timeFormat;
			} else {
				return '';
			}
		} else {
			return (
				LOCALE_DATE_FORMAT_NAMES.includes(inputFormat) ?
					getLocaleDateFormat(appLocale, inputFormat) : inputFormat
			);
		}
	}
	
	/**
	 * Get output format used for rendering the data label based on specified props
	 * @return {string}
	 */
	getOutputFormat() {
		const {outputFormat, useAppLocale, outputLocale, appLocale} = this.props;
		const defaultFormat = (!outputLocale && useAppLocale ?
			getLocaleDateFormat(appLocale, LOCALE_DATE_FORMAT_NAME.SHORT) : 'P'
		);
		
		if (Array.isArray(outputFormat)) {
			if (outputFormat.length === 3) {
				const dateFormat = (
					LOCALE_DATE_FORMAT_NAMES.includes(outputFormat[0]) ?
						getLocaleDateFormat(appLocale, outputFormat[0]) : outputFormat[0]
				);
				const dateTimeSeparator = outputFormat[1];
				const timeFormat = (
					LOCALE_TIME_FORMAT_NAMES.includes(outputFormat[2]) ?
						getLocaleTimeFormat(appLocale, outputFormat[2]) : outputFormat[2]
				);
				return dateFormat + dateTimeSeparator + timeFormat;
			} else {
				return defaultFormat;
			}
		} else {
			return (
				typeof outputFormat !== 'undefined' ?
					(
						LOCALE_DATE_FORMAT_NAMES.includes(outputFormat) ?
							getLocaleDateFormat(appLocale, outputFormat) : outputFormat
					) : 
					defaultFormat
			);
		}
	}

	/**
	 * Get default output string to render based on specified props
	 * @return {string}
	 */
	getDefault() {
		const {defaultDate, defaultOutput} = this.props;
		const inputLocale = this.getInputLocale();
		const outputLocale = this.getOutputLocale();
		const inputFormat = this.getInputFormat();
		const outputFormat = this.getOutputFormat();
		
		try {
			const date = getDate(defaultDate, inputFormat, inputLocale);
			if (defaultDate) return format(date, outputFormat,{locale: outputLocale});
			else return defaultOutput;
		} catch (e) {
			return defaultOutput;
		}
	}

	/**
	 * Get content to render as date label
	 * @note Prefix, suffix and icon will be rendered according to props values.
	 * @return {string}
	 */
	getContent() {
		const {inputDate} = this.props;
		const inputLocale = this.getInputLocale();
		const outputLocale = this.getOutputLocale();
		const inputFormat = this.getInputFormat();
		const outputFormat = this.getOutputFormat();
		const date = getDate(inputDate, inputFormat, inputLocale);
		const defaultOutput = this.getDefault();
		
		try {
			return (date ? format(date, outputFormat, {locale: outputLocale}) : defaultOutput);
		} catch (e) {
			return defaultOutput;
		}
	}

	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render label content
	 */
	renderLabelContent() {
		const {prefix, suffix, icon, iconPosition, iconSpin} = this.props;
		const content = this.getContent();

		// Get Icon component CSS style based on context
		let iconStyle = {};
		if (icon && content) {
			if (iconPosition === DATE_LABEL_ICON_POSITION.LEFT) iconStyle.marginRight = '0.5em';
			else if (iconPosition === DATE_LABEL_ICON_POSITION.RIGHT) iconStyle.marginLeft = '0.5em';
			else iconStyle.marginLeft = '';
		}

		// Check icon position
		const lIcon = (
			(iconPosition === DATE_LABEL_ICON_POSITION.LEFT || iconPosition === DATE_LABEL_ICON_POSITION.NONE) && icon
		);
		const rIcon = (iconPosition === DATE_LABEL_ICON_POSITION.RIGHT && icon);
		
		return (
			<React.Fragment>
				{prefix ? <span className="label-prefix">{prefix}</span> : null}
				{lIcon ? <Icon symbol={icon} spin={iconSpin} style={iconStyle} className="label-icon left" /> : null}
				{content}
				{rIcon ? <Icon symbol={icon} spin={iconSpin} style={iconStyle} className="label-icon right" /> : null}
				{suffix ? <span className="label-suffix">{suffix}</span> : null}
			</React.Fragment>
		);
	}

	render() {
		const {element, elementProps, tooltip, tooltipOptions} = this.props;
		
		// Convert camelCase (in this case lowercase) prop name into PascalCase
		// @note This is done because react component props use camelCase (JSX attributes use camelCase) by convention, but 
		// rendered React JSX component must be capitalized (PascalCase).
		const Element = element;

		return (
			Element ?
				tooltip ?
					<Tooltip
						tag="span" title={tooltip} size="small" position="top-center" arrow={true} interactive={false}
						{...tooltipOptions}
					>
						<Element {...elementProps}>
							{this.renderLabelContent()}
						</Element>
					</Tooltip>
					:
					<Element {...elementProps}>{this.renderLabelContent()}</Element>
				:
				<React.Fragment>
					{tooltip ?
						<Tooltip
							tag="span" title={tooltip} size="small" position="top-center" arrow={true} interactive={false}
							{...tooltipOptions}
						>
							{this.renderLabelContent()}
						</Tooltip>
						:
						this.renderLabelContent()
					}
				</React.Fragment>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
DateLabel.propTypes = {
	// Name of the wrapper HTML element (like 'i', 'span', ...) used to render the label
	// @note If not specified, wrapper will not be rendered.
	element: PropTypes.string,
	// Element attributes of the wrapper HTML element
	// @note If 'element' prop is not set, this value will be ignored. 
	elementProps: PropTypes.object,
	// Date label input date
	// @note Can be :
	// 	* number - Milliseconds timestamp.
	// 	* Object - JavaScript Date object.
	// 	* string - Any string date in which case 'inputFormat' prop will be used to determine the format.
	inputDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
	// Date label input date format
	// @note This supports LOCALE_DATE_FORMAT_NAMES and LOCALE_TIME_FORMAT_NAMES strings that use current app locale 
	// formats (@see app/i18n/locale.js) or any custom format (like 'MM/dd/yyyy'). If array, first item should be date 
	// format, second item should be date-time separator and third item should be time format.
	inputFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	// Date label output date format used to render the label
	// @note This supports LOCALE_DATE_FORMAT_NAMES and LOCALE_TIME_FORMAT_NAMES strings that use current app locale 
	// formats (@see app/i18n/locale.js) or any custom format (like 'MM/dd/yyyy'). If array, first item should be date 
	// format, second item should be date-time separator and third item should be time format.
	outputFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	// Default date used if date could not be rendered (undefined, empty, could not be converted, ...).
	// @note Can be :
	// 	* number - Milliseconds timestamp.
	// 	* Object - JavaScript Date object.
	// 	* string - Any string date in which case 'inputFormat' prop will be used to determine the format.
	defaultDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
	// Default output string that will be rendered if date could not be rendered (undefined, empty, could not be 
	// converted, ...).
	// @note This will only be used if 'defaultDate' prop is not defined.
	defaultOutput: PropTypes.string,
	// Date label output prefix
	prefix: PropTypes.any,
	// Date label output suffix
	suffix: PropTypes.any,
	// Icon symbol name
	icon: PropTypes.string,
	// Icon position
	iconPosition: PropTypes.oneOf([
		DATE_LABEL_ICON_POSITION.NONE, DATE_LABEL_ICON_POSITION.RIGHT, DATE_LABEL_ICON_POSITION.LEFT
	]),
	// If true, icon should spin.
	// @note Please not that this will work only if font icon set supports it and it's properly configured.
	iconSpin: PropTypes.bool,
	// Tooltip text for the date label
	tooltip: PropTypes.string,
	// Tooltip options
	tooltipOptions: PropTypes.object,
	// Flag that determines if current app locale will be used for both input and output
	useAppLocale: PropTypes.bool,
	// Locale to use for converting input date string into Date
	// @note If specified, 'useAppLocale' prop will be ignored.
	inputLocale: PropTypes.object,
	// Locale to use for rendering the date
	// @note If specified, 'useAppLocale' prop will be ignored.
	outputLocale: PropTypes.object,
};

/**
 * Define component default values for own props
 */
DateLabel.defaultProps = {
	element: '',
	elementProps: {},
	inputFormat: STANDARD_DATE_TIME_FORMAT.MYSQL_DATE,
	defaultOutput: '',
	prefix: '',
	suffix: '',
	icon: '',
	iconPosition: DATE_LABEL_ICON_POSITION.LEFT,
	iconSpin: false,
	tooltip: '',
	useAppLocale: true
};

export default connect(mapStateToProps, null)(DateLabel);
export * from "./const";