export const PAGINATION_TYPE_NONE = '';
export const PAGINATION_TYPE_STATIC = 'static';
export const PAGINATION_TYPE_DYNAMIC = 'dynamic';
export const PAGINATION_TYPE = {
	NONE: PAGINATION_TYPE_NONE,
	STATIC: PAGINATION_TYPE_STATIC,
	DYNAMIC: PAGINATION_TYPE_DYNAMIC
}
export const PAGINATION_TYPES = [PAGINATION_TYPE_NONE, PAGINATION_TYPE_STATIC, PAGINATION_TYPE_DYNAMIC];

export const PAGINATION_LABEL_TYPE_TOTAL = 'total';
export const PAGINATION_LABEL_TYPE_RANGE = 'range';
export const PAGINATION_LABEL_TYPE = {
	TOTAL: PAGINATION_LABEL_TYPE_TOTAL,
	RANGE: PAGINATION_LABEL_TYPE_RANGE
};
export const PAGINATION_LABEL_TYPES = [PAGINATION_LABEL_TYPE_TOTAL, PAGINATION_LABEL_TYPE_RANGE];
