import {cloneDeep, find, get, reject} from "lodash";
import {updateArrayItemProps} from "../../../helpers/data";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'popup';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@popup/reset',
	ADD_POPUP: '@popup/add',
	REMOVE_POPUP: '@popup/remove',
	CLEAR_POPUPS: '@popup/clear',
	SET_POPUP_INSTANCE: '@popup/set_popup_instance',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	openPopup: (GUIID, component, props, options) => ({type: REDUCER_TYPES.ADD_POPUP, GUIID, component, props, options}),
	closePopup: GUIID => ({type: REDUCER_TYPES.REMOVE_POPUP, GUIID}),
	closeAllPopups: () => ({type: REDUCER_TYPES.CLEAR_POPUPS}),
	setPopupInstance: (GUIID, instance) => ({type: REDUCER_TYPES.SET_POPUP_INSTANCE, GUIID, instance})
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	openedPopups: [],
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET:
			return {...initialState};
			
		case REDUCER_TYPES.ADD_POPUP:
			// Do not add popup if it is already opened.
			if (find(state.openedPopups, { GUIID: action.GUIID })) return state;

			let updatedState = cloneDeep(state);
			updatedState.openedPopups.push({
				GUIID: action.GUIID,
				component: action.component,
				props: action.props,
				options: action.options,
				instance: null,
			});

			return updatedState;
			
		case REDUCER_TYPES.REMOVE_POPUP:
			return {
				...state,
				openedPopups: reject(cloneDeep(state.openedPopups), { GUIID: action.GUIID })
			};
			
		case REDUCER_TYPES.CLEAR_POPUPS:
			return {
				...state,
				openedPopups: initialState.openedPopups
			}
			
		case REDUCER_TYPES.SET_POPUP_INSTANCE:
			return {
				...state,
				openedPopups: updateArrayItemProps(
					state.openedPopups, {GUIID: action.GUIID}, {instance: action.instance}
				)
			}

		default:
			return state;
	}
};

// Selectors
export const selectors = {
	getPopups: state => get(state, [reducerStoreKey, 'openedPopups'], initialState.openedPopups),
	getPopup: (state, GUIID) => find(state[reducerStoreKey].openedPopups, { GUIID }),
};

export default reducer;