import React from "react";
import PageDataComponent from "../../core/components/PageDataComponent";
import {connect} from "react-redux";
import {getPageActions} from "../../core/helpers/redux";
import {selectors} from "../../core/store/reducers";
import * as pageConfig from "./config";
import * as actions from "./actions";
import auth from "../../auth";
import {loginPageRouterPath} from "../../core/pages/login";
import {getPopupInstance} from "../../core/helpers/popup";
import AccountRolePopup from "./popups/AccountRolePopup";
import {Link} from "react-router-dom";
import Label from "../../core/components/display/Label";
import {Tooltip} from "react-tippy";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../core/components/display/Button";
import DataTable, {DATA_TABLE_CELL_TYPE} from "../../core/components/advanced/DataTable";
import {icon_font_edit_symbol} from "../../config";
import {PAGINATION_TYPE} from "../../core/components/action/Pagination";
import ACL from "../../acl";
import {areAllObjectPropsEmpty, isset} from "../../core/helpers/data";
import * as filterDataMap from "./dataMap/accountRoleListFilter";
import {scrollToSelector} from "../../core/helpers/dom";
import {getMenuSidebarShrankFromStorage} from "../../layout/elements/MainSidebar/helpers";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	/** @type {AccountRoleListItemDataObject[]} */
	accountRoleList: selectors.accountRoles.getAccountRoleList(state),
	accountRoleListPagination: selectors.accountRoles.getAccountRoleListPagination(state),
	accountRoleListSort: selectors.accountRoles.getAccountRoleListSort(state),
	accountRoleListFilter: selectors.accountRoles.getAccountRoleListFilter(state),
});

class AccountRoles extends PageDataComponent {
	constructor(props) {
		super(props, {
			data: {
				/**
				 * Currently selected search filter
				 */
				filter: {},
				/**
				 * Flag showing if filter is loading
				 */
				filterLoading: false,

				/**
				 * Flag showing if page is loading data
				 * @type {boolean}
				 */
				loading: false,
			},

			/**
			 * Flag that specifies if main data table height will be limited to the available space
			 */
			limitToAvailableSpace: true
		}, {
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			domPrefix: 'account-roles-page'
		}, 'page_title');
		
		// Refs
		this.accountRoleListFilterRef = null;

		// Data methods
		this.reloadAccountRoleList = this.reloadAccountRoleList.bind(this);
		this.loadAccountRoleListPage = this.loadAccountRoleListPage.bind(this);
		this.sortAccountRoleList = this.sortAccountRoleList.bind(this);
		this.filterAccountRoleList = this.filterAccountRoleList.bind(this);
		this.removeAccountRoleListFilter = this.removeAccountRoleListFilter.bind(this);
		this.isAccountRoleListFilterEmpty = this.isAccountRoleListFilterEmpty.bind(this);

		// Popup methods
		this.openAccountRolePopup = this.openAccountRolePopup.bind(this);
		this.closeAccountRolePopup = this.closeAccountRolePopup.bind(this);
		this.handleCloseAccountRolePopup = this.handleCloseAccountRolePopup.bind(this);
		
		// Render methods
		this.renderActions = this.renderActions.bind(this);
	}


	componentDidMount() {
		// Redirect to login page if user is not logged in
		if (auth.checkTokensForPage(loginPageRouterPath)) {
			return super.componentDidMount();
		}
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 */
	loadPageData() {
		const {accountRoleList, loadAccountRoleListAction} = this.props;

		// Do not load account role list if it is already loaded
		if (isset(accountRoleList)) {
			// Open filter if it is not empty
			if (!this.isAccountRoleListFilterEmpty() && this.accountRoleListFilterRef) {
				this.accountRoleListFilterRef.open();
			}
			return this.reloadAccountRoleList();
		}
		// Load account role list if it is not already loaded
		else {
			return this.setValue('loading', true)
				.then(() => this.executeAbortableAction(loadAccountRoleListAction))
				.then(() => this.setValue('loading', false));
		}
	}

	/**
	 * Reload account role list using current options (page, sort, ...)
	 * @return {Promise<*>}
	 */
	reloadAccountRoleList() {
		const {
			loadAccountRoleListAction, accountRoleListPagination, accountRoleListSort, 
			accountRoleListFilter
		} = this.props;
		const {pageNo, perPage} = accountRoleListPagination;
		const {sortBy, sortDir} = accountRoleListSort;
		const oFilter = filterDataMap.output(accountRoleListFilter);

		return this.executeAbortableAction(loadAccountRoleListAction, oFilter, pageNo, perPage, sortBy, sortDir)
			.then(() => this.accountRoleListFilterRef?.reload());
	}

	/**
	 * Reload account role list using current options (page, sort, ...) if any
	 * @param {number} [pageNo=1] - Page number to load (starts with 1).
	 * @return {Promise<*>}
	 */
	loadAccountRoleListPage(pageNo = 1) {
		const {
			loadAccountRoleListAction, accountRoleListPagination, accountRoleListSort,
			accountRoleListFilter
		} = this.props;
		const {perPage} = accountRoleListPagination;
		const {sortBy, sortDir} = accountRoleListSort;
		const oFilter = filterDataMap.output(accountRoleListFilter);
		
		return this.executeAbortableAction(loadAccountRoleListAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Sort account role list
	 * @param {string} sortBy - Name of the sort column.
	 * @param {string} sortDir - Direction of the sort.
	 * @return {Promise<*>}
	 */
	sortAccountRoleList(sortBy, sortDir) {
		const {
			loadAccountRoleListAction, accountRoleListPagination, accountRoleListFilter
		} = this.props;
		const {pageNo, perPage} = accountRoleListPagination;
		const oFilter = filterDataMap.output(accountRoleListFilter);

		return this.executeAbortableAction(loadAccountRoleListAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Filter account role list
	 * @param {Object} filter - Filter object where keys are filter field names and values are filter values.
	 * @return {Promise<*>}
	 */
	filterAccountRoleList(filter) {
		const {loadAccountRoleListAction, accountRoleListPagination, accountRoleListSort} = this.props;
		const {perPage} = accountRoleListPagination;
		const {sortBy, sortDir} = accountRoleListSort;
		const oFilter = filterDataMap.output(filter);

		return this.setValue('filterLoading', true)
			.then(() => this.executeAbortableAction(loadAccountRoleListAction, oFilter, 1, perPage, sortBy, sortDir))
			.then(() => this.setValue('filterLoading', false))
			.then(() => scrollToSelector('#main-page-table', false));
	}

	/**
	 * Remove account role list filter
	 * @return {Promise<*>}
	 */
	removeAccountRoleListFilter() {
		return this.filterAccountRoleList(null);
	}

	/**
	 * Check if account role list filter is applied
	 * @return {Boolean}
	 */
	isAccountRoleListFilterEmpty() {
		return areAllObjectPropsEmpty(this.getProp('accountRoleListFilter'), true, false);
	}


	// Router methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called if current URL matches the 'create' sub-url of the page
	 * @note Create sub-url uses '/new' router path relative to the router path of the page (see 'options.routerPath').
	 *
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'create' sub-url if such component exists.
	 */
	handleCreateUrl() {
		if (ACL.checkPermission(['ROLE_WRITE'])) return this.openAccountRolePopup(true);
		else { this.redirectToBase(); return ''; }
	}

	/**
	 * Method that will be called if current URL matches the 'item' sub-url of the page
	 * @note Item sub-url uses '/item' router path and 'id' as router path param ('/item/:id') on top of to the router
	 * path of the page (see 'options.routerPath').
	 *
	 * @param {string} id - Account role name.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'item' sub-url if such component exists.
	 */
	handleItemUrl(id) {
		const {loadAccountRoleAction} = this.props;

		// Open item popup if it is not already opened
		// @note This is done to ensure that create dialog does not open another dialog after creating the item 
		// or to avoid opening another dialog if item ID in the URL changes programmatically. Dialog data will 
		// change because Redux store is used.
		if (!this.urlComponentGUIID) {
			this.urlComponentGUIID = this.openAccountRolePopup();
		}
		// If dialog is opened update its dynamic items (tabs, action buttons, ...)
		else {
			/** @type {AccountRolePopup} */
			const accountRolePopup = getPopupInstance(this.urlComponentGUIID);
			accountRolePopup.updateDynamics({isNew: false}).then();
		}

		// Try to load account role item
		return new Promise(resolve =>
			// Timeout is added to allow for the popup open animation to finish
			setTimeout(() => resolve(
				this.executeAbortableAction(loadAccountRoleAction, id)
					.then(accountRole => {
						// If account role item is successfully loaded
						if (accountRole) {
							return this.urlComponentGUIID;
						}
						// If account role item could not be loaded (usually if item with ID from URL does not exist)
						else {
							// Close item popup if it is opened
							if (this.urlComponentGUIID) this.closeUrlComponent();
							// Redirect to page base url (removes item ID from URL if it exists)
							this.redirectToBase();
							return '';
						}
					})
			))
		);
	}

	/**
	 * Method that will be called if current URL matches the base URL of the page
	 *
	 * @return {string} GUI ID of the component (popup, dialog, ...) that is rendered when page is on its base URL if
	 * such component exists.
	 */
	handleBaseUrl() {
		this.closeAccountRolePopup();
		return '';
	}

	/**
	 * Method that will be called when page component unmounts and should handle closing of any page url or sub-url
	 * component if it exists.
	 */
	closeUrlComponent() {
		this.closeAccountRolePopup();
	}


	// Popup methods ----------------------------------------------------------------------------------------------------
	/**
	 * Open item popup
	 * @param {boolean} [isNew=false] - Flag that specifies if new assessments popup should be opened.
	 */
	openAccountRolePopup(isNew = false) {
		const {openPopupAction} = this.props;
		return openPopupAction(AccountRolePopup, {
			isNew,
			onClose: this.handleCloseAccountRolePopup,
			redirectToItem: this.redirectToItem,
		});
	}

	/**
	 * Close item popup
	 */
	closeAccountRolePopup() {
		const {closePopupAction, clearAccountRoleAction} = this.props;

		// Close item popup
		closePopupAction(this.getUrlComponentGUIID());
		this.clearUrlComponentGUIID();

		// Clear popup Redux data
		clearAccountRoleAction();
	}

	/**
	 * Handle item popup 'onClose' event
	 * @return {Promise<*>}
	 */
	handleCloseAccountRolePopup() {
		this.redirectToBase();
		return new Promise(resolve =>
			// Timeout is added to allow for the popup close animation to finish 
			setTimeout(() => resolve(this.reloadAccountRoleList()))
		);
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render data table actions cell
	 * @param {AccountRoleListItemDataObject} row - Data table row.
	 * @return {JSX.Element}
	 */
	renderActions(row) {
		return (
			<div className="actions">
				{
					ACL.checkPermission(['ROLE_WRITE']) ?
						<Tooltip
							tag="div"
							title={this.t('edit_tooltip')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<Button
								className="action-btn no-border-radius"
								displayStyle={BUTTON_STYLE.ACTION}
								displayType={BUTTON_DISPLAY_TYPE.NONE}
								icon={icon_font_edit_symbol}
								onClick={() => this.redirectToItem(row.id)}
							/>
						</Tooltip>
						: null
				}
			</div>
		);
	}
	
	/**
	 * Render page title
	 * @description This method specifies how page title will be rendered if page title should be rendered. It does not
	 * determine if page title should be rendered.
	 * @return {JSX.Element}
	 */
	renderPageTitle() {
		const {title} = this.state;

		return (
			<h1 className="page-title with-actions">
				<div className="content">{title ? this.translate(title, this.titlePathPrefix) : ''}</div>
				<div className="actions">
					{
						ACL.checkPermission(['ROLE_WRITE']) ?
							<div className="action-button">
								<Link to={this.getCreateRedirectTo()} className="button action solid big">
									<Label icon="plus" iconSymbolPrefix="icofont-" content={this.t('create_account_role')} />
								</Link>
							</div>
							: null
					}
					
					<div className="action-button">
						<Tooltip
							tag="div"
							title={this.t('Reload data', 'general')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<Button
								big={true}
								icon="refresh"
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								displayStyle={BUTTON_STYLE.SUBTLE}
								onClick={this.reloadAccountRoleList}
							/>
						</Tooltip>
					</div>
				</div>
			</h1>
		);
	}

	render() {
		const {
			accountRoleList, accountRoleListPagination, accountRoleListSort, mainSidebarShrank, toggleMainSidebarSizeAction 
		} = this.props;
		const {limitToAvailableSpace} = this.state;

		return this.renderLayout((
			<div id={this.getDomId()}>
				<DataTable
					id="main-page-table"
					limitToAvailableSpace={limitToAvailableSpace && !this.getProp('isMobileBreakpoint')}
					highlightOnHover={true}
					columns={[
						{
							name: 'name',
							label: this.t('name'),
						},
						{
							name: 'code',
							label: this.t('code'),
						},
						{
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: {
								content: this.renderActions,
								standardWrapper: false
							},
							width: 1,
							stopPropagation: true,
						},
					]}
					data={accountRoleList}
					onRowClick={row => this.redirectToItem(row.id)}
					paginationType={PAGINATION_TYPE.STATIC}
					onSortByColumn={this.sortAccountRoleList}
					onPaginationClick={this.loadAccountRoleListPage}
					{...accountRoleListPagination}
					{...accountRoleListSort}
				/>
			</div>
		), undefined, undefined, {
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default connect(mapStateToProps, getPageActions(actions))(AccountRoles);