import styles from "./index.module.css";

import React from "react";
import PageComponent from "../../components/PageComponent";
import {connect} from "react-redux";
import {get} from "lodash";
import {getGlobalActions} from "../../helpers/redux";
import {icon_font_error_symbol} from "../../../config";
import {hideLoading, showPageLoading} from "../../helpers/loading";
import Label from "../../components/display/Label";
import auth from "../../../auth";
import {LoadingOverlayObject} from "../../objects";
import Overlay from "../../components/global/Overlay";

/**
 * Main auth page
 * @description Auth page is used to handle all auth related redirects from the auth API. Is usually just redirects to 
 * the appropriate app page or display some auth error.
 * @note Default implementation of the auth is OAuth 2.
 */
class AuthPage extends PageComponent {
	constructor(props) {
		super(props, {
			layout: 'blank',
			routerPath: authPageRouterPath,
			domPrefix: 'auth-page',
			translationPath: 'Auth',
			disableLoad: true
		});

		// Render methods
		this.renderAuthorizationError = this.renderAuthorizationError.bind(this);
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 */
	loadPageData() {
		const {showErrorMessageAction} = this.props;
		const code = this.getUrlParam('code');
		const state = this.getUrlParam('state');
		const storedState = auth.getState();
		
		// Handle creating tokens when authorization code has been received from API through URL param
		if (code) {
			// If state is used and it is incorrect
			if ((state || storedState) && state !== storedState) {
				showErrorMessageAction(this.t('Security code is incorrect', p => `${p}.token.errors`));
			}
			// If state is not used or is correct
			else {
				const loading = showPageLoading(false, false);
				this.executeAbortableAction(auth.refreshTokensWithAuthorizationCode, code)
					// Store newly created tokens
					.then(response => {
						if (response) {
							auth.storeAccessToken(get(response, 'access_token'));
							auth.storeRefreshToken(get(response, 'refresh_token'));
						}
						return response;
					})

					// Call security init API to handle all server-side generated cookies
					.then(response => (response ? this.executeAbortableAction(auth.securityInit) : response))

					// Handle successful token refresh
					.then(() => {
						// Delete stored code verifier and state
						// @description We don't need them anymore and they can be a security risk.
						auth.deleteCodeVerifier();
						auth.deleteState();

						hideLoading(loading);
						
						// Redirect to app's home page
						// @note see app config 'app_home_page_router_path' value.
						this.redirectToHome(); 
					})

					// Catch errors
					.catch(error => {
						hideLoading(loading);
						showErrorMessageAction(error.message);
					});
			}
		}
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render authorization error
	 * @return {JSX.Element|null}
	 */
	renderAuthorizationError() {
		const error = this.getUrlParam('error');
		const errorMessage = (
			error && this.hasTranslation(error, p => `${p}.authorize.errors`) ?
				this.t(`authorize.errors.${error}`) :
				this.t(`authorize.errors.unknown`)
		);

		this.setBrowserTitle('Authorization failed!');

		return this.renderLayout(
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']}`}>
				<div className="card icon-card">
					<div className={`card-header ${styles['header']}`}>
						<Label 
							content={this.t('Authorization failed!')} 
							icon={icon_font_error_symbol} 
							iconClassName='card-header-icon error-color' 
						/>
					</div>
					<div className={`card-content ${styles['content']}`}>
						{errorMessage}
					</div>
				</div>
			</div>
		, 'background-image-error');
	}

	render() {
		return (
			this.getUrlParam('error') ? 
				this.renderAuthorizationError() :
				<Overlay
					data={new LoadingOverlayObject('#root', false, false, '3rem', 3)}
					element={document.getElementById('root')}
				/>
		);
	}
}

export const authPageRouterPath = '/auth';
export default connect(null, getGlobalActions())(AuthPage);