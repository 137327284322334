import {cloneDeep, get, set, find} from "lodash";
import {ReportDataObject} from "../../dataObjects/report";
import {addArrayItem, getArray, removeArrayItem, updateArrayItem} from "../../core/helpers/data";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'builder';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@builder/reset',
	
	SET_REPORT: '@builder/set_report',
	CLEAR_REPORT: '@builder/clear_report',
	SET_REPORT_VALUE: '@builder/set_report_value',
	CLEAR_REPORT_VALUE: '@builder/clear_report_value',
	SAVE_REPORT_VALUE_ITEM: '@builder/save_report_value_item',
	REMOVE_REPORT_VALUE_ITEM: '@builder/remove_report_value_item',
	
	SET_CHART_OPTIONS_VALIDATION_ERRORS: '@builder/set_chart_options_validation_errors',
	CLEAR_CHART_OPTIONS_VALIDATION_ERRORS: '@builder/clear_chart_options_validation_errors',
	SET_CHART_SERIES_VALIDATION_ERRORS: '@builder/set_chart_series_validation_errors',
	CLEAR_CHART_SERIES_VALIDATION_ERRORS: '@builder/clear_chart_series_validation_errors',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	setReport: report => ({type: REDUCER_TYPES.SET_REPORT, report}),
	clearReport: () => ({type: REDUCER_TYPES.CLEAR_REPORT}),
	setReportValue: (data, path) => ({type: REDUCER_TYPES.SET_REPORT_VALUE, data, path}),
	clearReportValue: path => ({type: REDUCER_TYPES.CLEAR_REPORT_VALUE, path}),
	saveReportValueItem: (item, path) => ({type: REDUCER_TYPES.SAVE_REPORT_VALUE_ITEM, item, path}),
	removeReportValueItem: (itemGUIID, path) => ({type: REDUCER_TYPES.REMOVE_REPORT_VALUE_ITEM, itemGUIID, path}),
	setChartOptionsValidationErrors: errors => ({type: REDUCER_TYPES.SET_CHART_OPTIONS_VALIDATION_ERRORS, errors}),
	clearChartOptionsValidationErrors: () => ({type: REDUCER_TYPES.CLEAR_CHART_OPTIONS_VALIDATION_ERRORS}),
	setChartSeriesValidationErrors: errors => ({type: REDUCER_TYPES.SET_CHART_SERIES_VALIDATION_ERRORS, errors}),
	clearChartSeriesValidationErrors: () => ({type: REDUCER_TYPES.CLEAR_CHART_SERIES_VALIDATION_ERRORS}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	report: new ReportDataObject(),
	chartOptionsValidationErrors: {},
	chartSeriesValidationErrors: {},
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	let report = cloneDeep(state.report ? state.report : initialState.report);
	
	switch (action.type) {
		case REDUCER_TYPES.RESET: return cloneDeep(initialState);
		
		case REDUCER_TYPES.SET_REPORT: return {...state, report: action.report};
		case REDUCER_TYPES.CLEAR_REPORT: return {...state, report: initialState.report};
		
		case REDUCER_TYPES.SET_REPORT_VALUE:
			set(report, action.path, action.data);
			return {...state, report};
		case REDUCER_TYPES.CLEAR_REPORT_VALUE:
			set(report, action.path, cloneDeep(get(initialState.report, action.path)));
			return {...state, report};
			
		case REDUCER_TYPES.SAVE_REPORT_VALUE_ITEM:
			const itemGUIID = get(action.item, 'GUIID');
			const item = find(
				getArray(state,Array.isArray(action.path) ? ['report', ...action.path] : `report.${action.path}`),
				{GUIID: itemGUIID}
			);
			// Update existing item
			if (item) {
				set(
					report, 
					action.path,
					updateArrayItem(getArray(report, action.path), {GUIID: itemGUIID}, action.item)
				);
				return {...state, report};
			}
			// Create new item
			else {
				set(report, action.path, addArrayItem(getArray(report, action.path), action.item));
				return {...state, report};
			}
		case REDUCER_TYPES.REMOVE_REPORT_VALUE_ITEM:
			set(report, action.path, removeArrayItem(getArray(report, action.path), {GUIID: action.itemGUIID}));
			return {...state, report};
			
		case REDUCER_TYPES.SET_CHART_OPTIONS_VALIDATION_ERRORS:
			return {...state, chartOptionsValidationErrors: cloneDeep(action.errors)};
		case REDUCER_TYPES.CLEAR_CHART_OPTIONS_VALIDATION_ERRORS:
			return {...state, chartOptionsValidationErrors: cloneDeep(initialState.chartOptionsValidationErrors)};
		case REDUCER_TYPES.SET_CHART_SERIES_VALIDATION_ERRORS:
			return {...state, chartSeriesValidationErrors: cloneDeep(action.errors)};
		case REDUCER_TYPES.CLEAR_CHART_SERIES_VALIDATION_ERRORS:
			return {...state, chartSeriesValidationErrors: cloneDeep(initialState.chartSeriesValidationErrors)};
			
		default: return state;
	}
};

// Selectors
export const selectors = {
	getReport: state => get(state, [reducerStoreKey, 'report'], cloneDeep(initialState.report)),
	getReportValue: (state, path) => get(
		state, 
		Array.isArray(path) ? [reducerStoreKey, 'report', ...path] : [reducerStoreKey, 'report', path],
		get(cloneDeep(initialState), path)
	),
	getReportValueItem: (state, path, GUIID) =>
		find(
			get(
				state,
				Array.isArray(path) ? [reducerStoreKey, 'report', ...path] : [reducerStoreKey, 'report', path],
				get(cloneDeep(initialState), path)
			), 
			{GUIID}
		),
	
	getChartOptionsValidationErrors: state => get(
		state, 
		[reducerStoreKey, 'chartOptionsValidationErrors'], 
		cloneDeep(initialState.chartOptionsValidationErrors)
	),
	getChartSeriesValidationErrors: state => get(
		state,
		[reducerStoreKey, 'chartSeriesValidationErrors'],
		cloneDeep(initialState.chartSeriesValidationErrors)
	),
};

export default reducer;