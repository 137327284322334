import styles from "./index.module.css";
import "./index.css";

import React from "react";
import PageComponent from "../../core/components/PageComponent";
import {connect} from "react-redux";
import * as pageConfig from "./config";
import * as actions from "./actions";
import {getPageActions} from "../../core/helpers/redux";
import Label from "../../core/components/display/Label";
import GeneratedReports from "./components/GeneratedReports";
import ScheduledReports from "./components/ScheduledReports";
import {Tooltip} from "react-tippy";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../core/components/display/Button";
import {getString} from "../../core/helpers/data";
import {matchPath} from "react-router-dom";
import {selectors} from "../../core/store/reducers";
import {getMenuSidebarShrankFromStorage} from "../../layout/elements/MainSidebar/helpers";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
});

class ReportsPage extends PageComponent {
	/**
	 * Ref of the current reports component (GeneratedReports or ScheduledReports)
	 */
	reportsComponentRef = null;
	
	constructor(props) {
		super(props, {
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
		}, 'page_title');

		// Router methods
		this.getUrlReportsType = this.getUrlReportsType.bind(this);

		// Render methods
		this.renderPageTitle = this.renderPageTitle.bind(this);
	}
	

	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return 'reports-page'; }


	// Router methods ---------------------------------------------------------------------------------------------------
	/**
	 * Get reports type to be display from URL
	 * @return {string}
	 */
	getUrlReportsType() {
		return `${getString(
			matchPath(this.getCurrentRouterPath(),
				{path: `${this.getOption('routerPath')}/:reportsType`, exact: false}),
			'params.reportsType',
			'generated'
		)}-reports`;
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render page title
	 * @description This method specifies how page title will be rendered if page title should be rendered. It does not
	 * determine if page title should be rendered.
	 * @return {JSX.Element}
	 */
	renderPageTitle() {
		const {title} = this.state;

		return (
			<h1 className="page-title with-actions">
				<div className="content">{title ? this.translate(title, this.titlePathPrefix) : ''}</div>
				<div className="actions">
					<div className="action-button">
						<Tooltip
							tag="div"
							title={this.t('Reload data', 'general')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<Button
								big={true}
								icon="refresh"
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								displayStyle={BUTTON_STYLE.SUBTLE}
								onClick={this.reportsComponentRef ? this.reportsComponentRef.reloadReports : undefined}
							/>
						</Tooltip>
					</div>
				</div>
			</h1>
		);
	}
	
	render() {
		const {mainSidebarShrank, toggleMainSidebarSizeAction} = this.props;
		const currentTabId = this.getUrlReportsType();
		
		return (
			this.renderLayout((
				<div id={this.getDomId()} className={styles['wrapper']}>
					<div className="simple-page-description">
						<Label content={this.t('page_short_description')} supportHtml={true} />
					</div>
					
					<div className="tabs attached">
						<Label
							element="div"
							elementProps={{
								className: `tab ${currentTabId === 'generated-reports' ? 'active' : ''}`,
								onClick: () => this.redirectToBase()
							}}
							iconSymbolPrefix="icofont-"
							icon="file-text"
							content={this.t('Generated reports')}
						/>
						<Label
							element="div"
							elementProps={{
								className: `tab ${currentTabId === 'scheduled-reports' ? 'active' : ''}`,
								onClick: () => this.redirectTo(`${this.getBaseRedirectTo()}/scheduled`)
							}}
							iconSymbolPrefix="icofont-"
							icon="clock-time"
							content={this.t('Scheduled reports')}
						/>
					</div>
					
					{
						currentTabId === 'generated-reports' ? 
							<GeneratedReports ref={node => { this.reportsComponentRef = node; }} /> 
						: null
					}
					{
						currentTabId === 'scheduled-reports' ? 
							<ScheduledReports ref={node => { this.reportsComponentRef = node; }} /> 
							: null
					}
				</div>
			), undefined, undefined, {
				mainSidebarShrank,
				toggleMainSidebarSizeAction,
			})
		);
	}
}

export * from "./config";
export default connect(mapStateToProps, getPageActions(actions))(ReportsPage);