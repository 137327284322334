import styles from "./index.module.css";
import logoLight from "../../../skin/images/logo_small_light.png";
import logoDark from "../../../skin/images/logo_small_dark.png";

import React from "react";
import PageComponent from "../../components/PageComponent";
import {connect} from "react-redux";
import {getGlobalActions} from "../../helpers/redux";
import * as actions from "./actions";
import {isSuccessful} from "../../helpers/io";
import PasswordReset from "../../components/advanced/PasswordReset";
import Label from "../../components/display/Label";
import Icon from "../../components/display/Icon";
import Button, {BUTTON_STYLE} from "../../components/display/Button";
import {loginPageRouterPath} from "../login";
import {getSkin} from "../../helpers/skin";
import {SKIN_MODE} from "../../const/global";
import {appHasLogin} from "../../helpers/login";
import auth from "../../../auth";
import * as pageConfig from "./config";

class CorePasswordResetPage extends PageComponent {
	constructor(props) {
		super(props, {
			layout: 'blank',
			routerPath: pageConfig.routerPath,
			domPrefix: 'password-reset-page',
			translationPath: pageConfig.translationPath,
			renderTitle: false,
		}, 'form_title');

		// Initiate component's state
		this.state = {
			/**
			 * Flag that specifies if password was successfully reset
			 * @type {boolean} 
			 */
			successful: false,
		}

		// Action methods
		this.resetPassword = this.resetPassword.bind(this);
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 */
	loadPageData() {
		if (appHasLogin()) {
			// Redirect to home page (defined in app config) if user is already logged in
			// @note This is added in case someone opens this page directly.
			auth.authorizedPing()
				.then(() => this.redirectToHome())
				.catch(() => { /* Do nothing */ });
		} else {
			this.redirectToHome();
		}
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Reset account password
	 */
	resetPassword(newPassword, confirmPassword) {
		const {resetPasswordAction} = this.props;
		const hash = this.getUrlParam('t');

		return this.executeAbortableAction(resetPasswordAction, hash, newPassword, confirmPassword)
			// Set 'success' flag depending on the password reset action result
			.then(response => this.setState({successful: isSuccessful(response)}));
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {successful} = this.state;
		const logo = (getSkin() === SKIN_MODE.DARK ? logoDark : logoLight);

		return this.renderLayout((
			!successful ?
				<PasswordReset
					logo={logo}
					className={`${styles['wrapper']}`}
					cardHeaderClassName={`card-header ${styles['header']}`}
					cardContentClassName={`card-content ${styles['content']}`}
					action={this.resetPassword}
				/>
				:
				<div className={`card icon-card ${styles['successWrapper']}`}>
					<div className="card-header">
						<div className="card-header-icon">
							<img src={logo} alt={this.t('title', 'App')}/>
						</div>
						<Label
							element="div"
							elementProps={{className: styles['appName']}}
							content={this.t('title', 'App')}
						/>
					</div>
					<div className={`card-content`}>
						<div className={`${styles['notice']}`}>
							<Icon symbol="check" className="page-notice-title-icon success-color" />
							<Label
								element="p"
								elementProps={{className: 'page-notice-title'}}
								content={this.t('success')}
							/>
							<Label
								element="p"
								elementProps={{className: 'page-notice'}}
								content={this.t('success_desc')}
							/>
						</div>
					</div>
					<div className="card-actions">
						<Button
							big={true}
							icon="unlock-alt"
							label={this.t('Login', 'Login')}
							displayStyle={BUTTON_STYLE.ACTION}
							onClick={() => this.redirectTo(loginPageRouterPath)}
						/>
					</div>
				</div>
		), 'background-image');
	}
}

export const passwordResetPageRouterPath = pageConfig.routerPath;
export default connect(null, getGlobalActions(actions))(CorePasswordResetPage);