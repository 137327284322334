import {getArray} from "../../../core/helpers/data";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'dynamicValues';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@dynamicValues/reset',
	SET: '@dynamicValues/set',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	set: dynamicValues => ({type: REDUCER_TYPES.SET, dynamicValues}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	data: [],
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET:
			return {...initialState};
			
		case REDUCER_TYPES.SET:
			return {
				...state,
				data: action.dynamicValues
			}

		default:
			return state;
	}
};

// Selectors
export const selectors = {
	getDynamicValues: state => getArray(state, [reducerStoreKey, 'data']),
};

export default reducer;