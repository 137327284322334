import { enUS, srLatn } from "date-fns/locale"; 

/** @type {LocaleObj[]} */
let localeList = [];

/**
 * English United States
 * @type {LocaleObj}
 */
export const englishUs = {
	locale: 'en-US',
	name: 'English (US)',
	engName: 'English (US)',
	fileName: 'en-US.json',
	code2: 'en',
	code3: 'eng',
	countryCode: 'US',
	countryCodeIso3: 'USA',
	numbers: {
		currency: {
			symbol: '$',
			position: 'left'
		},
		delimiters: {
			thousands: ',',
			decimal: '.'
		},
		abbreviations: {
			thousand: 'k',
			million: 'm',
			billion: 'b',
			trillion: 't'
		},
		ordinal: number => {
			const b = number % 10;
			return (~~ (number % 100 / 10) === 1) ? 
				'th' :
				(b === 1) ? 'st' :
				(b === 2) ? 'nd' :
				(b === 3) ? 'rd' : 
				'th'
			;
		},
	},
	plugins: {
		// date-fns library
		dateFns: {
			// Locale
			// @description To make sure locale file for date-fns library is available in the app, it is imported and 
			// loaded into this value. This value contains the whole date-fns locale file for this locale.
			locale: enUS,
			
			// Date formats
			dateFormat: {
				short: 'MM/dd/yyyy',
				standard: 'LLL do, yyyy',
				long: 'EEEE, LLLL do, yyyy'
			},

			// Time formats
			timeFormat: {
				short: 'HH:mm',
				standard: 'HH:mm:ss',
				long: 'HH:mm:ss.SSS'
			},

			// Date time separator string
			datetimeSeparator: ' '
		}
	}
};
localeList.push(englishUs);

/**
 * Serbian Latin (romanized)
 * @type {LocaleObj}
 */
export const serbianLatin = {
	locale: 'sr-Latn-RS',
	name: 'Srpski',
	engName: 'Serbian latin',
	fileName: 'sr-Latn-RS.json',
	code2: 'sr',
	code3: 'srp',
	countryCode: 'RS',
	countryCodeIso3: 'SRB',
	numbers: {
		currency: {
			symbol: ' din.',
			position: 'right'
		},
		delimiters: {
			thousands: '.',
			decimal: ','
		},
		abbreviations: {
			thousand: 'hilj.',
			million: 'mil.',
			billion: 'mlrd.',
			trillion: 'bil.'
		},
		ordinal: () => '.',
	},
	plugins: {
		// date-fns library
		dateFns: {
			// Locale
			// @description To make sure locale file for date-fns library is available in the app, it is imported and 
			// loaded into this value. This value contains the whole date-fns locale file for this locale.
			locale: srLatn,

			// Date formats
			dateFormat: {
				short: 'dd.MM.yyyy.',
				standard: 'dd. LLLL. yyyy.',
				long: 'EEEE, dd. LLLL yyyy.'
			},

			// Time formats
			timeFormat: {
				short: 'HH:mm',
				standard: 'HH:mm:ss',
				long: 'HH:mm:ss.SSS'
			},

			// Date time separator string
			datetimeSeparator: ' '
		}
	}
};
localeList.push(serbianLatin);

export default localeList;

// Type definitions
/**
 * @typedef {object} LocaleObj
 * @property {string} locale - Locale code used by the app (Uses IETF BCP 47 language tag) consisting of (ex: 
 * 'sr-Latn-BA', 'sr-Cyrl-RS', 'en-US'):
 * 	- lowercase, two letter ISO 639-1 code (ex: 'sr'),
 * 	- [optional] Title Case ISO 15924 script name (ex: 'Latn'),
 * 	- [optional] UPPERCASE two letter ISO 3166-1 alpha-2 country code (ex: 'RS')
 *
 * @property {string} name - Language name (ex: 'Srpski (latinica)').
 *
 * @property {string} engName - Language English name (ex: 'Serbian (romanized)').
 *
 * @property {string} fileName - Language file name, usually `${locale}.json` (ex: 'sr-Latn.json').
 *
 * @property {string} code2 - ISO 639-1 two-letter lowercase language code (ex: 'sr')
 * https://www.loc.gov/standards/iso639-2/php/code_list.php
 *
 * @property {string} code3 - ISO 639-2 three-letter lowercase language code (ex: 'srp')
 * https://www.loc.gov/standards/iso639-2/php/code_list.php
 *
 * @property {string} countryCode - ISO 3166-1 alpha-2 two-letter uppercase country code (ex: 'RS')
 * https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
 *
 * @property {string} countryCodeIso3 - ISO 3166-1 alpha-3 three-letter uppercase country code (ex: 'SRB')
 * https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3
 *
 * @property {{
 * 	currency: {
 * 	   symbol: string,
 * 	   position: string
 * 	},
 * 	delimiters: {
 * 		thousands: string, 
 * 		decimal: string
 * 	}, 
 * 	abbreviations: {
 * 	   thousand: string,
 * 	   million: string,
 * 	   billion: string,
 * 	   trillion: string
 * 	},
 * 	[ordinal]: Function<number>
 *	}} numbers
 *	@property {Object<string, {	
 *	   locale: any,
 *	   dateFormat: {
 *	      short: string,
 *	      standard: string,
 *	      long: string
 *	   },
 *	   timeFormat: {
 *	      short: string,
 *	      standard: string,
 *	      long: string
 *	   },
 *	   datetimeSeparator: string
 *	}>} [plugins] - Third-party library options (like date-fns).
 */