import styles from "./index.module.css";
import "./default.style.css";

import React from "react";
import DataComponent, {executeComponentCallback} from "../../../core/components/DataComponent";
import PropTypes from "prop-types";
import {getArray, getBoolean, isset} from "../../../core/helpers/data";
import Label from "../../../core/components/display/Label";
import {cloneDeep, find} from "lodash";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../../core/components/display/Button";
import {icon_font_help_circle_symbol, icon_font_symbol_class_prefix} from "../../../config";
import scrollIntoView from "scroll-into-view-if-needed";
import {DynamicValueGroupDataObject, DynamicValueDataObject, DynamicValueDataFormatDataObject} from "./dataObjects";
import TooltipDropdown from "../../../core/components/display/TooltipDropdown";
import {Tooltip} from "react-tippy";
import Icon from "../../../core/components/display/Icon";

class DynamicValues extends DataComponent {
	constructor(props) {
		super(props, {
			/**
			 * Dynamic values main data (groups and items)
			 * @type {Object[]}
			 */
			data: [],

			/**
			 * List of primary keys of expanded groups
			 * @type {string[]|number[]}
			 */
			expanded: [],

			/**
			 * Flag that determines if help notice should be visible
			 */
			showHelp: getBoolean(props, 'showHelpNoticeByDefault'),

			/**
			 * Flag that specifies if whole section is collapsed
			 */
			collapsed: false, // TODO: Load from session storage
		}, {
			translationPath: 'DynamicValuesComponent',
			domPrefix: 'dynamic-values-component',
			enableLoadOnDataPropChange: true,
			optimizedUpdate: true,
		});

		// Action methods
		this.handleInsert = this.handleInsert.bind(this);
		this.isExpanded = this.isExpanded.bind(this);
		this.expand = this.expand.bind(this);
		this.collapse = this.collapse.bind(this);
		this.toggle = this.toggle.bind(this);
		this.expandAll = this.expandAll.bind(this);
		this.collapseAll = this.collapseAll.bind(this);
		
		// Selection methods
		this.isSelected = this.isSelected.bind(this);
	}
	
	componentDidMount(override = false) {
		return super.componentDidMount(override)
			// Expand groups of selected item if 'autoExpandSelection' prop flag is set to true
			.then(async () => {
				/** @type {DynamicValueDataObject[]} */
				const selection = getArray(this.props, 'selection');
				const autoExpandSelection = getBoolean(this.props, 'autoExpandSelection');
				for (let i = 0; i < selection.length; i++) {
					if (autoExpandSelection) await this.expand(selection[i].group);
					
					// Scroll to the first selected item
					scrollIntoView(
						this.getDomElement()
							.querySelector(`#${this.getDomId()}-group-${selection[i].group.id} #${this.getDomId()}-item-${selection[i].id}`),
						{scrollMode: 'if-needed'}
					);
				}
			})
			.then(() => this.state);
	}
	

	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Handle insert action
	 * @param {Object} item - Dynamic value data from the tree (local state data).
	 * @param {Object} group - Dynamic value parent group from the tree (local state data).
	 * @param {string} target - Insert target (can be anything that a parent component needs to determine where to insert
	 * a dynamic value).
	 */
	handleInsert(item, group, target) {
		const dynamicValueGroup = new DynamicValueGroupDataObject(group.id, group.name);
		const dynamicValue = new DynamicValueDataObject(
			item.id,
			item.name,
			item.description,
			item.displayType,
			item.aggregateFunction,
			new DynamicValueDataFormatDataObject(
				item.defaultDataFormat?.dataFormat,
				item.defaultDataFormat?.thousandsSeparator,
				item.defaultDataFormat?.decimals
			),
			dynamicValueGroup
		);
		executeComponentCallback(this.props.onInsert, dynamicValue, target);
	}
	
	/**
	 * Check if group is expanded
	 * @param {Object} group - Dynamic value group from the tree (local state data).
	 * @return {boolean}
	 */
	isExpanded(group) { return (getArray(this.state, 'expanded').indexOf(group.id) !== -1); }

	/**
	 * Expand dynamic value group
	 * @param {Object} group - Dynamic value group from the tree (local state data).
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	expand(group) {
		if (!this.isExpanded(group)) return this.setState({expanded: [...this.state.expanded, group.id]});
		return Promise.resolve(this.state);
	}

	/**
	 * Collapse dynamic value group
	 * @param {Object} group - Dynamic value group from the tree (local state data).
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	collapse(group) {
		return this.setState({
			expanded: cloneDeep(getArray(this.state, 'expanded')).filter(i => i !== group.id)
		});
	}

	/**
	 * Toggle expand/collapse dynamic value group
	 * @param {Object} group - Group to toggle.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	toggle(group) { return (this.isExpanded(group) ? this.collapse(group) : this.expand(group)); }

	/**
	 * Expand all dynamic value groups
	 */
	expandAll() { return this.setState({expanded: getArray(this.getData()).map(g => g.id)}); }

	/**
	 * Collapse all dynamic value groups
	 */
	collapseAll() { return this.setState({expanded: []}); }
	
	
	// Selection methods ------------------------------------------------------------------------------------------------
	/**
	 * Check if item is selected
	 * @param {Object} item - Dynamic value item from the tree (local state data).
	 * @param {Object} group - Dynamic value group from the tree (local state data).
	 * @return {boolean}
	 */
	isSelected(item, group) {
		/** @type {DynamicValueDataObject[]} */
		const selection = getArray(this.getProp('selection'));
		if (selection.length) return isset(find(selection,i => (i.id === item.id && i.group.id === group.id)));
		return false;
	}

	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			styleName, className, noToolbarClassName, toolbarClassName, toolbarTitleClassName, contentClassName, 
			insertTrigger, helpNotice, hideToolbar
		} = this.props;
		const {showHelp, collapsed} = this.state;
		const groups = getArray(this.getData());
		
		return (
			<div 
				id={this.getDomId()} 
				className={
					`${this.getOption('domPrefix')} ${styleName}-style ${styles['wrapper']} ${className} ` +
					`${hideToolbar ? noToolbarClassName : ''} ` +
					`${collapsed === true ? styles['collapsed'] : ''} `
				}
			>
				{
					!hideToolbar ?
						<div className={`toolbar standard no-select ${styles['toolbar']} ${toolbarClassName}`}>
							<Label 
								element="span" 
								elementProps={{
									className: `toolbar-title ${toolbarTitleClassName}`,
									onClick: () => this.setState({collapsed: !collapsed})
								}}
								content={this.t('Dynamic values')}
							/>

							<Button
								icon={`angle-${collapsed === true ? 'down' : 'up'}`}
								displayStyle={BUTTON_STYLE.NONE}
								onClick={() => this.setState({collapsed: !collapsed})}
							/>
							{
								!collapsed ?
									<>
										<Button
											icon="expand-alt"
											iconProps={{symbolPrefix: 'icofont-'}}
											displayType={BUTTON_DISPLAY_TYPE.NONE}
											onClick={this.expandAll}
										/>
										<Button
											icon="collapse"
											iconProps={{symbolPrefix: 'icofont-'}}
											displayType={BUTTON_DISPLAY_TYPE.NONE}
											onClick={this.collapseAll}
										/>
									</>
									: null
							}
							{
								helpNotice ?
									<Button
										icon={icon_font_help_circle_symbol}
										displayType={BUTTON_DISPLAY_TYPE.NONE}
										onClick={() => this.setState({showHelp: !showHelp})}
									/>
									: null
							}
						</div>
					: null
				}
				
				{
					groups.length ?
						<div className={`groups ${styles['groups']} ${contentClassName}`}>
							{
								helpNotice && showHelp ?
									<Label 
										element="p" 
										elementProps={{className: 'notice default'}}
										icon={icon_font_help_circle_symbol}
										content={helpNotice}
									/>
									: null
							}
							
							{groups.map(group =>
								<div
									key={group.id}
									id={`${this.getDomId()}-group-${group.id}`}
									className={`group ${styles['group']}`}
								>
									<Label 
										icon={this.isExpanded(group) ? 'folder-open' : 'folder'} 
										content={group.name}
										element="div"
										elementProps={{
											className: 'label',
											onClick: () => this.toggle(group)
										}}
									/>
									{
										this.isExpanded(group) ?
											getArray(group, 'children').map(item =>
												<div 
													key={item.id}
													id={`${this.getDomId()}-item-${item.id}`}
													className={
														`item ${styles['item']} ${this.isSelected(item, group) ? 'selected' : ''}`
													}
												>
													{
														insertTrigger === 'dropdown' ?
															<TooltipDropdown
																element="div"
																className={`item-inner ${styles['itemInner']}`}
																label={
																	<Label
																		icon={item.aggregateFunction ? 'ruler-alt-2' : 'hashtag'}
																		iconSymbolPrefix={
																			item.aggregateFunction ?
																				'icofont-' :
																				icon_font_symbol_class_prefix
																		}
																		content={(
																			<span>
																				{item.name}
																				{
																					item.description ?
																						<Tooltip
																							className={styles['itemHelpIcon']}
																							tag="span"
																							title={this.tt(item.description, 'description')}
																							position="top-center"
																							size="small"
																							interactive={false}
																							arrow={true}
																						>
																							<Icon symbol={icon_font_help_circle_symbol} />
																						</Tooltip>
																						: null
																				}
																			</span>
																		)}
																		element="div"
																		elementProps={{className: 'label'}}
																	/>
																}
																position="bottom-start"
																distance={5}
															>
																<div
																	className="dropdown-item"
																	onClick={() => this.handleInsert(item, group, 'selectColumns')}
																>
																	<Label
																		icon="columns"
																		content={this.t('Add to select columns')}
																	/>
																</div>
																<div
																	className="dropdown-item"
																	onClick={() => this.handleInsert(item, group, 'filter')}
																>
																	<Label
																		iconSymbolPrefix="icofont-"
																		icon="filter"
																		content={this.t('Add to filter')}
																	/>
																</div>
															</TooltipDropdown>
														: insertTrigger === 'click' || insertTrigger === 'doubleClick' ?
															<div className={`item-inner ${styles['itemInner']}`}>
																<Label
																	icon={item.aggregateFunction ? 'ruler-alt-2' : 'hashtag'}
																	iconSymbolPrefix={
																		item.aggregateFunction ?
																			'icofont-' :
																			icon_font_symbol_class_prefix
																	}
																	content={<span>{item.name}</span>}
																	element="div"
																	elementProps={{
																		className: 'label',
																		onClick: () => {
																			if (insertTrigger === 'click') {
																				this.handleInsert(item, group, '')
																			}
																		},
																		onDoubleClick: () => {
																			if (insertTrigger === 'doubleClick') {
																				this.handleInsert(item, group, '')
																			}
																		}
																	}}
																	tooltip={
																		item.description ?
																			this.tt(item.description, 'description') :
																			''
																	}
																	tooltipOptions={{
																		position: 'top-start',
																		delay: [500, 0],
																		hideDelay: 0,
																		appendTo: `#${this.getDomId()}-item-${item.id} .${styles['itemInner']}`
																	}}
																/>
															</div>
														: null
													}
												</div>
											)
											: null
									}
								</div>
							)}
						</div>
						: null
				}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
DynamicValues.propTypes = {
	...DataComponent.propTypes,

	// Component style name
	// @description Component style name is a name of the style that will be used to determine the CSS used to style the
	// component.
	styleName: PropTypes.string,
	// Wrapper element class for when 'hideToolbar' prop is true
	noToolbarClassName: PropTypes.string,
	// Section toolbar element class attribute
	toolbarClassName: PropTypes.string,
	// Section toolbar title element class attribute
	toolbarTitleClassName: PropTypes.string,
	// Section content element class attribute
	contentClassName: PropTypes.string,
	// Determines what will trigger the 'onInsert' event
	insertTrigger: PropTypes.oneOf(['click', 'doubleClick', 'dropdown']),
	// Help notice
	// @description Help notice will be rendered if 'showHelpNoticeByDefault' prop is true or when user click on the help
	// icon in the toolbar. Help icon will automatically appear if this prop is set. Help notice must already be 
	// translated.
	helpNotice: PropTypes.string,
	// Flag that determines if help notice will be shown by default when component mounts
	// @note If 'helpNotice' prop is empty or not defined, help notice and help notice button will not be rendered.
	showHelpNoticeByDefault: PropTypes.bool,
	// Flag that hides the toolbar
	hideToolbar: PropTypes.bool,
	// Selected dynamic values
	// @type {DynamicValueDataObject[]}
	selection: PropTypes.arrayOf(PropTypes.object),
	// Flag that determines if selected item groups will be expanded when component mounts
	autoExpandSelection: PropTypes.bool,
	
	// Events
	onInsert: PropTypes.func, // Arguments: {DynamicValueDataObject} Dynamic value to insert anywhere; {string} Target.
};

/**
 * Define component default values for own props
 */
DynamicValues.defaultProps = {
	styleName: 'default',
	className: '',
	noToolbarClassName: '',
	toolbarClassName: '',
	toolbarTitleClassName: '',
	contentClassName: '',
	insertTrigger: 'doubleClick',
	helpNotice: '',
	showHelpNoticeByDefault: false,
	hideToolbar: false,
	selection: [],
	autoExpandSelection: true,
};

export * from "./const";
export default DynamicValues;