import {actionCreators} from "./reducer";
import {CookieData} from "../../../core/dataProtection/objects/cookie";
import {getStorageValue, setStorageValue, STORAGE_TYPE} from "../../../core/storage";
import CookieConsent from "../../../core/dataProtection/cookieConsent";
import {getBoolFromTinyInt, getTinyIntFormBool} from "../../../core/helpers/data";
import {app_id} from "../../../config";

/**
 * Show main sidebar
 */
export const showMainSidebarAction = () => dispatch => { dispatch(actionCreators.show()); };

/**
 * Hide main sidebar
 */
export const hideMainSidebarAction = () => dispatch => { dispatch(actionCreators.hide()); };

/**
 * Toggle main sidebar
 */
export const toggleMainSidebarAction = () => dispatch => { dispatch(actionCreators.toggle()); };

/**
 * Shrink main sidebar
 */
export const shrinkMainSidebarAction = () => dispatch => {
	// Get menu shrank cookie settings
	const menuShrankCookie = new CookieData('preferences', 'sidebar_menu_shrank', STORAGE_TYPE.LOCAL);

	if (CookieConsent.isAllowed(menuShrankCookie)) {
		setStorageValue(`${app_id}-sidebar_menu_shrank`, '1', STORAGE_TYPE.LOCAL);
	}
	
	dispatch(actionCreators.shrink()); 
};

/**
 * Enlarge main sidebar
 */
export const enlargeMainSidebarAction = () => dispatch => {
	// Get menu shrank cookie settings
	const menuShrankCookie = new CookieData('preferences', 'sidebar_menu_shrank', STORAGE_TYPE.LOCAL);
	
	if (CookieConsent.isAllowed(menuShrankCookie)) {
		setStorageValue(`${app_id}-sidebar_menu_shrank`, '0', STORAGE_TYPE.LOCAL);
	}
	
	dispatch(actionCreators.enlarge()); 
};

/**
 * Toggle main sidebar size
 */
export const toggleMainSidebarSizeAction = () => dispatch => {
	// Get menu shrank cookie settings
	const menuShrankCookie = new CookieData('preferences', 'sidebar_menu_shrank', STORAGE_TYPE.LOCAL);

	if (CookieConsent.isAllowed(menuShrankCookie)) {
		setStorageValue(
			`${app_id}-sidebar_menu_shrank`, 
			getTinyIntFormBool(
				getBoolFromTinyInt(getStorageValue(`${app_id}-sidebar_menu_shrank`, STORAGE_TYPE.LOCAL)),
				'',
				'0',
				true
			),
			STORAGE_TYPE.LOCAL
		);
	}
	
	dispatch(actionCreators.toggleSize()); 
};
