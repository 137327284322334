import "./default.style.css";

import React from "react";
import DataComponent from "../../DataComponent";
import Label from "../../display/Label";
import FormWrapper, {FormField} from "../FormWrapper";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../display/Button";
import {omit} from "lodash";
import PropTypes from "prop-types";
import {FORM_FIELD_LABEL_POSITION} from "../FormWrapper/FormField";
import {connect} from "react-redux";
import {getGlobalActions} from "../../../helpers/redux";
import TextInput from "../../input/TextInput";
import DataValueValidation from "../../../validation";
import Icon from "../../display/Icon";

class PasswordResetRequest extends DataComponent {
	constructor(props) {
		super(props, {
			data: {
				email: '',
			}
		}, {
			disableLoad: true,
			translationPath: 'PasswordResetRequest'
		});

		// Action method
		this.requestPassword = this.requestPassword.bind(this);
	}


	// DataValueValidation methods -----------------------------------------------------------------------------------------------
	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const dataValidation = new DataValueValidation();
		const dataToValidate = this.getData();

		dataValidation.addRule('email', 'required', 'email');

		const validationErrors = dataValidation.run(dataToValidate);
		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		else this.clearValidationErrors().then();
		return !validationErrors;
	}


	// Action method ----------------------------------------------------------------------------------------------------
	/**
	 * Request new password
	 */
	requestPassword() {
		const {action} = this.props;
		const email = this.getValue('email');

		this.clearValidationErrors()
			.then(() => {
				if (this.validate()) return action(email);
			});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			styleName, className, cardClassName, cardHeaderClassName, cardContentClassName, cardActionsClassName,
			labelPosition, labelIcon, labelElement, labelElementProps, buttonProps, submitOnEnter, logo, backAction, 
		} = this.props;

		return (
			<div id={this.getDomId()} className={`password-reset-request-component ${styleName}-style ${className}`}>
				<div className={cardClassName}>
					<div className={cardHeaderClassName}>
						{
							logo ?
								<div className="card-header-icon">
									<img src={logo} alt={this.t('title', 'App')}/>
								</div>
								:
								<Icon symbol={labelIcon} className="card-header-icon" />
						}
						<Label
							element="div"
							elementProps={{className: 'app-name'}}
							content={this.t('title', 'App')}
						/>
						<Label
							element={labelElement}
							elementProps={labelElementProps}
							content={this.t('form_title')}
							supportHtml={true}
						/>
						<Label element="div" elementProps={{className: 'desc'}} content={this.t('form_description')} />
					</div>
					<div className={cardContentClassName}>
						<FormWrapper>
							<FormField
								required={true}
								label={this.t('Email')}
								labelPosition={labelPosition}
								errorMessages={this.getValidationErrors('email')}
							>
								<TextInput
									type="email"
									name="email"
									value={this.getValue('email')}
									onChange={this.handleInputChange}
									onEnterKey={submitOnEnter ? this.requestPassword : undefined}
									placeholder={this.t('Enter your email address')}
								/>
							</FormField>
						</FormWrapper>
					</div>
					<div className={cardActionsClassName}>
						{
							backAction ?
								<Button
									big={true}
									icon="chevron-left"
									label={this.t('Back', 'general')}
									displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
									onClick={backAction}
								/>
								: null
						}
						<Button
							label={this.t('action_btn')}
							onClick={this.requestPassword}
							{...omit(buttonProps, ['onClick'])}
						/>
					</div>
				</div>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PasswordResetRequest.propTypes = {
	// Component style name
	// @description Component style name is a name of the style that will be used to determine the CSS used to style the
	// component.
	styleName: PropTypes.string,
	// Login component wrapper element ID class attribute
	id: PropTypes.string,
	// Login component wrapper element class attribute
	className: PropTypes.string,
	// Login component internal element card class name
	cardClassName: PropTypes.string,
	// Login component internal element card header class name
	cardHeaderClassName: PropTypes.string,
	// Login component internal element card content class name
	cardContentClassName: PropTypes.string,
	// Login component internal element card actions class name
	cardActionsClassName: PropTypes.string,
	// Login component input fields label position
	// @see FORM_FIELD_LABEL_POSITION const.
	labelPosition: PropTypes.string,
	// Login component input fields label icon
	labelIcon: PropTypes.string,
	// Login component input fields label wrapper element name
	// @note If empty, no wrapper element will be rendered.
	labelElement: PropTypes.string,
	// Login component input fields label wrapper element props
	labelElementProps: PropTypes.object,
	// Main button component props
	buttonProps: PropTypes.object,
	// Form submit function
	action: PropTypes.func, // Arguments: email
	// Form back action
	backAction: PropTypes.func,
	// Flag that determines if form will be submitted on email input enter key press
	submitOnEnter: PropTypes.bool,
	// Logo to render
	logo: PropTypes.any,
};

/**
 * Define component default values for own props
 */
PasswordResetRequest.defaultProps = {
	styleName: 'default',
	className: '',
	cardClassName: 'card icon-card',
	cardHeaderClassName: 'card-header',
	cardContentClassName: 'card-content',
	cardActionsClassName: 'card-actions',
	labelPosition: FORM_FIELD_LABEL_POSITION.STACKED,
	labelIcon: 'key',
	labelElement: '',
	buttonProps: {
		icon: 'envelope-o',
		displayStyle: BUTTON_STYLE.ACTION,
		displayType: BUTTON_DISPLAY_TYPE.SOLID,
		big: true
	},
	submitOnEnter: true,
	logo: null,
};

export default connect(null, getGlobalActions())(PasswordResetRequest);