// Standard locale date format names
// @see src/i18n/locale.js
export const LOCALE_DATE_FORMAT_NAME_SHORT = 'short';
export const LOCALE_DATE_FORMAT_NAME_STANDARD = 'standard';
export const LOCALE_DATE_FORMAT_NAME_LONG = 'long';
export const LOCALE_DATE_FORMAT_NAME = {
	SHORT: LOCALE_DATE_FORMAT_NAME_SHORT,
	STANDARD: LOCALE_DATE_FORMAT_NAME_STANDARD,
	LONG: LOCALE_DATE_FORMAT_NAME_LONG
};
export const LOCALE_DATE_FORMAT_NAMES = [
	LOCALE_DATE_FORMAT_NAME_SHORT, LOCALE_DATE_FORMAT_NAME_STANDARD, LOCALE_DATE_FORMAT_NAME_LONG
];

// Standard locale time format names
// @see src/i18n/locale.js
export const LOCALE_TIME_FORMAT_NAME_SHORT = 'short';
export const LOCALE_TIME_FORMAT_NAME_STANDARD = 'standard';
export const LOCALE_TIME_FORMAT_NAME_LONG = 'long';
export const LOCALE_TIME_FORMAT_NAME = {
	SHORT: LOCALE_TIME_FORMAT_NAME_SHORT,
	STANDARD: LOCALE_TIME_FORMAT_NAME_STANDARD,
	LONG: LOCALE_TIME_FORMAT_NAME_LONG
};
export const LOCALE_TIME_FORMAT_NAMES = [
	LOCALE_TIME_FORMAT_NAME_SHORT, LOCALE_TIME_FORMAT_NAME_STANDARD, LOCALE_TIME_FORMAT_NAME_LONG
];