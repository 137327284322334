/** @typedef {string} ReportOutputType */
export const REPORT_OUTPUT_TYPE_DATA_TABLE = 'DATA_TABLE';
export const REPORT_OUTPUT_TYPE_LINE_CHART = 'LINE_CHART';
export const REPORT_OUTPUT_TYPE_AREA_CHART = 'AREA_CHART';
export const REPORT_OUTPUT_TYPE_BAR_CHART = 'BAR_CHART';
export const REPORT_OUTPUT_TYPE_PIE_CHART = 'PIE_CHART';
export const REPORT_OUTPUT_TYPE_DONUT_CHART = 'DONUT_CHART';
/** @enum {ReportOutputType} */
export const REPORT_OUTPUT_TYPE = {
	DATA_TABLE: REPORT_OUTPUT_TYPE_DATA_TABLE,
	LINE_CHART: REPORT_OUTPUT_TYPE_LINE_CHART,
	AREA_CHART: REPORT_OUTPUT_TYPE_AREA_CHART,
	BAR_CHART: REPORT_OUTPUT_TYPE_BAR_CHART,
	PIE_CHART: REPORT_OUTPUT_TYPE_PIE_CHART,
	DONUT_CHART: REPORT_OUTPUT_TYPE_DONUT_CHART,
}
/** @type {ReportOutputType[]} */
export const REPORT_OUTPUT_TYPES = [
	REPORT_OUTPUT_TYPE_DATA_TABLE,
	REPORT_OUTPUT_TYPE_LINE_CHART,
	REPORT_OUTPUT_TYPE_AREA_CHART,
	REPORT_OUTPUT_TYPE_BAR_CHART,
	REPORT_OUTPUT_TYPE_PIE_CHART,
	REPORT_OUTPUT_TYPE_DONUT_CHART,
];
/** @type {Object<ReportOutputType, {symbol: string, [iconSymbolPrefix]: string}>} */
export const REPORT_OUTPUT_TYPE_ICON_PROPS = {
	DATA_TABLE: { symbol: 'table' },
	LINE_CHART: { symbol: 'line-chart' },
	AREA_CHART: { symbol: 'area-chart' },
	BAR_CHART: { symbol: 'bar-chart' },
	PIE_CHART: { symbol: 'pie-chart' },
	DONUT_CHART: { symbol: 'chart-pie-alt', symbolPrefix: 'icofont-' },
};

/**
 * List of report output types representing chart reports
 * @type {ReportOutputType[]}
 */
export const REPORT_OUTPUT_CHART_TYPES = [
	REPORT_OUTPUT_TYPE_LINE_CHART,
	REPORT_OUTPUT_TYPE_AREA_CHART,
	REPORT_OUTPUT_TYPE_BAR_CHART,
	REPORT_OUTPUT_TYPE_PIE_CHART,
	REPORT_OUTPUT_TYPE_DONUT_CHART,
];

/** @typedef {string} ReportChartCategory */
export const REPORT_CHART_CATEGORY_LINEAR = 'LINEAR';
export const REPORT_CHART_CATEGORY_CIRCULAR = 'CIRCULAR';
/** @enum {ReportChartCategory} */
export const REPORT_CHART_CATEGORY = {
	LINEAR: REPORT_CHART_CATEGORY_LINEAR,
	CIRCULAR: REPORT_CHART_CATEGORY_CIRCULAR,
};
/** @type {ReportChartCategory[]} */
export const REPORT_CHART_CATEGORIES = [
	REPORT_CHART_CATEGORY_LINEAR,
	REPORT_CHART_CATEGORY_CIRCULAR,
];

/**
 * Report chart output types by chart categories
 */
export const REPORT_CHART_CATEGORY_OUTPUT_TYPES = {
	[REPORT_CHART_CATEGORY_LINEAR]: [
		REPORT_OUTPUT_TYPE_LINE_CHART,
		REPORT_OUTPUT_TYPE_AREA_CHART,
		REPORT_OUTPUT_TYPE_BAR_CHART,
	],
	[REPORT_CHART_CATEGORY_CIRCULAR]: [
		REPORT_OUTPUT_TYPE_PIE_CHART,
		REPORT_OUTPUT_TYPE_DONUT_CHART,
	],
};

/** @typedef {string} ReportFileType */
export const REPORT_FILE_TYPE_CSV = 'CSV';
export const REPORT_FILE_TYPE_EXCEL = 'EXCEL';
export const REPORT_FILE_TYPE_PDF = 'PDF';
export const REPORT_FILE_TYPE_SVG = 'SVG';
export const REPORT_FILE_TYPE_PNG = 'PNG';
export const REPORT_FILE_TYPE_JPG = 'JPG';
export const REPORT_FILE_TYPE_BMP = 'BMP';
/** @enum {ReportFileType} */
export const REPORT_FILE_TYPE = {
	CSV: REPORT_FILE_TYPE_CSV,
	EXCEL: REPORT_FILE_TYPE_EXCEL,
	PDF: REPORT_FILE_TYPE_PDF,
	SVG: REPORT_FILE_TYPE_SVG,
	PNG: REPORT_FILE_TYPE_PNG,
	JPG: REPORT_FILE_TYPE_JPG,
	BMP: REPORT_FILE_TYPE_BMP,
}
/** @type {ReportFileType[]} */
export const REPORT_FILE_TYPES = [
	REPORT_FILE_TYPE_CSV,
	REPORT_FILE_TYPE_EXCEL,
	REPORT_FILE_TYPE_PDF,
	REPORT_FILE_TYPE_SVG,
	REPORT_FILE_TYPE_PNG,
	REPORT_FILE_TYPE_JPG,
	REPORT_FILE_TYPE_BMP,
];
/** @type {Object<ReportFileType, {symbol: string, [symbolPrefix]: string}>} */
export const REPORT_FILE_TYPE_ICON_PROPS = {
	[REPORT_FILE_TYPE_CSV]: {symbol: 'file-text', symbolPrefix: 'icofont-'},
	[REPORT_FILE_TYPE_EXCEL]: {symbol: 'file-excel', symbolPrefix: 'icofont-'},
	[REPORT_FILE_TYPE_PDF]: {symbol: 'file-pdf', symbolPrefix: 'icofont-'},
	[REPORT_FILE_TYPE_SVG]: {symbol: 'file-svg', symbolPrefix: 'icofont-'},
	[REPORT_FILE_TYPE_PNG]: {symbol: 'file-png', symbolPrefix: 'icofont-'},
	[REPORT_FILE_TYPE_JPG]: {symbol: 'file-jpg', symbolPrefix: 'icofont-'},
	[REPORT_FILE_TYPE_BMP]: {symbol: 'file-bmp', symbolPrefix: 'icofont-'},
};
/** @type {Object<ReportOutputType, ReportFileType[]>} */
export const REPORT_FILE_TYPE_FOR_OUTPUT = {
	[REPORT_OUTPUT_TYPE_DATA_TABLE]: [REPORT_FILE_TYPE_CSV, REPORT_FILE_TYPE_EXCEL],
	[REPORT_OUTPUT_TYPE_LINE_CHART]: [
		REPORT_FILE_TYPE_PDF, REPORT_FILE_TYPE_SVG, REPORT_FILE_TYPE_PNG, REPORT_FILE_TYPE_JPG, REPORT_FILE_TYPE_BMP
	],
	[REPORT_OUTPUT_TYPE_AREA_CHART]: [
		REPORT_FILE_TYPE_PDF, REPORT_FILE_TYPE_SVG, REPORT_FILE_TYPE_PNG, REPORT_FILE_TYPE_JPG, REPORT_FILE_TYPE_BMP
	],
	[REPORT_OUTPUT_TYPE_BAR_CHART]: [
		REPORT_FILE_TYPE_PDF, REPORT_FILE_TYPE_SVG, REPORT_FILE_TYPE_PNG, REPORT_FILE_TYPE_JPG, REPORT_FILE_TYPE_BMP
	],
	[REPORT_OUTPUT_TYPE_PIE_CHART]: [
		REPORT_FILE_TYPE_PDF, REPORT_FILE_TYPE_SVG, REPORT_FILE_TYPE_PNG, REPORT_FILE_TYPE_JPG, REPORT_FILE_TYPE_BMP
	],
	[REPORT_OUTPUT_TYPE_DONUT_CHART]: [
		REPORT_FILE_TYPE_PDF, REPORT_FILE_TYPE_SVG, REPORT_FILE_TYPE_PNG, REPORT_FILE_TYPE_JPG, REPORT_FILE_TYPE_BMP
	],
};


/** @typedef {string} ReportFilterComparator */
export const REPORT_FILTER_COMPARATOR_EQ = 'EQ';
export const REPORT_FILTER_COMPARATOR_NEQ = 'NEQ';
export const REPORT_FILTER_COMPARATOR_GT = 'GT';
export const REPORT_FILTER_COMPARATOR_GTE = 'GTE';
export const REPORT_FILTER_COMPARATOR_LT = 'LT';
export const REPORT_FILTER_COMPARATOR_LTE = 'LTE';
export const REPORT_FILTER_COMPARATOR_LIKE = 'LIKE';
export const REPORT_FILTER_COMPARATOR_IN = 'IN';
export const REPORT_FILTER_COMPARATOR_NOT_IN = 'NOT_IN';
export const REPORT_FILTER_COMPARATOR_STARTS = 'STARTS';
export const REPORT_FILTER_COMPARATOR_ENDS = 'ENDS';
export const REPORT_FILTER_COMPARATOR_BETWEEN = 'BTW';
/** @enum {ReportFilterComparator} */
export const REPORT_FILTER_COMPARATOR = {
	EQ: REPORT_FILTER_COMPARATOR_EQ,
	NEQ: REPORT_FILTER_COMPARATOR_NEQ,
	GT: REPORT_FILTER_COMPARATOR_GT,
	GTE: REPORT_FILTER_COMPARATOR_GTE,
	LT: REPORT_FILTER_COMPARATOR_LT,
	LTE: REPORT_FILTER_COMPARATOR_LTE,
	LIKE: REPORT_FILTER_COMPARATOR_LIKE,
	IN: REPORT_FILTER_COMPARATOR_IN,
	NOT_IN: REPORT_FILTER_COMPARATOR_NOT_IN,
	STARTS: REPORT_FILTER_COMPARATOR_STARTS,
	ENDS: REPORT_FILTER_COMPARATOR_ENDS,
	BETWEEN: REPORT_FILTER_COMPARATOR_BETWEEN,
}
/** @type {ReportFilterComparator[]} */
export const REPORT_FILTER_COMPARATORS = [
	REPORT_FILTER_COMPARATOR_EQ,
	REPORT_FILTER_COMPARATOR_NEQ,
	REPORT_FILTER_COMPARATOR_GT,
	REPORT_FILTER_COMPARATOR_GTE,
	REPORT_FILTER_COMPARATOR_LT,
	REPORT_FILTER_COMPARATOR_LTE,
	REPORT_FILTER_COMPARATOR_LIKE,
	REPORT_FILTER_COMPARATOR_IN,
	REPORT_FILTER_COMPARATOR_NOT_IN,
	REPORT_FILTER_COMPARATOR_STARTS,
	REPORT_FILTER_COMPARATOR_ENDS,
	REPORT_FILTER_COMPARATOR_BETWEEN,
];


/** @typedef {string} ReportFilterItemDisplayType */
export const REPORT_FILTER_ITEM_DISPLAY_TYPE_TEXT = 'TEXT';
export const REPORT_FILTER_ITEM_DISPLAY_TYPE_INTEGER = 'INTEGER';
export const REPORT_FILTER_ITEM_DISPLAY_TYPE_FLOAT = 'FLOAT';
export const REPORT_FILTER_ITEM_DISPLAY_TYPE_BOOLEAN = 'BOOLEAN';
export const REPORT_FILTER_ITEM_DISPLAY_TYPE_DATE = 'DATE';
export const REPORT_FILTER_ITEM_DISPLAY_TYPE_DATETIME = 'DATE_TIME';
export const REPORT_FILTER_ITEM_DISPLAY_TYPE_MONTH = 'MONTH';
export const REPORT_FILTER_ITEM_DISPLAY_TYPE_SELECT = 'SELECT';
export const REPORT_FILTER_ITEM_DISPLAY_TYPE_ASYNC_SELECT = 'ASYNC_SELECT';
/** @enum {ReportFilterItemDisplayType} */
export const REPORT_FILTER_ITEM_DISPLAY_TYPE = {
	TEXT: REPORT_FILTER_ITEM_DISPLAY_TYPE_TEXT,
	INTEGER: REPORT_FILTER_ITEM_DISPLAY_TYPE_INTEGER,
	FLOAT: REPORT_FILTER_ITEM_DISPLAY_TYPE_FLOAT,
	BOOLEAN: REPORT_FILTER_ITEM_DISPLAY_TYPE_BOOLEAN,
	DATE: REPORT_FILTER_ITEM_DISPLAY_TYPE_DATE,
	DATETIME: REPORT_FILTER_ITEM_DISPLAY_TYPE_DATETIME,
	MONTH: REPORT_FILTER_ITEM_DISPLAY_TYPE_MONTH,
	SELECT: REPORT_FILTER_ITEM_DISPLAY_TYPE_SELECT,
	ASYNC_SELECT: REPORT_FILTER_ITEM_DISPLAY_TYPE_ASYNC_SELECT,
};
/** @type {ReportFilterItemDisplayType[]} */
export const REPORT_FILTER_ITEM_DISPLAY_TYPES = [
	REPORT_FILTER_ITEM_DISPLAY_TYPE_TEXT,
	REPORT_FILTER_ITEM_DISPLAY_TYPE_INTEGER,
	REPORT_FILTER_ITEM_DISPLAY_TYPE_FLOAT,
	REPORT_FILTER_ITEM_DISPLAY_TYPE_BOOLEAN,
	REPORT_FILTER_ITEM_DISPLAY_TYPE_DATE,
	REPORT_FILTER_ITEM_DISPLAY_TYPE_DATETIME,
	REPORT_FILTER_ITEM_DISPLAY_TYPE_MONTH,
	REPORT_FILTER_ITEM_DISPLAY_TYPE_SELECT,
	REPORT_FILTER_ITEM_DISPLAY_TYPE_ASYNC_SELECT,
];

/**
 * Defines which comparators are available for each report filter item display type
 */
export const REPORT_FILTER_DISPLAY_TYPE_COMPARATORS = {
	[REPORT_FILTER_ITEM_DISPLAY_TYPE_TEXT]: [
		REPORT_FILTER_COMPARATOR_EQ,
		REPORT_FILTER_COMPARATOR_NEQ,
		REPORT_FILTER_COMPARATOR_LIKE,
		REPORT_FILTER_COMPARATOR_STARTS,
		REPORT_FILTER_COMPARATOR_ENDS,
		REPORT_FILTER_COMPARATOR_IN,
		REPORT_FILTER_COMPARATOR_NOT_IN,
		REPORT_FILTER_COMPARATOR_BETWEEN
	],
	[REPORT_FILTER_ITEM_DISPLAY_TYPE_INTEGER]: [
		REPORT_FILTER_COMPARATOR_EQ,
		REPORT_FILTER_COMPARATOR_NEQ,
		REPORT_FILTER_COMPARATOR_GT,
		REPORT_FILTER_COMPARATOR_GTE,
		REPORT_FILTER_COMPARATOR_LT,
		REPORT_FILTER_COMPARATOR_LTE,
		REPORT_FILTER_COMPARATOR_IN,
		REPORT_FILTER_COMPARATOR_NOT_IN,
		REPORT_FILTER_COMPARATOR_BETWEEN
	],
	[REPORT_FILTER_ITEM_DISPLAY_TYPE_FLOAT]: [
		REPORT_FILTER_COMPARATOR_EQ,
		REPORT_FILTER_COMPARATOR_NEQ,
		REPORT_FILTER_COMPARATOR_GT,
		REPORT_FILTER_COMPARATOR_GTE,
		REPORT_FILTER_COMPARATOR_LT,
		REPORT_FILTER_COMPARATOR_LTE,
		REPORT_FILTER_COMPARATOR_IN,
		REPORT_FILTER_COMPARATOR_NOT_IN,
		REPORT_FILTER_COMPARATOR_BETWEEN
	],
	[REPORT_FILTER_ITEM_DISPLAY_TYPE_BOOLEAN]: [
		REPORT_FILTER_COMPARATOR_EQ,
		REPORT_FILTER_COMPARATOR_NEQ,
		REPORT_FILTER_COMPARATOR_IN,
		REPORT_FILTER_COMPARATOR_NOT_IN
	],
	[REPORT_FILTER_ITEM_DISPLAY_TYPE_DATE]: [
		REPORT_FILTER_COMPARATOR_EQ,
		REPORT_FILTER_COMPARATOR_NEQ,
		REPORT_FILTER_COMPARATOR_GT,
		REPORT_FILTER_COMPARATOR_GTE,
		REPORT_FILTER_COMPARATOR_LT,
		REPORT_FILTER_COMPARATOR_LTE,
		REPORT_FILTER_COMPARATOR_IN,
		REPORT_FILTER_COMPARATOR_NOT_IN,
		REPORT_FILTER_COMPARATOR_BETWEEN
	],
	[REPORT_FILTER_ITEM_DISPLAY_TYPE_DATETIME]: [
		REPORT_FILTER_COMPARATOR_EQ,
		REPORT_FILTER_COMPARATOR_NEQ,
		REPORT_FILTER_COMPARATOR_GT,
		REPORT_FILTER_COMPARATOR_GTE,
		REPORT_FILTER_COMPARATOR_LT,
		REPORT_FILTER_COMPARATOR_LTE,
		REPORT_FILTER_COMPARATOR_IN,
		REPORT_FILTER_COMPARATOR_NOT_IN,
		REPORT_FILTER_COMPARATOR_BETWEEN
	],
	[REPORT_FILTER_ITEM_DISPLAY_TYPE_MONTH]: [
		REPORT_FILTER_COMPARATOR_EQ,
		REPORT_FILTER_COMPARATOR_NEQ,
		REPORT_FILTER_COMPARATOR_GT,
		REPORT_FILTER_COMPARATOR_GTE,
		REPORT_FILTER_COMPARATOR_LT,
		REPORT_FILTER_COMPARATOR_LTE,
		REPORT_FILTER_COMPARATOR_IN,
		REPORT_FILTER_COMPARATOR_NOT_IN,
		REPORT_FILTER_COMPARATOR_BETWEEN
	],
	[REPORT_FILTER_ITEM_DISPLAY_TYPE_SELECT]: [
		REPORT_FILTER_COMPARATOR_EQ,
		REPORT_FILTER_COMPARATOR_NEQ,
		REPORT_FILTER_COMPARATOR_IN,
		REPORT_FILTER_COMPARATOR_NOT_IN,
		REPORT_FILTER_COMPARATOR_BETWEEN
	],
	[REPORT_FILTER_ITEM_DISPLAY_TYPE_ASYNC_SELECT]: [
		REPORT_FILTER_COMPARATOR_EQ,
		REPORT_FILTER_COMPARATOR_NEQ,
		REPORT_FILTER_COMPARATOR_IN,
		REPORT_FILTER_COMPARATOR_NOT_IN,
		REPORT_FILTER_COMPARATOR_BETWEEN
	],
};

/** 
 * Chart type defined by API that is not directly used in the app
 * @note Please use ReportOutputType instead.
 * @typedef {string} ReportChartType
 */
export const REPORT_CHART_TYPE_LINE = 'LINE';
export const REPORT_CHART_TYPE_AREA = 'AREA';
export const REPORT_CHART_TYPE_BAR = 'BAR';
export const REPORT_CHART_TYPE_PIE = 'PIE';
export const REPORT_CHART_TYPE_DONUT = 'DONUT';
/** @enum {ReportChartType} */
export const REPORT_CHART_TYPE = {
	LINE: REPORT_CHART_TYPE_LINE,
	AREA: REPORT_CHART_TYPE_AREA,
	BAR: REPORT_CHART_TYPE_BAR,
	PIE: REPORT_CHART_TYPE_PIE,
	DONUT: REPORT_CHART_TYPE_DONUT,
};
/** @type {ReportChartType[]} */
export const REPORT_CHART_TYPES = [
	REPORT_CHART_TYPE_LINE,
	REPORT_CHART_TYPE_AREA,
	REPORT_CHART_TYPE_BAR,
	REPORT_CHART_TYPE_PIE,
	REPORT_CHART_TYPE_DONUT,
];

/**
 * Map report output type to report chart type
 */
export const REPORT_OUTPUT_TYPE_TO_REPORT_CHART_TYPE = {
	[REPORT_OUTPUT_TYPE_LINE_CHART]: REPORT_CHART_TYPE_LINE,
	[REPORT_OUTPUT_TYPE_AREA_CHART]: REPORT_CHART_TYPE_AREA,
	[REPORT_OUTPUT_TYPE_BAR_CHART]: REPORT_CHART_TYPE_BAR,
	[REPORT_OUTPUT_TYPE_PIE_CHART]: REPORT_CHART_TYPE_PIE,
	[REPORT_OUTPUT_TYPE_DONUT_CHART]: REPORT_CHART_TYPE_DONUT,
};