import {cloneDeep} from "lodash";
import {getArray, getBoolean, getNumber, getString, resetObjectValues} from "../../../core/helpers/data";
import {AccountDataObject} from "../dataObjects";
import {ACCOUNT_USER_TYPE} from "../../../const/account";
import * as accountRoleDataMap from "../../accountRoles/dataMap/accountRole";


/**
 * Get input data from raw data
 * @param {{}} rawData - Raw data.
 * @return {AccountDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new AccountDataObject(
		getString(result, 'id'),
		getString(result, 'userName'),
		getString(result, 'userType'),
		getString(result, 'email'),
		getString(result, 'firstName'),
		getString(result, 'lastName'),
		getArray(result, 'roleDataList').map(r => accountRoleDataMap.input(r)),
		getBoolean(result, 'active'),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {AccountDataObject} inputData - Input data.
 * @return {{}}
 */
export const output = inputData =>  {
	let result = cloneDeep(inputData);

	result = {
		userName: getString(result, 'userName'),
		userType: getString(result, 'userType'),
		email: getString(result, 'email'),
		firstName: getString(result, 'firstName'),
		lastName: getString(result, 'lastName'),
		roleIds: (
			// Clear role IDs if user is super admin since super admins have full access and don't need roles
			getString(result, 'userType') === ACCOUNT_USER_TYPE.SUPER_ADMIN ?
				[] : getArray(result, 'roles').map(r => getNumber(r, 'id'))
		),
		active: getBoolean(result, 'active'),
		clientId: '',
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}