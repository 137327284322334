import styles from "./index.module.css";
import "./index.css";

import React from "react";
import PageDataComponent from "../../core/components/PageDataComponent";
import {connect} from "react-redux";
import * as pageConfig from "./config";
import * as actions from "./actions";
import {selectors} from "../../core/store/reducers";
import {getPageActions} from "../../core/helpers/redux";
import DataTable, {DATA_TABLE_CELL_TYPE} from "../../core/components/advanced/DataTable";
import {areAllObjectPropsEmpty, getArray, getNumber, getString, isset} from "../../core/helpers/data";
import * as filterDataMap from "./dataMap/filter";
import {scrollToSelector} from "../../core/helpers/dom";
import ConfirmDialog from "../../core/components/dialogs/ConfirmDialog";
import {routerPath as builderPageRouterPath} from "../builder";
import SimpleStaticSearch, {
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE,
	SIMPLE_STATIC_SEARCH_LAYOUT,
	SimpleStaticSearchOptionObject
} from "../../core/components/advanced/SimpleStaticSearch";
import {STANDARD_DATE_TIME_FORMAT} from "../../core/const/datetime";
import {DATETIME_ROUND_TO} from "../../core/components/input/DateInput/const";
import Label from "../../core/components/display/Label";
import {LOCALE_DATE_FORMAT_NAME, LOCALE_TIME_FORMAT_NAME} from "../../core/const/locale";
import {getLocaleDatetimeSeparator} from "../../core/helpers/locale";
import {PAGINATION_TYPE} from "../../core/components/action/Pagination";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../core/components/display/Button";
import {Tooltip} from "react-tippy";
import {icon_font_delete_symbol, icon_font_edit_symbol} from "../../config";
import ACL from "../../acl";
import {getMenuSidebarShrankFromStorage} from "../../layout/elements/MainSidebar/helpers";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	appLocale: selectors.i18n.getLocale(state),
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	reportTemplates: selectors.reportTemplates.getReportTemplates(state),
	reportTemplatesPagination: selectors.reportTemplates.getReportTemplatesPagination(state),
	reportTemplatesSort: selectors.reportTemplates.getReportTemplatesSort(state),
	reportTemplatesFilter: selectors.reportTemplates.getReportTemplatesFilter(state),
});

class ReportTemplatesPage extends PageDataComponent {
	constructor(props) {
		super(props, {
			data: {
				/**
				 * Currently selected search filter
				 */
				filter: {},
				/**
				 * Flag showing if filter is loading
				 */
				filterLoading: false,

				/** List of data sources loaded from the codebook */
				dataSources: [],
				dataSourcesLoading: false,
			},

			/**
			 * Flag that specifies if main data table height will be limited to the available space
			 */
			limitToAvailableSpace: true
		}, {
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			disableLoad: true,
		}, 'page_title');

		// Data loading methods
		this.getUsernames = this.getUsernames.bind(this);
		this.loadDataSources = this.loadDataSources.bind(this);

		// Data methods
		this.reloadReportTemplates = this.reloadReportTemplates.bind(this);
		this.loadReportTemplatesPage = this.loadReportTemplatesPage.bind(this);
		this.sortReportTemplates = this.sortReportTemplates.bind(this);
		this.filterReportTemplates = this.filterReportTemplates.bind(this);
		this.removeReportTemplatesFilter = this.removeReportTemplatesFilter.bind(this);
		this.isReportTemplatesFilterEmpty = this.isReportTemplatesFilterEmpty.bind(this);

		// Action methods
		this.editReportTemplate = this.editReportTemplate.bind(this);
		this.deleteReportTemplate = this.deleteReportTemplate.bind(this);

		// Render methods
		this.renderActions = this.renderActions.bind(this);
		this.renderPageTitle = this.renderPageTitle.bind(this);
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return 'report-templates-page'; }


	// Data loading methods ---------------------------------------------------------------------------------------------
	/**
	 * Fetch usernames list based on query string
	 * @note This action is perfect for use in async select components.
	 *
	 * @param {string} [query=''] - Query string to search for.
	 * @return {Promise<[]>}
	 */
	getUsernames(query = '') {
		const {fetchUsernamesAction} = this.props;

		return new Promise(resolve => {
			if (query.length > 2) {
				this.executeAbortableAction(fetchUsernamesAction, query)
					.then(responseData => {resolve(getArray(responseData, 'data'))})
					.catch(() => {resolve([])});
			} else {
				resolve([]);
			}
		});
	}

	/**
	 * Load data sources into local state data
	 * @return {Promise<*>}
	 */
	loadDataSources() {
		const {fetchDataSourcesAction} = this.props;
		
		// Load data sources used in filters
		return this.setValue('dataSourcesLoading', true)
			.then(() => this.executeAbortableAction(fetchDataSourcesAction))
			.then(response => {
				if (response) {
					return this.setValue('dataSources', getArray(response, 'data'))
						.then(() => this.setValue('dataSourcesLoading', false))
				}
				return Promise.resolve(this.state);
			});
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {Promise<*>}
	 */
	loadPageData() {
		const {reportTemplates, loadReportTemplatesAction} = this.props;

		// Load data sources used in filters
		this.loadDataSources().then();

		// Reload report templates if they are not already loaded
		// @note This will load report templates using current options (filter, pagination, ...)
		if (isset(reportTemplates)) {
			// Open filter if it is not empty
			if (!this.isReportTemplatesFilterEmpty() && this.reportTemplatesFilterRef) {
				this.reportTemplatesFilterRef.open();
			}
			return this.reloadReportTemplates();
		}
		// Load report templates if they are not already loaded
		else {
			return this.setValue('loading', true)
				.then(() => this.executeAbortableAction(loadReportTemplatesAction))
				.then(() => this.setValue('loading', false));
		}
	}

	/**
	 * Reload report templates using current options (page, sort, ...)
	 * @return {Promise<*>}
	 */
	reloadReportTemplates() {
		const {
			loadReportTemplatesAction, reportTemplatesPagination, reportTemplatesSort, reportTemplatesFilter
		} = this.props;
		const {pageNo, perPage} = reportTemplatesPagination;
		const {sortBy, sortDir} = reportTemplatesSort;
		const oFilter = filterDataMap.output(reportTemplatesFilter);
		
		return this.executeAbortableAction(loadReportTemplatesAction, oFilter, pageNo, perPage, sortBy, sortDir)
			.then(() => this.reportTemplatesFilterRef?.reload());
	}

	/**
	 * Load report templates page
	 * @param {number} [pageNo=1] - Page number to load (starts with 1).
	 * @return {Promise<*>}
	 */
	loadReportTemplatesPage(pageNo = 1) {
		const {
			loadReportTemplatesAction, reportTemplatesPagination, reportTemplatesSort, reportTemplatesFilter
		} = this.props;
		const {perPage} = reportTemplatesPagination;
		const {sortBy, sortDir} = reportTemplatesSort;
		const oFilter = filterDataMap.output(reportTemplatesFilter);
		
		return this.executeAbortableAction(loadReportTemplatesAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Sort report templates
	 * @param {string} sortBy - Name of the sort column.
	 * @param {string} sortDir - Direction of the sort.
	 * @return {Promise<*>}
	 */
	sortReportTemplates(sortBy, sortDir) {
		const {loadReportTemplatesAction, reportTemplatesPagination, reportTemplatesFilter} = this.props;
		const {pageNo, perPage} = reportTemplatesPagination;
		const oFilter = filterDataMap.output(reportTemplatesFilter);
		
		return this.executeAbortableAction(loadReportTemplatesAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Filter report templates
	 * @param {Object} filter - Filter object where keys are filter field names and values are filter values.
	 * @return {Promise<*>}
	 */
	filterReportTemplates(filter) {
		const {loadReportTemplatesAction, reportTemplatesPagination, reportTemplatesSort} = this.props;
		const {perPage} = reportTemplatesPagination;
		const {sortBy, sortDir} = reportTemplatesSort;
		const oFilter = filterDataMap.output(filter);
		
		return this.setValue('filterLoading', true)
			.then(() => this.executeAbortableAction(loadReportTemplatesAction, oFilter, 1, perPage, sortBy, sortDir))
			.then(() => this.setValue('filterLoading', false))
			.then(() => {
				if (areAllObjectPropsEmpty(filter, true, false)) {
					if (this.reportTemplatesFilterRef) this.reportTemplatesFilterRef.close();
				} else {
					scrollToSelector('#main-page-table', false, 80);
				}
			});
	}

	/**
	 * Remove report templates filter
	 * @return {Promise<*>}
	 */
	removeReportTemplatesFilter() {
		return this.filterReportTemplates(null);
	}

	/**
	 * Check if report templates filter is applied
	 * @return {Boolean}
	 */
	isReportTemplatesFilterEmpty() {
		return areAllObjectPropsEmpty(this.getProp('reportTemplatesFilter'), true, false);
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Edit report template
	 * @param {Object} data - Report template data to edit.
	 */
	editReportTemplate(data) {
		this.redirectTo(`${builderPageRouterPath}/item/${data.id}`);
	}

	/**
	 * Delete report template
	 * @param {Object} data - Report template data of the report that will be deleted.
	 */
	deleteReportTemplate(data) {
		const {openDialogAction, closeDialogAction, deleteReportTemplateAction} = this.props;

		const dialogGUIID = openDialogAction('', ConfirmDialog, {
			message: this.t('confirm_delete'),
			supportHtml: true,
			onYes: () => {
				this.executeAbortableAction(deleteReportTemplateAction, data.id)
					.then(() => this.reloadReportTemplates())
					// Go to the previous page if there are no table rows after one has been deleted
					.then(() => {
						const reportTemplates = getArray(this.props, 'reportTemplates');
						const pageNo = getNumber(this.props, 'reportTemplatesPagination.pageNo', 1);
						if (reportTemplates.length === 0 && pageNo > 1) return this.loadReportTemplatesPage(pageNo-1);
					})
					.then(() => closeDialogAction(dialogGUIID));
			},
			onNo: () => closeDialogAction(dialogGUIID)
		}, {
			id: 'report-template-delete-dialog',
			closeOnEscape: true,
			closeOnClickOutside: true,
			hideCloseBtn: true,
			maxWidth: 500
		});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render data table actions cell
	 * @param {Object} reportTemplate - Report template data object (one data table row).
	 * @return {JSX.Element}
	 */
	renderActions(reportTemplate) {
		return (
			<div className="actions">
				{
					ACL.checkPermission(['REPORT_TEMPLATE_CREATE']) ?
						<Tooltip
							tag="div"
							title={this.t('edit_tooltip')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<Button
								className="action-btn"
								displayStyle={BUTTON_STYLE.ACTION}
								displayType={BUTTON_DISPLAY_TYPE.NONE}
								icon={icon_font_edit_symbol}
								onClick={() => this.editReportTemplate(reportTemplate)}
							/>
						</Tooltip>
						: null
				}

				{
					ACL.checkPermission(['REPORT_TEMPLATE_DELETE']) ?
						<Tooltip
							tag="div"
							title={this.t('delete_tooltip')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<Button
								className="action-btn"
								displayStyle={BUTTON_STYLE.ACTION}
								displayType={BUTTON_DISPLAY_TYPE.NONE}
								icon={icon_font_delete_symbol}
								onClick={() => this.deleteReportTemplate(reportTemplate)}
							/>
						</Tooltip>
						: null
				}
			</div>
		);
	}

	/**
	 * Render page title
	 * @description This method specifies how page title will be rendered if page title should be rendered. It does not
	 * determine if page title should be rendered.
	 * @return {JSX.Element}
	 */
	renderPageTitle() {
		const {title} = this.state;

		return (
			<h1 className="page-title with-actions">
				<div className="content">{title ? this.translate(title, this.titlePathPrefix) : ''}</div>
				<div className="actions">
					<div className="action-button">
						<Tooltip
							tag="div"
							title={this.t('Reload data', 'general')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<Button
								big={true}
								icon="refresh"
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								displayStyle={BUTTON_STYLE.SUBTLE}
								onClick={this.reloadReportTemplates}
							/>
						</Tooltip>
					</div>
				</div>
			</h1>
		);
	}

	render() {
		const {
			appLocale, reportTemplates, reportTemplatesPagination, reportTemplatesSort, reportTemplatesFilter,
			mainSidebarShrank, toggleMainSidebarSizeAction,
		} = this.props;
		const {limitToAvailableSpace} = this.state;
		
		return (
			this.renderLayout((
				<div id={this.getDomId()} className={styles['wrapper']}>
					<div className="simple-page-description">
						<Label content={this.t('page_short_description')} supportHtml={true} />
					</div>
					
					<SimpleStaticSearch
						className="main-search"
						defaultCollapse={true}
						layout={SIMPLE_STATIC_SEARCH_LAYOUT.STACKED}
						buttonProps={{
							displayStyle: BUTTON_STYLE.DEFAULT
						}}
						options={[
							new SimpleStaticSearchOptionObject(
								'fileName', 
								this.t('Name')
							),
							new SimpleStaticSearchOptionObject(
								'dataSourceId',
								this.t('Data source'),
								SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
								{
									isClearable: true,
									options: this.getValue('dataSources').map(i => ({label: i.name, value: i.id})),
								}
							),
							new SimpleStaticSearchOptionObject(
								'username',
								this.t('Creator'),
								SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT_ASYNC,
								{
									isClearable: true,
									cacheOptions: false,
									loadOptions: this.getUsernames,
								}
							),
							new SimpleStaticSearchOptionObject(
								'dateFrom',
								this.t('Date from'),
								SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
								{
									valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
									roundTo: DATETIME_ROUND_TO.START
								}
							),
							new SimpleStaticSearchOptionObject(
								'dateTo',
								this.t('Date to'),
								SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
								{
									valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
									roundTo: DATETIME_ROUND_TO.END
								}
							),
						]}
						value={reportTemplatesFilter}
						title={(<Label icon="search" content={this.t('Search', 'general')} />)}
						applied={!this.isReportTemplatesFilterEmpty()}
						enableToolbar={true}
						enableResetButton={false}
						onChange={this.filterReportTemplates}
						onRemove={this.removeReportTemplatesFilter}
						onToggle={visible => this.setState({limitToAvailableSpace: visible})}
						ref={node => { this.reportTemplatesFilterRef = node; }}
					/>

					<DataTable
						id="main-page-table"
						limitToAvailableSpace={limitToAvailableSpace && !this.getProp('isMobileBreakpoint')}
						highlightOnHover={true}
						columns={[
							{
								name: 'creationDate',
								sortName: 'creationDate',
								label: this.t('Creation date'),
								dataType: DATA_TABLE_CELL_TYPE.DATE,
								dataTypeOptions: {
									inputFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
									outputFormat: [
										LOCALE_DATE_FORMAT_NAME.SHORT,
										getLocaleDatetimeSeparator(appLocale),
										LOCALE_TIME_FORMAT_NAME.STANDARD
									]
								},
								width: 200,
							},
							{
								name: 'creator',
								label: this.t('Creator'),
								dataType: DATA_TABLE_CELL_TYPE.ANY,
								dataTypeOptions: {
									content: report =>
										<div className="no-wrap">
											{getString(report, 'creator')}
										</div>
								},
								width: 1,
							},
							{
								name: 'fileName',
								label: this.t('Name'),
							},
							{
								dataType: DATA_TABLE_CELL_TYPE.ANY,
								dataTypeOptions: {
									content: this.renderActions,
									standardWrapper: false
								},
								width: 1,
								stopPropagation: true,
							},
						]}
						data={reportTemplates}
						onRowClick={reportTemplate => this.editReportTemplate(reportTemplate)}
						paginationType={PAGINATION_TYPE.STATIC}
						onSortByColumn={this.sortReportTemplates}
						onPaginationClick={this.loadReportTemplatesPage}
						{...reportTemplatesPagination}
						{...reportTemplatesSort}
					/>
				</div>
			), undefined, undefined, {
				mainSidebarShrank,
				toggleMainSidebarSizeAction,
			})
		);
	}
}

export * from "./config";
export default connect(mapStateToProps, getPageActions(actions))(ReportTemplatesPage);