import styles from "./index.module.css";
import "./index.css";

import React from "react";
import BaseComponent from "../../../../core/components/BaseComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Dropdown, {DROPDOWN_POSITION} from "../../../../core/components/display/Dropdown";
import {Tooltip} from "react-tippy";
import {selectors} from "../../../../core/store/reducers";
import {getPageActions} from "../../../../core/helpers/redux";
import * as builderActions from "../../actions";
import Label from "../../../../core/components/display/Label";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../../../core/components/display/Button";
import TextInput from "../../../../core/components/input/TextInput";
import {getArray, getString} from "../../../../core/helpers/data";
import {icon_font_close_symbol, icon_font_save_symbol, icon_font_symbol_class_prefix} from "../../../../config";
import * as pageConfig from "../../config";
import Icon from "../../../../core/components/display/Icon";
import {routerPath as reportsPageRouterPath} from "../../../reports/config";
import {routerPath as reportTemplatesPageRouterPath} from "../../../reportTemplates/config";
import {isSuccessful} from "../../../../core/helpers/io";
import ACL from "../../../../acl";
import ConfirmDialog from "../../../../core/components/dialogs/ConfirmDialog";
import {REPORT_FILE_TYPE_FOR_OUTPUT, REPORT_FILE_TYPE_ICON_PROPS} from "../../../../const/report";
import * as reportDataMap from "../../dataMap/report";
import {isChartReport} from "../../../../helpers/report";
import DataValueValidation from "../../../../core/validation";
import {get, omit} from "lodash";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @param {object} ownProps - Components own props.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = (state, ownProps) => ({
	report: selectors.builder.getReport(state),
});

class BuilderPageTitle extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: pageConfig.translationPath,
			domPrefix: 'builder-page-title',
		});

		// Validation and error methods
		this.validate = this.validate.bind(this);

		// Action methods
		this.generateReport = this.generateReport.bind(this);
		this.createReportTemplate = this.createReportTemplate.bind(this);
		this.updateReportTemplate = this.updateReportTemplate.bind(this);
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }

	
	// Validation and error methods -------------------------------------------------------------------------------------
	/**
	 * Validate report builder data
	 * @return {boolean}
	 */
	validate() {
		const {
			selectRef, filterRef, report, setChartOptionsValidationErrorsAction, clearChartOptionsValidationErrorsAction,
			setChartSeriesValidationErrorsAction, clearChartSeriesValidationErrorsAction
		} = this.props;
		let selectValid = true;
		let filterValid = true;
		let chartValid = true;
		
		// Reset chart validation
		// @note This is done because chart validation is stored in Redux
		clearChartOptionsValidationErrorsAction();
		clearChartSeriesValidationErrorsAction();
		
		// Validate select section
		if (selectRef && !selectRef.validate()) selectValid = false;
		// Validate filter section
		if (filterRef && !filterRef.validate()) filterValid = false;

		// Validate chart
		if (isChartReport(report)) {
			let chartOptionsValid = true;
			let chartSeriesValid = true;
			
			// Validate chart options
			const chartOptionsDataValidation = new DataValueValidation();
			const chartOptionsDataToValidate = omit(get(report, 'chart'), ['seriesList']);
			chartOptionsDataValidation.addRule('imageWidth', 'required', 'integer');
			chartOptionsDataValidation.addRule('imageHeight', 'required', 'integer');
			const chartOptionsValidationErrors = chartOptionsDataValidation.run(chartOptionsDataToValidate);
			if (chartOptionsValidationErrors) chartOptionsValid = false;
			setChartOptionsValidationErrorsAction(chartOptionsValidationErrors);
			
			// Validate chart series
			const chartSeriesDataValidation = new DataValueValidation();
			const chartSeriesDataToValidate = getArray(report, 'chart.seriesList');
			chartSeriesDataValidation.addRule('axisColumnX', 'required');
			chartSeriesDataValidation.addRule('axisColumnY', 'required');
			const chartSeriesValidationErrors = chartSeriesDataValidation.runOnArray(
				chartSeriesDataToValidate, 'GUIID'
			);
			if (chartSeriesValidationErrors) chartSeriesValid = false;
			setChartSeriesValidationErrorsAction(chartSeriesValidationErrors);
			chartValid = (chartOptionsValid && chartSeriesValid);
		}
		
		return (selectValid && filterValid && chartValid);
	}
	
	
	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Start report building process based on report builder data
	 * @param {ReportFileType} fileType - File type of the report to generate.
	 * @return {Promise<*>}
	 */
	generateReport(fileType) {
		const {report, generateReportAction, clearReportBuilderAction, history} = this.props;
		if (this.validate()) {
			const reportToGenerate = reportDataMap.output({...report, fileType});
			return this.executeAbortableAction(generateReportAction, reportToGenerate)
				.then(response => {
					if (isSuccessful(response)) {
						history.push(reportsPageRouterPath);
						clearReportBuilderAction();
					}
				});
		} else {
			return Promise.resolve();
		}
	}

	/**
	 * Create report template for scheduled reports
	 * @param {ReportFileType} fileType - File type of the report to be created on schedule using this template. 
	 * @return {Promise<*>}
	 */
	createReportTemplate(fileType) {
		const {report, createReportTemplateAction, clearReportBuilderAction, history} = this.props;
		if (this.validate()) {
			const reportTemplateToSave = reportDataMap.output({...report, fileType});
			return this.executeAbortableAction(createReportTemplateAction, reportTemplateToSave)
				.then(response => {
					if (isSuccessful(response)) {
						history.push(reportTemplatesPageRouterPath);
						clearReportBuilderAction();
					}
				});
		} else {
			return Promise.resolve();
		}
	}

	/**
	 * Update report template for scheduled reports
	 * @param {ReportFileType} fileType - File type of the report to be created on schedule using this template.
	 * @return {Promise<*>}
	 */
	updateReportTemplate(fileType) {
		const {report, reportTemplateId, updateReportTemplateAction, addSuccessMessageAction} = this.props;
		if (this.validate()) {
			const reportTemplateToUpdate = reportDataMap.output({...report, fileType});
			return this.executeAbortableAction(updateReportTemplateAction, reportTemplateId, reportTemplateToUpdate)
				.then(response => {
					if (isSuccessful(response)) {
						addSuccessMessageAction(this.t('update_report_template_success_msg'), 5);
					}
				});
		} else {
			return Promise.resolve();
		}
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			reportTemplateId, history, clearReportBuilderAction, openDialogAction, closeDialogAction, setReportValueAction
		} = this.props;
		/** @type {ReportDataObject} */
		const report = this.getProp('report');
		const canGenerateReport = (report.title && report.selectColumns.length);
		const fileTypes = getArray(REPORT_FILE_TYPE_FOR_OUTPUT, report.outputType);

		return (
			<h1 id={this.getDomId()} className={`page-title ${styles['title']}`}>
				<div className={styles['content']}>
					<TextInput
						className={styles['titleInput']}
						value={report.title}
						onChange={e => setReportValueAction(getString(e, 'target.value'), 'title')}
						onEnterKey={e => e.target.blur()}
						placeholder={!!reportTemplateId ? this.t('Unnamed template') : this.t('Unnamed report')}
					/>
					<div className={`${styles['actions']}`}>
						{
							ACL.checkPermission(['REPORT_TEMPLATE_CREATE']) ?
								<Dropdown
									className={`${styles['templateBtnWrapper']} ${styles['templateBtnDropdown']}`}
									labelClassName={styles['templateBtnWrapper']}
									label={(
										!report.title ?
											<Tooltip
												tag="div"
												className={styles['templateBtnWrapper']}
												title={
													!!reportTemplateId ? 
														this.t('template_title_not_set') : 
														this.t('title_not_set')
												}
												size="small"
												position="left-center"
												arrow={true}
												interactive={false}
											>
												<Button
													className={styles['templateBtn']}
													big={true}
													displayStyle={!!reportTemplateId ? BUTTON_STYLE.ACTION : BUTTON_STYLE.SUBTLE}
													displayType={
														!!reportTemplateId ? 
															BUTTON_DISPLAY_TYPE.SOLID : 
															BUTTON_DISPLAY_TYPE.TRANSPARENT
													}
													icon={icon_font_save_symbol}
													iconProps={{className: styles['templateBtnIcon']}}
													label={!!reportTemplateId ? ' ' + this.t('Save', 'general') : ''}
													disabled={!canGenerateReport}
												>
													<Icon symbol="angle-down" className={styles['templateBtnArrow']} />
												</Button>
											</Tooltip>
											:
											<Tooltip
												tag="div"
												className={styles['templateBtnWrapper']}
												title={
													!!reportTemplateId ? 
														this.t('Save template') : 
														this.t('Save as report template')
												}
												size="small"
												position="left-center"
												arrow={true}
												interactive={false}
											>
												<Button
													className={styles['templateBtn']}
													big={true}
													displayStyle={!!reportTemplateId ? BUTTON_STYLE.ACTION : BUTTON_STYLE.SUBTLE}
													displayType={
														!!reportTemplateId ? 
															BUTTON_DISPLAY_TYPE.SOLID : 
															BUTTON_DISPLAY_TYPE.TRANSPARENT
													}
													icon={icon_font_save_symbol}
													iconProps={{className: styles['templateBtnIcon']}}
													label={!!reportTemplateId ? ' ' + this.t('Save', 'general') : ''}
													disabled={!canGenerateReport}
												>
													<Icon symbol="angle-down" className={styles['templateBtnArrow']} />
												</Button>
											</Tooltip>
									)}
									position={DROPDOWN_POSITION.RIGHT}
									disabled={!canGenerateReport}
								>
									{fileTypes.map(ft =>
										<div 
											key={ft} 
											className={
												`dropdown-item ${styles['templateBtnItem']} ` +
												(report.fileType === ft ? `${styles['active']} ` : ' ')
											}
											onClick={() => {
												if (!!reportTemplateId) return this.updateReportTemplate(ft);
												return this.createReportTemplate(ft);
											}}
										>
											<Label
												icon={getString(REPORT_FILE_TYPE_ICON_PROPS[ft], 'symbol')}
												iconSymbolPrefix={getString(
													REPORT_FILE_TYPE_ICON_PROPS, 
													[ft, 'symbolPrefix'],
													icon_font_symbol_class_prefix
												)}
												content={`${ft} ${report.fileType === ft ? '*' : ''}`}
											/>
										</div>
									)}
								</Dropdown>
								: null
						}

						{
							!reportTemplateId ?
								<Dropdown
									className={styles['buildBtnWrapper']}
									labelClassName={styles['buildBtnWrapper']}
									label={(
										!report.title ?
											<Tooltip
												tag="div"
												className={styles['buildBtnWrapper']}
												title={this.t('title_not_set')}
												size="small"
												position="left-center"
												arrow={true}
												interactive={false}
											>
												<Button
													className={styles['buildBtn']}
													big={true}
													displayStyle={BUTTON_STYLE.ACTION}
													icon="play-circle"
													label={this.t('Build report')}
													disabled={!canGenerateReport}
												>
													<Icon symbol="angle-down" className={styles['buildBtnArrow']} />
												</Button>
											</Tooltip>
											:
											<Button
												className={styles['buildBtn']}
												big={true}
												displayStyle={BUTTON_STYLE.ACTION}
												icon="play-circle"
												label={this.t('Build report')}
												disabled={!canGenerateReport}
											>
												<Icon symbol="angle-down" className={styles['buildBtnArrow']} />
											</Button>
									)}
									position={DROPDOWN_POSITION.RIGHT}
									disabled={!canGenerateReport}
								>
									{fileTypes.map(ft =>
										<div 
											key={ft}
											className={`dropdown-item ${styles['buildBtnItem']}`}
											onClick={() => this.generateReport(ft)}
										>
											<Label
												icon={REPORT_FILE_TYPE_ICON_PROPS[ft].symbol}
												iconSymbolPrefix={getString(
													REPORT_FILE_TYPE_ICON_PROPS,
													[ft, 'symbolPrefix'],
													icon_font_symbol_class_prefix
												)} 
												content={ft} 
											/>
										</div>
									)}
								</Dropdown>
								: null
						}

						{
							!!reportTemplateId ?
								<Button
									className={styles['closeTemplateBtnDropdown']}
									big={true}
									displayStyle={BUTTON_STYLE.SUBTLE}
									displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
									icon={icon_font_close_symbol}
									onClick={() => history.push(reportTemplatesPageRouterPath)}
								/>
								:
								<Tooltip
									tag="div"
									className={styles['templateBtnWrapper']}
									title={this.t('clear_report_builder')}
									size="small"
									position="left-center"
									arrow={true}
									interactive={false}
								>
									<Button
										className={styles['clearBtn']}
										big={true}
										displayStyle={BUTTON_STYLE.SUBTLE}
										displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
										icon={icon_font_close_symbol}
										onClick={() => {
											openDialogAction('', ConfirmDialog, {
												message: this.t('confirm_clear_report_builder'),
												onYes: dialogGUIID => {
													clearReportBuilderAction();
													closeDialogAction(dialogGUIID);
												}
											}, {
												closeOnEscape: true,
												closeOnClickOutside: true,
												hideCloseBtn: true,
												maxWidth: 550,
											});
										}}
									/>
								</Tooltip>
						}
					</div>
				</div>
			</h1>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
BuilderPageTitle.propTypes = {
	// Report template ID
	// @note If this prop is set report builder is used to edit a report template.
	reportTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	// Router history object
	history: PropTypes.object,
	// Report builder select section component ref
	selectRef: PropTypes.object,
	// Report builder filter section component ref
	filterRef: PropTypes.object,
};

export default connect(mapStateToProps, getPageActions(builderActions))(BuilderPageTitle);