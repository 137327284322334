import "./default.style.css";

import React from "react";
import DataComponent from "../../DataComponent";
import DataValueValidation from "../../../validation";
import Label from "../../display/Label";
import FormWrapper, {FormField} from "../FormWrapper";
import TextInput, {PasswordStrengthPreview, TEXT_INPUT_LABEL_POSITION} from "../../input/TextInput";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../display/Button";
import {connect} from "react-redux";
import {getGlobalActions} from "../../../helpers/redux";
import PropTypes from "prop-types";
import {FORM_FIELD_LABEL_POSITION} from "../FormWrapper/FormField";
import {omit} from "lodash";
import {icon_font_save_symbol} from "../../../../config";
import Icon from "../../display/Icon";

class PasswordReset extends DataComponent {
	constructor(props) {
		super(props, {
			data: {
				newPassword: '',
				confirmPassword: ''
			},
		}, {
			translationPath: 'PasswordReset',
			disableLoad: true
		});

		// Action methods
		this.resetPassword = this.resetPassword.bind(this);
	}

	// DataValueValidation methods --------------------------------------------------------------------------------------
	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const dataValidation = new DataValueValidation();
		const dataToValidate = this.getData();

		dataValidation.addRule('newPassword', 'required', 'password');
		dataValidation.addRule('confirmPassword', 'required', {
			name: 'sameAs', options: { field: 'newPassword', label: this.t('password_label') }
		});

		const validationErrors = dataValidation.run(dataToValidate);
		if (validationErrors) this.setValidationErrors('', validationErrors);
		return !validationErrors;
	}

	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Reset user's password
	 */
	resetPassword() {
		const {action} = this.props;
		const password = this.getValue('newPassword');
		const confirmPassword = this.getValue('confirmPassword');

		this.clearValidationErrors()
			.then(() => this.clearErrorMessage())
			.then(() => {
				if (this.validate()) return action(password, confirmPassword);
			});
	}

	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			styleName, className, cardClassName, cardHeaderClassName, cardContentClassName, cardActionsClassName,
			labelPosition, labelIcon, labelElement, labelElementProps, buttonProps, logo,
		} = this.props;
		const error = this.getErrorMessage();
		
		return (
			<div id={this.getDomId()} className={`password-reset-component ${styleName}-style ${className}`}>
				<div className={cardClassName}>
					<div className={cardHeaderClassName}>
						{
							logo ?
								<div className="card-header-icon">
									<img src={logo} alt={this.t('title', 'App')}/>
								</div>
								:
								<Icon symbol={labelIcon} className="card-header-icon" />
						}
						<Label
							element="div"
							elementProps={{className: 'app-name'}}
							content={this.t('title', 'App')}
						/>
						<Label
							element={labelElement}
							elementProps={labelElementProps}
							content={this.t('form_title')}
							supportHtml={true}
						/>
						<Label element="div" elementProps={{className: 'desc'}} content={this.t('form_description')} />
					</div>
					<div className={cardContentClassName}>
						<FormWrapper>
							<FormField
								label={this.t('password_label')}
								labelPosition={labelPosition}
								errorMessages={this.getValidationErrors('newPassword')}
								required={true}
							>
								<TextInput
									type="password"
									name="newPassword"
									value={this.getValue('newPassword')}
									showLabel={true}
									labelClass="no-padding"
									labelWidth={50}
									labelPosition={TEXT_INPUT_LABEL_POSITION.RIGHT}
									previewAsLabel={true}
									getPreview={PasswordStrengthPreview}
									placeholder={this.t('password_placeholder')}
									onChange={this.handleInputChange}
									onEnterKey={this.resetPassword}
									autoComplete="new-password"
								/>
							</FormField>

							<FormField
								label={this.t('confirm_password_label')}
								labelPosition={labelPosition}
								errorMessages={this.getValidationErrors('confirmPassword')}
								required={true}
							>
								<TextInput
									type="password"
									name="confirmPassword"
									value={this.getValue('confirmPassword')}
									placeholder={this.t('confirm_password_placeholder')}
									onChange={this.handleInputChange}
									onEnterKey={this.resetPassword}
									autoComplete="new-password"
								/>
							</FormField>
						</FormWrapper>
					</div>
					<div className={cardActionsClassName}>
						<Button
							label={this.t('action_btn')}
							onClick={this.resetPassword}
							{...omit(buttonProps, ['onClick'])}
						/>
					</div>
					{error ? <p className={`component-error error-color`}>{error}</p> : null}
				</div>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PasswordReset.propTypes = {
	// Component style name
	// @description Component style name is a name of the style that will be used to determine the CSS used to style the
	// component.
	styleName: PropTypes.string,
	// Login component wrapper element ID class attribute
	id: PropTypes.string,
	// Login component wrapper element class attribute
	className: PropTypes.string,
	// Login component internal element card class name
	cardClassName: PropTypes.string,
	// Login component internal element card header class name
	cardHeaderClassName: PropTypes.string,
	// Login component internal element card content class name
	cardContentClassName: PropTypes.string,
	// Login component internal element card actions class name
	cardActionsClassName: PropTypes.string,
	// Login component input fields label position
	// @see FORM_FIELD_LABEL_POSITION const.
	labelPosition: PropTypes.string,
	// Login component input fields label icon
	labelIcon: PropTypes.string,
	// Login component input fields label wrapper element name
	// @note If empty, no wrapper element will be rendered.
	labelElement: PropTypes.string,
	// Login component input fields label wrapper element props
	labelElementProps: PropTypes.object,
	// Main button component props
	buttonProps: PropTypes.object,
	// Login form submit function
	action: PropTypes.func, // Arguments: username, password
	// Logo to render
	logo: PropTypes.any,
};

/**
 * Define component default values for own props
 */
PasswordReset.defaultProps = {
	styleName: 'default',
	className: '',
	cardClassName: 'card icon-card',
	cardHeaderClassName: 'card-header',
	cardContentClassName: 'card-content',
	cardActionsClassName: 'card-actions',
	labelPosition: FORM_FIELD_LABEL_POSITION.STACKED,
	labelIcon: 'key',
	labelElement: '',
	buttonProps: {
		icon: icon_font_save_symbol,
		displayStyle: BUTTON_STYLE.ACTION,
		displayType: BUTTON_DISPLAY_TYPE.SOLID,
		big: true
	},
	logo: null,
};

export default connect(null, getGlobalActions())(PasswordReset);