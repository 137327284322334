import {get} from 'lodash';
import {getBoolean, getInteger, getString} from "../../core/helpers/data";
import {SORT_ORDER} from "../../core/const/global";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'accountPermissions';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@accountPermissions/reset',
	SET_ACCOUNT_PERMISSION_LIST_DATA: '@accountPermissions/set_account_permission_list_data',
	CLEAR_ACCOUNT_PERMISSION_LIST_DATA: '@accountPermissions/clear_account_permission_list_data',
	SET_ACCOUNT_PERMISSION: '@accountPermissions/set_account_permission',
	CLEAR_ACCOUNT_PERMISSION: '@accountPermissions/clear_account_permission',
};

// define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	/**
	 * @param {IoJsonFetchResponseObject} accountPermissionListData
	 * @return {{type: string, accountPermissionListData: IoJsonFetchResponseObject}}
	 */
	setAccountPermissionListData: accountPermissionListData => ({
		type: REDUCER_TYPES.SET_ACCOUNT_PERMISSION_LIST_DATA, 
		accountPermissionListData
	}),
	clearAccountPermissionListData: () => ({type: REDUCER_TYPES.CLEAR_ACCOUNT_PERMISSION_LIST_DATA}),
	/**
	 * @param {AccountPermissionDataObject} accountPermission
	 * @return {{type: string, accountPermission: AccountPermissionDataObject}}
	 */
	setAccountPermission: accountPermission => ({type: REDUCER_TYPES.SET_ACCOUNT_PERMISSION, accountPermission }),
	clearAccountPermission: () => ({type: REDUCER_TYPES.CLEAR_ACCOUNT_PERMISSION }),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/** @type {IoJsonFetchResponseObject} */
	accountPermissionListData: null,

	/**
	 * @note Value is null to differentiate between unloaded and new items (new items should be a data object with
	 * default values).
	 * @type {AccountPermissionDataObject} 
	 */
	accountPermission: null,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET:
			return { ...initialState };

		case REDUCER_TYPES.SET_ACCOUNT_PERMISSION_LIST_DATA: 
			return {...state, accountPermissionListData: action.accountPermissionListData};
		case REDUCER_TYPES.CLEAR_ACCOUNT_PERMISSION_LIST_DATA:
			return {...state, accountPermissionListData: initialState.accountPermissionListData};

		case REDUCER_TYPES.SET_ACCOUNT_PERMISSION: return {...state, accountPermission: action.accountPermission};
		case REDUCER_TYPES.CLEAR_ACCOUNT_PERMISSION: return {...state, accountPermission: initialState.accountPermission};

		default:
			return state;
	}
};

// Selectors
export const selectors = {
	getAccountPermissionList: state => 
		get(state, [reducerStoreKey, 'accountPermissionListData', 'data']),
	getAccountPermissionListPagination: state => ({
		perPage: getInteger(state, [reducerStoreKey, 'accountPermissionListData', 'perPage']),
		pageNo: getInteger(state, [reducerStoreKey, 'accountPermissionListData', 'pageNo']),
		isLastPage: getBoolean(state, [reducerStoreKey, 'accountPermissionListData', 'isLastPage']) 
	}),
	getAccountPermissionListSort: state => ({
		sortBy: getString(state, [reducerStoreKey, 'accountPermissionListData', 'sortBy']),
		sortDir: getString(state, [reducerStoreKey, 'accountPermissionListData', 'sortDir'], SORT_ORDER.ASC)
	}),
	getAccountPermissionListFilter: state => 
		get(state, [reducerStoreKey, 'accountPermissionListData', 'filter'], null),

	getAccountPermission: state => get(state, [reducerStoreKey, 'accountPermission']),
};

export default reducer;