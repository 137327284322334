import React from "react";

/**
 * Cookie policy for sr-Cyrl-RS locale
 * @note This will automatically be used in CookiePolicy component (index.js) if sr-Cyrl-RS is the current locale.
 * There is no need to use this component directly.
 *
 * @param {any} props - Any prop that will be added to the DIV element.
 * @return {JSX.Element}
 * @constructor
 */
function CookiePolicySrCyrlRS(props) {
	return (
		<div {...props}>
			<h2>О колачићима</h2>
			<p>...</p>
		</div>
	);
}

export default CookiePolicySrCyrlRS;