/**
 * Main access-control list class
 */
import {getStorageValue, setStorageValue} from "../storage";
import {acl_check_mode, acl_storage_type, acl_storage_var, acl_super_permission} from "../../config";
import {arrayIncludes, arrayIncludesAny, getArray} from "../helpers/data";

class CoreACL {
	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Get permissions from storage
	 * @note Storage field and type is defined in 'acl_storage_var' and 'acl_storage_type' config options respectively.
	 * @return {string[]} Returns the array of permission or an empty array if permissions are not defined or
	 * they cannot be parsed.
	 */
	static getPermissions() {
		try {
			return getArray(JSON.parse(getStorageValue(acl_storage_var, acl_storage_type)));
		} catch (e) {
			return [];
		}
	}

	/**
	 * Check if supper permission is available in storage
	 * @return {boolean}
	 */
	static hasSuper() {
		return CoreACL.getPermissions().includes(acl_super_permission);
	}

	/**
	 * Save permissions to storage
	 * @note Storage field and type is defined in 'acl_storage_var' and 'acl_storage_type' config options respectively.
	 * @param {string[]} permissions - Permissions to save into storage.
	 * @throws {TypeError} ("cyclic object value") exception when a circular reference is found.
	 * @throws {TypeError} ("BigInt value can't be serialized in JSON") when trying to stringify a BigInt value.
	 */
	static savePermissions(permissions) {
		setStorageValue(acl_storage_var, JSON.stringify(permissions), acl_storage_type);
	}

	/**
	 * Check if access is allowed based on the specified permissions
	 * @note This method checks permissions using a method defined in 'acl_check_mode' acl settings option.
	 * 
	 * @param {string[]} [permissions=[]] - List for permission codes to check access for.
	 * @return {boolean}
	 */
	static checkPermission(permissions) {
		// Allow access if required permission are empty or not defined
		if (!permissions || permissions.length === 0) return true;
		
		// Allow access for if super permission is available
		if (CoreACL.hasSuper()) return true;
		
		try {
			const availablePermissions = CoreACL.getPermissions();
			if (availablePermissions.length) {
				switch (acl_check_mode) {
					case 'AND': return arrayIncludes(availablePermissions, permissions);
					case 'OR': return arrayIncludesAny(availablePermissions, permissions);
					default: {
						console.error(
							"ACL check mode config option is invalid or undefined! Please check the value of " +
							"'acl_check_mode' in acl config file."
						);
						return false;
					}
				}
			} else {
				console.error("ACL check failed! Available permissions are empty or undefined.")
				return false;
			}
		} catch (e) {
			console.error(
				"ACL check failed! Could not parse available permissions. Check if permissions are present in storage " +
				"define by 'acl_storage_var' and 'acl_storage_type' config options.", e
			);
			return false;
		}
	}
}

export default CoreACL;