/**
 * @typedef {string} DynamicValueDisplayType
 */
export const DYNAMIC_VALUE_DISPLAY_TYPE_TEXT = 'TEXT';
export const DYNAMIC_VALUE_DISPLAY_TYPE_INTEGER = 'INTEGER';
export const DYNAMIC_VALUE_DISPLAY_TYPE_FLOAT = 'FLOAT';
export const DYNAMIC_VALUE_DISPLAY_TYPE_BOOLEAN = 'BOOLEAN';
export const DYNAMIC_VALUE_DISPLAY_TYPE_DATE = 'DATE';
export const DYNAMIC_VALUE_DISPLAY_TYPE_SELECT = 'SELECT';
export const DYNAMIC_VALUE_DISPLAY_TYPE_ASYNC_SELECT = 'ASYNC_SELECT';
/**
 * @enum {DynamicValueDisplayType}
 */
export const DYNAMIC_VALUE_DISPLAY_TYPE = {
	TEXT: DYNAMIC_VALUE_DISPLAY_TYPE_TEXT,
	INTEGER: DYNAMIC_VALUE_DISPLAY_TYPE_INTEGER,
	FLOAT: DYNAMIC_VALUE_DISPLAY_TYPE_FLOAT,
	BOOLEAN: DYNAMIC_VALUE_DISPLAY_TYPE_BOOLEAN,
	DATE: DYNAMIC_VALUE_DISPLAY_TYPE_DATE,
	SELECT: DYNAMIC_VALUE_DISPLAY_TYPE_SELECT,
	ASYNC_SELECT: DYNAMIC_VALUE_DISPLAY_TYPE_ASYNC_SELECT,
};
/**
 * @type {DynamicValueDisplayType[]}
 */
export const DYNAMIC_VALUE_DISPLAY_TYPES = [
	DYNAMIC_VALUE_DISPLAY_TYPE_TEXT,
	DYNAMIC_VALUE_DISPLAY_TYPE_INTEGER,
	DYNAMIC_VALUE_DISPLAY_TYPE_FLOAT,
	DYNAMIC_VALUE_DISPLAY_TYPE_BOOLEAN,
	DYNAMIC_VALUE_DISPLAY_TYPE_DATE,
	DYNAMIC_VALUE_DISPLAY_TYPE_SELECT,
	DYNAMIC_VALUE_DISPLAY_TYPE_ASYNC_SELECT,
];


/** @typedef {string} DynamicValueAggregation */
export const DYNAMIC_VALUE_AGGREGATION_SUM = 'SUM';
export const DYNAMIC_VALUE_AGGREGATION_COUNT = 'COUNT';
export const DYNAMIC_VALUE_AGGREGATION_COUNT_DISTINCT = 'COUNT_DISTINCT';
export const DYNAMIC_VALUE_AGGREGATION_AVERAGE = 'AVERAGE';
export const DYNAMIC_VALUE_AGGREGATION_MIN = 'MIN';
export const DYNAMIC_VALUE_AGGREGATION_MAX = 'MAX';
/** @enum {DynamicValueAggregation} */
export const DYNAMIC_VALUE_AGGREGATION = {
	SUM: DYNAMIC_VALUE_AGGREGATION_SUM,
	COUNT: DYNAMIC_VALUE_AGGREGATION_COUNT,
	COUNT_DISTINCT: DYNAMIC_VALUE_AGGREGATION_COUNT_DISTINCT,
	AVERAGE: DYNAMIC_VALUE_AGGREGATION_AVERAGE,
	MIN: DYNAMIC_VALUE_AGGREGATION_MIN,
	MAX: DYNAMIC_VALUE_AGGREGATION_MAX
}
/** @type {DynamicValueAggregation[]} */
export const DYNAMIC_VALUE_AGGREGATIONS = [
	DYNAMIC_VALUE_AGGREGATION_SUM,
	DYNAMIC_VALUE_AGGREGATION_COUNT,
	DYNAMIC_VALUE_AGGREGATION_COUNT_DISTINCT,
	DYNAMIC_VALUE_AGGREGATION_AVERAGE,
	DYNAMIC_VALUE_AGGREGATION_MIN,
	DYNAMIC_VALUE_AGGREGATION_MAX
];