/**
 * WARNING: Be careful when importing this helper because it uses Redux store and some other imports that can cause
 * circular dependencies!
 */

import {closeAllDialogsAction, closeDialogAction, openDialogAction} from "../components/global/Dialog";
import reduxStore from "../store";

/**
 * Open dialog
 *
 * @param {string} [GUIID=''] - Dialog's unique GUI ID. If not specified, unique GUI ID will be generated and used.
 * @param {React.Component} component - Component to render inside the opened dialog.
 * @param {object} [props={}] - Props to send to the component rendered inside the opened dialog.
 * @param {{
 * 	[id]: string, 
 * 	[className]: string, 
 * 	[closeOnEscape]: boolean, 
 * 	[closeOnClickOutside]: boolean, 
 * 	[hideCloseBtn]: boolean,
 * 	[maxWidth]: string|number
 *	}} [options={}] - Opened dialog options.
 *	@return Dialog's unique GUI ID.
 */
export const openDialog = (GUIID = '', component, props = {}, options = {}) => openDialogAction(
	GUIID, component, props, options
)(reduxStore.dispatch);

/**
 * Close opened dialog
 *
 * @param {string} GUIID - Dialog unique GUIID.
 */
export const closeDialog = GUIID => closeDialogAction(GUIID)(reduxStore.dispatch);

/**
 * Close all opened dialogs
 */
export const closeAllDialogs = ()  => closeAllDialogsAction()(reduxStore.dispatch);

/**
 * Function returning a promise that will resolve when a specific dialog has been closed
 * @param {string} dialogGUIID - GUI ID of the dialog that was closed.
 * @return {Promise<string>} Promise that will resolve with the closed dialog's GUI ID.
 */
export const afterDialogClose = dialogGUIID => {
	return new Promise(resolve => {
		const controller = new AbortController();
		window.addEventListener(
			`dialogClosed:${dialogGUIID}`, 
			() => { resolve(dialogGUIID); controller.abort(); }, 
			{signal: controller.signal}
		);
	});
};