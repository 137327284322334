import React from "react";

/**
 * Cookie policy for en-US locale
 * @note This will automatically be used in CookiePolicy component (index.js) if en-US is the current locale.
 * There is no need to use this component directly.
 *
 * @param {any} props - Any prop that will be added to the DIV element.
 * @return {JSX.Element}
 * @constructor
 */
function CookiePolicyEnUs(props) {
	return (
		<div {...props}>
			<h2>About cookies</h2>
			<p>...</p>
		</div>
	);
}

export default CookiePolicyEnUs;