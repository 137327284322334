import styles from "./index.module.css";
import "./index.css";

import React from "react";
import PageComponent from "../../core/components/PageComponent";
import {connect} from "react-redux";
import * as pageConfig from "./config";
import * as actions from "./actions";
import {selectors} from "../../core/store/reducers";
import {getPageActions} from "../../core/helpers/redux";
import BuilderSelectSection from "./SelectSection";
import BuilderFilterSection from "./FilterSection";
import {scrollToSelector} from "../../core/helpers/dom";
import {waitingFunction} from "../../core/helpers/function";
import {blinkBackgroundSlowAnimation} from "../../core/helpers/animation";
import BuilderPageTitle from "./components/PageTitle";
import {routerPath as reportTemplateRouterPath} from "../reportTemplates/config";
import Label from "../../core/components/display/Label";
import DataValueValidation from "../../core/validation";
import {get, omit, isEqual, cloneDeep} from "lodash";
import {app_id} from "../../config";
import Button, {BUTTON_STYLE} from "../../core/components/display/Button";
import {FormField} from "../../core/components/advanced/FormWrapper";
import {FORM_FIELD_LABEL_POSITION} from "../../core/components/advanced/FormWrapper/FormField";
import DataSourceSelectInput from "../../components/input/DataSourceSelectInput";
import CardsSelectInput from "../../core/components/input/CardsSelectInput";
import {CARDS_SELECT_INPUT_CONTENT_DISPLAY_TYPE} from "../../core/components/input/CardsSelectInput/const";
import {
	REPORT_OUTPUT_TYPE,
	REPORT_OUTPUT_TYPE_ICON_PROPS,
	REPORT_OUTPUT_TYPE_TO_REPORT_CHART_TYPE,
	REPORT_OUTPUT_TYPES
} from "../../const/report";
import {CardsSelectInputItemDataObject} from "../../core/components/input/CardsSelectInput/dataObjects";
import Icon from "../../core/components/display/Icon";
import {ReportChartDataObject, ReportChartSeriesDataObject, ReportDataObject} from "../../dataObjects/report";
import {CookieData} from "../../core/dataProtection/objects/cookie";
import {setStorageValue, STORAGE_TYPE} from "../../core/storage";
import CookieConsent from "../../core/dataProtection/cookieConsent";
import {getString} from "../../core/helpers/data";
import {isChartReport, validateReport} from "../../helpers/report";
import {getMenuSidebarShrankFromStorage} from "../../layout/elements/MainSidebar/helpers";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	report: selectors.builder.getReport(state),
	hasDataSource: !!selectors.builder.getReportValue(state, 'dataSource'),
	dynamicValues: selectors.dynamicValues.getDynamicValues(state),
});

class BuilderPage extends PageComponent {
	initialState = {
		/**
		 * Data source selected by the user
		 * @note This is only available when starting a new report builder.
		 * @type {ReportDataSourceObject}
		 */
		selectedDataSource: null,

		/**
		 * Report output type selected by the user
		 * @note This is only available when starting a new report builder.
		 * @type {ReportOutputType}
		 */
		selectedOutputType: getString(this.props, 'report.outputType', REPORT_OUTPUT_TYPE.DATA_TABLE),

		/**
		 * Validation errors
		 * @type {Object<string, string[]>}
		 */
		errors: {},
	};

	/**
	 * Report builder select section component ref
	 */
	selectRef = null;
	
	/**
	 * Report builder filter section component ref
	 */
	filterRef = null;
	
	constructor(props) {
		super(props, {
			layout: 'insertSidebar',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			domPrefix: 'builder-page'
		}, 'page_title');
		
		// Initialize local state
		this.state = cloneDeep(this.initialState);

		// Validation and error methods -------------------------------------------------------------------------------------
		this.getValidationErrors = this.getValidationErrors.bind(this);
		this.clearValidationErrors = this.clearValidationErrors.bind(this);

		// Action methods
		this.dynamicValueInsert = this.dynamicValueInsert.bind(this);
	}

	
	componentDidMount() {
		const {reloadReportFromStorageAction} = this.props;
		const isReportTemplate = !!this.getItemUrlId();
		
		return super.componentDidMount()
			// Try to load report builder data from cookie into Redux store if report builder is in create report mode 
			// (not editing a report template)
			.then(() => {
				if (!isReportTemplate) reloadReportFromStorageAction();
			});
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		return super.componentDidUpdate(prevProps, prevState, snapshot)
			.then(() => {
				const isReportTemplate = !!this.getItemUrlId();

				// Save the current report builder data from Redux store into the cookie if it changes and report builder is in 
				// create report mode (not editing a report template)
				if (!isReportTemplate) {
					const builderCookie = new CookieData('necessary', 'builder', STORAGE_TYPE.SESSION);
					if (CookieConsent.isAllowed(builderCookie)) {
						const {report} = this.props;
						if (!isEqual(prevProps.report, report)) {
							try {
								setStorageValue(`${app_id}-builder`, report, STORAGE_TYPE.SESSION, {}, true);
							} catch (e) {
								console.error('Could not store report builder data into the cookie!', e);
							}
						}
					}
				}
			});
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Router methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called if current URL matches the 'item' sub-url of the page
	 * @note Item sub-url uses '/item' router path and 'id' as router path param ('/item/:id') on top of to the router
	 * path of the page (see 'options.routerPath').
	 *
	 * @param {string|number} id - ID from URL.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'item' sub-url if such component exists.
	 */
	handleItemUrl(id) {
		const {
			fetchReportTemplateAction, setReportAction, addErrorMessageAction, removeMessageAction
		} = this.props;
		return this.executeAbortableAction(fetchReportTemplateAction, id)
			// Redirect to report templates page if template could not be loaded
			.then(response => {
				if (response) {
					const reportValidationCode = validateReport(response);
					if (reportValidationCode === 0) {
						this.setBrowserTitle(this.t('page_title_template'));
						setReportAction(response);
					} else {
						const errorMessageGUIID = addErrorMessageAction(
							this.t(
								'clone_error',
								'ReportsPage',
								'',
								{name: getString(response, 'title')}
							) + ' ' + this.t(`${reportValidationCode}`, 'constants.report_validation_errors'),
							undefined, undefined, undefined, undefined, undefined, true, {}, {
								onClose: () => {
									removeMessageAction(errorMessageGUIID);
									this.redirectTo(reportTemplateRouterPath);
								},
							}
						);
					}
				} else {
					this.redirectTo(reportTemplateRouterPath);
				}
				return '';
			});
	}

	/**
	 * Method that will be called when page URL changes
	 * @param {string|number} prevItemUrlId - Previous item URL ID if any.
	 * @param {string} prevSubUrlPath - Previous page sub-url path.
	 * @param {Object} prevLocation - Previous router location object.
	 */
	handleUrlChange(prevItemUrlId, prevSubUrlPath, prevLocation) {
		const {reloadReportFromStorageAction} = this.props;
		const itemUrlId = this.getItemUrlId();
		
		// If item ID was removed from URL
		if (prevItemUrlId && !itemUrlId) {
			reloadReportFromStorageAction();
			this.setBrowserTitle(this.t('page_title'));
		}
	}

	/**
	 * Method that will be called when page component unmounts and should handle closing of any page url or sub-url
	 * component if it exists.
	 */
	closeUrlComponent() {
		const itemUrlId = this.getItemUrlId();
		
		// Reset the browser title if leaving the builder while editing a report template
		if (itemUrlId) {
			this.setBrowserTitle(this.t('page_title'));
		}
	}
	
	componentWillUnmount() {
		super.componentWillUnmount();
		
		const {clearReportAction} = this.props;
		clearReportAction();
	}


	// Validation and error methods -------------------------------------------------------------------------------------
	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const dataValidation = new DataValueValidation();
		const dataToValidate = this.state;

		dataValidation.addRule('selectedDataSource', 'required');
		dataValidation.addRule('selectedOutputType', 'required');
		
		const validationErrors = dataValidation.run(dataToValidate);
		if (validationErrors) this.setState({errors: validationErrors}).then();
		else this.setState({errors: {}}).then();
		return !validationErrors;
	}
	
	/**
	 * Get validation errors array
	 * @note Use this if component data is an object.
	 *
	 * @param {string|string[]} path - Path of the field inside the data object to get the errors array for. If not
	 * specified, errors for all fields will be returned.
	 * @return {Object<string, string[]>|undefined} Validation errors array for a specified field or undefined if there
	 * are no errors for the field. If 'path' is not specified, errors for all fields will be returned.
	 */
	getValidationErrors(path = '') {
		if (path) return get(this.state, ['errors', ...(Array.isArray(path) ? path : [path])]);
		else return get(this.state, 'errors');
	}

	/**
	 * Clear validation errors for a specific component's field
	 *
	 * @param {string|string[]} path - Path of the field inside the data object to ser the errors array for. If not
	 * specified, errors for all fields will be cleared.
	 * @return {Promise<Object>}
	 */
	clearValidationErrors(path = '') {
		if (path) return this.setState(state => ({...state, errors: omit(state.errors, path)}));
		else return this.setState({errors: {}});
	}
	
	
	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Handle dynamic value insert
	 * @param {DynamicValueDataObject} dynamicValue - Dynamic value to insert.
	 * @param {'selectColumns'|'filter'} target - Insert target.
	 */
	dynamicValueInsert(dynamicValue, target) {
		const {addSelectColumnAction, addFilterItemAction} = this.props;
		
		if (target === 'selectColumns') {
			const GUIID = addSelectColumnAction(dynamicValue);
			waitingFunction(() => {
				const selector = `#report-builder-select-column-${GUIID}`;
				const element = document.querySelector(selector);
				if (element) {
					scrollToSelector(
						selector,
						true,
						'20',
						`.${styles['selectContent']}`
					);
					blinkBackgroundSlowAnimation(element);
					return true;
				}
			}, 10, 500).then();
		} else if (target === 'filter') {
			const GUIID = addFilterItemAction(dynamicValue);
			waitingFunction(() => {
				const selector = `#report-builder-filter-item-${GUIID}`;
				const element = document.querySelector(selector);
				if (element) {
					scrollToSelector(
						selector,
						true,
						'20',
						`.${styles['filterContent']}`
					);
					blinkBackgroundSlowAnimation(element);
					return true;
				}
			}, 10, 500).then();
		}
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render page title
	 * @description This method specifies how page title will be rendered if page title should be rendered. It does not
	 * determine if page title should be rendered.
	 * @return {JSX.Element}
	 */
	renderPageTitle() {
		const {hasDataSource} = this.props;
		const isReportTemplate = !!this.getItemUrlId();
		return (
			hasDataSource ?
				<>
					<Label
						element="div"
						elementProps={{className: 'page-above-title'}}
						content={isReportTemplate ? this.t('Report template editor') : this.t('Report builder')}
					/>
					<BuilderPageTitle
						reportTemplateId={this.getItemUrlId()}
						history={this.props.history}
						selectRef={this.selectRef}
						filterRef={this.filterRef}
					/>
				</>
				: !isReportTemplate ?
					<>
						<Label element="h1" elementProps={{className: 'page-title'}} content={this.t('page_title')} />
						<div className="simple-page-description">
							<Label content={this.t('start_title')} supportHtml={true} />
						</div>
					</>
				: null
		);
	}

	render() {
		const {hasDataSource, dynamicValues, setReportAction, mainSidebarShrank, toggleMainSidebarSizeAction} = this.props;
		const {selectedDataSource, selectedOutputType} = this.state;
		const isReportTemplate = !!this.getItemUrlId();
		const domPrefix = this.getOption('domPrefix');
		
		return (
			this.renderLayout((
				<div id={this.getDomId()} className={`${domPrefix} ${styles['wrapper']}`}>
					{
						hasDataSource ?
							<>
								<BuilderSelectSection
									isReportTemplate={isReportTemplate}
									ref={node => { this.selectRef = node; }}
								/>
								<BuilderFilterSection
									isReportTemplate={isReportTemplate}
									ref={node => { this.filterRef = node; }}
								/>
							</>
						: !isReportTemplate ?
							<>
								<FormField
									inputClassName="full"
									label={`${this.t('select_data_source_label')}:`}
									labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
									errorMessages={this.getValidationErrors('selectedDataSource')}
								>
									<DataSourceSelectInput
										classNamePrefix="data-source-select select-input"
										simpleValue={false}
										value={selectedDataSource}
										onChange={o =>
											this.clearValidationErrors('selectedDataSource')
												.then(() => this.setState({selectedDataSource: o}))
										}
									/>
								</FormField>

								<FormField
									inputClassName="full"
									label={`${this.t('select_output_type')}:`}
									labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
									errorMessages={this.getValidationErrors('selectedOutputType')}
								>
									<CardsSelectInput
										className={styles['selectOutputs']}
										itemIconClassName={styles['selectOutputIcon']}
										contentDisplayType={CARDS_SELECT_INPUT_CONTENT_DISPLAY_TYPE.ALIGNED}
										options={REPORT_OUTPUT_TYPES.map(o => new CardsSelectInputItemDataObject(
											o,
											this.t(o, 'constants.report_output_type'),
											'',
											get(REPORT_OUTPUT_TYPE_ICON_PROPS[o], 'symbol'),
											get(REPORT_OUTPUT_TYPE_ICON_PROPS[o], 'symbolPrefix')
										))}
										value={selectedOutputType}
										onChange={outputType =>
											this.clearValidationErrors('selectedOutputType')
												.then(() => this.setState({selectedOutputType: outputType}))
										}
									/>
								</FormField>

								<div className={`actions ${styles['actions']}`}>
									<Button
										big={true}
										displayStyle={BUTTON_STYLE.ACTION}
										label={this.t('Continue', 'general')}
										onClick={() => {
											if (this.validate()) {
												// Create a new report with the selected data source and output type
												let newReport = new ReportDataObject(selectedDataSource, selectedOutputType);
												if (isChartReport(newReport)) newReport.chart = new ReportChartDataObject(
													REPORT_OUTPUT_TYPE_TO_REPORT_CHART_TYPE[selectedOutputType],
													undefined, 
													undefined, 
													undefined, 
													undefined, 
													undefined,
													[new ReportChartSeriesDataObject()]
												);
												setReportAction(newReport);
												// Clear initial report form, so it will be empty if report builder is cleared 
												// either by clicking the clear button or removing the data source in the sidebar
												this.setState(this.initialState).then();
											}
										}}
									>
										<Icon symbol="hand-drawn-right" symbolPrefix="icofont-" />
									</Button>
								</div>
							</>
						: null
					}
				</div>
			),
				hasDataSource ? '' : `no-data-source ${styles['noDataSource']}`,
				{},
				{
					mainSidebarShrank,
					toggleMainSidebarSizeAction,
					hasDataSource,
					dynamicValues,
					dynamicValueInsertAction: this.dynamicValueInsert,
					isReportTemplate,
				}
			)
		);
	}
}

export * from "./config";
export default connect(mapStateToProps, getPageActions(actions))(BuilderPage);