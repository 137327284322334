import {STORAGE_TYPE} from "../core/storage/const";
import {app_id} from "./app";

/**
 * Storage type used to save user's access-control list (ACL)
 * @type {string}
 */
export const acl_storage_type = STORAGE_TYPE.LOCAL;

/**
 * Name of the variable used for storing user's access-control list (ACL)
 * @type {string}
 */
export const acl_storage_var = `${app_id}_acl`;

/**
 * Mode for checking permissions
 * @description When checking if user has permissions to access a resource or execute some action there can be more 
 * than one type of permission checking based on the way permissions are defined in the system.
 * @type {'AND'|'OR'}
 * 	- 'AND': Check is performed so that all permissions must be met.
 * 	- 'OR': 	Check is performed so that at least one permission must be met.
 */
export const acl_check_mode = 'AND';

/**
 * Super permission that has access to all resources and can execute any command
 * @note If empty or null no super permission will exist in the app.  
 * @type {any}
 */
export const acl_super_permission = 'SUPER_ADMIN';