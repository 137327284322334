/** @typedef {string} AccountUserType */
export const ACCOUNT_USER_TYPE_SUPER_ADMIN = 'SUPER_ADMIN';
export const ACCOUNT_USER_TYPE_OPERATOR = 'OPERATOR';
/** @enum {AccountUserType} */
export const ACCOUNT_USER_TYPE = {
	SUPER_ADMIN: ACCOUNT_USER_TYPE_SUPER_ADMIN,
	OPERATOR: ACCOUNT_USER_TYPE_OPERATOR,
};
/** @type {AccountUserType[]} */
export const ACCOUNT_USER_TYPES = [
	ACCOUNT_USER_TYPE_SUPER_ADMIN,
	ACCOUNT_USER_TYPE_OPERATOR,
];
