import {hideLoading, hideLoadingFunction, showLoading} from "../../core/helpers/loading";
import {ioJsonDeleteItemAction, ioJsonFetchAction, ioJsonFindAction} from "../../core/store/actions/io";
import {actionCreators} from "../../core/store/reducers";
import * as filterDataMap from "./dataMap/filter";
import {clearMessagesAction} from "../../core/components/global/Message";

/**
 * Load report templates into redux store
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Report templates filter plane object.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): *}
 */
export const loadReportTemplatesAction = (
	abortCallback, filter, pageNo, perPage, sortBy = 'creationDate', sortDir = 'DESC'
) => dispatch => {
	const loading = showLoading("#main-page-table");
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'report-template/search',
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy,
		sortDir
	)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) {
				dispatch(actionCreators.reportTemplates.setReportTemplatesData({
					...responseData,
					filter: filterDataMap.input(responseData.filter)
				}));
			}
			hideLoading(loading);
			return responseData;
		});
};

/**
 * Clear report templates data from redux store
 * @return {(function(*=): void)|*}
 */
export const clearReportTemplatesAction = () => dispatch => {
	clearMessagesAction()(dispatch);
	dispatch(actionCreators.reportTemplates.clearReportTemplatesData());
};

/**
 * Delete report template action
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - ID of the report that will be deleted.
 */
export const deleteReportTemplateAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#report-template-delete-dialog .dialog-content-component .buttons');
	return ioJsonDeleteItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'report-template/delete',
		id,
		{},
		hideLoadingFunction(loading)
	)(dispatch);
};

/**
 * Fetch usernames list based on query string
 * @note This action is perfect for use in async select components.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [query] - Query string to search for.
 * @return {function(*): function(*=): *}
 */
export const fetchUsernamesAction = (abortCallback, query) => dispatch => {
	return ioJsonFindAction(
		abortCallback,
		'defaultAuthorizedApi',
		'report-template/creators-search',
		query
	)(dispatch);
};