import jws from "jws";
import {v4} from "uuid";
import JsSHA from "jssha";
import {
	access_token_storage_type,
	access_token_storage_field_name,
	refresh_token_storage_type,
	refresh_token_storage_field_name,
	io_default_request_options,
	auth_api,
	auth_api_endpoints,
	app_id,
	decoded_access_token_storage_field_name,
	temporary_access_token_storage_field_name,
	auth_api_request_type,
	auth_api_response_type,
	auth_code_challenge_method,
	auth_code_challenge_output_format,
	auth_code_verifier_storage_field_name,
	auth_state_storage_field_name,
	auth_api_handler_router_path,
	io_use_country_request_processor_for_auth, acl_storage_var, acl_storage_type, current_user_storage_field_name
} from '../../config';
import {deleteStorageKey, getStorageValue, setStorageValue, STORAGE_TYPE} from '../storage';
import {buildRequest, getIOUrl, rawRequest} from "../io/helper";
import {REQUEST_TYPE, RESPONSE_DATA_TYPE} from "../io/const";
import {getUrl, redirectToPath, redirectToUrl} from "../helpers/url";
import {isSuccessful} from "../helpers/io";
import {getString} from "../helpers/data";
import {IoError} from "../errors";
import {translate} from "../i18n";
import {hideLoading, showPageLoading} from "../helpers/loading";

/**
 * Main auth core class implementing OAuth 2 by default
 * @note This class can me overridden in the app itself in order to extend it or implement some other auth standard.
 */
class CoreAuth {
	// API methods ------------------------------------------------------------------------------------------------------
	/**
	 * Ping method for authorized user
	 *
	 * @param {Function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as 
	 * an argument.
	 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
	 * ("/src/config/io.js").
	 * @param {Headers} [headers=null] - Request headers.
	 * @param {boolean} [withAuth=false] - If true, 'auth' response processor will be used.
	 * @return {Promise<any>} Promise that resolves if ping was successful. Errors should be handled by response 
	 * processors and not here.
	 */
	static authorizedPing(abortCallback, options = io_default_request_options, headers = null, withAuth = false) {
		let requestProcessors = ['auth', 'csrfToken'];
		if (io_use_country_request_processor_for_auth) requestProcessors.push('country');
		let responseProcessors = ['error'];
		if (withAuth) responseProcessors.push('auth');
		if (auth_api_response_type === RESPONSE_DATA_TYPE.JSON) {
			responseProcessors.push('data');
			responseProcessors.push('standardJsonError');
		}
		
		// Get API to use
		const api = (
			getString(auth_api_endpoints.authorized_ping, 'api') ? auth_api_endpoints.authorized_ping.api : auth_api
		);
		
		return rawRequest({
			type: auth_api_request_type,
			url: getIOUrl(api, auth_api_endpoints.authorized_ping.path),
			api: api,
			endpoint: auth_api_endpoints.authorized_ping.path,
			data: {...auth_api_endpoints.authorized_ping.params},
			method: auth_api_endpoints.authorized_ping.method,
			autoToken: true,
			options,
			headers,
			responseType: auth_api_response_type,
			canPause: false,
			requestProcessors,
			responseProcessors,
			abortCallback
		});
	}

	/**
	 * Generate authorization code using authorization code request JWS token.
	 *
	 * @param {Function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as
	 * an argument.
	 * @param {string} authorizationCodeRequestJws - Authorization code request JWS (usually received through URL).
	 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
	 * ("/src/config/io.js").
	 * @param {Headers} [headers=null] - Request headers.
	 * @return {Promise} Return a request error (400 Bad Request or 500 Internal Server Error) which should be handled by
	 * response processors or redirects if successful.
	 */
	static generateAuthorizationCode(
		abortCallback, authorizationCodeRequestJws, options = io_default_request_options, headers = null
	) {
		let requestProcessors = ['auth', 'csrfToken'];
		if (io_use_country_request_processor_for_auth) requestProcessors.push('country');
		const responseProcessors = ['error', 'auth'];
		if (auth_api_response_type === RESPONSE_DATA_TYPE.JSON) {
			responseProcessors.push('data');
			responseProcessors.push('standardJsonError');
		}

		// Get API to use
		const api = (
			getString(auth_api_endpoints.generate_authorization_code, 'api') ? 
				auth_api_endpoints.generate_authorization_code.api : 
				auth_api
		);
		
		return rawRequest({
			type: auth_api_request_type,
			url: getIOUrl(api, auth_api_endpoints.generate_authorization_code.path),
			api: api,
			endpoint: auth_api_endpoints.generate_authorization_code.path,
			data: {
				authorizationCodeRequestJws: authorizationCodeRequestJws,
				...auth_api_endpoints.generate_authorization_code.params
			},
			method: auth_api_endpoints.generate_authorization_code.method,
			autoToken: true,
			options,
			headers,
			responseType: auth_api_response_type,
			canPause: false,
			requestProcessors,
			responseProcessors,
			abortCallback
		}).then(responseData => {
			if (isSuccessful(responseData)) {
				const url = getString(responseData, 'data');
				if (url) redirectToUrl(url);
				return responseData;
			}
		});
	}

	/**
	 * Try to refresh tokens using the refresh token
	 *
	 * @param {Function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as
	 * an argument.
	 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
	 * ("/src/config/io.js").
	 * @param {Headers} [headers=null] - Request headers.
	 * @return {Promise} Returns a promise that resolves with new tokens, or it will reject if tokens were not refreshed.
	 */
	static refreshTokens(abortCallback, options = io_default_request_options, headers = null) {
		let requestProcessors = ['csrfToken'];
		if (io_use_country_request_processor_for_auth) requestProcessors.push('country');
		let responseProcessors = ['error'];
		if (auth_api_response_type === RESPONSE_DATA_TYPE.JSON) {
			responseProcessors.push('data');
		}

		// Get API to use
		const api = (
			getString(auth_api_endpoints.refresh_tokens, 'api') ?
				auth_api_endpoints.refresh_tokens.api :
				auth_api
		);

		return rawRequest({
			type: REQUEST_TYPE.STANDARD,
			url: getIOUrl(api, auth_api_endpoints.refresh_tokens.path),
			api: api,
			endpoint: auth_api_endpoints.refresh_tokens.path,
			data: {
				...auth_api_endpoints.refresh_tokens.params,
				code: getStorageValue(refresh_token_storage_field_name, refresh_token_storage_type),
				client_id: app_id
			},
			method: auth_api_endpoints.refresh_tokens.method,
			autoToken: true,
			options,
			headers,
			responseType: auth_api_response_type,
			canPause: false,
			requestProcessors,
			responseProcessors,
			abortCallback
		}).then(data => {
			const isError = (!data || data.hasOwnProperty('error'));
			if (isError) {
				throw new IoError(
					translate(getString(data, 'error'), 'Auth.authorize.errors'), 
					getIOUrl(api, auth_api_endpoints.refresh_tokens.path),
					api,
					auth_api_endpoints.refresh_tokens.path,
					buildRequest(REQUEST_TYPE.STANDARD, auth_api_endpoints.refresh_tokens.method, {
						...auth_api_endpoints.refresh_tokens.params,
						code: getStorageValue(refresh_token_storage_field_name, refresh_token_storage_type),
						client_id: app_id
					}, options, headers),
					auth_api_response_type,
					abortCallback
				);
			} else {
				return data;
			}
		});
	}

	/**
	 * Try to refresh tokens using the authorization code
	 * 
	 * @param {Function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as
	 * an argument.
	 * * @param {string} authorizationCode - Authorization code.
	 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
	 * ("/src/config/io.js").
	 * @param {Headers} [headers=null] - Request headers.
	 * @return {Promise<Object>} Returns a promise that resolves to the 'token' API response or rejects on error.
	 */
	static refreshTokensWithAuthorizationCode(
		abortCallback, authorizationCode, options = io_default_request_options, headers = null
	) {
		let requestProcessors = ['csrfToken'];
		if (io_use_country_request_processor_for_auth) requestProcessors.push('country');
		let responseProcessors = ['error'];
		if (auth_api_response_type === RESPONSE_DATA_TYPE.JSON) {
			responseProcessors.push('data');
		}

		// Get API to use
		const api = (
			getString(auth_api_endpoints.refresh_tokens_with_authorization_code, 'api') ?
				auth_api_endpoints.refresh_tokens_with_authorization_code.api :
				auth_api
		);

		return rawRequest({
			type: REQUEST_TYPE.STANDARD,
			url: getIOUrl(api, auth_api_endpoints.refresh_tokens_with_authorization_code.path),
			api: api,
			endpoint: auth_api_endpoints.refresh_tokens_with_authorization_code.path,
			data: {
				...auth_api_endpoints.refresh_tokens_with_authorization_code.params,
				code: authorizationCode,
				redirect_uri: getUrl(auth_api_handler_router_path),
				client_id: app_id,
				code_verifier: getStorageValue(auth_code_verifier_storage_field_name, STORAGE_TYPE.SESSION)
			},
			method: auth_api_endpoints.generate_authorization_code.method,
			autoToken: true,
			options,
			headers,
			responseType: auth_api_response_type,
			canPause: false,
			requestProcessors,
			responseProcessors,
			abortCallback
		}).then(data => {
			const isError = (!data || data.hasOwnProperty('error'));
			if (isError) {
				throw new IoError(
					translate(getString(data, 'error'), 'Auth.authorize.errors'),
					getIOUrl(api, auth_api_endpoints.refresh_tokens.path),
					api,
					auth_api_endpoints.refresh_tokens.path,
					buildRequest(REQUEST_TYPE.STANDARD, auth_api_endpoints.refresh_tokens.method, {
						...auth_api_endpoints.refresh_tokens.params,
						code: getStorageValue(refresh_token_storage_field_name, refresh_token_storage_type),
						client_id: app_id
					}, options, headers),
					auth_api_response_type,
					abortCallback
				);
			} else {
				return data;
			}
		});
	}

	/**
	 * Call the API server security init method
	 * @description This should create httpOnly cookie for storing signature portion of the access cookie and a csrfToken
	 * cookie (security items for short).
	 * @note Temporarily stored access token will be used to make the API call. Make sure you stored it properly before
	 * calling this method.
	 *
	 * @param {Function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as
	 * an argument. This will not be used but is specified as param so that the standard 'executeAbortableAction' method,
	 * used by all components extending the abstract BaseComponent, will work properly.
	 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
	 * ("/src/config/io.js").
	 * @param {Headers} [headers=null] - Request headers.
	 * @return {Promise<any>} Promise that resolves successfully if everything went well and security items were created
	 * or rejects if it didn't.
	 */
	static securityInit(abortCallback, options = io_default_request_options, headers = null) {
		let requestProcessors = ['auth', 'csrfToken'];
		if (io_use_country_request_processor_for_auth) requestProcessors.push('country');
		let responseProcessors = ['error'];
		if (auth_api_response_type === RESPONSE_DATA_TYPE.JSON) {
			responseProcessors.push('data');
			responseProcessors.push('standardJsonError');
		}

		// Get API to use
		const api = (
			getString(auth_api_endpoints.security_init, 'api') ?
				auth_api_endpoints.security_init.api :
				auth_api
		);

		return rawRequest({
			type: auth_api_request_type,
			url: getIOUrl(api, auth_api_endpoints.security_init.path),
			api: api,
			endpoint: auth_api_endpoints.security_init.path,
			data: {
				access_token: getStorageValue(temporary_access_token_storage_field_name, STORAGE_TYPE.MEMORY),
				...auth_api_endpoints.security_init.params,
			},
			method: auth_api_endpoints.security_init.method,
			autoToken: true,
			options,
			headers,
			responseType: auth_api_response_type,
			canPause: false,
			requestProcessors,
			responseProcessors,
			abortCallback
		});
	}

	/**
	 * Try to log in the user using specified credentials
	 *
	 * @param {Function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as
	 * an argument.
	 * @param {string} username - Username to log in with.
	 * @param {string} password - Password to log in with.
	 * @param {string} [scope=''] - Scope to log in with.
	 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
	 * ("/src/config/io.js").
	 * If response type was not specified, and it cannot be retrieved from the config, response will be return as Blob.
	 * @param {Headers} [headers=null] - Request headers.
	 * @return {Promise<any>} Promise that resolves with newly created access and refresh tokens and some other data or 
	 * rejects if login was not successful.
	 */
	static login(abortCallback, username, password, scope = '', options = io_default_request_options, headers = null) {
		let requestProcessors = ['captcha'];
		if (io_use_country_request_processor_for_auth) requestProcessors.push('country');
		let responseProcessors = ['error'];
		if (auth_api_response_type === RESPONSE_DATA_TYPE.JSON) {
			responseProcessors.push('data');
			responseProcessors.push('standardJsonError');
		}

		// Get API to use
		const api = (
			getString(auth_api_endpoints.login, 'api') ?
				auth_api_endpoints.login.api :
				auth_api
		);

		return rawRequest({
			type: auth_api_request_type,
			url: getIOUrl(api, auth_api_endpoints.login.path),
			api: api,
			endpoint: auth_api_endpoints.login.path,
			data: {
				userName: username,
				password,
				scope,
				clientId: app_id,
				...auth_api_endpoints.login.params,
			},
			method: auth_api_endpoints.login.method,
			autoToken: false,
			options,
			headers,
			responseType: auth_api_response_type,
			canPause: false,
			captchaAction: 'login',
			requestProcessors,
			responseProcessors,
			abortCallback
		});
	}

	/**
	 * Logout the user
	 * @description This method will just remove tokens from storage.
	 * @return {Promise<void>}
	 */
	static logout() {
		deleteStorageKey(temporary_access_token_storage_field_name, STORAGE_TYPE.MEMORY);
		deleteStorageKey(access_token_storage_field_name, access_token_storage_type);
		deleteStorageKey(refresh_token_storage_field_name, refresh_token_storage_type);
		deleteStorageKey(decoded_access_token_storage_field_name, access_token_storage_type);
		deleteStorageKey(acl_storage_var, acl_storage_type);
		deleteStorageKey(current_user_storage_field_name, STORAGE_TYPE.LOCAL);
		deleteStorageKey(current_user_storage_field_name, STORAGE_TYPE.REDUX);
		return Promise.resolve();
	}
	
	
	// Access token methods ---------------------------------------------------------------------------------------------
	/**
	 * Get temporarily stored access token from memory
	 * @note Temporary access token is stored into memory for security reasons. Since it should only be used after 
	 * getting a new set of access and refresh tokens to initialize the security items created by the API (httpOnly 
	 * cookies), it does not need to be stored anywhere permanently.
	 * @return {string|undefined}
	 */
	static getTemporaryAccessToken() {
		return getStorageValue(temporary_access_token_storage_field_name, STORAGE_TYPE.MEMORY);
	}
	
	/**
	 * Get app's access token from storage
	 * @description This will get the partial access token, containing header and payload, from the storage. This is the
	 * access token used by IO calls and actions. Signature section will be read by the API server from a httpOnly cookie
	 * that it creates when security init API call is made.
	 * @note Storage type and field name are configured in auth config ("/src/config/auth.js" file).
	 *
	 * @return {string|undefined}
	 */
	static getAccessToken() { return getStorageValue(access_token_storage_field_name, access_token_storage_type); }

	/**
	 * Get decoded access token from storage
	 * @return {Object}
	 */
	static getDecodedAccessToken() {
		return JSON.parse(getStorageValue(decoded_access_token_storage_field_name, access_token_storage_type));
	}

	/**
	 * Temporally store the access token into memory
	 * @note Temporary access token is stored into memory for security reasons. Since it should only be used after getting a
	 * new set of access and refresh tokens to initialize the security items created by the API (httpOnly cookies), it
	 * does not need to be stored anywhere permanently.
	 * 
	 * Since this is stored in memory there is no need to clear this storage value.
	 * 
	 * @param {string} accessToken - Whole access token.
	 */
	static storeTemporaryAccessToken(accessToken) {
		setStorageValue(temporary_access_token_storage_field_name, accessToken, STORAGE_TYPE.MEMORY);
	}
	
	/**
	 * Store access token
	 * @note Storage type and field name are configured in auth config ("/src/config/auth.js" file).
	 * @param {string} accessToken - JWS access token.
	 */
	static storeAccessToken(accessToken) {
		// Temporally store access token into memory
		setStorageValue(temporary_access_token_storage_field_name, accessToken, STORAGE_TYPE.MEMORY)

		// Extract and store header and payload sections of the access token as app's access token
		// @note API will combine these two sections with the signature section located in a httpOnly cookie created by 
		// the server.
		const accessTokenToStore = accessToken.slice(0, accessToken.lastIndexOf('.'));
		setStorageValue(access_token_storage_field_name, accessTokenToStore, access_token_storage_type);

		// Extract and store decoded header and payload sections of the access token
		// @note This data can be used by the app as needed.
		const decodedAccessToken = jws.decode(accessToken);
		const localAccessTokenData = {header: decodedAccessToken.header, payload: JSON.parse(decodedAccessToken.payload)};
		setStorageValue(
			decoded_access_token_storage_field_name, JSON.stringify(localAccessTokenData), access_token_storage_type
		);
	};


	// Refresh token methods --------------------------------------------------------------------------------------------
	/**
	 * Get refresh token from storage
	 * @note Storage type and field name are configured in auth config ("/src/config/auth.js" file).
	 *
	 * @return {*|undefined}
	 */
	static getRefreshToken() { return getStorageValue(refresh_token_storage_field_name, refresh_token_storage_type); }
	
	/**
	 * Store refresh token
	 * @note Storage type and field name are configured in auth config ("/src/config/auth.js" file).
	 * @param {string} refreshToken - JWS refresh token.
	 */
	static storeRefreshToken(refreshToken) {
		setStorageValue(refresh_token_storage_field_name, refreshToken, refresh_token_storage_type);
	};
	
	
	// Code verifier and state ------------------------------------------------------------------------------------------
	/**
	 * Generate random code verifier
	 * @return {string}
	 */
	static generateCodeVerifier() { 
		return v4(); 
	}

	/**
	 * Store already generated code verifier into session storage
	 */
	static storeCodeVerifier(codeVerifier) {
		setStorageValue(auth_code_verifier_storage_field_name, codeVerifier, STORAGE_TYPE.SESSION);
	}

	/**
	 * Get code verifier from session storage
	 * @return {string}
	 */
	static getCodeVerifier() {
		getStorageValue(auth_code_verifier_storage_field_name, STORAGE_TYPE.SESSION);
	}

	/**
	 * Delete code verifier from session storage
	 */
	static deleteCodeVerifier() {
		deleteStorageKey(auth_code_verifier_storage_field_name, STORAGE_TYPE.SESSION);
	}

	/**
	 * Calculate code challenge from code verifier
	 * @description Generates and returns an SHA hash of a code verifier.
	 * 
	 * @param {string} codeVerifier
	 * @return {string}
	 */
	static calculateCodeChallenge(codeVerifier) {
		const shaObj = new JsSHA(auth_code_challenge_method, "TEXT", { encoding: "UTF8" });
		shaObj.update(codeVerifier);
		return shaObj.getHash(auth_code_challenge_output_format);
	}

	/**
	 * Generate random state 
	 * @return {string}
	 */
	static generateState() { 
		return v4(); 
	}

	/**
	 * Store already generated state into session storage
	 * @param state
	 */
	static storeState(state) {
		setStorageValue(auth_state_storage_field_name, state, STORAGE_TYPE.SESSION);
	}

	/**
	 * Get state from session storage
	 * @return {string}
	 */
	static getState() {
		return getStorageValue(auth_state_storage_field_name, STORAGE_TYPE.SESSION);
	}

	/**
	 * Delete state from session storage
	 */
	static deleteState() {
		deleteStorageKey(auth_state_storage_field_name, STORAGE_TYPE.SESSION);
	}

	/**
	 * Get redirect URI
	 * @return {string}
	 */
	static getRedirectUri() {
		return getUrl(auth_api_handler_router_path);
	}
	
	
	// Current user -----------------------------------------------------------------------------------------------------
	/**
	 * Store current user data in storage
	 * @note Please only rely on Redux storage value in components in order to detect any changes in user data, for 
	 * example change header username when current user data has been updated.
	 * @param {Object} user - User to set as current.
	 */
	static storeCurrentUser(user) {
		setStorageValue(current_user_storage_field_name, user, STORAGE_TYPE.LOCAL, {}, true);
		setStorageValue(current_user_storage_field_name, user, STORAGE_TYPE.REDUX);
	}

	/**
	 * Get current user data from local storage
	 * @return {Object}
	 */
	static getCurrentUser() {
		return getStorageValue(current_user_storage_field_name, STORAGE_TYPE.LOCAL, true);
	}

	
	// Helpers ----------------------------------------------------------------------------------------------------------
	/**
	 * Check if access and refresh tokens are present
	 * @note This can be used as a rudimentary check to see if user is logged in. It does not check if tokens are valid, 
	 * but it can be used as a first step to check before loading certain pages.
	 */
	static hasTokens() {
		const accessToken = getStorageValue(access_token_storage_field_name, access_token_storage_type);
		const refreshToken = getStorageValue(refresh_token_storage_field_name, refresh_token_storage_type);
		return (accessToken && refreshToken);
	}

	/**
	 * Check if all required auth tokens are present before page can be shown and redirect to login page if they are not
	 * @note This method will display a non-transparent page loading overlay since pages that need users to be logged in
	 * should not be visible until this check is done.
	 * 
	 * @param {string} loginPageRouterPath - URL path of the login page to redirect to if tokens are not present.
	 * @return {boolean}
	 */
	static checkTokensForPage(loginPageRouterPath) {
		// Redirect to login page if user is not logged in
		// @note Simple access token check is performed since API does not have an authorized ping endpoint
		const authLoading = showPageLoading(false)
		if (!this.hasTokens()) {
			hideLoading(authLoading);
			redirectToPath(loginPageRouterPath);
			return false;
		} else {
			hideLoading(authLoading);
			return true;
		}
	}

	/**
	 * Delete all auth tokens
	 */
	static deleteAuthTokens() {
		deleteStorageKey(temporary_access_token_storage_field_name, STORAGE_TYPE.MEMORY);
		deleteStorageKey(access_token_storage_field_name, access_token_storage_type);
		deleteStorageKey(refresh_token_storage_field_name, refresh_token_storage_type);
		deleteStorageKey(decoded_access_token_storage_field_name, access_token_storage_type);
		deleteStorageKey(current_user_storage_field_name, STORAGE_TYPE.LOCAL);
		deleteStorageKey(current_user_storage_field_name, STORAGE_TYPE.REDUX);
	}
}

export default CoreAuth;