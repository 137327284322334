import "./index.css";

import React from "react";
import BaseComponent from "../../../core/components/BaseComponent";
import PropTypes from "prop-types";
import {getArray} from "../../../core/helpers/data";
import FormWrapper from "../../../core/components/advanced/FormWrapper";
import ReportBuilderFilterItem from "./FilterItem";
import {connect} from "react-redux";
import {getPageActions} from "../../../core/helpers/redux";
import * as builderActions from "../../../pages/builder/actions";
import * as fileActions from "../../../store/actions/file";
import {selectors} from "../../../core/store/reducers";
import ConfirmDialog from "../../../core/components/dialogs/ConfirmDialog";
import {ReactSortable} from "react-sortablejs";
import {set, map} from "lodash";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	filter: selectors.builder.getReportValue(state, 'filter'),
});

class ReportBuilderFilter extends BaseComponent {
	/**
	 * List of item refs
	 * @note This is primarily used for validation.
	 */
	itemRefs = {};
	
	constructor(props) {
		super(props, {
			translationPath: 'ReportBuilderFilterComponent',
			domPrefix: 'report-builder-filter-component',
			optimizedUpdate: true,
		});
		
		// Validation and error methods
		this.validate = this.validate.bind(this);

		// Action methods
		this.remove = this.remove.bind(this);
	}

	
	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Get component's main data from local state
	 * @return {ReportFilterItemDataObject[]} Cloned component's main data from local state.
	 */
	getData() { return getArray(this.getProp('filter')); }


	// Validation and error methods -------------------------------------------------------------------------------------
	/**
	 * Check if filter is valid by validating each filter item individually
	 * @note Each item is checked by calling items 'validate' method using items ref.
	 * @return {boolean}
	 */
	validate() {
		const itemGUIIDs = map(this.getData(), 'GUIID');

		// Validate each item using item refs
		for (let GUIID in this.itemRefs) {
			if (this.itemRefs.hasOwnProperty(GUIID)) {
				// Items ref object cleanup
				if (!itemGUIIDs.includes(GUIID)) delete this.itemRefs[GUIID];
				// Check each item by calling its 'validate' method
				else if (!this.itemRefs[GUIID].validate()) return false;
			}
		}
		
		return true;
	}
	
	
	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Remove select item
	 * @param {string} GUIID - Select items GUI ID.
	 */
	remove(GUIID) {
		const {openDialogAction, closeDialogAction, removeReportValueItemAction} = this.props;
		
		const dialogGUIID = openDialogAction('', ConfirmDialog, {
			message: this.t('remove_confirmation'),
			onYes: () => {
				removeReportValueItemAction(GUIID, 'filter');
				closeDialogAction(dialogGUIID);
			},
			onNo: () => closeDialogAction(dialogGUIID)
		}, {
			closeOnEscape: true,
			closeOnClickOutside: true,
			hideCloseBtn: true,
			maxWidth: 450
		});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			className, itemClassName, saveReportValueItemAction, setReportValueAction, loadOptionsAction, 
			readLinesFromFileAction,
		} = this.props;
		const items = this.getData();

		return (
			<div
				id={this.getDomId()}
				className={`${this.getOption('domPrefix')} ${className}`}
			>
				<FormWrapper>
					<ReactSortable
						handle=".handle-wrapper"
						list={items}
						setList={list => setReportValueAction(list, 'filter')}
						animation={250}
						delayOnTouchStart={true}
						delay={2}
					>
						{items.map((i, idx) =>
							<ReportBuilderFilterItem
								key={i.GUIID}
								id={`report-builder-filter-item-${i.GUIID}`}
								className={itemClassName}
								data={i}
								index={idx}
								onChange={item => saveReportValueItemAction(item, 'filter')}
								onDelete={this.remove}
								loadOptionsAction={loadOptionsAction}
								readLinesFromFileAction={readLinesFromFileAction}
								ref={node => { set(this.itemRefs, i.GUIID, node); }}
							/>
						)}
					</ReactSortable>
				</FormWrapper>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ReportBuilderFilter.propTypes = {
	...BaseComponent.propTypes,
	
	// Component's wrapper element id attribute
	id: PropTypes.string,
	// Component's wrapper element class attribute
	className: PropTypes.string,
	// Filter item class name
	itemClassName: PropTypes.string,
	
	// Actions
	loadOptionsAction: PropTypes.func,
};

/**
 * Define component default values for own props
 */
ReportBuilderFilter.defaultProps = {
	className: '',
	itemClassName: '',
};

export default connect(
	mapStateToProps, getPageActions(builderActions, fileActions), null, {forwardRef: true}
)(ReportBuilderFilter);