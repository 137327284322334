import React from "react";
import PageDataComponent from "../../core/components/PageDataComponent";
import {connect} from "react-redux";
import {getPageActions} from "../../core/helpers/redux";
import {selectors} from "../../core/store/reducers";
import * as pageConfig from "./config";
import * as actions from "./actions";
import auth from "../../auth";
import {loginPageRouterPath} from "../../core/pages/login";
import {getPopupInstance} from "../../core/helpers/popup";
import AccountPopup from "./popups/AccountPopup";
import {Link} from "react-router-dom";
import Label from "../../core/components/display/Label";
import {Tooltip} from "react-tippy";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../core/components/display/Button";
import DataTable, {DATA_TABLE_CELL_TYPE} from "../../core/components/advanced/DataTable";
import {icon_font_edit_symbol} from "../../config";
import {PAGINATION_TYPE} from "../../core/components/action/Pagination";
import ACL from "../../acl";
import {areAllObjectPropsEmpty, getArray, isset} from "../../core/helpers/data";
import * as filterDataMap from "./dataMap/accountListFilter";
import {scrollToSelector} from "../../core/helpers/dom";
import {getMenuSidebarShrankFromStorage} from "../../layout/elements/MainSidebar/helpers";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	/** @type {AccountListItemDataObject[]} */
	accountList: selectors.accounts.getAccountList(state),
	accountListPagination: selectors.accounts.getAccountListPagination(state),
	accountListSort: selectors.accounts.getAccountListSort(state),
	accountListFilter: selectors.accounts.getAccountListFilter(state),
});

class Accounts extends PageDataComponent {
	constructor(props) {
		super(props, {
			data: {
				/**
				 * Currently selected search filter
				 */
				filter: {},
				/**
				 * Flag showing if filter is loading
				 */
				filterLoading: false,

				/**
				 * Flag showing if page is loading data
				 * @type {boolean}
				 */
				loading: false,
			},

			/**
			 * Flag that specifies if main data table height will be limited to the available space
			 */
			limitToAvailableSpace: true
		}, {
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			domPrefix: 'accounts-page'
		}, 'page_title');
		
		// Refs
		this.accountListFilterRef = null;

		// Data methods
		this.reloadAccountList = this.reloadAccountList.bind(this);
		this.loadAccountListPage = this.loadAccountListPage.bind(this);
		this.sortAccountList = this.sortAccountList.bind(this);
		this.filterAccountList = this.filterAccountList.bind(this);
		this.removeAccountListFilter = this.removeAccountListFilter.bind(this);
		this.isAccountListFilterEmpty = this.isAccountListFilterEmpty.bind(this);

		// Popup methods
		this.openAccountPopup = this.openAccountPopup.bind(this);
		this.closeAccountPopup = this.closeAccountPopup.bind(this);
		this.handleCloseAccountPopup = this.handleCloseAccountPopup.bind(this);
		
		// Render methods
		this.renderActions = this.renderActions.bind(this);
	}


	componentDidMount() {
		// Redirect to login page if user is not logged in
		if (auth.checkTokensForPage(loginPageRouterPath)) {
			return super.componentDidMount();
		}
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 */
	loadPageData() {
		const {accountList, loadAccountListAction} = this.props;

		// Do not load account list if it is already loaded
		if (isset(accountList)) {
			// Open filter if it is not empty
			if (!this.isAccountListFilterEmpty() && this.accountListFilterRef) {
				this.accountListFilterRef.open();
			}
			return this.reloadAccountList();
		}
		// Load account list if it is not already loaded
		else {
			return this.setValue('loading', true)
				.then(() => this.executeAbortableAction(loadAccountListAction))
				.then(() => this.setValue('loading', false));
		}
	}

	/**
	 * Reload account list using current options (page, sort, ...)
	 * @return {Promise<*>}
	 */
	reloadAccountList() {
		const {
			loadAccountListAction, accountListPagination, accountListSort, 
			accountListFilter
		} = this.props;
		const {pageNo, perPage} = accountListPagination;
		const {sortBy, sortDir} = accountListSort;
		const oFilter = filterDataMap.output(accountListFilter);

		return this.executeAbortableAction(loadAccountListAction, oFilter, pageNo, perPage, sortBy, sortDir)
			.then(() => this.accountListFilterRef?.reload());
	}

	/**
	 * Reload account list using current options (page, sort, ...) if any
	 * @param {number} [pageNo=1] - Page number to load (starts with 1).
	 * @return {Promise<*>}
	 */
	loadAccountListPage(pageNo = 1) {
		const {
			loadAccountListAction, accountListPagination, accountListSort,
			accountListFilter
		} = this.props;
		const {perPage} = accountListPagination;
		const {sortBy, sortDir} = accountListSort;
		const oFilter = filterDataMap.output(accountListFilter);
		
		return this.executeAbortableAction(loadAccountListAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Sort account list
	 * @param {string} sortBy - Name of the sort column.
	 * @param {string} sortDir - Direction of the sort.
	 * @return {Promise<*>}
	 */
	sortAccountList(sortBy, sortDir) {
		const {
			loadAccountListAction, accountListPagination, accountListFilter
		} = this.props;
		const {pageNo, perPage} = accountListPagination;
		const oFilter = filterDataMap.output(accountListFilter);

		return this.executeAbortableAction(loadAccountListAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Filter account list
	 * @param {Object} filter - Filter object where keys are filter field names and values are filter values.
	 * @return {Promise<*>}
	 */
	filterAccountList(filter) {
		const {loadAccountListAction, accountListPagination, accountListSort} = this.props;
		const {perPage} = accountListPagination;
		const {sortBy, sortDir} = accountListSort;
		const oFilter = filterDataMap.output(filter);

		return this.setValue('filterLoading', true)
			.then(() => this.executeAbortableAction(loadAccountListAction, oFilter, 1, perPage, sortBy, sortDir))
			.then(() => this.setValue('filterLoading', false))
			.then(() => scrollToSelector('#main-page-table', false));
	}

	/**
	 * Remove account list filter
	 * @return {Promise<*>}
	 */
	removeAccountListFilter() {
		return this.filterAccountList(null);
	}

	/**
	 * Check if account list filter is applied
	 * @return {Boolean}
	 */
	isAccountListFilterEmpty() {
		return areAllObjectPropsEmpty(this.getProp('accountListFilter'), true, false);
	}


	// Router methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called if current URL matches the 'create' sub-url of the page
	 * @note Create sub-url uses '/new' router path relative to the router path of the page (see 'options.routerPath').
	 *
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'create' sub-url if such component exists.
	 */
	handleCreateUrl() {
		if (ACL.checkPermission(['USER_WRITE'])) return this.openAccountPopup(true);
		else { this.redirectToBase(); return ''; }
	}

	/**
	 * Method that will be called if current URL matches the 'item' sub-url of the page
	 * @note Item sub-url uses '/item' router path and 'id' as router path param ('/item/:id') on top of to the router
	 * path of the page (see 'options.routerPath').
	 *
	 * @param {string} id - Account name.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'item' sub-url if such component exists.
	 */
	handleItemUrl(id) {
		const {loadAccountAction} = this.props;

		// Open item popup if it is not already opened
		// @note This is done to ensure that create dialog does not open another dialog after creating the item 
		// or to avoid opening another dialog if item ID in the URL changes programmatically. Dialog data will 
		// change because Redux store is used.
		if (!this.urlComponentGUIID) {
			this.urlComponentGUIID = this.openAccountPopup();
		}
		// If dialog is opened update its dynamic items (tabs, action buttons, ...)
		else {
			/** @type {AccountPopup} */
			const accountPopup = getPopupInstance(this.urlComponentGUIID);
			accountPopup.updateDynamics({isNew: false}).then();
		}

		// Try to load account item
		return new Promise(resolve =>
			// Timeout is added to allow for the popup open animation to finish
			setTimeout(() => resolve(
				this.executeAbortableAction(loadAccountAction, id)
					.then(account => {
						// If account item is successfully loaded
						if (account) {
							return this.urlComponentGUIID;
						}
						// If account item could not be loaded (usually if item with ID from URL does not exist)
						else {
							// Close item popup if it is opened
							if (this.urlComponentGUIID) this.closeUrlComponent();
							// Redirect to page base url (removes item ID from URL if it exists)
							this.redirectToBase();
							return '';
						}
					})
			))
		);
	}

	/**
	 * Method that will be called if current URL matches the base URL of the page
	 *
	 * @return {string} GUI ID of the component (popup, dialog, ...) that is rendered when page is on its base URL if
	 * such component exists.
	 */
	handleBaseUrl() {
		this.closeAccountPopup();
		return '';
	}

	/**
	 * Method that will be called when page component unmounts and should handle closing of any page url or sub-url
	 * component if it exists.
	 */
	closeUrlComponent() {
		this.closeAccountPopup();
	}


	// Popup methods ----------------------------------------------------------------------------------------------------
	/**
	 * Open item popup
	 * @param {boolean} [isNew=false] - Flag that specifies if new assessments popup should be opened.
	 */
	openAccountPopup(isNew = false) {
		const {openPopupAction} = this.props;
		return openPopupAction(AccountPopup, {
			isNew,
			onClose: this.handleCloseAccountPopup,
			redirectToItem: this.redirectToItem,
		});
	}

	/**
	 * Close item popup
	 */
	closeAccountPopup() {
		const {closePopupAction, clearAccountAction} = this.props;

		// Close item popup
		closePopupAction(this.getUrlComponentGUIID());
		this.clearUrlComponentGUIID();

		// Clear popup Redux data
		clearAccountAction();
	}

	/**
	 * Handle item popup 'onClose' event
	 * @return {Promise<*>}
	 */
	handleCloseAccountPopup() {
		this.redirectToBase();
		return new Promise(resolve =>
			// Timeout is added to allow for the popup close animation to finish 
			setTimeout(() => resolve(this.reloadAccountList()))
		);
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render data table actions cell
	 * @param {AccountListItemDataObject} row - Data table row.
	 * @return {JSX.Element}
	 */
	renderActions(row) {
		return (
			<div className="actions">
				{
					ACL.checkPermission(['USER_WRITE']) ?
						<Tooltip
							tag="div"
							title={this.t('edit_tooltip')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<Button
								className="action-btn no-border-radius"
								displayStyle={BUTTON_STYLE.ACTION}
								displayType={BUTTON_DISPLAY_TYPE.NONE}
								icon={icon_font_edit_symbol}
								onClick={() => this.redirectToItem(row.id)}
							/>
						</Tooltip>
						: null
				}
			</div>
		);
	}
	
	/**
	 * Render page title
	 * @description This method specifies how page title will be rendered if page title should be rendered. It does not
	 * determine if page title should be rendered.
	 * @return {JSX.Element}
	 */
	renderPageTitle() {
		const {title} = this.state;

		return (
			<h1 className="page-title with-actions">
				<div className="content">{title ? this.translate(title, this.titlePathPrefix) : ''}</div>
				<div className="actions">
					{
						ACL.checkPermission(['USER_WRITE']) ?
							<div className="action-button">
								<Link to={this.getCreateRedirectTo()} className="button action solid big">
									<Label icon="plus" iconSymbolPrefix="icofont-" content={this.t('create_account')} />
								</Link>
							</div>
							: null
					}
					
					<div className="action-button">
						<Tooltip
							tag="div"
							title={this.t('Reload data', 'general')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<Button
								big={true}
								icon="refresh"
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								displayStyle={BUTTON_STYLE.SUBTLE}
								onClick={this.reloadAccountList}
							/>
						</Tooltip>
					</div>
				</div>
			</h1>
		);
	}

	render() {
		const {
			accountList, accountListPagination, accountListSort, mainSidebarShrank, toggleMainSidebarSizeAction, 
		} = this.props;
		const {limitToAvailableSpace} = this.state;

		return this.renderLayout((
			<div id={this.getDomId()}>
				<DataTable
					id="main-page-table"
					limitToAvailableSpace={limitToAvailableSpace && !this.getProp('isMobileBreakpoint')}
					highlightOnHover={true}
					columns={[
						{
							name: 'userType',
							label: this.t('userType'),
							dataType: DATA_TABLE_CELL_TYPE.TEXT,
							dataTypeOptions: {
								translatePath: 'constants.account_type'
							}
						},
						{
							name: 'userName',
							label: this.t('userName'),
						},
						{
							label: this.t('fullName'),
							dataType: DATA_TABLE_CELL_TYPE.TEMPLATE,
							dataTypeOptions: {
								template: `{$firstName} {$lastName}`,
								nullAsEmpty: true,
							}
						},
						{
							name: 'email',
							label: this.t('email'),
						},
						{
							name: 'active',
							label: this.t('active'),
							dataType: DATA_TABLE_CELL_TYPE.BOOL,
							width: 1,
						},
						{
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: {
								content: this.renderActions,
								standardWrapper: false
							},
							width: 1,
							stopPropagation: true,
						},
					]}
					highlightedRows={[
						// Inactive users style
						{style: {opacity: 0.5}, rows: getArray(accountList).filter(i => !i.active)}
					]}
					data={accountList}
					onRowClick={row => this.redirectToItem(row.id)}
					paginationType={PAGINATION_TYPE.STATIC}
					onSortByColumn={this.sortAccountList}
					onPaginationClick={this.loadAccountListPage}
					{...accountListPagination}
					{...accountListSort}
				/>
			</div>
		), undefined, undefined, {
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default connect(mapStateToProps, getPageActions(actions))(Accounts);