import CoreAuth from "../core/auth";
import {
	acl_super_permission, app_id,
	auth_api,
	auth_api_endpoints, auth_api_request_type,
	auth_api_response_type,
	io_default_request_options,
	io_use_country_request_processor_for_auth, temporary_access_token_storage_field_name
} from "../config";
import {RESPONSE_DATA_TYPE} from "../core/io/const";
import {getArray, getString} from "../core/helpers/data";
import {getIOUrl, rawRequest} from "../core/io/helper";
import {deleteStorageKey, getStorageValue, STORAGE_TYPE} from "../core/storage";
import {isSuccessful} from "../core/helpers/io";
import ACL from "../acl";
import {get} from "lodash";

/**
 * App auth class
 * @note This class must extend CoreAuth class, but it does not have to implement any changes if no custom functionality
 * is needed.
 */
class Auth extends CoreAuth {
	/**
	 * OVERRIDDEN TO HANDLE PERMISSION STORING
	 * 
	 * Call the API server security init method
	 * @description This should create httpOnly cookie for storing signature portion of the access cookie and a csrfToken
	 * cookie (security items for short).
	 * @note Temporarily stored access token will be used to make the API call. Make sure you stored it properly before
	 * calling this method.
	 *
	 * @param {Function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as
	 * an argument. This will not be used but is specified as param so that the standard 'executeAbortableAction' method,
	 * used by all components extending the abstract BaseComponent, will work properly.
	 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
	 * ("/src/config/io.js").
	 * @param {Headers} [headers=null] - Request headers.
	 * @return {Promise<any>} Promise that resolves successfully if everything went well and security items were created
	 * or rejects if it didn't.
	 */
	static securityInit(abortCallback, options = io_default_request_options, headers = null) {
		let requestProcessors = ['auth', 'csrfToken'];
		if (io_use_country_request_processor_for_auth) requestProcessors.push('country');
		let responseProcessors = ['error'];
		if (auth_api_response_type === RESPONSE_DATA_TYPE.JSON) {
			responseProcessors.push('data');
			responseProcessors.push('standardJsonError');
		}

		// Get API to use
		const api = (
			getString(auth_api_endpoints.security_init, 'api') ?
				auth_api_endpoints.security_init.api :
				auth_api
		);

		return rawRequest({
			type: auth_api_request_type,
			url: getIOUrl(api, auth_api_endpoints.security_init.path),
			api: api,
			endpoint: auth_api_endpoints.security_init.path,
			data: {
				access_token: getStorageValue(temporary_access_token_storage_field_name, STORAGE_TYPE.MEMORY),
				...auth_api_endpoints.security_init.params,
			},
			method: auth_api_endpoints.security_init.method,
			autoToken: true,
			options,
			headers,
			responseType: auth_api_response_type,
			canPause: false,
			requestProcessors,
			responseProcessors,
			abortCallback
		})
			/**
			 * @override Save permissions to storage
			 */
			.then(response => {
				if (isSuccessful(response)) {
					let permissions = getArray(response, 'data.permissionCodes');
					if (get(response, 'data.userAccountType') === acl_super_permission) {
						permissions.push(acl_super_permission);
					}
					ACL.savePermissions(permissions);
				}
			});
	}

	/**
	 * Logout the user
	 * @description This method will just remove tokens from storage.
	 * @return {Promise<void>}
	 *
	 * @override Deleted some additional cookies.
	 */
	static logout() {
		return super.logout()
			// Delete builder related session cookies
			.then(() => {
				deleteStorageKey(`${app_id}-builder`, STORAGE_TYPE.SESSION);
				deleteStorageKey(`${app_id}-builder_select_section_collapsed`, STORAGE_TYPE.SESSION);
				deleteStorageKey(`${app_id}-builder_filter_section_collapsed`, STORAGE_TYPE.SESSION);
				return Promise.resolve();
			});
	}
}

export default Auth;