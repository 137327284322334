import React from "react";
import PropTypes from "prop-types"; 
import DataComponent from "../../DataComponent";
import {v4} from "uuid";
import {calculateDropdownPosition} from "./helper";
import {DROPDOWN_POSITION} from "./const";

import style from "./index.module.css";

class Dropdown extends DataComponent {
	/**
	 * Child element click timeoutID
	 * @type {number}
	 */
	childClickTimeout;
	
	constructor(props) {
		super(props, {
			data: {
				GUIID: v4(),
				show: false
			}
		}, { disableLoad: true, debug: true });

		// Bind component methods
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.toggleDropdown = this.toggleDropdown.bind(this);
		this.renderChildren = this.renderChildren.bind(this);

		// Refs
		this.innerContentRef = null;
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		return super.componentDidUpdate(prevProps, prevState, snapshot).then(() => {
			// Calculate position of the dropdown content
			calculateDropdownPosition(this.getValue('GUIID'), this.getProp('dropdownTopOffset'));
		});
	}
	
	componentWillUnmount() {
		if (this.childClickTimeout > 0) clearTimeout(this.childClickTimeout);
		super.componentWillUnmount();
	}

	/**
	 * Method that will be called every time mouse clicks outside the component
	 */
	handleClickOutside() { this.setValue('show', false); }

	/**
	 * Toggle dropdown content
	 * @param {boolean} [fromChild=false] - If true, this method was called by the child item.
	 */
	toggleDropdown(fromChild = false) {
		const { preserveScroll, onClick, disabled } = this.props;
		
		if (fromChild || !disabled) {
			this.invertBoolValue('show').then(() => {
				// Reset dropdown content scrolling position if 'preserveScroll' flag is false.
				if (!preserveScroll && this.getValue('show') && this.innerContentRef) {
					this.innerContentRef.scrollTo(0,0);
				}
			});

			// Call dropdown component's 'onClick' function if it is defined in props.
			if(onClick) onClick();
		}
	}

	/**
	 * Return child elements (dropdown content) to render
	 */
	renderChildren() {
		const {children} = this.props;
		
		return React.Children.map(children, child =>
			child ?
				React.cloneElement(child, { 
					onMouseUp: () => {
						this.childClickTimeout = setTimeout(() => this.toggleDropdown(true), 100);
					}})
				: null
		);
	}
	
	render() {
		const {label, className, labelClassName, contentClassName, position} = this.props;
		const GUIID = this.getValue('GUIID');
		const show = this.getValue('show');
		
		return (
			<div 
				id={`dropdown-${GUIID}`}
				className={
					`dropdown-component ${className} ${show ? `show ${style['show']}` : ''} ${style['dropdown']} ${position}`
				}
				ref={this.setWrapperRef}
			>
				<span 
					onClick={this.toggleDropdown} 
					className={`dropdown-label ${style['dropdown-label']} ${labelClassName}`}
				>
					{label}
				</span>
				<div className={`dropdown-content ${style['dropdown-content']} ${contentClassName}`}>
					<div 
						className={`dropdown-content-inner ${style['dropdown-content-inner']}`} 
						ref={node => { this.innerContentRef = node; }}
					>
						{this.renderChildren()}
					</div>
				</div>
			</div>
		);
	}
}
/**
 * Define component's own props that can be passed to it by parent components
 */
Dropdown.propTypes = {
	// Dropdown trigger label component
	label: PropTypes.element,
	// Dropdown component's CSS class
	className: PropTypes.string,
	// Dropdown label CSS class
	// @note Dropdown label is used to open the dropdown by clicking on it
	labelClassName: PropTypes.string,
	// Dropdown content CSS class
	contentClassName: PropTypes.string,
	// Flag that determines if scroll position inside dropdown is preserved after dropdown closes
	preserveScroll: PropTypes.bool,
	// Dropdown content position (see DROPDOWN_POSITION const)
	position: PropTypes.oneOf([DROPDOWN_POSITION.LEFT, DROPDOWN_POSITION.RIGHT]),
	// Dropdown top position offset in pixels
	dropdownTopOffset: PropTypes.number,
	// Flag that determines if dropdown will be disabled
	// @description Disabled dropdown will not show its content on label click and will not trigger 'onClick' event.
	disabled: PropTypes.bool,

	// Events
	onClick: PropTypes.func,
};

/**
 * Define component default values for own props
 */
Dropdown.defaultProps = {
	className: '',
	labelClassName: '',
	contentClassName: '',
	preserveScroll: false,
	position: DROPDOWN_POSITION.LEFT,
	dropdownTopOffset: 0,
	disabled: false,
};

export default Dropdown;
export * from "./const";