import styles from "./index.module.css";
import "./default.style.css";

import React from "react";
import BaseComponent from "../../../../components/BaseComponent";
import PropTypes from "prop-types";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../../../components/display/Button";
import CookieConsent from "../../index";
import {openDialog} from "../../../../helpers/dialog";
import CookieSettings from "../CookieSettings";
import Label from "../../../../components/display/Label";
import {cookie_consent_enabled} from "../../../../../config";

class CookieNotice extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: 'CookieNotice',
			domPrefix: 'cookie-notice-component',
		});

		// Action methods
		this.showSettings = this.showSettings.bind(this);
		this.close = this.close.bind(this);
	}

	
	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Show cookie settings dialog
	 */
	showSettings() {
		openDialog('', CookieSettings, {}, {
			id: 'CookieSettingsDialog',
			className: 'cookie-settings-dialog',
			closeOnEscape: false,
			closeOnClickOutside: false,
			maxWidth: 1421
		});
	}

	/**
	 * Close cookie notice
	 */
	close() { new CookieConsent().closeCookieNotice(); }
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {styleName, className} = this.props;
		
		return (
			cookie_consent_enabled ?
				<div
					id={this.getDomId()}
					className={`${this.getOption('domPrefix')} ${styles['wrapper']} ${styleName}-style ${className}`}
				>
					<Label supportHtml={true} content={
						(
							this.t('notice') +
							` <a
								href="${this.t('cookie_notice_link', 'App.info')}"
								target="_blank"
								rel="noopener noreferrer"
							>${this.t('link_label')}</a>`
						)
					} />
					<div className={`actions ${styles['actions']}`}>
						<Button
							displayStyle={BUTTON_STYLE.ACTION} 
							onClick={() => { new CookieConsent().acceptAll(); }} 
							label={this.t('accept_all')} 
						/>
						<Button
							displayStyle={BUTTON_STYLE.ACTION}
							onClick={this.showSettings} 
							label={this.t('settings')} 
						/>
						<Button
							className={`close-button`}
							icon="times" 
							displayStyle={BUTTON_STYLE.ACTION} 
							displayType={BUTTON_DISPLAY_TYPE.NONE} 
							onClick={this.close}
						/>
					</div>
				</div>
				: null
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
CookieNotice.propTypes = {
	// Component style name
	// @description Component style name is a name of the style that will be used to determine the CSS used to style the
	// component.
	styleName: PropTypes.string,
	// Component's wrapper element id attribute
	id: PropTypes.string,
	// Component's wrapper element class attribute
	className: PropTypes.string,
};

/**
 * Define component default values for own props
 */
CookieNotice.defaultProps = {
	styleName: 'default',
	id: '',
	className: '',
};

export default CookieNotice;