import {cloneDeep} from "lodash";
import {getArray, getString, resetObjectValues} from "../../../core/helpers/data";
import {AccountRoleListItemDataObject} from "../dataObjects";
import * as accountPermissionDataMap from "../../accountPermissions/dataMap/accountPermission";


/**
 * Get input data from raw data
 * @param {{id: string|number, code: string, name: string, description: string, permissionDataList: Object[]}} rawData -
 * Raw data.
 * @return {AccountRoleListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new AccountRoleListItemDataObject(
		getString(result, 'id'),
		getString(result, 'code'),
		getString(result, 'name'),
		getString(result, 'description'),
		getArray(result, 'permissionDataList').map(i => accountPermissionDataMap.input(i))
	);

	return result;
}

/**
 * Get output data from input data
 * @param {AccountRoleListItemDataObject} inputData - Input data.
 * @return {{id: string|number, code: string, name: string, description: string, permissionDataList: Object[]}}
 */
export const output = inputData =>  {
	let result = cloneDeep(inputData);

	result = {
		id: getString(result, 'id'),
		code: getString(result, 'code'),
		name: getString(result, 'name'),
		description: getString(result, 'description'),
		permissionDataList: getArray(result, 'permissions').map(i => accountPermissionDataMap.output(i))
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}