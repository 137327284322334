import {hideLoading, hideLoadingFunction, showLoading} from "../../../../core/helpers/loading";
import {ioJsonDeleteItemAction, ioJsonFetchAction} from "../../../../core/store/actions/io";
import {actionCreators} from "../../../../core/store/reducers";
import * as filterDataMap from "../../dataMap/filter";
import {clearMessagesAction} from "../../../../core/components/global/Message";

/**
 * Load scheduled reports into redux store
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Reports filter plane object.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): *}
 */
export const loadScheduledReportsAction = (
	abortCallback, filter, pageNo, perPage, sortBy = 'scheduledTime', sortDir = 'ASC' 
) => dispatch => {
	const loading = showLoading("#main-page-table");
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'report/search-future-reports',
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy,
		sortDir
	)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) {
				dispatch(actionCreators.scheduledReports.setReportsData({
					...responseData,
					filter: filterDataMap.input(responseData.filter)
				}));
			}
			hideLoading(loading);
			return responseData;
		});
};

/**
 * Load scheduled reports on interval into redux store
 * @note Sets only reports list and not pagination, sort and filter data.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Reports filter plane object.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): *}
 */
export const loadScheduledReportsIntervalAction = (
	abortCallback, filter, pageNo, perPage, sortBy, sortDir
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'report/search-future-reports',
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy,
		sortDir
	)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators.scheduledReports.setReports(responseData.data));
			return responseData;
		});
};

/**
 * Clear scheduled reports data from redux store
 * @return {(function(*=): void)|*}
 */
export const clearScheduledReportsAction = () => dispatch => {
	clearMessagesAction()(dispatch);
	dispatch(actionCreators.scheduledReports.clearReportsData());
};

/**
 * Delete scheduled report action
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - ID of the report that will be deleted.
 */
export const deleteScheduledReportAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#report-delete-dialog .dialog-content-component .buttons');
	return ioJsonDeleteItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'report/delete',
		id,
		{},
		hideLoadingFunction(loading)
	)(dispatch);
};