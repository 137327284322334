/**
 * Abstract page component used to create app pages
 * NOTE: Components created using this abstract component will have a 'data' field in the local state which will store
 * main component's data. This is a convention chosen by design to separate main component's data (data need by the
 * component to work properly) and other local state values used for GUI or other less significant or more specific
 * purposes.
 */

import React from "react";
import DataComponent from "./DataComponent";
import {cloneDeep, get} from "lodash";
import {
	app_home_page_router_path,
	app_title,
	browser_title_prefix,
	browser_title_prefix_separator,
	default_layout, page_url_item_id_type
} from "../../config";
import CoreLayout from "../layout";
import {getInteger, getString, isset} from "../helpers/data";
import {cssStyleStringToObject, decodeURLParams, ltrimString} from "../helpers/string";
import {matchPath} from "react-router-dom";
import {scrollToSelector, scrollToTop} from "../helpers/dom";
import ACL from "../../acl";

class PageDataComponent extends DataComponent {
	/**
	 * GUI ID of the component used to handle page url or sub-url if such component exists
	 * @type {string}
	 */
	urlComponentGUIID = '';
	
	/**
	 * Page data component constructor
	 *
	 * @param {object} props - Component props.
	 * @param {object} [initialState={}] - Initial state from child class that will override the default initial state.
	 * @param {PageDataComponentOptions} [options={}] - Component options from child class that will override the default 
	 * options.
	 * @param {string|null} [title] - Page title translation path. This value affects both browser page (tab) title and
	 * page title rendered by the layout.
	 * 	* If defined as string (not an empty string) title layout element will be rendered with the translated value
	 * 	defined by this path and browser page title will be generated using that value and app title config values.
	 * 	* If empty string, title layout element won't be rendered and browser page title will be set to default browser
	 * 	title (like when 'title' HTML element is not specified).
	 * 	* If null, title layout element won't be rendered and browser page title will be generated using app title
	 * 	config values.
	 * 	* If not defined (undefined), title layout element won't be rendered and no browser title will be set.
	 * @param {string} [titlePathPrefix] - If set this will be used as translation path for the title instead of
	 * component's internal 'translationPath' option.
	 */
	constructor(props, initialState = {}, options = {}, title, titlePathPrefix = undefined) {
		// Initialize initial state
		const _initialState = {
			/**
			 * Layout component used to render the page
			 * @description Layout component will be dynamically imported based on 'layout' options value.
			 */
			layout: null,

			/**
			 * Page title translation path
			 * @note Translation path is used instead of translated value to make the title change when locale changes.
			 * @type string|null
			 */
			title,

			...(initialState ? cloneDeep(initialState) : {}),
		}

		/**
		 * Set component options by combining default options overridden by any options from 'options' argument
		 * @type {PageDataComponentOptions}
		 * @private
		 */
		const _options = {
			/**
			 * Page layout
			 * @note If not specified, default layout from config will be used (see '/src/config/app.js' file).
			 * @type {string} Directory name of the layout (in /src/layout/ directory).
			 */
			layout: default_layout,

			/**
			 * Flag that determines if page title layout element should be rendered even if page title is empty, null or
			 * not defined (undefined).
			 */
			forceRenderTitle: false,

			/**
			 * Flag that determines if page title should be rendered if title is is defined
			 */
			renderTitle: true,

			/**
			 * Page router path
			 * @type {string}
			 */
			routerPath: '',

			/**
			 * Scroll to the top of the page on page mount
			 * @note If 'scrollToSelectorOnMount' options is set this option will be ignored.
			 * @type {boolean}
			 */
			scrollToTopOnMount: true,

			/**
			 * Scroll to the DOM element defined by this CSS selector
			 * @note If this options is set 'scrollToTopOnMount' option will be ignored.
			 * @type {string}
			 */
			scrollToSelectorOnMount: '',

			/**
			 * List of permissions associated with the page.
			 * @note Permissions check method is defined in 'acl_check_mode' acl config option.
			 * @type {Array|null}
			 */
			permissions: null,

			...cloneDeep(options)
		}
		
		super(props, _initialState, _options);

		// Set initial component's internal state
		this.state = cloneDeep(this.initialState);

		// Set translation path used to translate the title
		// @note If empty or not defined component's 'translationPath' option value will be used.
		this.title = title;
		this.titlePathPrefix = titlePathPrefix ? titlePathPrefix : this.getOption('translationPath');

		// ACL methods
		this.canAccess = this.canAccess.bind(this);
		
		// Data methods
		this.loadPageData = this.loadPageData.bind(this);

		// Router methods
		this.getUrlParams = this.getUrlParams.bind(this);
		this.getUrlParam = this.getUrlParam.bind(this);
		this.getCurrentRouterPath = this.getCurrentRouterPath.bind(this);
		this.getUrlComponentGUIID = this.getUrlComponentGUIID.bind(this);
		this.clearUrlComponentGUIID = this.clearUrlComponentGUIID.bind(this);
		this.isBaseUrl = this.isBaseUrl.bind(this);
		this.isCreateUrl = this.isCreateUrl.bind(this);
		this.isItemUrl = this.isItemUrl.bind(this);
		this.getItemUrlId = this.getItemUrlId.bind(this);
		this.getHomeRedirectTo = this.getHomeRedirectTo.bind(this);
		this.getBaseRedirectTo = this.getBaseRedirectTo.bind(this);
		this.getCreateRedirectTo = this.getCreateRedirectTo.bind(this);
		this.getItemRedirectTo = this.getItemRedirectTo.bind(this);
		this.redirectTo = this.redirectTo.bind(this);
		this.redirectToHome = this.redirectToHome.bind(this);
		this.redirectToBase = this.redirectToBase.bind(this);
		this.redirectToCreate = this.redirectToCreate.bind(this);
		this.redirectToItem = this.redirectToItem.bind(this);
		this.handleSubUrl = this.handleSubUrl.bind(this);
		this.handleCreateUrl = this.handleCreateUrl.bind(this);
		this.handleItemUrl = this.handleItemUrl.bind(this);
		this.handleBaseUrl = this.handleBaseUrl.bind(this);
		this.handleUnknownUrl = this.handleUnknownUrl.bind(this);
		this.handleUrlChange = this.handleUrlChange.bind(this);
		this.closeUrlComponent = this.closeUrlComponent.bind(this);

		// Title methods
		this.setBrowserTitle = this.setBrowserTitle.bind(this);
		this.setPageTitle = this.setPageTitle.bind(this);
		this.setTitle = this.setTitle.bind(this);

		// Render methods
		this.renderPageTitle = this.renderPageTitle.bind(this);
		this.renderLayout = this.renderLayout.bind(this);
	}

	componentDidMount(override = false) {
		return super.componentDidMount(override)
			.then(() => {
				// IMPORTANT: This is required in order for dynamic import to load properly when app is built for production. 
				// Dynamic import cannot resolve imported value 'default_layout' so there needs top be a local const getting the 
				// value before it can be used in the dynamic import.
				const defaultLayout = default_layout;
	
				// Dynamically import required layout
				return import(`../../layout/layouts/${this.getOption('layout', defaultLayout)}`)
					// Set layout
					.then(({default: layout}) => this.setState({layout}))
					// Set title (both layout and browser)
					.then(() => this.setTitle(this.title))
					// Scroll to ...
					.then(() => {
						const scrollToSelectorOnMount = this.getOption('scrollToSelectorOnMount');
						if (scrollToSelectorOnMount) scrollToSelector(scrollToSelectorOnMount);
						else if (this.getOption('scrollToTopOnMount')) scrollToTop();
					})
					// Load data required by the page
					.then(() => this.loadPageData())
					// Handle page URLs
					.then(async () => {
						await this.handleSubUrl(
							ltrimString(this.getCurrentRouterPath(), this.getOption('routerPath', '')),
							get(this.props, 'location')
						);
						if (this.isCreateUrl()) this.urlComponentGUIID = await this.handleCreateUrl();
						else if (this.isItemUrl()) this.urlComponentGUIID = await this.handleItemUrl(this.getItemUrlId());
						else if (this.isBaseUrl()) this.urlComponentGUIID = await this.handleBaseUrl();
						else this.urlComponentGUIID = await this.handleUnknownUrl();
					});
			})
			// Add 'stuck' class to page title when sticky position is reached
			.then(() => {
				const pageTitleElement = document.querySelector('.page-title');
				if (pageTitleElement) {
					const observer = new IntersectionObserver(
						([e]) => e.target.classList.toggle("stuck", e.intersectionRatio < 1),
						{ threshold: [1] }
					);
					observer.observe(pageTitleElement);
				}
				return this.state;
			});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		return super.componentDidUpdate(prevProps, prevState, snapshot)
			.then(async () => {
				// If page URL changes
				if (prevProps.location.pathname !== this.props.location.pathname) {
					// Handle page URL changes
					await this.handleUrlChange(
						this.getItemUrlId(undefined, prevProps),
						ltrimString(this.getCurrentRouterPath(prevProps), this.getOption('routerPath')),
						prevProps.location
					);

					// Handle page URLs
					await this.handleSubUrl(
						ltrimString(this.getCurrentRouterPath(), this.getOption('routerPath')),
						get(this.props, 'location')
					);
					if (this.isCreateUrl()) this.urlComponentGUIID = await this.handleCreateUrl();
					else if (this.isItemUrl()) this.urlComponentGUIID = await this.handleItemUrl(this.getItemUrlId());
					else if (this.isBaseUrl()) this.urlComponentGUIID = await this.handleBaseUrl();
					else this.urlComponentGUIID = await this.handleUnknownUrl();
				}
			})
			.then(() => this.state);
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		this.closeUrlComponent();
	}


	// I18n -------------------------------------------------------------------------------------------------------------
	/**
	 * Update dynamic translations
	 * @description Dynamic translations are translations that are not called in component's 'render' or
	 * 'componentDidUpdate' methods. Since automatic translation works by updating the component when locale changes,
	 * only values translated in 'render' and 'componentDidUpdate' methods will be automatically translated. All other
	 * translations need to be handled manually using this method.
	 * @note This method will be called after locale change has been handled.
	 *
	 * @param {object} translation - Currently loaded translation object.
	 */
	updateDynamicTranslations(translation) {
		// Update browser title translation
		if (isset(this.state.title)) this.setBrowserTitle(this.state.title);
	}


	// ACL methods ------------------------------------------------------------------------------------------------------
	/**
	 * Check if page can be accessed using ACL
	 * @note Permissions should be defined in 'permissions' option, and it should usually get the value from page config.
	 * @return {boolean}
	 */
	canAccess() {
		/** @type {string[]} pagePermissions */
		const pagePermissions = this.getOption('permissions');

		// Allow access if there are no permissions defined for this page
		if (!pagePermissions || (Array.isArray(pagePermissions) && pagePermissions.length === 0)) return true;
		// Check if access is allowed if there are permissions defined for this page
		// @note Page permissions are checked against currently available permissions saved in storage.
		else ACL.checkPermission(pagePermissions);
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 */
	loadPageData() {
		// Implement this method in the component that extends this abstract component
	}


	// Router methods ---------------------------------------------------------------------------------------------------
	// @note By default, pages have two predefined sub-urls: 'create' and 'item'. These can be used in any way. The
	// benefit of using these is that there are a few helper methods like 'redirectToCreate' and 'redirectToItem' built
	// around them to make creating standardized pages easier.
	/**
	 * Get page URL params parsed as key-value object
	 * @note URL params are everything after '?' character in the URL.
	 * @return {Object}
	 */
	getUrlParams() { return decodeURLParams(getString(this.props, 'location.search')); }

	/**
	 * Get a single URL param value
	 * @note URL params are everything after '?' character in the URL.
	 *
	 * @param {string} param - Param name to get the value for.
	 * @param {string} [defaultValue=''] - Default value if param does not exist.
	 * @return {string}
	 */
	getUrlParam(param, defaultValue = '') { return getString(this.getUrlParams(), param, defaultValue); }
	
	/**
	 * Get current router path name
	 * @note Pages must be connected to the router so that they will receive location data.
	 * @param {Object} [props] - Props to use for getting location.
	 * @return {string}
	 */
	getCurrentRouterPath(props) { return getString(props ? props : this.props, 'location.pathname'); }

	/**
	 * Get GUI ID of the component used to handle a url or sub-url if such component exists.
	 * @return {string}
	 */
	getUrlComponentGUIID() { return this.urlComponentGUIID; }

	/**
	 * Clear GUI ID of the component used to handle a url or sub-url.
	 */
	clearUrlComponentGUIID() { this.urlComponentGUIID = ''; }

	/**
	 * Check if current URL is this pages base URL
	 * @see options.routerPath
	 * @return {boolean}
	 */
	isBaseUrl() { return (this.getCurrentRouterPath() === this.getOption('routerPath')); }

	/**
	 * Check if current URL is this pages 'create' sub-url
	 * @note Create sub-url uses '/new' router path relative to the router path of the page (see 'options.routerPath').
	 * @return {boolean}
	 */
	isCreateUrl() { return (this.getCurrentRouterPath() === `${this.getOption('routerPath')}/new`); }

	/**
	 * Check if current URL is this pages 'item' sub-url
	 * @note Item sub-url uses '/item' router path and 'id' as router path param ('/item/:id') on top of to the router
	 * path of the page (see 'options.routerPath').
	 * @return {boolean}
	 */
	isItemUrl() {
		return !!matchPath(
			this.getCurrentRouterPath(), {path: `${this.getOption('routerPath')}/item/:id`, exact: false}
		);
	}

	/**
	 * Get item ID from URL
	 * @param {'integer'|'string'} resultDataType - Data type of the detected URL ID.
	 * @param {Object} [props] - Props to use for getting location.
	 * @return {number|string}
	 */
	getItemUrlId(resultDataType = page_url_item_id_type, props) {
		if (resultDataType === 'integer') {
			return getInteger(
				matchPath(
					this.getCurrentRouterPath(props),
					{path: `${this.getOption('routerPath')}/item/:id`, exact: false}),
				'params.id',
				undefined
			);
		} else {
			return getString(
				matchPath(this.getCurrentRouterPath(props),
					{path: `${this.getOption('routerPath')}/item/:id`, exact: false}),
				'params.id',
				undefined
			);
		}
	}

	/**
	 * Get router 'to' value of the app's home page
	 * @return {string}
	 */
	getHomeRedirectTo() { return app_home_page_router_path; }
	
	/**
	 * Get router 'to' value of the pages base URL
	 * @return {string}
	 */
	getBaseRedirectTo() { return this.getOption('routerPath'); }

	/**
	 * Get router 'to' value of the pages 'create' sub-url
	 * @note Create sub-url uses '/new' router path relative to the router path of the page (see 'options.routerPath').
	 * @return {string}
	 */
	getCreateRedirectTo() { return `${this.getOption('routerPath')}/new`; }

	/**
	 * Get router 'to' value of the pages 'item' sub-url
	 * @note Item sub-url uses '/item' router path and 'id' as router path param ('/item/:id') on top of to the router
	 * path of the page (see 'options.routerPath').
	 *
	 * @param {string} id - Item ID.
	 * @return {string}
	 */
	getItemRedirectTo(id) { return `${this.getOption('routerPath')}/item/${id}`; }

	/**
	 * Redirect to any router path
	 * @param {string} to - Router path (like react router Link component 'to' prop).
	 */
	redirectTo(to) { this.props.history.push(to); }

	/**
	 * Redirect to app's home page
	 * @see app config 'app_home_page_router_path'.
	 */
	redirectToHome() { this.props.history.push(this.getHomeRedirectTo()); }

	/**
	 * Redirect to pages base url
	 * @see options.routerPath
	 */
	redirectToBase() { this.props.history.push(this.getBaseRedirectTo()); }

	/**
	 * Redirect to pages 'create' sub-url
	 * @note Create sub-url uses '/new' router path relative to the router path of the page (see 'options.routerPath').
	 */
	redirectToCreate() { this.props.history.push(this.getCreateRedirectTo()); }

	/**
	 * Redirect to pages 'create' sub-url
	 * @note Item sub-url uses '/item' router path and 'id' as router path param ('/item/:id') on top of to the router
	 * path of the page (see 'options.routerPath').
	 * @param {string} id - Item ID.
	 */
	redirectToItem(id) { this.props.history.push(this.getItemRedirectTo(id)); }

	/**
	 * Method that will be called for each page sub-url
	 *
	 * @param {string} subUrlPath - Current page sub-url path or empty string if on page base path.
	 * @param {Object} location - Current router location object.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'create' sub-url if such component exists.
	 */
	handleSubUrl(subUrlPath, location) {
		// Implement this method in the component that extends this abstract component
		return '';
	}

	/**
	 * Method that will be called if current URL matches the 'create' sub-url of the page
	 * @note Create sub-url uses '/new' router path relative to the router path of the page (see 'options.routerPath').
	 *
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'create' sub-url if such component exists.
	 */
	handleCreateUrl() {
		// Implement this method in the component that extends this abstract component
		return '';
	}

	/**
	 * Method that will be called if current URL matches the 'item' sub-url of the page
	 * @note Item sub-url uses '/item' router path and 'id' as router path param ('/item/:id') on top of to the router
	 * path of the page (see 'options.routerPath').
	 *
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'item' sub-url if such component exists.
	 */
	handleItemUrl(id) {
		// Implement this method in the component that extends this abstract component
		return '';
	}

	/**
	 * Method that will be called if current URL matches the base URL of the page
	 *
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on its 
	 * base URL if such component exists.
	 */
	handleBaseUrl() {
		// Implement this method in the component that extends this abstract component
		return '';
	}

	/**
	 * Method that will be called if current URL is an unknown page URL
	 *
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page URL is 
	 * unknown if such component exists.
	 */
	handleUnknownUrl() {
		// Implement this method in the component that extends this abstract component
		return '';
	}

	/**
	 * Method that will be called when page URL changes
	 * @param {string|number} prevItemUrlId - Previous item URL ID if any.
	 * @param {string} prevSubUrlPath - Previous page sub-url path.
	 * @param {Object} prevLocation - Previous router location object.
	 */
	handleUrlChange(prevItemUrlId, prevSubUrlPath, prevLocation) {
		// Implement this method in the component that extends this abstract component
	}

	/**
	 * Method that will be called when page component unmounts and should handle closing of any page url or sub-url
	 * component if it exists.
	 */
	closeUrlComponent() {
		// Implement this method in the component that extends this abstract component
	}


	// Title methods ----------------------------------------------------------------------------------------------------
	/**
	 * Set page browser title only
	 * @note Page browser title is calculated based on specified title and app config.
	 *
	 * @param {string|null} [title] - Page browser title to set.
	 */
	setBrowserTitle(title) {
		document.title = (
			title === '' ? '' :
				!title ? (browser_title_prefix ? this.translatePath(browser_title_prefix) : this.translatePath(app_title)):
					this.translatePath(browser_title_prefix)
					+ browser_title_prefix_separator
					+ this.translate(title, this.titlePathPrefix)
		);
	}

	/**
	 * Set page layout title only
	 *
	 * @param {string|null} [title] - Page layout title to set.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	setPageTitle(title) { return this.setState({title}); }

	/**
	 * Set page title
	 * @note This method will also set the browser title.
	 *
	 * @param {string|null} [title] - Page title to set.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	setTitle(title) {
		return this.setPageTitle(title).then(state => {
			if (isset(state.title)) this.setBrowserTitle(state.title);
			return state;
		});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render page title
	 * @description This method specifies how page title will be rendered if page title should be rendered. It does not
	 * determine if page title should be rendered.
	 * @return {JSX.Element}
	 */
	renderPageTitle() {
		const {title} = this.state;
		
		return (
			<h1 className="page-title">
				{title ? this.translate(title, this.titlePathPrefix) : ''}
			</h1>
		);
	}
	
	/**
	 * Render page with appropriate layout
	 * @note This method should be used in actual page component's 'render' method to properly render the page.
	 *
	 * @param {any} content - Page content
	 * @param {string} [className=''] - Additional layout element CSS class name.
	 * @param {Object|string} [style={}] - Layout element inline CSS style.
	 * @param {Object} [otherLayoutProps={}] - Other props that will be sent to the layout component.
	 * @return {JSX.Element|null} Rendered page with appropriate layout.
	 */
	renderLayout(content, className = '', style = {}, otherLayoutProps = {}) {
		if (!this.state.layout) return null;
		const {title} = this.state;
		const styleObject = (typeof style === 'string' ? cssStyleStringToObject(style) : style);

		const PageLayout = this.state.layout;
		return (
			<CoreLayout>
				<PageLayout
					pageTitle={title ? this.translate(title, this.titlePathPrefix) : ''}
					className={className}
					style={styleObject}
					{...otherLayoutProps}
				>
					{
						this.getOption('forceRenderTitle') || (this.getOption('renderTitle') && title) ?
							this.renderPageTitle() 
							: null
					}
					<div className={`page-content`}>
						{content}
					</div>
				</PageLayout>
			</CoreLayout>
		);
	}
}

// Type definitions
/**
 * @typedef {Object} PageDataComponentOptions
 * @property {string} [translationPath] - Path inside the translation JSON file where component translations are
 * defined.
 * @property {string} [domPrefix='base-component'] - Prefix used for component's main DOM element. This is used in
 * methods like 'getDomId'.
 * @property {boolean} [forceLinearState=false] - Flag that determines if set state will use the linear mode. Linear
 * mode uses the 'setStateQueue' to ensure that 'setState' methods will be executed linearly. This means that
 * consequent 'setState' calls will run only after all previous 'setState' calls are finished setting the state.
 * @property {boolean} [forceFastState=false] - Flag that determines if set state queue will use the fast mode.
 * WARNING: Set state queue fast mode does not guarantee linear set state for non async set state calls, for example
 * if linear set state method is called within a for loop. Async calls should work properly.
 * @property {number} [domManipulationIntervalTimeout=0] - Timeout in ms (milliseconds) for DOM manipulation interval.
 * If less than zero DOM manipulation interval will be disabled.
 * @property {boolean} [optimizedUpdate=false] - Flag that determines if set component will skip updates if both props
 * and state are equal.
 * @property {boolean} [forceFastLoad=false] - Flag that determines if load queue will use the fast mode. WARNING: Load
 * queue fast mode does not guarantee linear loads for non async load calls, for example if load method s called within
 * a for loop. Async calls should work properly.
 * @property {boolean} [disableLoad=false] - Flag that determines if load functionality is disabled. If true 'load'
 * method will not load data from props into local state.
 * @property {boolean} [enableLoadOnDataPropChange=false] - Flag that determines if data will be loaded from props to
 * local state every time data prop changes. This flag will be ignored if 'disableLoad' is true.
 * @property {string} [dataPropAlias=''] - Main data prop alisa. This is used by child components that need to have a
 * different prop field for main data, like input components that use 'value' instead of 'data'.
 * @property {string} [originalDataPropAlias=''] - Original data prop alisa. This is used by child components that need
 * to have a different prop field for original data, like input components that use 'originalValue' instead of
 * 'originalData'.
 * @property {CustomTypeOptions} [customType={}] - Custom type options if component should use custom type as main data.
 * @property {boolean} [wholePropAsData=false] - Flag that determines if whole props will be used as main data on load
 * instead of 'data' prop or 'dataPropAlias' options.
 * @property {string} [layout] - Page layout. If not specified, default layout from config will be used (see
 * '/src/config/app.js' file 'default_layout' option).
 * @property {boolean} [forceRenderTitle=false] - Flag that determines if page title layout element should be rendered
 * even if page title is empty, null or not defined (undefined).
 * @property {boolean} [renderTitle=true] - Flag that determines if page title should be rendered if title is defined.
 * @property {string} [routerPath=''] - Page router path.
 * @property {boolean} [scrollToTopOnMount=true] - Scroll to the top of the page on page mount.
 * @property {string} [scrollToSelectorOnMount=''] - Scroll to the DOM element defined by this CSS selector.
 * @property {Array|null} [permissions=null] - List of permissions associated with the page. Permissions check method is
 * defined in 'acl_check_mode' acl config option.
 */

export default PageDataComponent;
export {executeComponentCallback, executeComponentCallbackPromise} from "./BaseComponent";