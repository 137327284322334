// TODO: Move report GUIID chase change (see uppercaseGUIID(reportData); and lowercaseGUIID(reportData);) to map functions

import {cloneDeep, find} from "lodash";
import {ReportChartDataObject} from "../../../dataObjects/report";
import {getColumnByName, getColumnName} from "../helper";
import {isChartReport} from "../../../helpers/report";
import {getArray, getString} from "../../../core/helpers/data";
import {REPORT_OUTPUT_TYPE_TO_REPORT_CHART_TYPE} from "../../../const/report";

/**
 * Get input data from raw data
 * @param {ReportDataObject} rawData - Raw data received from IO.
 * @return {ReportDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);
	const chart = result.chart ? result.chart : new ReportChartDataObject();
	
	result = {
		...result,
		chart: {
			...chart,
			seriesList: getArray(chart, 'seriesList').map(s => ({
				...s,
				// Convert axisColumnX column name to select column GUIID
				axisColumnX: getString(getColumnByName(s.axisColumnX, result.selectColumns), 'GUIID'),
				// Convert axisColumnY column name to select column GUIID
				axisColumnY: getString(getColumnByName(s.axisColumnY, result.selectColumns), 'GUIID'),
				// TODO: For now series filter is cleared since global filter will be cloned to the series filter on output
				filter: null,
			}))
		}
	};

	if (isChartReport(result.outputType) && result.chart) {
		// TODO: For now clone the global filter from the first (an only) series
		result.filter = getArray(rawData, 'chart.seriesList[0].filter');
	}
	
	return result;
};

/**
 * Get output data from input data
 * @param {ReportDataObject} inputData - Input data.
 * @return {ReportDataObject}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);
	
	if (isChartReport(result.outputType) && result.chart) {
		result.chart.chartType = REPORT_OUTPUT_TYPE_TO_REPORT_CHART_TYPE[result.outputType];
		result.chart.seriesList = getArray(result.chart, 'seriesList').map(s => ({
			...s,
			// Convert axisColumnX select column GUIID to column name
			axisColumnX: getColumnName(find(result.selectColumns, {GUIID: s.axisColumnX})),
			// Convert axisColumnY select column GUIID to column name
			axisColumnY: getColumnName(find(result.selectColumns, {GUIID: s.axisColumnY})),
			// TODO: For now the filer is cloned from the main filter for presentation purposes. Proper series filters must be implemented.
			filter: cloneDeep(result.filter),
		}));
		// Clear global filter since it was copied to the only series filter we have right now
		// TODO: For now we only have one series.
		result.filter = null;
	} else {
		result.chart = null;
	}
	
	return result;
};