import styles from "./index.module.css";
import "./index.css";

import React from "react";
import DialogComponent, {DIALOG_TYPE} from "../../DialogComponent";
import {cloneDeep} from "lodash";
import {focusSelector} from "../../../helpers/dom";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../display/Button";
import {getStringForDisplay} from "../../../helpers/data";
import Label from "../../display/Label";
import {icon_font_stack_class} from "../../../../config";
import {blinkBackgroundSlowAnimation} from "../../../helpers/animation";
import TextareaInput from "../../input/TextareaInput";
import PropTypes from "prop-types";

/**
 * Dialog used to display an error with the full stacktrace
 */
class StacktraceDialog extends DialogComponent {
	constructor(props) {
		super(props, {
			translationPath: `StacktraceDialog`,
			dialogType: DIALOG_TYPE.MESSAGE,
		});

		this.initialState = {
			wordWrap: false,
		};
		this.state = cloneDeep(this.initialState);
	}

	componentDidMount() {
		super.componentDidMount();

		// Set focus to the copy button because it is used frequently
		focusSelector(`.${styles['wrapper']} #copy-button`);
	}

	/**
	 * Render dialog action buttons
	 * @note Low-level method to render any dialog action buttons.
	 *
	 * @param {DialogButton[]} buttons - Array of buttons to render.
	 * @param {string} [className=''] - Buttons wrapper additional CSS class.
	 * @return {*} Action buttons JSX to use in the main render method.
	 */
	renderActionButtons(buttons = [], className = '') {
		return (
			buttons.length > 0 ?
				<div className={`message-dialog-buttons ${className}`}>
					{buttons.map((button, index) =>
						<Button
							key={index}
							big={true}
							icon={button.icon}
							displayType={button.type ? button.type : BUTTON_DISPLAY_TYPE.SOLID}
							displayStyle={button.style ? button.style : BUTTON_STYLE.DEFAULT}
							onClick={button.onClick}
						>
							{button.label ? button.label : null}
						</Button>
					)}
				</div>
				:
				null
		);
	}

	render() {
		const {errorCode, errorMessage, errorStacktrace} = this.props;
		const {wordWrap} = this.state;

		return this.renderDialog(
			this.renderCustomIconTitle('bug'),
			(
				<div className={`report-debug-dialog-component ${styles['wrapper']}`}>
					<div className={`${styles['row']}`}>
						<Label
							content={`${this.t('errorCode')}:`}
							element="strong"
							elementProps={{className: `${styles['label']}`}}
						/>
						<Label content={getStringForDisplay(errorCode)} element="span" />
					</div>
					<div className={`${styles['row']}`}>
						<Label
							content={`${this.t('errorMessage')}:`}
							element="strong"
							elementProps={{className: `${styles['label']}`}}
						/>
						<Label content={getStringForDisplay(errorMessage)} element="span" />
					</div>
					<Label content={`${this.t('errorStacktrace')}:`} element="strong" />
					<div className={`toolbar standard ${styles['stacktraceToolbar']}`}>
						<Button
							iconProps={{
								symbolPrefix: 'icofont-',
								stackClassName: `${icon_font_stack_class} word-wrap`
							}}
							icon={['align-left', 'undo']}
							displayStyle={wordWrap ? BUTTON_STYLE.ACTION : BUTTON_STYLE.NONE}
							onClick={() => { this.setState({wordWrap: !wordWrap}) }}
						/>
						<Button
							id="copy-button"
							icon="copy"
							displayStyle={BUTTON_STYLE.NONE}
							onClick={() => {
								if (this.stackTraceRef) {
									this.stackTraceRef.inputRef.select();
									document.execCommand("copy");
									this.stackTraceRef.inputRef.setSelectionRange(0, 0);
									blinkBackgroundSlowAnimation(this.stackTraceRef.inputRef);
								}
							}}
						/>
					</div>
					<TextareaInput
						value={errorStacktrace} readOnly={true}
						className={`${styles['stacktrace']} ${wordWrap ? styles['wrap'] : ''}`}
						ref={node => { this.stackTraceRef = node; }}
					/>
				</div>
			),
			this.t('Close', 'general')
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
StacktraceDialog.propTypes = {
	// Unique GUI ID of the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogGUIID: PropTypes.string,
	// Dialog options
	// @note This is automatically sent by the global Dialog component.
	dialogOptions: PropTypes.object,
	// Action used to close the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogCloseAction: PropTypes.func,

	// Debug data
	errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	errorMessage: PropTypes.string,
	errorStacktrace: PropTypes.string,

	// Events
	onClose: PropTypes.func
};

/**
 * Use this const as a 'className' option on the dialog when opening it in order to get the default style.
 * @type {string}
 */
export const stacktraceDialogClassName = 'stacktrace-dialog';
export default StacktraceDialog;