import PropTypes from "prop-types";
import PopupComponent, {PopupActionDataObject, PopupTabDataObject} from "../../../../core/components/PopupComponent";
import {connect} from "react-redux";
import * as pageConfig from "../../config";
import {cloneDeep, get, omit, orderBy} from "lodash";
import {getPageActions} from "../../../../core/helpers/redux";
import * as actions from "../../actions";
import {selectors} from "../../../../core/store/reducers";
import {icon_font_close_symbol, icon_font_delete_symbol, icon_font_save_symbol} from "../../../../config";
import {BUTTON_STYLE} from "../../../../core/components/display/Button";
import {getString, isset} from "../../../../core/helpers/data";
import {isSuccessful} from "../../../../core/helpers/io";
import * as dataMap from "../../dataMap/data";
import ACL from "../../../../acl";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	isNew: !get(selectors.schedule.getScheduleItem(state), 'id'),
	scheduleItem: dataMap.input(selectors.schedule.getScheduleItem(state)),
});

class SchedulePopup extends PopupComponent {
	/**
	 * IMPORTANT! Must be defined in components that extend this abstract component like this:
	 * dirname = __dirname;
	 *
	 * @note This is done in order for automatic tab component loading to work properly.
	 */
	dirname = __dirname;
	
	constructor(props) {
		super(props, {
			translationPath: `${pageConfig.translationPath}.SchedulePopup`,
			domPrefix: 'schedule-page-schedule-popup',
			hideSingleTab: true,
		});

		this.initialState = {
			/**
			 * List of all popup tabs
			 * @type {PopupTabDataObject[]}
			 */
			tabs: [],

			/**
			 * List of all popup actions
			 * @type {PopupActionDataObject[]}
			 */
			actions: [],

			/**
			 * ID of the currently opened tab
			 * @type {string}
			 */
			currentTabId: '',
		};

		this.state = cloneDeep(this.initialState);

		// Tab methods
		this.dynamicActionButtons = this.dynamicActionButtons.bind(this);

		// Action methods
		this.saveScheduleItem = this.saveScheduleItem.bind(this);
		this.saveScheduleItemAndClose = this.saveScheduleItemAndClose.bind(this);
		this.deleteScheduleItem = this.deleteScheduleItem.bind(this);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		// If schedule item new status changes
		if (this.props.isNew !== prevProps.isNew) {
			// Update dynamic action buttons ('create' and 'update') because they depend on new status
			this.dynamicActionButtons().then();
		}
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return 'schedule-page-schedule-popup'; }


	// Tab methods ------------------------------------------------------------------------------------------------------
	/**
	 * Update dynamic action buttons that depend on current state and props
	 * @return {Promise<void>}
	 */
	async dynamicActionButtons() {
		const {isNew} = this.props;
		
		// Remove global actions before deciding which ones to display
		await this.removeActions(['create', 'update', 'update_and_close']);

		// Add create/update global action
		if (ACL.checkPermission(['AUTOMATIC_REPORT_SCHEDULE_CREATE'])) {
			await this.addAction(
				new PopupActionDataObject({
					id: (isNew ? 'create' : 'update'),
					action: this.saveScheduleItem,
					buttonProps: {
						label: (isNew ? 'SchedulePage.create_new' : 'general.Save'),
						icon: (isNew ? "calendar-plus-o" : icon_font_save_symbol),
						displayStyle: BUTTON_STYLE.ACTION
					},
					ordinal: 2
				})
			);
		}
		
		// Add update and close global action
		if (!isNew) {
			if (ACL.checkPermission(['AUTOMATIC_REPORT_SCHEDULE_CREATE'])) {
				await this.addAction(
					new PopupActionDataObject({
						id: 'update_and_close',
						action: this.saveScheduleItemAndClose,
						buttonProps: {
							label: this.getTranslationPath('update_and_close'),
							icon: icon_font_save_symbol,
							displayStyle: BUTTON_STYLE.ACTION
						},
						ordinal: 3
					})
				);
			}
			
			if (ACL.checkPermission(['AUTOMATIC_REPORT_SCHEDULE_DELETE'])) {
				await this.addAction(
					new PopupActionDataObject({
						id: 'delete',
						action: this.deleteScheduleItem,
						buttonProps: {
							label: 'general.Delete',
							icon: icon_font_delete_symbol,
							displayStyle: BUTTON_STYLE.ERROR
						},
						ordinal: 1
					})
				);
			}
		}
	}
	
	/**
	 * Initialize popup by specifying initial tabs, actions and current tab
	 * @note If current tab is not set it will default to the first visible and valid tab. Valid tab is tab that has
	 * 'component' property specified (manually or automatically loaded).
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	async init() {
		// Add static actions that don't depend on current state or props
		await this.addAction(
			new PopupActionDataObject({
				id: 'close',
				action: this.close,
				buttonProps: {
					label: 'general.Close',
					icon: icon_font_close_symbol
				},
				ordinal: 0
			}),
		);
		// Add dynamic actions that depend on current state or props
		await this.dynamicActionButtons();

		// Add tabs
		await this.setTabs([
			new PopupTabDataObject({
				id: 'MainTab',
			}),
		]).then(this.importTabComponents);

		return Promise.resolve(this.state);
	}

	/**
	 * Try to automatically load tab components from standard location for tabs that don't have components defined
	 * @note To automatically load tab components the need to be located in a 'tabs' subdirectory either as a component
	 * file (like ./tabs/InfoTab.js) or subdirectory with index file (./tabs/InfoTab/index.js) where directory name or
	 * filename must be the tab ID.
	 *
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	importTabComponents() {
		const tabs = orderBy(this.getSortedTabs(), ['preloadPriority'], ['desc']);
		return Promise.all(tabs.map(tab => {
			if (!isset(tab.component)) return this.handleTabComponentImport(tab, import(`./tabs/${tab.id}`));
			else return Promise.resolve(this.state);
		}));
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Save schedule item
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'create'|'update'} actionId - ID of the clicked action.
	 * @return {Promise<*>}
	 */
	saveScheduleItem(allTabsData, event, actionId) {
		const {redirectToItem, createScheduleItemAction, updateScheduleItemAction, addSuccessMessageAction} = this.props;
		if (actionId === 'create') {
			return this.getTabRef('MainTab').validateTab()
				.then(valid => {
					if (valid) {
						return this.executeAbortableAction(
							createScheduleItemAction,
							dataMap.output(omit(get(allTabsData, 'MainTab'), ['id', 'reportTemplateDataList']))
						)
							// Redirect to item URL if item was created successfully and show success message
							.then(responseData => {
								if (isSuccessful(responseData)) {
									redirectToItem(get(responseData, 'id'));
									addSuccessMessageAction(this.t('create_schedule_success_msg'), 5);
								}
							});
					}
				})
		} else if (actionId === 'update') {
			return this.getTabRef('MainTab').validateTab()
				.then(valid => {
					if (valid) {
						return this.executeAbortableAction(
							updateScheduleItemAction,
							getString(allTabsData, 'MainTab.id', '', true),
							dataMap.output(omit(get(allTabsData, 'MainTab'), ['id', 'reportTemplateDataList']))
						)
							// Show success message
							.then(responseData => {
								if (isSuccessful(responseData)) {
									addSuccessMessageAction(this.t('update_schedule_success_msg'), 5);
								}
							});
					}
				});
		}
		return Promise.resolve();
	}

	/**
	 * Save schedule item and close the popup
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'update_and_close'} actionId - ID of the clicked action.
	 * @return {Promise<*>}
	 */
	saveScheduleItemAndClose(allTabsData, event, actionId) {
		return this.saveScheduleItem(allTabsData, event, 'update')
			.then(this.close);
	}

	/**
	 * Delete schedule item and close the popup
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'update_and_close'} actionId - ID of the clicked action.
	 * @return {Promise<*>}
	 */
	deleteScheduleItem(allTabsData, event, actionId) {
		const {deleteScheduleItem} = this.props;
		const scheduleItemId = get(allTabsData, 'MainTab.id');
		if (scheduleItemId) return deleteScheduleItem(scheduleItemId);
		return Promise.resolve();
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
SchedulePopup.propTypes = {
	// Router history objects
	// @note Can be used to redirect to any route. 
	history: PropTypes.object,
	// Function that will redirect to item URL
	redirectToItem: PropTypes.func, // Arguments: {string|number} - Item id to redirect to.
	// Function that will delete a schedule item
	deleteScheduleItem: PropTypes.func, // Arguments: {string|number} - Item id top delete.

	// Events
	onClose: PropTypes.func,
	onGlobalAction: PropTypes.func,
	onTabAction: PropTypes.func,
};

export default connect(mapStateToProps, getPageActions(actions))(SchedulePopup);