/**
 * Dynamic value group data object
 * @note This object does not contain groups children. 
 */
export class DynamicValueGroupDataObject {
	/**
	 * @param {string|number} id - Dynamic value group ID.
	 * @param {string} name - Dynamic value group name.
	 */
	constructor(id, name) {
		this.id = id;
		this.name = name;
	}
}

/**
 * Dynamic value data object
 * @note This object contains dynamic value group data of the group that it belongs to.
 */
export class DynamicValueDataObject {
	/**
	 * @param {string|number} id - Dynamic value ID.
	 * @param {string} name - Dynamic value name.
	 * @param {string} description - Dynamic value description.
	 * @param {DynamicValueDisplayType} displayType - Dynamic value display type.
	 * @param {DynamicValueAggregation} aggregateFunction - Dynamic value aggregation function.
	 * @param {DynamicValueDataFormatDataObject} defaultDataFormat - Dynamic value default data format.
	 * @param {DynamicValueGroupDataObject} group - Dynamic value group that this dynamic value belongs to.
	 */
	constructor(id, name, description, displayType, aggregateFunction, defaultDataFormat, group) {
		this.id = id;
		this.name = name;
		this.description = description;
		this.displayType = displayType;
		this.aggregateFunction = aggregateFunction;
		this.defaultDataFormat = defaultDataFormat;
		this.group = group;
	}
}

/**
 * Dynamic value data object
 * @note This object contains dynamic value data format information.
 */
export class DynamicValueDataFormatDataObject {
	/**
	 * @note Display type is not controlled by this data object and it should not be aware of it. When you use this data 
	 * object be sure to take the display type into consideration.
	 * 
	 * @param {string|null} [dataFormat=null] - Format used for data. This depends on display type. 
	 * @param {boolean|null} [thousandsSeparator=null] - Flag that specifies if thousands separator should be used. This 
	 * applies ony if display type is a numeric.
	 * @param {number|null} [decimals=null] - Number of decimals used to format the number. This applies ony if display 
	 * type is a numeric.
	 */
	constructor(dataFormat = null, thousandsSeparator = null, decimals = null) {
		this.dataFormat = dataFormat;
		this.thousandsSeparator = thousandsSeparator;
		this.decimals = decimals;
	}
}