import styles from "./index.module.css";

import React from "react";
import BaseComponent, {executeComponentCallback} from "../../BaseComponent";
import PropTypes from "prop-types";
import {omit} from "lodash";
import {getString} from "../../../helpers/data";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_DISPLAY_TYPES, BUTTON_STYLES} from "../../display/Button";
import mime from "mime-types";
import {openDialog} from "../../../helpers/dialog";
import MessageDialog from "../../dialogs/MessageDialog";
import {MESSAGE_STYLE} from "../../global/Message/const";
import {icon_font_close_symbol} from "../../../../config";

/**
 * File input component
 * @description Basic file input component supported by the DataComponent 'handleFileInputChange' method.
 * @note This is a controlled component which means it does not maintain it's own state and value is controlled by the
 * parent component.
 */
class FileInput extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: 'FileInput',
			domPrefix: 'file-input-component',
		});

		// Validation
		this.validateFiles = this.validateFiles.bind(this);

		// Data change handling methods
		this.handleChange = this.handleChange.bind(this);

		// DOM methods
		this.clear = this.clear.bind(this);
	}

	
	// Validation -------------------------------------------------------------------------------------------------------
	/**
	 * Validate mime-type and size of all files
	 * @param {File[]} files - Array of input files.
	 */
	validateFiles(files) {
		const {maxSize} = this.props;
		
		for (let i = 0; i < files.length; i++) {
			// Validate file size
			if (maxSize > 0 && files[i].size > maxSize) return false;
		}
		
		return true;
	}


	// Data change handling methods -------------------------------------------------------------------------------------
	/**
	 * Handle file input change
	 * @note This method will trigger 'onChange' event if component is not disabled.
	 * @param {Event} event - DOM element's event object.
	 */
	handleChange(event) {
		const {fieldName, additionalData, disabled, maxSize} = this.props;
		if (!disabled) {
			if (this.validateFiles(event.target?.files || [])) {
				executeComponentCallback(this.props.onChange, event, fieldName, additionalData);
			} else {
				this.clear();
				openDialog(
					'', 
					MessageDialog,
					{
						message: {
							style: MESSAGE_STYLE.ERROR,
							content: this.t(
								'Selected file is too big! Maximum file size is %{bytes} bytes.',
								undefined, 
								undefined, 
								{bytes: `${maxSize}`}
							)
						},
						allowHtml: true,
					}, {
						closeOnClickOutside: true,
						closeOnEscape: true,
						hideCloseBtn: true,
						maxWidth: 500
					}
				);
			}
		}
	}


	// DOM methods ------------------------------------------------------------------------------------------------------
	/**
	 * Clear file input value
	 * @note This method can be called by the 'clearFileInputValue' method of the DataComponent or any other 
	 * component extending DataComponent.
	 */
	clear() { if (this.wrapperRef) this.wrapperRef.value = ''; }
	

	// Render methods ---------------------------------------------------------------------------------------------------
	renderInput() {
		const {
			className, formControlStyle, name, acceptExtensions, multiple, disabled, buttonOnly, buttonOnlyProps
		} = this.props;
		
		return (
			<input
				type="file"
				id={this.getDomId()}
				className={
					`${this.getOption('domPrefix')} ${formControlStyle ? 'form-control' : ''} ${styles['input']} ` +
					`${className} ${buttonOnly ? styles['buttonOnly'] : ''}`
				}
				name={name}
				title={getString(buttonOnlyProps, 'title')}
				multiple={multiple}
				disabled={disabled}
				accept={acceptExtensions.map(mt => mime.lookup(mt)).join(', ')}
				onChange={this.handleChange}
				ref={this.setWrapperRef}
			/>
		);
	}
	
	render() {
		const {className, buttonOnly, buttonOnlyProps, showClearButton} = this.props;
		return (
			buttonOnly ?
				<div className={`${className ? `${className}-wrapper` : ''} ${styles['buttonOnlyWrapper']}`}>
					<Button
						className={`${getString(buttonOnlyProps, 'className')} ${styles['button']}`}
						icon={getString(buttonOnlyProps, 'icon', 'upload')}
						{...omit(buttonOnlyProps, ['className', 'icon', 'title'])}
					/>
					{this.renderInput()}
				</div>
				:
				<div className={`${className ? `${className}-wrapper` : ''} ${styles['withClearWrapper']}`}>
					{this.renderInput()}
					{
						showClearButton ?
							<Button
								className={`${styles['clearButton']}`}
								displayType={BUTTON_DISPLAY_TYPE.NONE}
								icon={icon_font_close_symbol}
								onClick={() => {
									this.clear();

									// Trigger change
									let ev = new Event('change', { bubbles: true });
									ev.simulated = true;
									this.wrapperRef.dispatchEvent(ev);
								}}
							/>
							: null
					}
				</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
FileInput.propTypes = {
	// Input element's id attribute
	id: PropTypes.string,
	// Input element's class attribute
	className: PropTypes.string,
	// Flag that determines if input will have a standard form control style
	formControlStyle: PropTypes.bool,
	// Input element's name attribute
	name: PropTypes.string,
	// Field name in returned FormData where file(s) will be stored
	fieldName: PropTypes.string,
	// Additional FormData values that will be added to the result
	additionalData: PropTypes.object,
	// Specify supported file extensions
	// @note If empty or not specified, all file types will be accepted. Mime-types will be calculated automatically. 
	acceptExtensions: PropTypes.array,
	// Maximal supported file size (in bytes) tha can be uploaded
	// @none If 0 or not specified, no file size restriction will be applied by this component. Please note that backend
	// size restrictions may apply regardless of this value.
	maxSize: PropTypes.number,
	// Flag that specifies if multiple file upload is allowed
	multiple: PropTypes.bool,
	// Flag that determines if the input should be disabled
	disabled: PropTypes.bool,
	// Flag that determines if only custom upload button will be visible instead of input
	buttonOnly: PropTypes.bool,
	// Custom upload button props props if 'buttonOnly' prop is true
	buttonOnlyProps: PropTypes.shape({
		// Button element 'id' attribute.
		id: PropTypes.string,
		// Button element CSS class attribute.
		className: PropTypes.string,
		// The default behavior of the button. Possible values are: 'submit', 'reset' or 'button'.
		type: PropTypes.string,
		// Button display type ('none', 'solid', 'transparent', ...)
		displayType: PropTypes.oneOf(BUTTON_DISPLAY_TYPES),
		// Button display style ('default', 'success', 'error', ...)
		displayStyle: PropTypes.oneOf(BUTTON_STYLES),
		// If true, bigger button will be rendered.
		big: PropTypes.bool,
		// The name of the button, submitted as a pair with the button’s value as part of the form data.
		name: PropTypes.string,
		// Defines the value associated with the button’s name when it’s submitted with the form data. This value is passed
		// to the server in params when the form is submitted.
		value: PropTypes.string,
		// This Boolean attribute specifies that the button should have input focus when the page loads. 
		// @note Only one element in a document can have this attribute.
		autofocus: PropTypes.bool,
		// This Boolean attribute prevents the user from interacting with the button: it cannot be pressed or focused.
		disabled: PropTypes.bool,
		// If true, button will not be rendered.
		hide: PropTypes.bool,
		// Button label rendered as a child of the <button> component before any other child elements but after the icon.
		label: PropTypes.string,
		// Set to true to support HTML in 'label' prop.
		// @warning Be careful when using this flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' 
		// to allow HTML content. 
		allowHtmlLabel: PropTypes.bool,
		// Font icon symbol name.
		icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
		// If true, icon will spin (if supported by font icon set used).
		spinIcon: PropTypes.bool,
		// Icon props
		// @see Icon component
		iconProps: PropTypes.object,

		// Events
		onClick: PropTypes.func,
	}),
	// Flag that determines if clear button will be rendered
	// @note This only works if 'buttonOnly' props is false.
	// TODO: Make this work for when 'buttonOnly' is true.
	showClearButton: PropTypes.bool,

	// Value change event
	// @description This event will be triggers when input value changes (file is selected).
	onChange: PropTypes.func, // Arguments: {Event} event, {string} fieldName, {Object} additionalData
};

/**
 * Define component default values for own props
 */
FileInput.defaultProps = {
	className: '',
	formControlStyle: true,
	name: '',
	fieldName: 'files',
	additionalData: {},
	acceptExtensions: [],
	maxSize: 0,
	multiple: false,
	disabled: false, 
	buttonOnly: false,
	showClearButton: false,
};

export default FileInput;