import {ioStandardUploadAction} from "../../core/store/actions/io";
import {prepareFilesForUpload} from "../../core/helpers/file";

/**
 * Read lines form a text file (usually CSV)
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {File} file - File to upload and parse (usually CSV).
 * @param {Object} [additionalData={}] - Additional FormData values that will be added.
 * @param {string} [fieldName='file'] - Field name in FormData where selected file(s) will be stored.
 * @return {(function(*))|*}
 */
export const readLinesFromFileAction = (
	abortCallback, file, additionalData = {}, fieldName = 'file'
) => dispatch => {
	return ioStandardUploadAction(
		abortCallback,
		'defaultAuthorizedApi',
		'utility/read-lines-from-file',
		prepareFilesForUpload(file, additionalData, fieldName)
	)(dispatch);
};