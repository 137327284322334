import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import DataComponent from "../../../core/components/DataComponent";
import SelectInput from "../../../core/components/input/SelectInput";
import {getArray, getBoolean, isset} from "../../../core/helpers/data";
import IconSingleValueComponent from "../../../core/components/input/SelectInput/singleValues/IconSingleValue";
import IconOptionComponent from "../../../core/components/input/SelectInput/options/IconOption";
import {getPageActions} from "../../../core/helpers/redux";

/**
 * Data source select component
 */
class DataSourceSelectInput extends DataComponent {
	constructor(props) {
		super(props, {
			/** List of data sources loaded from the codebook */
			dataSources: [],
			dataSourcesLoading: false,
		}, {
			disableLoad: true,
		});
	}

	componentDidMount(override = false) {
		return super.componentDidMount(override)
			.then(() => {
				const {options, fetchDataSourcesAction} = this.props;
				
				if (!isset(options)) {
					return this.setState({dataSourcesLoading: true})
						.then(() => this.executeAbortableAction(fetchDataSourcesAction))
						.then(response => {
							if (response) {
								return this.setState({dataSources: getArray(response, 'data')})
									.then(() => this.setState({dataSourcesLoading: false}))
							}
							return Promise.resolve(this.state);
						});
				} else {
					return this.setState({dataSources: getArray(options)});
				}
			});
	}

	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		return (
			<SelectInput
				primaryKey="id"
				options={getArray(this.state, 'dataSources')}
				isLoading={getBoolean(this.state, 'dataSourcesLoading')}
				getOptionLabel={o => o.name}
				getOptionValue={o => o.id}
				components={{Option: IconOptionComponent, SingleValue: IconSingleValueComponent}}
				labelProps={{symbol: 'database'}}
				singleValueProps={{symbol: 'database'}}
				{...this.props}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
DataSourceSelectInput.propTypes = {
	// The id to set on the SelectContainer component
	id: PropTypes.string,
	// Apply a className to the control
	className: PropTypes.string,
	// Apply classNames to inner elements with the given prefix
	classNamePrefix: PropTypes.string,
	// Disable the control
	isDisabled: PropTypes.bool,
	// Allow the user to select multiple values
	isMulti: PropTypes.bool,
	// Allow the user to search for matching options
	isSearchable: PropTypes.bool,
	// Flag that determines if input will have a standard form control style
	formControlStyle: PropTypes.bool,
	// Text to display when there are no options
	noOptionsMessage: PropTypes.func,
	// Placeholder for the select value
	placeholder: PropTypes.string,
	// Control the current value
	value: PropTypes.any,
	// Set the initial value of the control
	defaultValue: PropTypes.any,
	// If true, 'onChange' will return a simple value of the option defined in 'primaryKey'
	simpleValue: PropTypes.bool,
	// Is the select value clearable
	isClearable: PropTypes.bool,

	// Events
	onChange: PropTypes.func, // Arguments: selected option (object or value depending on 'simpleValue' prop)
	// ... react-select prop types (@link https://react-select.com/props)
};

/**
 * Define component default values for own props
 */
DataSourceSelectInput.defaultProps = {
	id: '',
	className: '',
	classNamePrefix: '',
	isDisabled: false,
	isMulti: false,
	isSearchable: true,
	formControlStyle: true,
	simpleValue: true,
	isClearable: false,
};

export default connect(null, getPageActions())(DataSourceSelectInput);