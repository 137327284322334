import {set} from 'lodash';
import {combineReducers} from 'redux';
import {current_user_storage_field_name, redux_storage_var} from "../../../config";

import * as breakpoint from "./breakpoint";
import * as i18n from '../../i18n/reducer';
import * as storage from '../../storage/reducer';
import * as dialog from '../../components/global/Dialog/reducer';
import * as popup from '../../components/global/Popup/reducer';
import * as message from '../../components/global/Message/reducer';
import * as overlay from './overlay';

// Import app specific reducers, selectors and action creators
import {
	reducers as appReducers, selectors as appSelectors, actionCreators as appActionCreators
} from "../../../store/reducers";

// All imported reducers must be added to this array
const reducerImports = [breakpoint, i18n, storage, dialog, popup, message, overlay];

// Define reducer types
export const REDUCER_TYPE = {
	// Resets all app reducers to the initial app state
	RESET: 'RESET',
};


let importedReducers = {...appReducers};
let importedSelectors = {...appSelectors};
let importedActionCreators = {...appActionCreators};
// Combine all imported reducers. Imported reducers must export 'reducerStoreKey' constant containing unique store key
// associated to that reducer.
reducerImports.forEach(reducerImport => {
	set(importedReducers, reducerImport.reducerStoreKey, reducerImport.default);
	set(importedSelectors, reducerImport.reducerStoreKey, reducerImport.selectors);
	set(importedActionCreators, reducerImport.reducerStoreKey, reducerImport.actionCreators);
});
const rootReducer = combineReducers(importedReducers);


/**
 * Define root reducer
 * 
 * @param state - Current redux store state.
 * @param action - Redux action object.
 */
const reducer = (state, action) => {
	// Reset all reducers to their default states
	if (action.type === REDUCER_TYPE.RESET) {
		let clearReducers = {};
		reducerImports.forEach(reducerImport => { clearReducers[reducerImport.reducerStoreKey] = reducerImport.initialState; });

		state = {
			...state,
			...clearReducers
		}
	}

	return rootReducer(state, action);
}

// Define global selectors
importedSelectors = {
	...importedSelectors,
	getCurrentUser: state => importedSelectors[redux_storage_var].getItem(state, current_user_storage_field_name),
}

// Combined selectors and action creators
export { importedSelectors as selectors, importedActionCreators as actionCreators };

export default reducer;