import {set} from 'lodash';

// Import individual reducers here
import * as mainSidebar from "../../layout/elements/MainSidebar/reducer";
import * as dynamicValuesReducer from "../../components/advanced/DynamicValues/reducer";
import * as builderPageReducer from "../../pages/builder/reducer";
import * as generatedReportsPageReducer from "../../pages/reports/components/GeneratedReports/reducer";
import * as scheduledReportsPageReducer from "../../pages/reports/components/ScheduledReports/reducer";
import * as schedulePageReducer from "../../pages/schedule/reducer";
import * as reportTemplatesPageReducer from "../../pages/reportTemplates/reducer";
import * as accountPermissionsPageReducer from "../../pages/accountPermissions/reducer";
import * as accountRolesPageReducer from "../../pages/accountRoles/reducer";
import * as accountsPageReducer from "../../pages/accounts/reducer";

// All imported reducers must be added to this array
const reducerImports = [
	mainSidebar,
	dynamicValuesReducer,
	builderPageReducer,
	generatedReportsPageReducer,
	scheduledReportsPageReducer,
	schedulePageReducer,
	reportTemplatesPageReducer,
	accountPermissionsPageReducer,
	accountRolesPageReducer,
	accountsPageReducer,
];




// ---------------------------------------------------------------------------------------------------------------------
// Don't change anything below this line -------------------------------------------------------------------------------
let importedReducers = {};
let importedSelectors = {};
let importedActionCreators = {};
// Combine all imported reducers. Imported reducers must export 'reducerStoreKey' constant containing unique store key
// associated to that reducer.
reducerImports.forEach(reducerImport => {
	set(importedReducers, reducerImport.reducerStoreKey, reducerImport.default);
	set(importedSelectors, reducerImport.reducerStoreKey, reducerImport.selectors);
	set(importedActionCreators, reducerImport.reducerStoreKey, reducerImport.actionCreators);
});

// Combined selectors and action creators
export { importedReducers as reducers, importedSelectors as selectors, importedActionCreators as actionCreators };