import {find} from "lodash";
import {RELATIVE_DATE_VALUE_PREFIX, RELATIVE_DATE_VALUE_SUFFIX, RELATIVE_DATE_VALUES} from "./const";


/**
 * Check if string contains any relative date value or a specific one (defined in 'value' argument)
 *
 * @param {string} string - String to check.
 * @param {RelativeDateValue|string} [value] - Specific relative date value object or relative date value code to
 * check. If not specified, function will check for any relative data value.
 * @return {boolean}
 */
export const containsRelativeDataValue = (string, value) => {
	if (typeof value === 'undefined') {
		return generateRelativeDateValuesRegex().test(string);
	} else if (typeof value === 'string') {
		return generateRelativeDateValueRegex(find(RELATIVE_DATE_VALUES, {code: value})).test(string);
	} else {
		return generateRelativeDateValueRegex(value).test(string);
	}
};

/**
 * Generate a regular expression string for a particular relative data value
 * @param {RelativeDateValue} value - Relative date value to generate the regular expression for.
 * @return {string}
 */
export const generateRelativeDateValueRegexString = value => {
	return (
		`${RELATIVE_DATE_VALUE_PREFIX}(${value.code})` +
		`${value.modifiable ? `(?:([+-]\\d+)${value.units?.length ? `(${value.units.join('|')})` : ''})?` : ''}` +
		`${RELATIVE_DATE_VALUE_SUFFIX}`
	);
};
/**
 * Generate a regular expression for a particular relative data value
 * @param {RelativeDateValue} value - Relative date value to generate the regular expression for.
 * @return {RegExp}
 */
export const generateRelativeDateValueRegex = value => 
	new RegExp(generateRelativeDateValueRegexString(value), 'mg');

/**
 * Generate the regular expression string that will check for all relative date values
 * @return {string}
 */
const generateRelativeDateValuesRegexString = () => 
	RELATIVE_DATE_VALUES.map(generateRelativeDateValueRegexString).join('|');

/**
 * Generate the regular expression that will check for all relative date values
 * @return {RegExp}
 */
const generateRelativeDateValuesRegex = () => new RegExp(generateRelativeDateValuesRegexString(), 'mg');

export default generateRelativeDateValuesRegex;