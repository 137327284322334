import {CookieData} from "../../../core/dataProtection/objects/cookie";
import {getStorageValue, STORAGE_TYPE} from "../../../core/storage";
import CookieConsent from "../../../core/dataProtection/cookieConsent";
import {app_id} from "../../../config";
import {getBoolFromTinyInt, isset} from "../../../core/helpers/data";

/**
 * Get menu sidebar shrunk flag value form storage
 * @note This function will check cookie access.
 * 
 * @param {'0'|'1'} [defaultValue] - Default shrunk value if data is not in storage.
 * @return {boolean|undefined} Returns boolean if flag is stored in storage. If default value is not set and data is not
 * in storage, undefined will be returned.
 */
export const getMenuSidebarShrankFromStorage = defaultValue => {
	const menuShrankCookie = new CookieData('preferences', 'sidebar_menu_shrank', STORAGE_TYPE.LOCAL);
	if (CookieConsent.isAllowed(menuShrankCookie)) {
		const shrankFromStorage = getStorageValue(`${app_id}-sidebar_menu_shrank`, STORAGE_TYPE.LOCAL);
		if (isset(shrankFromStorage)) return getBoolFromTinyInt(shrankFromStorage);
		else if (defaultValue) return getBoolFromTinyInt(defaultValue);
	}
	return (defaultValue ? getBoolFromTinyInt(defaultValue) : undefined);
};