import {cloneDeep} from "lodash";
import {getString, resetObjectValues} from "../../../core/helpers/data";
import {AccountPermissionListItemDataObject} from "../dataObjects";


/**
 * Get input data from raw data
 * @param {{id: string|number, code: string, name: string, description: string}} rawData - Raw data.
 * @return {AccountPermissionListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new AccountPermissionListItemDataObject(
		getString(result, 'id'),
		getString(result, 'code'),
		getString(result, 'name'),
		getString(result, 'description'),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {AccountPermissionListItemDataObject} inputData - Input data.
 * @return {{id: string|number, code: string, name: string, description: string}}
 */
export const output = inputData =>  {
	let result = cloneDeep(inputData);

	result = {
		id: getString(result, 'id'),
		code: getString(result, 'code'),
		name: getString(result, 'name'),
		description: getString(result, 'description'),
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}