import styles from "./index.module.css";

import React from "react";
import DataComponent, {executeComponentCallback} from "../../../../core/components/DataComponent";
import {FORM_FIELD_LABEL_POSITION} from "../../../../core/components/advanced/FormWrapper/FormField";
import SelectInput from "../../../../core/components/input/SelectInput";
import {RELATIVE_DATE_VALUES} from "./const";
import NumberInput from "../../../../core/components/input/NumberInput";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../../../core/components/display/Button";
import {FormField} from "../../../../core/components/advanced/FormWrapper";
import {waitingFunctionCallback} from "../../../../core/helpers/function";
import PropTypes from "prop-types";
import {icon_font_delete_symbol} from "../../../../config";
import DataValueValidation from "../../../../core/validation";
import {isset} from "../../../../core/helpers/data";

/**
 * Empty data object used to easily clear component's main data
 * @type {{unit: string, modifier: number, value: RelativeDateValue}}
 */
export const emptyData = {
	/** @type {RelativeDateValue} */
	value: undefined,
	modifier: undefined,
	unit: ''
};

export class RelativeDateRow extends DataComponent {
	/**
	 * Interval ID for insert dialog element getter
	 * @type {number}
	 */
	insertDialogIntervalId;
	
	constructor(props) {
		super(props, {
			data: emptyData,
			insertValueDialog: false,
		}, {
			translationPath: 'InsertValueDialogRelativeDateSection',
			enableLoadOnDataPropChange: true,
			optimizedUpdate: true,
			optimizedUpdateIgnoreProps: [
				// Ignore event props because anonymous functions (that are usually used for them) are generated on each 
				// parent component render so they are always different and optimization will not work. This is true for any
				// function prop.
				'onChange', 'onDelete', 'onEnterKey'
			],
			// Include only state fields relevant for rendering the component
			// @note This component extends and abstract component that might have some state fields.
			optimizedUpdateIncludeState: ['data', 'insertValueDialog', 'error']
		});

		// Data methods
		this.getRelativeDateValues = this.getRelativeDateValues.bind(this);

		// Data change handling methods
		this.handleRelativeDateValueChange = this.handleRelativeDateValueChange.bind(this);
	}

	componentWillUnmount() {
		// Clear intervals that would otherwise run indefinitely
		clearInterval(this.insertDialogIntervalId);

		super.componentWillUnmount();
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Get the list of available relative date values
	 * @return {RelativeDateValue[]}
	 */
	getRelativeDateValues() {
		const {filterValues} = this.props;
		return (isset(filterValues) ? RELATIVE_DATE_VALUES.filter(filterValues) : RELATIVE_DATE_VALUES);
	}
	
	/**
	 * Trigger component's onChange event with component's main data as param
	 * @return {any|null} Any return value from 'onChange' event function or null.
	 */
	update() {
		return executeComponentCallback(this.props.onChange, this.props.GUIID, this.getDataToReturn());
	}


	// Validation and error methods -------------------------------------------------------------------------------------
	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const dataValidation = new DataValueValidation();
		const dataToValidate = this.getData();

		/** @type {{label: string, value: RelativeDateValue}} */
		const value = this.getValue('value');
		const modifier = this.getValue('modifier');
		const unit = this.getValue('unit');
		const hasModifier = (isset(modifier) && modifier !== 0);
		
		if (value) {
			const isModifiable = !!value.value.modifiable;
			const isUnitable = !!value.value.units?.length;
			
			// Unit is required if value is modifiable, have a modifier and can have a unit
			if (isModifiable && isUnitable && hasModifier) dataValidation.addRule('unit', 'required');
			// Modifier is required if value is modifiable, can hav and have a unit
			else if (isModifiable && isUnitable && unit) dataValidation.addRule('modifier', 'required');
		}

		const validationErrors = dataValidation.run(dataToValidate);
		if (validationErrors) this.setErrorMessage(this.tt('invalid_row', 'errors'));
		else this.clearErrorMessage();
		return !validationErrors;
	}


	// Data change handling methods -------------------------------------------------------------------------------------
	/**
	 * Handle relative data value change
	 * @param {{label: string, value: RelativeDateValue}} value - Selected relative date value.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	async handleRelativeDateValueChange(value) {
		// Update value
		await this.handleValueChangeAndUpdate('value', value);

		// Clear modifier and unit fields if they are not relevant to the newly selected value
		const isModifiable = !!value?.value?.modifiable;
		const isUnitable = !!value?.value?.units?.length;
		// If value is selected
		if (value && value.value) {
			// Clear modifier and unit fields if value is not modifiable
			if (!isModifiable) {
				await this.setSubState('data', d => ({...d, modifier: undefined, unit: ''}));
				await this.update();
			}
			// Clear unit field if value modifier does not have a unit
			else if (!isUnitable) {
				await this.setValue('unit', '');
				await this.update();
			}
		}
		// Clear all fields if value is not selected
		// @note This should not happen since value select is not clearable but is added in case it becomes clearable.
		else {
			await this.setData(emptyData);
		}
		
		// Validate data if it was previously invalid
		if (this.getErrorMessage()) this.validate();
		
		return Promise.resolve(this.state);
	}

	/**
	 * Handle item child field changes and call component's update method
	 *
	 * @param {string} path - Path to the component's main data field that will be updated.
	 * @param {any} value - Value that will be used to update component's main data field.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	handleValueChangeAndUpdate(path, value) {
		return super.handleValueChangeAndUpdate(path, value).then(state => {
			// Validate data if it was previously invalid
			if (this.getErrorMessage()) this.validate();
			return state;
		});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {GUIID, className, count} = this.props;
		const {insertValueDialog} = this.state;
		const errorMessage = this.getErrorMessage();

		/** @type {{label: string, value: RelativeDateValue}} */
		const value = this.getValue('value');
		const modifier = this.getValue('modifier');
		const unit = this.getValue('unit');

		// Wait for dialogs component to become available in order to portal selects to it so they can render properly
		if (!insertValueDialog) {
			const insertDialogSel = '#root > .dialogs';
			waitingFunctionCallback(
				i => this.insertDialogIntervalId = i,
				() => !!document.querySelector(insertDialogSel),
				1,
				Infinity
			).then(() => this.setState({insertValueDialog: document.querySelector(insertDialogSel)}));
		}
		
		return (
			<FormField
				className={`relative-date-row ${styles['row']} ${className}`}
				inputClassName={`${styles['rowInput']}`}
				labelPosition={FORM_FIELD_LABEL_POSITION.NONE}
				errorMessages={errorMessage ? [errorMessage] : null}
			>
				<SelectInput
					className={`${styles['value']}`}
					simpleValue={false}
					value={value ? value : null}
					options={this.getRelativeDateValues().map(v => ({label: this.tt(v.code, 'code'), value: v}))}
					menuPortalTarget={insertValueDialog}
					onChange={this.handleRelativeDateValueChange}
					onEnterKey={e => executeComponentCallback(this.props.onEnterKey, e, GUIID)}
					placeholder={this.tt('value_placeholder', 'form')}
				/>
				{
					value?.value?.modifiable ?
						<NumberInput
							wrapperClassName={`${styles['modifier']}`}
							value={modifier}
							onChange={v => this.handleValueChangeAndUpdate('modifier', v)}
							onEnterKey={e => executeComponentCallback(this.props.onEnterKey, e, GUIID)}
							intOnly={true}
							placeholder={this.tt('modifier_placeholder', 'form')}
						/>
						: null
				}
				{
					value?.value?.modifiable && value?.value?.units?.length ?
						<SelectInput
							className={`${styles['unit']}`}
							isClearable={true}
							value={unit}
							options={value?.value?.units.map(u => (
								{label: this.tt(u, 'unit'), value: u}
							))}
							menuPortalTarget={insertValueDialog}
							onChange={v => this.handleValueChangeAndUpdate('unit', v)}
							onEnterKey={e => executeComponentCallback(this.props.onEnterKey, e, GUIID)}
							placeholder={this.tt('unit_placeholder', 'form')}
						/>
						: null
				}
				<Button
					className={`relative-date-row-delete ${styles['deleteBtn']}`}
					iconProps={(count === 1 ? {symbolPrefix: 'icofont-'} : {})}
					icon={(count === 1 ? 'eraser' : icon_font_delete_symbol)}
					displayStyle={BUTTON_STYLE.SUBTLE}
					displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
					onClick={() => this.clearErrorMessage().then(() => executeComponentCallback(this.props.onDelete, GUIID))}
				/>
			</FormField>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
RelativeDateRow.propTypes = {
	// GUI ID of the component
	GUIID: PropTypes.string.isRequired,
	// Component's wrapper element id attribute
	id: PropTypes.string,
	// Component's wrapper element class attribute
	className: PropTypes.string,
	// Row index in the list
	index: PropTypes.number.isRequired,
	// Total number of rows
	count: PropTypes.number,
	// Main component's data
	// @type {RelativeDateValue}
	data: PropTypes.shape({
		value: PropTypes.object,
		modifier: PropTypes.number,
		unit: PropTypes.string,
	}),
	// Function to filter available relative date values
	// @param {RelativeDateValue} - Relative date value.
	// @param {number} - Index of the value in the RELATIVE_DATE_VALUES list.
	// @return {boolean} True to include, false to not include.
	filterValues: PropTypes.func,
	
	// Events
	onChange: PropTypes.func, // Arguments:
	onDelete: PropTypes.func, // Arguments: Row GUI ID.
	onEnterKey: PropTypes.func, // Arguments: keypress event, row GUI ID.
};

/**
 * Define component default values for own props
 */
RelativeDateRow.defaultProps = {
	id: '',
	className: '',
	data: {
		value: undefined,
		modifier: undefined,
		unit: ''
	},
};

export default RelativeDateRow;