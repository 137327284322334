/**
 * Insert value dialog section data object
 */
export class InsertValueDialogSectionDataObject {
	/**
	 * @param {string} key - Unique key used as React element key because insert value dialog can have multiple sections.
	 * @param {React.ElementType} component - Insert value dialog section component. 
	 * @param {Object} [props] - Insert value dialog section component props.
	 * @param {function(string: string, value?: RelativeDateValue|string)} contains - Function that checks if any string
	 * contain insert value(s).
	 */
	constructor(key, component, props, contains) {
		this.key = key;
		this.component = component;
		this.props = props;
		this.contains = contains;
	}
}