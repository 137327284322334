import {get} from "lodash";

/**
 * Check if IO response was successful.
 * @note Successful response should have 'success: true' field.
 * 
 * @param {any} responseData - Response to check.
 * @return {boolean}
 */
export const isSuccessful = responseData => (get(responseData, 'success') === true);

/**
 * Get IO response error code
 * @note This is an error code from an IO request that was received properly (200) but has a 'success: false'.
 *
 * @param {any} responseData - Response to check.
 * @return {string}
 */
export const getErrorCode = responseData => {
	if (!isSuccessful(responseData)) {
		return get(responseData, 'errorCode') ? get(responseData, 'errorCode') : '';
	} else return '';
};

/**
 * Get IO response error message
 * @note This is an error code from an IO request that was received properly (200) but has a 'success: false'.
 *
 * @param {any} responseData - Response to check.
 * @param {string} [defaultMessage=''] - Default message to return if error message from request does not exists or is 
 * empty.
 * @return {string}
 */
export const getErrorMessage = (responseData, defaultMessage = '') => {
	if (!isSuccessful(responseData)) {
		return get(responseData, 'errorMessage') ? get(responseData, 'errorMessage') : defaultMessage;
	} else return '';
};