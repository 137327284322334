import React from "react";
import {components} from "react-select";
import {get, set, forOwn, omit} from "lodash";
import Icon from "../../../../display/Icon";
import {SelectDynamicValueFunction} from "../../index";

/**
 * Select single value replacement component using Icon component
 * @description Use this as an SingleValue component replacement for the SelectInput (react-select) component. You can 
 * pass Label component props through 'singleValueProps' prop of the SelectInput component. These prop can be an 
 * instance of SelectDynamicValueFunction class if prop should depend on option data.
 *
 * @param {Object} - Single value props sent by the react-select component to all the single values.
 * @return {JSX.Element}
 * @constructor
 */
const IconSingleValueComponent = ({children, ...props}) => {
	const singleValueProps = get(props, 'selectProps.singleValueProps', {});

	// If any prop is an instance of SelectDynamicValueFunction class, call the inner func and pass the option data to it 
	// to get the actual value
	let iconComponentProps = {};
	forOwn(singleValueProps, (value, key) => {
		if (value instanceof SelectDynamicValueFunction) {
			try { set(iconComponentProps, key, value.func(props.data)); }
			catch (e) { omit(iconComponentProps, [key]); }
		}
		else set(iconComponentProps, key, value);
	});

	return (
		<components.SingleValue {...props}>
			{iconComponentProps ? <Icon {...iconComponentProps} /> : null} {children}
		</components.SingleValue>
	)
};

export default IconSingleValueComponent;