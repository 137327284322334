import style from "../../display/Button/index.module.css";

import React, {useState} from "react";
import PropTypes from "prop-types";
import Icon from "../../display/Icon";
import {BUTTON_DISPLAY_TYPE, BUTTON_DISPLAY_TYPES, BUTTON_STYLE, BUTTON_STYLES} from "../../display/Button";
import Html from "../../display/Html";
import {ioDownloadRequest} from "../../../io";
import {REQUEST_TYPE, REQUEST_TYPES} from "../../../io/const";
import {icon_font_loading_symbol} from "../../../../config";
import {translate} from "../../../i18n";

/**
 * HTML button component using fetch request to download a file
 * @description This button uses fetch request to get the file and URL.createObjectURL to generate file that will be
 * downloaded by creating a dummy download link and simulating a click on it (see 'ioDownloadRequest' function).
 * @note This component uses a default <button> element and it can accept any attribute that <button> can.
 */
function DownloadButton({
	id,
	className = '',
	type = 'button',
	displayType = BUTTON_DISPLAY_TYPE.SOLID,
	displayStyle = BUTTON_STYLE.DEFAULT,
	big = false,
	name,
	value,
	autofocus = false,
	disabled = false,
	hide = false,
	label = translate('Download', 'general'),
	allowHtmlLabel = false,
	icon = 'download',
	iconProps = {},
	spinIcon = false,
	onClick,
	onDownload,
	onDownloadError,
	children,

	url,
	requestType = REQUEST_TYPE.JSON,
	filename = 'file',
	method = 'POST',
	data = null,
	requestProps = {},
	
	...otherProps
}) {
	const [downloading, setDownloading] = useState(false);
	
	/**
	 * Handle download button click
	 * @param {MouseEvent} e
	 */
	async function handleClick(e) {
		e.preventDefault();
		
		if (!disabled && !downloading && url) {
			let canDownload = true;
			
			// Stop the download if 'onClick' returns false
			if (onClick) canDownload = (await Promise.resolve(onClick(e))) !== false;
			
			if (canDownload) {
				setDownloading(true);
				ioDownloadRequest({
					type: requestType,
					url,
					downloadFilename: filename,
					method,
					data,
					...requestProps
				})
					.then(data => { if (onDownload) onDownload(data); })
					.catch(error => { if (onDownloadError) onDownloadError(error); })
					.finally(() => setDownloading(false));
			}
		}
	}
	
	const iconToUse = (downloading ? icon_font_loading_symbol : icon);
	
	return (
		!hide ?
			<button
				id={id}
				className={
					`button-component ${className} ${big ? 'big ' + style['big'] : ''} ${style['button']}` +
					` ${displayType} ${style[displayType]} ${displayStyle} ${style[displayStyle]}` +
					` ${!children && !label ? `icon-only ${style['icon-only']}` : ''}`
				}
				type={type}
				name={name}
				value={value}
				autoFocus={autofocus}
				disabled={disabled || downloading || !url}
				onClick={handleClick}
				{...otherProps}
			>
				{iconToUse ? <Icon
					symbol={iconToUse}
					spin={downloading || spinIcon}
					className={`button-component-icon ${style['icon']}`}
					{...iconProps}
				/> : null }{
				label ?
					(allowHtmlLabel ? <Html content={label} element="span" /> : label)
					: null
			} {children}
			</button>
			:
			null
	);
}

/**
 * Define component's own props that can be passed to it by parent components
 */
DownloadButton.propTypes = {
	// Button element 'id' attribute.
	id: PropTypes.string,
	// Button element CSS class attribute.
	className: PropTypes.string,
	// The default behavior of the button. Possible values are: 'submit', 'reset' or 'button'.
	type: PropTypes.string,
	// Button display type ('none', 'solid', 'transparent', ...)
	displayType: PropTypes.oneOf(BUTTON_DISPLAY_TYPES),
	// Button display style ('default', 'success', 'error', ...)
	displayStyle: PropTypes.oneOf(BUTTON_STYLES),
	// If true, bigger button will be rendered.
	big: PropTypes.bool,
	// The name of the button, submitted as a pair with the button’s value as part of the form data.
	name: PropTypes.string,
	// Defines the value associated with the button’s name when it’s submitted with the form data. This value is passed
	// to the server in params when the form is submitted.
	value: PropTypes.string,
	// This Boolean attribute specifies that the button should have input focus when the page loads. 
	// @note Only one element in a document can have this attribute.
	autofocus: PropTypes.bool,
	// This Boolean attribute prevents the user from interacting with the button: it cannot be pressed or focused.
	disabled: PropTypes.bool,
	// If true, button will not be rendered.
	hide: PropTypes.bool,
	// Button label rendered as a child of the <button> component before any other child elements but after the icon.
	label: PropTypes.string,
	// Set to true to support HTML in 'label' prop.
	// @warning Be careful when using this flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' 
	// to allow HTML content. 
	allowHtmlLabel: PropTypes.bool,
	// Font icon symbol name.
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	// If true, icon will spin (if supported by font icon set used).
	spinIcon: PropTypes.bool,
	// Icon props
	// @see Icon component
	iconProps: PropTypes.object,

	// URL of the file download request
	url: PropTypes.string,
	// Download request type
	requestType: PropTypes.oneOf(REQUEST_TYPES),
	// Filename (with extension if needed) of the downloaded file
	// @note Browsers may automatically set the file extension if they detect the file type.
	filename: PropTypes.string,
	// Download request method
	method: PropTypes.oneOf(['GET', 'POST']),
	// Download request data (body)
	// @note Only POST methods can have body. If 'method' is 'GET', data will be ignored.
	data: PropTypes.any,
	// Other download request props
	// @see 'ioDownloadRequest' function for reference.
	requestProps: PropTypes.object,

	// Events
	onClick: PropTypes.func,
	onDownload: PropTypes.func,
	onDownloadError: PropTypes.func,
};

export default DownloadButton;
export * from "../../display/Button/const";