import {
	auth_default_auto_token,
	io_default_request_options,
	io_default_response_data_type,
	io_default_upload_request_options, messages_default_auto_hide_after,
	pagination_default_per_page
} from "../../../config";
import {getIOUrl} from "../../io/helper";
import {addErrorMessageAction} from "../../components/global/Message";
import {ioStandardJsonRequest, ioStandardRequest, ioUploadRequest} from "../../io";
import {SORT_ORDER} from "../../const/global";

/**
 * Low-level action used for making raw IO requests
 * @note You should probably use one of the other actions defined below :)
 *
 * @param {Function} requestFunction - Function that makes the request and returns a promise (like ioRequest,
 * ioStandardRequest, ioStandardJsonRequest, ...).
 * @param {string} url - IO url.
 * @param {any} data - Data to send in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {Function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @param {boolean} [manualCatch=false] - If true, request will not catch exceptions automatically displaying
 * appropriate error message to the user. This means that exceptions must be caught manually using the 'catch' method so
 * you need to be EXTRA CAREFUL when using this option.
 * @return {function(*): *}
 */
export const rawIoAction = ({
	requestFunction, url, data, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', method = 'post',
	options = io_default_request_options, responseType = io_default_response_data_type,
	abortCallback = (abortController) => {}, otherRequestOptions = {}, manualCatch = false
}) => dispatch => {
	return requestFunction({
		url, data, method, autoToken, token, options, responseType, abortCallback, ...otherRequestOptions
	})
		.then(responseData => {
			// Hide loading overlay if specified
			if (hideLoadingFunction) hideLoadingFunction();
			// Return response data
			return responseData;
		})
		.catch(error => {
			if (manualCatch) throw error;
			else {
				// Render error if it is not 'AbortError'
				// @note Error should be an object with 'message' field already translated and ready for display.
				if (error.name !== 'AbortError') dispatch(addErrorMessageAction(error.message, messages_default_auto_hide_after));
				// Hide loading overlay if specified
				if (hideLoadingFunction) hideLoadingFunction();
			}
		});
}

/**
 * Low-level action used for making IO requests
 * @note You should probably use one of the other actions defined below :)
 *
 * @param {Function} requestFunction - Function that makes the request and returns a promise (like ioRequest, 
 * ioStandardRequest, ioStandardJsonRequest, ...).
 * @param {string} [api='defaultApi'] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {any} data - Data to send in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @param {boolean} [manualCatch=false] - If true, request will not catch exceptions automatically displaying 
 * appropriate error message to the user. This means that exceptions must be caught manually using the 'catch' method so
 * you need to be EXTRA CAREFUL when using this option.
 * @return {function(*): *} 
 */
export const ioAction = ({
	requestFunction, api = 'defaultApi', endpoint, data, hideLoadingFunction, autoToken = auth_default_auto_token, 
	token = '', method = 'post', options = io_default_request_options, responseType = io_default_response_data_type,
	abortCallback = (abortController) => {}, otherRequestOptions = {}, manualCatch = false
}) => dispatch => {
	const url = getIOUrl(api, endpoint);
	return requestFunction({
		url, api, endpoint, data, method, autoToken, token, options, responseType, abortCallback, 
		...otherRequestOptions
	})
		.then(responseData => {
			// Hide loading overlay if specified
			if (hideLoadingFunction) hideLoadingFunction();
			// Return response data
			return responseData;
		})
		.catch(error => {
			if (manualCatch) throw error;
			else {
				// Render error if it is not 'AbortError'
				// @note Error should be an object with 'message' field already translated and ready for display.
				if (error.name !== 'AbortError') {
					dispatch(addErrorMessageAction(error.message, messages_default_auto_hide_after));
				}
				// Hide loading overlay if specified
				if (hideLoadingFunction) hideLoadingFunction();
			}
		});
}

/**
 * Action used for fetching IO data
 * @note Usually used for table data.
 *
 * @param {Function} requestFunction - Function that makes the request and returns a promise (like ioRequest,
 * ioStandardRequest, ioStandardJsonRequest, ...).
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} [query=''] - Search query.
 * @param {Object} [filter=null] - Simple filter object with 'column: value' pairs.
 * @param {Object} [advancedFilter=null] - Advance filter.
 * @param {number} [pageNo=1] - Current page number used in pagination (starts from 1).
 * @param {number} [perPage] - Items per page used in pagination.
 * @param {string} [sortBy=''] - Sort column.
 * @param {string} [sortDir='asc'] - Sort direction ('asc' or 'desc').
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @param {boolean} [manualCatch=false] - If true, request will not catch exceptions automatically displaying
 * appropriate error message to the user. This means that exceptions must be caught manually using the 'catch' method so
 * you need to be EXTRA CAREFUL when using this option.
 * @return {function(*): *}
 */
export const ioFetchAction = ({
	requestFunction, api, endpoint, query = '', filter = null, advancedFilter = null, pageNo = 1, 
	perPage = pagination_default_per_page, sortBy = '', sortDir = 'asc', additionalData = {}, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	responseType = io_default_response_data_type, abortCallback = (abortController) => {}, otherRequestOptions = {},
	manualCatch = false
}) => dispatch => ioAction({
	requestFunction, api, endpoint, 
	data: { query, filter, advancedFilter, pageNo, perPage, sortBy, sortDir, ...additionalData},
	hideLoadingFunction, autoToken, token, method, options, responseType, abortCallback, otherRequestOptions, manualCatch
})(dispatch);

/**
 * Action used for finding IO data
 * @note Usually used for autosuggestion options.
 *
 * @param {Function} requestFunction - Function that makes the request and returns a promise (like ioRequest,
 * ioStandardRequest, ioStandardJsonRequest, ...).
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} [query=''] - Search query.
 * @param {number} [maxResults=-1] - Maximal number of result to get.
 * @param {string} [sortBy=''] - Sort column.
 * @param {string} [sortDir='asc'] - Sort direction ('asc' or 'desc').
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @param {boolean} [manualCatch=false] - If true, request will not catch exceptions automatically displaying
 * appropriate error message to the user. This means that exceptions must be caught manually using the 'catch' method so
 * you need to be EXTRA CAREFUL when using this option.
 * @return {function(*=): *}
 */
export const ioFindAction = ({
	requestFunction, api, endpoint, query = '', maxResults = -1, sortBy = '', sortDir = 'asc', additionalData = {}, 
	hideLoadingFunction, autoToken = auth_default_auto_token, token = '', method = 'post', 
	options = io_default_request_options, responseType = io_default_response_data_type, 
	abortCallback = (abortController) => {}, otherRequestOptions = {}, manualCatch = false
}) => dispatch => ioAction({
	requestFunction, api, endpoint, 
	data: { query, maxResults: (maxResults > -1 ? maxResults : ''), sortBy, sortDir, ...additionalData }, 
	hideLoadingFunction, autoToken, token, method, options, responseType, abortCallback, otherRequestOptions, manualCatch
})(dispatch);

/**
 * Action used for saving IO data
 *
 * @param {Function} requestFunction - Function that makes the request and returns a promise (like ioRequest,
 * ioStandardRequest, ioStandardJsonRequest, ...).
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {any} data - Data to save.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {boolean} [requestSavedData=false] - Flag defining if saved data should be returned after successful save.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @param {boolean} [manualCatch=false] - If true, request will not catch exceptions automatically displaying
 * appropriate error message to the user. This means that exceptions must be caught manually using the 'catch' method so
 * you need to be EXTRA CAREFUL when using this option.
 * @return {function(*=): *}
 */
export const ioSaveAction = ({
	requestFunction, api, endpoint, data, additionalData = {}, requestSavedData = false, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	responseType = io_default_response_data_type, abortCallback = (abortController) => {}, otherRequestOptions = {},
	manualCatch = false
}) => dispatch => ioAction({
	requestFunction, api, endpoint, data: {...data, requestSavedData, ...additionalData}, hideLoadingFunction, autoToken,
	token, method, options, responseType, abortCallback, otherRequestOptions, manualCatch
})(dispatch);

/**
 * Action used for deleting IO data
 * @note Usually used for deleting data selected in a table.
 *
 * @param {Function} requestFunction - Function that makes the request and returns a promise (like ioRequest,
 * ioStandardRequest, ioStandardJsonRequest, ...).
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string[]} ids - IDs of IO items to delete.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @param {boolean} [manualCatch=false] - If true, request will not catch exceptions automatically displaying
 * appropriate error message to the user. This means that exceptions must be caught manually using the 'catch' method so
 * you need to be EXTRA CAREFUL when using this option.
 * @return {function(*=): *}
 */
export const ioDeleteAction = ({
	requestFunction, api, endpoint, ids, additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, 
	token = '', method = 'post', options = io_default_request_options, responseType = io_default_response_data_type,
	abortCallback = (abortController) => {}, otherRequestOptions = {}, manualCatch = false
}) => dispatch => ioAction({
	requestFunction, api, endpoint, data: { ids: (Array.isArray(ids) && ids.length > 0 ? ids : []), ...additionalData}, 
	hideLoadingFunction, autoToken, token, method, options, responseType, abortCallback, otherRequestOptions, manualCatch
})(dispatch);

/**
 * Action used for fetching one item from IO
 * @note Usually used for deleting data selected in a table.
 *
 * @param {Function} requestFunction - Function that makes the request and returns a promise (like ioRequest,
 * ioStandardRequest, ioStandardJsonRequest, ...).
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} id - ID of an IO item to fetch.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @param {boolean} [manualCatch=false] - If true, request will not catch exceptions automatically displaying
 * appropriate error message to the user. This means that exceptions must be caught manually using the 'catch' method so
 * you need to be EXTRA CAREFUL when using this option.
 * @return {function(*=): *}
 */
export const ioFetchItemAction = ({
	requestFunction, api, endpoint, id, additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, 
	token = '', method = 'post', options = io_default_request_options, responseType = io_default_response_data_type,
	abortCallback = (abortController) => {}, otherRequestOptions = {}, manualCatch = false
}) => dispatch => ioAction({
	requestFunction, api, endpoint, data: {id, ...additionalData}, hideLoadingFunction, autoToken, token, method, 
	options, responseType, abortCallback, otherRequestOptions, manualCatch
})(dispatch);

/**
 * Action used for finding one item from IO
 *
 * @param {Function} requestFunction - Function that makes the request and returns a promise (like ioRequest,
 * ioStandardRequest, ioStandardJsonRequest, ...).
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {Object} [filter=null] - Simple filter object with 'column: value' pairs.
 * @param {Object} [advancedFilter=null] - Advance filter.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @param {boolean} [manualCatch=false] - If true, request will not catch exceptions automatically displaying
 * appropriate error message to the user. This means that exceptions must be caught manually using the 'catch' method so
 * you need to be EXTRA CAREFUL when using this option.
 * @return {function(*=): *}
 */
export const ioFindItemAction = ({
	requestFunction, api, endpoint, filter = null, advancedFilter = null, additionalData = {}, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	responseType = io_default_response_data_type, abortCallback = (abortController) => {}, otherRequestOptions = {},
	manualCatch = false
}) => dispatch => ioAction({
	requestFunction, api, endpoint, data: { filter, advancedFilter, ...additionalData }, hideLoadingFunction, autoToken, 
	token, method, options, responseType, abortCallback, otherRequestOptions, manualCatch
})(dispatch);

/**
 * Action used for creating/saving one item from IO
 *
 * @param {Function} requestFunction - Function that makes the request and returns a promise (like ioRequest,
 * ioStandardRequest, ioStandardJsonRequest, ...).
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} [id=''] - ID of the IO item to save. If empty IO should create a new item.
 * @param {any} data - Item data to save.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {boolean} [requestSavedData=false] - Flag defining if saved data should be returned after successful save.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @param {boolean} [manualCatch=false] - If true, request will not catch exceptions automatically displaying
 * appropriate error message to the user. This means that exceptions must be caught manually using the 'catch' method so
 * you need to be EXTRA CAREFUL when using this option.
 * @return {function(*=): *}
 */
export const ioSaveItemAction = ({
	requestFunction, api, endpoint, id = '', data, additionalData = {}, requestSavedData = false, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	responseType = io_default_response_data_type, abortCallback = (abortController) => {}, otherRequestOptions = {},
	manualCatch = false
}) => dispatch => ioSaveAction({
	requestFunction, api, endpoint, data: {...data, id}, additionalData, requestSavedData, hideLoadingFunction, 
	autoToken, token, method, options, responseType, abortCallback, otherRequestOptions, manualCatch
})(dispatch);

/**
 * Action used for deleting one item from IO
 *
 * @param {Function} requestFunction - Function that makes the request and returns a promise (like ioRequest,
 * ioStandardRequest, ioStandardJsonRequest, ...).
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string|number|string[]|number[]} [ids=''] - ID or array of IDs of the IO item to delete.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @param {boolean} [manualCatch=false] - If true, request will not catch exceptions automatically displaying
 * appropriate error message to the user. This means that exceptions must be caught manually using the 'catch' method so
 * you need to be EXTRA CAREFUL when using this option.
 * @return {function(*=): *}
 */
export const ioDeleteItemAction = ({
	requestFunction, api, endpoint, ids='', additionalData = {}, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	responseType = io_default_response_data_type, abortCallback = (abortController) => {}, otherRequestOptions = {}, 
	manualCatch = false
}) => dispatch => ioDeleteAction({
	requestFunction, api, endpoint, ids: (Array.isArray(ids) ? ids : [ids]), additionalData, hideLoadingFunction, 
	autoToken, token, method, options, responseType, abortCallback, otherRequestOptions, manualCatch
})(dispatch);

/**
 * Action used uploading files
 *
 * @param {Function} requestFunction - Function that makes the request and returns a promise (like ioRequest,
 * ioStandardRequest, ioStandardJsonRequest, ...).
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @param {boolean} [manualCatch=false] - If true, request will not catch exceptions automatically displaying
 * appropriate error message to the user. This means that exceptions must be caught manually using the 'catch' method so
 * you need to be EXTRA CAREFUL when using this option.
 * @return {function(*=): *}
 */
export const ioUploadAction = ({
	api, endpoint, data, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', method = 'post', 
	options = io_default_upload_request_options, responseType = io_default_response_data_type, 
	abortCallback = (abortController) => {}, otherRequestOptions = {}, manualCatch = false
}) => dispatch => ioAction({
	requestFunction: ioUploadRequest, api, endpoint, data, hideLoadingFunction, autoToken, token, method, options,
	responseType, abortCallback, otherRequestOptions, manualCatch
})(dispatch);



// Standard request ----------------------------------------------------------------------------------------------------
/**
 * Low-level action used for making IO requests using a standard request
 * @note You should probably use one of the other actions defined below :)
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {any} data - Data to send in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*): *}
 */
export const ioStandardAction = (
	abortCallback = (abortController) => {},
	api, endpoint, data, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', method = 'post',
	options = io_default_request_options, responseType = io_default_response_data_type, otherRequestOptions = {}
) => dispatch => ioAction({
	requestFunction: ioStandardRequest, api, endpoint, data, hideLoadingFunction, autoToken, token, method, options, 
	responseType, abortCallback, otherRequestOptions
})(dispatch);

/**
 * Same as 'ioStandardAction' but without automatic exception catching
 * IMPORTANT: You will have to catch exception manually.
 * @see ioStandardAction
 */
export const ioManualStandardAction = (
	abortCallback = (abortController) => {},
	api, endpoint, data, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', method = 'post',
	options = io_default_request_options, responseType = io_default_response_data_type, otherRequestOptions = {}
) => dispatch => ioAction({
	requestFunction: ioStandardRequest, api, endpoint, data, hideLoadingFunction, autoToken, token, method, options,
	responseType, abortCallback, otherRequestOptions, manualCatch: true
})(dispatch);

/**
 * Action used for fetching IO data using a standard request
 * @note Usually used for table data.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} [query=''] - Search query.
 * @param {Object} [filter=null] - Simple filter object with 'column: value' pairs.
 * @param {Object} [advancedFilter=null] - Advance filter.
 * @param {number} [pageNo=1] - Current page number used in pagination (starts from 1).
 * @param {number} [perPage] - Items per page used in pagination.
 * @param {string} [sortBy=''] - Sort column.
 * @param {string} [sortDir='asc'] - Sort direction ('asc' or 'desc').
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*): *}
 */
export const ioStandardFetchAction = (
	abortCallback = (abortController) => {},
	api, endpoint, query = '', filter = null, advancedFilter = null, pageNo = 1, perPage = pagination_default_per_page, 
	sortBy = '', sortDir = 'asc', additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, 
	token = '', method = 'post', options = io_default_request_options, responseType = io_default_response_data_type,
	otherRequestOptions = {}
) => dispatch => ioFetchAction({
	requestFunction: ioStandardRequest, api, endpoint, query, filter, advancedFilter, pageNo, perPage, sortBy, sortDir, 
	additionalData, hideLoadingFunction, autoToken, token, method, options, responseType, abortCallback, 
	otherRequestOptions
})(dispatch);

/**
 * Action used for finding IO data using a standard request
 * @note Usually used for autosuggestion options.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} [query=''] - Search query.
 * @param {number} [maxResults=-1] - Maximal number of result to get.
 * @param {string} [sortBy=''] - Sort column.
 * @param {string} [sortDir='asc'] - Sort direction ('asc' or 'desc').
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioStandardFindAction = (
	abortCallback = (abortController) => {},
	api, endpoint, query = '', maxResults = -1, sortBy = '', sortDir = 'asc', additionalData = {}, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	responseType = io_default_response_data_type, otherRequestOptions = {}
) => dispatch => ioFindAction({
	requestFunction: ioStandardRequest, api, endpoint, query, maxResults, sortBy, sortDir, additionalData, 
	hideLoadingFunction, autoToken, token, method, options, responseType, abortCallback, otherRequestOptions
})(dispatch);

/**
 * Action used for saving IO data using a standard request
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {any} data - Data to save.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {boolean} [requestSavedData=false] - Flag defining if saved data should be returned after successful save.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioStandardSaveAction = (
	abortCallback = (abortController) => {},
	api, endpoint, data, additionalData = {}, requestSavedData = false, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	responseType = io_default_response_data_type, otherRequestOptions = {}
) => dispatch => ioSaveAction({
	requestFunction: ioStandardRequest, api, endpoint, data, additionalData, requestSavedData, hideLoadingFunction, 
	autoToken, token, method, options, responseType, abortCallback, otherRequestOptions
})(dispatch);

/**
 * Action used for deleting IO data using a standard request
 * @note Usually used for deleting data selected in a table.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string[]} ids - IDs of IO items to delete.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioStandardDeleteAction = (
	abortCallback = (abortController) => {},
	api, endpoint, ids, additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', 
	method = 'post', options = io_default_request_options, responseType = io_default_response_data_type, 
	otherRequestOptions = {}
) => dispatch => ioDeleteAction({
	requestFunction: ioStandardRequest, api, endpoint, ids, additionalData, hideLoadingFunction, autoToken, token, 
	method, options, responseType, abortCallback, otherRequestOptions
})(dispatch);

/**
 * Action used for fetching one item from IO using a standard request
 * @note Usually used for deleting data selected in a table.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} id - ID of an IO item to fetch.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioStandardFetchItemAction = (
	abortCallback = (abortController) => {},
	api, endpoint, id, additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', 
	method = 'post', options = io_default_request_options, responseType = io_default_response_data_type,
	otherRequestOptions = {}
) => dispatch => ioFetchItemAction({
	requestFunction: ioStandardRequest, api, endpoint, id, additionalData, hideLoadingFunction, autoToken, token, method,
	options, responseType, abortCallback, otherRequestOptions
})(dispatch);

/**
 * Action used for finding one item from IO using a standard request
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {Object} [filter=null] - Simple filter object with 'column: value' pairs.
 * @param {Object} [advancedFilter=null] - Advance filter.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioStandardFindItemAction = (
	abortCallback = (abortController) => {},
	api, endpoint, filter = null, advancedFilter = null, additionalData = {}, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	responseType = io_default_response_data_type, otherRequestOptions = {}
) => dispatch => ioFindItemAction({
	requestFunction: ioStandardRequest, api, endpoint, filter, advancedFilter, additionalData, hideLoadingFunction, 
	autoToken, token, method, options, responseType, abortCallback, otherRequestOptions
})(dispatch);

/**
 * Action used for creating/saving one item from IO using a standard request
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} [id=''] - ID of the IO item to save. If empty IO should create a new item.
 * @param {any} data - Item data to save.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {boolean} [requestSavedData=false] - Flag defining if saved data should be returned after successful save.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioStandardSaveItemAction = (
	abortCallback = (abortController) => {},
	api, endpoint, id = '', data, additionalData = {}, requestSavedData = false, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	responseType = io_default_response_data_type, otherRequestOptions = {}
) => dispatch => ioSaveItemAction({
	requestFunction: ioStandardRequest, api, endpoint, id, data, additionalData, requestSavedData, hideLoadingFunction, 
	autoToken, token, method, options, responseType, abortCallback, otherRequestOptions
})(dispatch);

/**
 * Action used for deleting one item from IO using a standard request
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string|number|string[]|number[]} [ids=''] - ID or array of IDs of the IO item to delete.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioStandardDeleteItemAction = (
	abortCallback = (abortController) => {},
	api, endpoint, ids, additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', 
	method = 'post', options = io_default_request_options, responseType = io_default_response_data_type,
	otherRequestOptions = {}
) => dispatch => ioDeleteItemAction({
	requestFunction: ioStandardRequest, api, endpoint, ids, additionalData, hideLoadingFunction, autoToken, token, method, 
	options, responseType, abortCallback, otherRequestOptions
})(dispatch);




// JSON request --------------------------------------------------------------------------------------------------------
/**
 * Low-level action used for making IO requests using a JSON request
 * @note You should probably use one of the other actions defined below :)
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {any} data - Data to send in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*): *}
 */
export const ioJsonAction = (
	abortCallback = (abortController) => {},
	api, endpoint, data, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', method = 'post',
	options = io_default_request_options, responseType = io_default_response_data_type, otherRequestOptions = {}
) => dispatch => ioAction({
	requestFunction: ioStandardJsonRequest, api, endpoint, data, hideLoadingFunction, autoToken, token, method, options, 
	responseType, abortCallback, otherRequestOptions
})(dispatch);

/**
 * Same as 'ioJsonAction' but without automatic exception catching
 * IMPORTANT: You will have to catch exception manually.
 * @see ioJsonAction
 */
export const ioManualJsonAction = (
	abortCallback = (abortController) => {},
	api, endpoint, data, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', method = 'post',
	options = io_default_request_options, responseType = io_default_response_data_type, otherRequestOptions = {}
) => dispatch => ioAction({
	requestFunction: ioStandardJsonRequest, api, endpoint, data, hideLoadingFunction, autoToken, token, method, options,
	responseType, abortCallback, otherRequestOptions, manualCatch: true
})(dispatch);


/**
 * Action used for fetching IO data using a JSON request
 * @note Usually used for table data.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} [query=''] - Search query.
 * @param {Object} [filter=null] - Simple filter object with 'column: value' pairs.
 * @param {Object} [advancedFilter=null] - Advance filter.
 * @param {number} [pageNo=1] - Current page number used in pagination (starts from 1).
 * @param {number} [perPage] - Items per page used in pagination.
 * @param {string} [sortBy=''] - Sort column.
 * @param {string} [sortDir='asc'] - Sort direction ('asc' or 'desc').
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*): *}
 */
export const ioJsonFetchAction = (
	abortCallback = (abortController) => {},
	api, endpoint, query = '', filter = null, advancedFilter = null, pageNo = 1, perPage = pagination_default_per_page,
	sortBy = '', sortDir = SORT_ORDER.ASC, additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, 
	token = '', method = 'post', options = io_default_request_options, responseType = io_default_response_data_type,
	otherRequestOptions = {}
) => dispatch => ioFetchAction({
	requestFunction: ioStandardJsonRequest, api, endpoint, query, filter, advancedFilter, pageNo, perPage, sortBy, 
	sortDir, additionalData, hideLoadingFunction, autoToken, token, method, options, responseType, abortCallback,
	otherRequestOptions
})(dispatch);

/**
 * Action used for finding IO data using a JSON request
 * @note Usually used for autosuggestion options.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} [query=''] - Search query.
 * @param {number} [maxResults=-1] - Maximal number of result to get.
 * @param {string} [sortBy=''] - Sort column.
 * @param {string} [sortDir='asc'] - Sort direction ('asc' or 'desc').
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioJsonFindAction = (
	abortCallback = (abortController) => {},
	api, endpoint, query = '', maxResults = -1, sortBy = '', sortDir = 'asc', additionalData = {}, hideLoadingFunction,
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options,
	responseType = io_default_response_data_type, otherRequestOptions = {}
) => dispatch => ioFindAction({
	requestFunction: ioStandardJsonRequest, api, endpoint, query, maxResults, sortBy, sortDir, additionalData, 
	hideLoadingFunction, autoToken, token, method, options, responseType, abortCallback, otherRequestOptions
})(dispatch);

/**
 * Action used for saving IO data using a JSON request
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {any} data - Data to save.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {boolean} [requestSavedData=false] - Flag defining if saved data should be returned after successful save.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioJsonSaveAction = (
	abortCallback = (abortController) => {},
	api, endpoint, data, additionalData = {}, requestSavedData = false, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	responseType = io_default_response_data_type, otherRequestOptions = {}
) => dispatch => ioSaveAction({
	requestFunction: ioStandardJsonRequest, api, endpoint, data, additionalData, requestSavedData, hideLoadingFunction, 
	autoToken, token, method, options, responseType, abortCallback, otherRequestOptions
})(dispatch);

/**
 * Action used for deleting IO data using a JSON request
 * @note Usually used for deleting data selected in a table.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string[]} ids - IDs of IO items to delete.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioJsonDeleteAction = (
	abortCallback = (abortController) => {},
	api, endpoint, ids, additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', 
	method = 'post', options = io_default_request_options, responseType = io_default_response_data_type,
	otherRequestOptions = {}
) => dispatch => ioDeleteAction({
	requestFunction: ioStandardJsonRequest, api, endpoint, ids, additionalData, hideLoadingFunction, autoToken, token, 
	method, options, responseType, abortCallback, otherRequestOptions
})(dispatch);

/**
 * Action used for fetching one item from IO using a JSON request
 * @note Usually used for deleting data selected in a table.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} id - ID of an IO item to fetch.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioJsonFetchItemAction = (
	abortCallback = (abortController) => {},
	api, endpoint, id, additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', 
	method = 'post', options = io_default_request_options, responseType = io_default_response_data_type,
	otherRequestOptions = {}
) => dispatch => ioFetchItemAction({
	requestFunction: ioStandardJsonRequest, api, endpoint, id, additionalData, hideLoadingFunction, autoToken, token, 
	method, options, responseType, abortCallback, otherRequestOptions
})(dispatch);

/**
 * Action used for finding one item from IO using a JSON request
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {Object} [filter=null] - Simple filter object with 'column: value' pairs.
 * @param {Object} [advancedFilter=null] - Advance filter.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioJsonFindItemAction = (
	abortCallback = (abortController) => {},
	api, endpoint, filter = null, advancedFilter = null, additionalData = {}, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	responseType = io_default_response_data_type, otherRequestOptions = {}
) => dispatch => ioFindItemAction({
	requestFunction: ioStandardJsonRequest, api, endpoint, filter, advancedFilter, additionalData, hideLoadingFunction, 
	autoToken, token, method, options, responseType, abortCallback, otherRequestOptions
})(dispatch);

/**
 * Action used for creating/saving one item from IO using a JSON request
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} [id=''] - ID of the IO item to save. If empty IO should create a new item.
 * @param {any} data - Item data to save.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {boolean} [requestSavedData=false] - Flag defining if saved data should be returned after successful save.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioJsonSaveItemAction = (
	abortCallback = (abortController) => {},
	api, endpoint, id = '', data, additionalData = {}, requestSavedData = false, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	responseType = io_default_response_data_type, otherRequestOptions = {}
) => dispatch => ioSaveItemAction({
	requestFunction: ioStandardJsonRequest, api, endpoint, id, data, additionalData, requestSavedData, 
	hideLoadingFunction, autoToken, token, method, options, responseType, abortCallback, otherRequestOptions
})(dispatch);

/**
 * Action used for deleting one item from IO using a JSON request
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string|number|string[]|number[]} [ids=''] - ID or array of IDs of the IO item to delete.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioJsonDeleteItemAction = (
	abortCallback = (abortController) => {},
	api, endpoint, ids, additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', 
	method = 'post', options = io_default_request_options, responseType = io_default_response_data_type,
	otherRequestOptions = {}
) => dispatch => ioDeleteItemAction({
	requestFunction: ioStandardJsonRequest, api, endpoint, ids, additionalData, hideLoadingFunction, autoToken, token, 
	method, options, responseType, abortCallback, otherRequestOptions
})(dispatch);


// Standard upload -----------------------------------------------------------------------------------------------------
/**
 * Action used for uploading files
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {FormData} formData - FormData with all files and params.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified and it cannot be retrieved from the config, response will be return as Blob.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioStandardUploadAction = (
	abortCallback = (abortController) => {},
	api, endpoint, formData, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', method = 'post',
	options = io_default_upload_request_options, responseType = io_default_response_data_type, otherRequestOptions = {}
) => dispatch => ioUploadAction({
	api, endpoint, data: formData, hideLoadingFunction, autoToken, token, method, options, responseType, abortCallback,
	otherRequestOptions
})(dispatch);
