import styles from "./index.module.css";

import React from "react";
import DialogDataComponent from "../../../../../../../core/components/DialogDataComponent";
import PropTypes from "prop-types";
import {omit, cloneDeep, set} from "lodash";
import * as pageConfig from "../../../../../config";
import FormWrapper, {FormField} from "../../../../../../../core/components/advanced/FormWrapper";
import TextInput, {
	DatePreview, 
	TEXT_INPUT_LABEL_POSITION, 
	TEXT_INPUT_DATE_PREVIEW_FORMATS
} from "../../../../../../../core/components/input/TextInput";
import ToggleInput from "../../../../../../../core/components/input/ToggleInput";
import {getArray, getBoolean, getInteger, getString} from "../../../../../../../core/helpers/data";
import SelectInput from "../../../../../../../core/components/input/SelectInput";
import {DYNAMIC_VALUE_AGGREGATIONS} from "../../../../../../../components/advanced/DynamicValues";
import Separator, {LABEL_ICON_POSITION} from "../../../../../../../core/components/display/Separator";
import {REPORT_FILTER_ITEM_DISPLAY_TYPE} from "../../../../../../../const/report";
import Label from "../../../../../../../core/components/display/Label";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../../../../../../core/components/display/Button";
import Dropdown from "../../../../../../../core/components/display/Dropdown";
import {formatDate} from "../../../../../../../core/helpers/datetime";
import {Tooltip} from "react-tippy";
import {DynamicValueDataFormatDataObject} from "../../../../../../../components/advanced/DynamicValues/dataObjects";
import {openDialog} from "../../../../../../../core/helpers/dialog";
import DateFnsFormatInfoDialog from "../../../../../../../core/components/dialogs/DateFnsFormatInfoDialog";
import {icon_font_info_symbol} from "../../../../../../../config";

class PropertiesDialog extends DialogDataComponent {
	constructor(props) {
		super(props, {
			/** @type {ReportColumnDataObject} */
			data: null,
		}, {
			domPrefix: 'select-column-properties-dialog-component',
			translationPath: `${pageConfig.translationPath}.select_section.item.properties_dialog`,
		});

		// Data change handling methods
		this.handleDataFormatFieldChange = this.handleDataFormatFieldChange.bind(this);
	}

	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Get data to load into local component's state
	 * @description Create and return data that can be loaded directly into local component's state based on the raw
	 * external data (usually sent through props). In some sense this is a method that maps external data into format
	 * that component can use in its local state. This method should return data in the same format as 'getData' method.
	 * @note This method will not mutate the passed data.
	 *
	 * @param {any} rawData - External data that will be used to create local component's state compatible data.
	 * @return {any|null} Local component's state compatible data or null if data could not be loaded.
	 */
	getDataToLoad(rawData) {
		let dataToLoad = cloneDeep(rawData);

		// Set group heading to group name if 'groupHeading' is empty
		if (!getString(dataToLoad, 'groupHeading')) {
			set(dataToLoad, 'groupHeading', getString(dataToLoad, 'dynamicValue.group.name'));
		}
		// Set column heading to column name if 'columnHeading' is empty
		if (!getString(dataToLoad, 'columnHeading')) {
			set(dataToLoad, 'columnHeading', getString(dataToLoad, 'dynamicValue.name'));
		}

		return dataToLoad;
	}

	/**
	 * Get data that will be returned to the parent component
	 * @description Create and return data that will be returned to paren component (usually through onChange event)
	 * based on the local component's raw state data
	 * @note This method will not mutate the passed data.
	 *
	 * @return {any} Data that will be returned to the parent component.
	 */
	getDataToReturn() {
		let dataToReturn;

		// Filter out properties added by ReactSortable (react-sortablejs) component.
		dataToReturn = omit(this.getData(), ['chosen', 'selected', 'filtered']);

		// Set group heading to group name if 'groupHeading' is empty
		if (!getString(dataToReturn, 'groupHeading')) {
			set(dataToReturn, 'groupHeading', getString(dataToReturn, 'dynamicValue.group.name'));
		}
		// Set column heading to column name if 'columnHeading' is empty
		if (!getString(dataToReturn, 'columnHeading')) {
			set(dataToReturn, 'columnHeading', getString(dataToReturn, 'dynamicValue.name'));
		}
		
		// Clear data format values that are not relevant to the actual display type
		const displayType = getString(dataToReturn, 'displayType');
		switch (displayType) {
			case REPORT_FILTER_ITEM_DISPLAY_TYPE.TEXT:
			case REPORT_FILTER_ITEM_DISPLAY_TYPE.BOOLEAN:
			case REPORT_FILTER_ITEM_DISPLAY_TYPE.SELECT:
			case REPORT_FILTER_ITEM_DISPLAY_TYPE.ASYNC_SELECT:
				set(dataToReturn, 'dataFormat', new DynamicValueDataFormatDataObject());
				break;
			case REPORT_FILTER_ITEM_DISPLAY_TYPE.INTEGER:
				set(dataToReturn, 'dataFormat.dataFormat', null);
				set(dataToReturn, 'dataFormat.decimals', null);
				break;
			case REPORT_FILTER_ITEM_DISPLAY_TYPE.FLOAT:
				set(dataToReturn, 'dataFormat.dataFormat', null);
				break;
			case REPORT_FILTER_ITEM_DISPLAY_TYPE.DATE:
			case REPORT_FILTER_ITEM_DISPLAY_TYPE.DATETIME:
			case REPORT_FILTER_ITEM_DISPLAY_TYPE.MONTH:
				set(dataToReturn, 'dataFormat.decimals', null);
				set(dataToReturn, 'dataFormat.thousandsSeparator', null);
				break;
			// no default
		}

		return dataToReturn;
	}


	// Data change handling methods -------------------------------------------------------------------------------------
	/**
	 * Handle data format filed change
	 * @param {string} field - Data format field name.
	 * @param {any} value - Data format field value.
	 */
	handleDataFormatFieldChange(field, value) {
		this.setSubState('data.dataFormat', {[field]: value})
			// Handle empty data format
			// @description Data format cannot be empty so default data format will from dynamic value be used instead.
			.then(state => {
				if (field === 'dataFormat' && !value) {
					return this.setSubState('data.dataFormat', {
						[field]: this.getValue('dynamicValue.defaultDataFormat.dataFormat')
					});
				}
				return state;
			});
	}
	

	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that should return true if component can be rendered or false otherwise
	 * @return {boolean} True if component can be rendered or false otherwise.
	 */
	canRender() { return (this.getData() !== null) }
	
	render() {
		if (!this.canRender()) return null;
		
		/**
		 * Get select column display type
		 * @type {ReportFilterItemDisplayType}
		 */
		const displayType = this.getValue('displayType');
		
		return this.renderDialog(
			this.renderTitle(this.t('title')), (
				<FormWrapper className={styles['wrapper']}>
					<FormField
						label={this.t('Custom headings')}
					>
						<ToggleInput
							name="customHeadings"
							checked={this.getValue('customHeadings')}
							onChange={this.handleInputChange}
						/>
					</FormField>
					
					<FormField
						label={this.t('Group heading')}
					>
						<TextInput
							name="groupHeading"
							value={
								this.getValue('groupHeading') ?
									this.getValue('groupHeading') :
									this.getValue('dynamicValue.group.name')
							}
							onChange={this.handleInputChange}
							disabled={!getBoolean(this.getValue('customHeadings'))}
							placeholder={this.getValue('dynamicValue.group.name')}
						/>
					</FormField>

					<FormField
						label={this.t('Column heading')}
					>
						<TextInput
							name="columnHeading"
							value={
								this.getValue('columnHeading') ?
									this.getValue('columnHeading') :
									this.getValue('dynamicValue.name')
							}
							onChange={this.handleInputChange}
							disabled={!getBoolean(this.getValue('customHeadings'))}
							placeholder={this.getValue('dynamicValue.name')}
						/>
					</FormField>

					<FormField
						label={this.t('Contains HTML markup')}
					>
						<ToggleInput
							name="htmlAllowedInHeadings"
							checked={this.getValue('htmlAllowedInHeadings')}
							onChange={this.handleInputChange}
							disabled={!getBoolean(this.getValue('customHeadings'))}
						/>
					</FormField>

					<FormField
						label={this.t('Aggregation function')}
					>
						<SelectInput
							menuPlacement="top"
							simpleValue={true}
							isClearable={true}
							value={this.getValue('aggregateFunction')}
							options={
								DYNAMIC_VALUE_AGGREGATIONS.map(a => ({
									label: this.translate(
										a, 'BuilderPage.select_section.item.formula_dialog.aggregation_functions'
									),
									value: a
								}))
							}
							onChange={v => this.handleValueChange('aggregateFunction', v)}
						/>
					</FormField>
					
					<Separator content={this.t('Data format')} />
					{
						getArray(this.getValue('formula')).length > 0 ?
							<div className="notice default">
								<Label content={this.t('Formula does not have format settings')} />
							</div>
							:
							<React.Fragment>
								{
									displayType === REPORT_FILTER_ITEM_DISPLAY_TYPE.TEXT ||
									displayType === REPORT_FILTER_ITEM_DISPLAY_TYPE.BOOLEAN ||
									displayType === REPORT_FILTER_ITEM_DISPLAY_TYPE.SELECT ||
									displayType === REPORT_FILTER_ITEM_DISPLAY_TYPE.ASYNC_SELECT ||
									displayType === REPORT_FILTER_ITEM_DISPLAY_TYPE.MONTH ?
										<div className="notice default">
											<Label content={this.t('No format settings')} />
										</div>
										: null
								}

								<FormField label={this.t('Display type')}>
									<Label content={this.tt(displayType, 'data_types')} />
								</FormField>
								{
									displayType === REPORT_FILTER_ITEM_DISPLAY_TYPE.INTEGER ||
									displayType === REPORT_FILTER_ITEM_DISPLAY_TYPE.FLOAT ?
										<FormField
											label={this.t("Use 1000's separator")}
										>
											<ToggleInput
												checked={this.getValue('dataFormat.thousandsSeparator')}
												onChange={e => this.handleDataFormatFieldChange(
													'thousandsSeparator',
													getBoolean(e, 'target.checked')
												)}
											/>
										</FormField>
										: null
								}
								{
									displayType === REPORT_FILTER_ITEM_DISPLAY_TYPE.FLOAT ?
										<FormField
											label={this.t("Decimal places")}
											inputClassName="small"
										>
											<SelectInput
												menuPlacement="top"
												simpleValue={true}
												value={getInteger(this.getValue('dataFormat.decimals'))}
												options={[
													{label: '0', value: 0},
													{label: '1', value: 1},
													{label: '2', value: 2},
													{label: '3', value: 3},
													{label: '4', value: 4},
													{label: '5', value: 5},
													{label: '6', value: 6},
													{label: '7', value: 7},
													{label: '8', value: 8},
												]}
												onChange={v => this.handleDataFormatFieldChange('decimals', v)}
											/>
										</FormField>
										: null
								}
								{
									displayType === REPORT_FILTER_ITEM_DISPLAY_TYPE.DATE ||
									displayType === REPORT_FILTER_ITEM_DISPLAY_TYPE.DATETIME ?
										<FormField
											label={this.t('Date format')}
											multipleInputs={[0, 1]}
										>
											<Dropdown
												label={
													<Tooltip
														tag="div"
														title={this.t('date_format_button_tooltip')}
														size="small"
														position="top-center"
														arrow={true}
														interactive={false}
													>
														<Button
															className={styles['dateFormatButton']}
															icon="magic"
															displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
															displayStyle={BUTTON_STYLE.SUBTLE}
														/>
													</Tooltip>
												}
												className={styles['dateFormatDropdown']}
												labelClassName={styles['dateFormatDropdownLabel']}
												contentClassName={styles['dateFormatDropdownContent']}
											>
												{Object.keys(TEXT_INPUT_DATE_PREVIEW_FORMATS).map(formatGroupName =>
													<div key={formatGroupName} className="dropdown-group">
														<Label
															content={this.tt(formatGroupName, 'date_format_group')}
															element="div"
															elementProps={{
																className: `dropdown-group-name ${styles['dateFormatDropdownGroup']}`
															}}
														/>
														{getArray(TEXT_INPUT_DATE_PREVIEW_FORMATS, formatGroupName).map((format, index) =>
															<div
																key={index}
																className={`dropdown-item ${styles['dateFormatDropdownItem']}`}
																onClick={() => {
																	if (this.dataFormatRef) this.dataFormatRef.updateValue(format);
																}}
															>
																<Label content={formatDate(new Date(), format)} />
															</div>
														)}
													</div>
												)}
											</Dropdown>
											<TextInput
												value={this.getValue('dataFormat.dataFormat')}
												showLabel={true}
												labelPosition={TEXT_INPUT_LABEL_POSITION.RIGHT}
												previewAsLabel={true}
												getPreview={DatePreview}
												labelWidth="auto"
												onChange={e => this.handleDataFormatFieldChange(
													'dataFormat',
													getString(e, 'target.value')
												)}
												ref={node => { this.dataFormatRef = node; }}
											/>
											<small>
												<Label
													icon={icon_font_info_symbol}
													iconPosition={LABEL_ICON_POSITION.LEFT}
													content={this.t('date_format_help')}
													element="a"
													elementProps={{ 
														className: styles['link'],
														onClick: () => {
															openDialog('', DateFnsFormatInfoDialog, {
																
															}, {
																closeOnEscape: true,
																closeOnClickOutside: true,
																hideCloseBtn: false,
																maxWidth: 800
															});
														} 
													}}
												/>
											</small>
										</FormField>
										: null
								}
							</React.Fragment>
					}
				</FormWrapper>
			)
		)
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PropertiesDialog.propTypes = {
	...DialogDataComponent.propTypes,

	// Main dialog data
	// @type {ReportColumnDataObject}
	data: PropTypes.object,
};

export default PropertiesDialog;
