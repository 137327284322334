import React from "react";
import BaseComponent, {executeComponentCallbackPromise} from "../../BaseComponent";
import PropTypes from "prop-types";
import {cloneDeep, get, range} from "lodash";
import {PAGINATION_LABEL_TYPE, PAGINATION_LABEL_TYPES} from "./const";

import styles from "./index.module.css";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../display/Button";
import NumberLabel from "../../display/NumberLabel";
import {pagination_default_max_page_buttons, pagination_default_per_page} from "../../../../config";

/**
 * Dynamic pagination
 * @description Component used to render dynamic pagination where dynamic means that it gets the total number of results
 * and dynamically calculates first and last page based on the number of items per page.
 */
class Pagination extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: 'Pagination',
		});

		// Custom component methods
		this.getPageNo = this.getPageNo.bind(this);
		this.totalPages = this.totalPages.bind(this);
		this.getAvailablePages = this.getAvailablePages.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}

	/**
	 * Get page number
	 * @return {number}
	 */
	getPageNo() { return (this.props.pageNo < 1 ? 1 : this.props.pageNo); }
	
	/**
	 * Get total number of pages
	 * @return {number}
	 */
	totalPages() {
		const {totalResults, perPage} = this.props;
		return Math.ceil(totalResults / perPage);
	}

	/**
	 * Get available page numbers to render
	 * @return {number[]}
	 */
	getAvailablePages() {
		const {maxPages} = this.props;
		const pageNo = this.getPageNo();
		const totalPages = this.totalPages();
		
		let minPageNumber;
		let maxPageNumber;
		
		// If max pages number is odd
		if (maxPages % 2 === 1) {
			minPageNumber = pageNo - ((maxPages - 1) / 2);
			maxPageNumber = pageNo + ((maxPages - 1) / 2);
		}
		// If max pages number is even
		else {
			minPageNumber = pageNo - ((maxPages/ 2) - 1);
			maxPageNumber = pageNo + (maxPages/ 2);
		}

		// First 2 pages
		if (minPageNumber < 1) {
			minPageNumber = 1;
			maxPageNumber = (totalPages >= maxPages) ? maxPages : totalPages;
		}

		// Last 2 pages
		else if (maxPageNumber > totalPages) {
			minPageNumber = (totalPages - (maxPages - 1) >= 1) ? totalPages - (maxPages - 1) : 1;
			maxPageNumber = totalPages;
		}
		
		return range(minPageNumber, maxPageNumber + 1);
	}

	/**
	 * Handle page button click
	 * @param {number} pageNo - Page number that has been clicked.
	 * @return {Promise<any>} Promise that resolves to the result of the executed event callback function or null if
	 * function does not exist or no data if sort event callback function was not celled at all.
	 */
	handleClick(pageNo) {
		// Trigger 'onPageSelect' event
		return executeComponentCallbackPromise(this.props.onPageSelect, pageNo);
	}

	/**
	 * Method that should return true if component can be rendered or false otherwise
	 * @return {boolean} True if component can be rendered or false otherwise.
	 */
	canRender() { return this.getProp('totalResults') > 0 }

	render()	{
		// Do not render component if 'canRender' returns false
		if (!this.canRender()) return null;

		const {className, perPage, totalResults, hideFirstAndLast, hideNumbers, hideLabel} = this.props;
		const pageNo = this.getPageNo();
		const labelType = get(this.props, 'labelType', PAGINATION_LABEL_TYPE.RANGE);

		const availablePages = this.getAvailablePages();
		const singlePage = (totalResults <= perPage);

		return (
			!singlePage ?
				<div className={`pagination-wrapper dynamic ${className} ${styles['wrapper']} ${styles['dynamic']}`}>
					<div className={`pagination-buttons ${className} ${styles['buttons']}`}>
						{
							!hideFirstAndLast ?
								<Button
									displayType={BUTTON_DISPLAY_TYPE.NONE}
									className={`pagination-button first-button ${styles['button']} ${styles['first']}`}
									label={this.t('First')}
									allowHtmlLabel={true}
									disabled={(pageNo === 1)}
									onClick={() => this.handleClick(1)}
								/>
								: null
						}
	
						<Button
							displayType={BUTTON_DISPLAY_TYPE.NONE}
							className={`pagination-button prev-button ${styles['button']} ${styles['prev']}`}
							label={this.t('Prev')}
							allowHtmlLabel={true}
							disabled={(pageNo === 1)}
							onClick={() => this.handleClick(pageNo - 1)}
						/>
	
						{
							!hideNumbers ?
								availablePages.map(item =>
									<Button
										key={item}
										displayType={(pageNo === item ? BUTTON_DISPLAY_TYPE.SOLID : BUTTON_DISPLAY_TYPE.NONE)}
										displayStyle={(pageNo === item ? BUTTON_STYLE.ACTION : BUTTON_STYLE.DEFAULT)}
										className={
											`pagination-button page-button ${styles['button']} ${styles['page']}` +
											(pageNo === item ? ` active ${styles['active']}` : '')
										}
										label={item.toString()}
										onClick={() => this.handleClick(item)}
									/>
								)
								: null
						}
	
						<Button
							displayType={BUTTON_DISPLAY_TYPE.NONE}
							className={`pagination-button next-button ${styles['button']} ${styles['next']}`}
							label={this.t('Next')}
							allowHtmlLabel={true}
							disabled={(pageNo === this.totalPages())}
							onClick={() => this.handleClick(pageNo + 1)}
						/>
	
						{
							!hideFirstAndLast ?
								<Button
									displayType={BUTTON_DISPLAY_TYPE.NONE}
									className={`pagination-button last-button ${styles['button']} ${styles['last']}`}
									label={this.t('Last')}
									allowHtmlLabel={true}
									disabled={(pageNo === this.totalPages())}
									onClick={() => this.handleClick(this.totalPages())}
								/>
								: null
						}
					</div>

					{
						!hideLabel ?
							labelType === PAGINATION_LABEL_TYPE.TOTAL ?
								<span className={`pagination-label total ${styles['label']} ${styles['total']}`}>
									<NumberLabel
										prefix={`${this.t('Total results', 'general')}: `}
										number={totalResults}
									/>
								</span>
								: labelType === PAGINATION_LABEL_TYPE.RANGE ?
								<span className={`pagination-label range ${styles['label']} ${styles['range']}`}>
									{
										`${pageNo * perPage - perPage + 1} - ` +
										(pageNo * perPage < totalResults ? pageNo * perPage : totalResults) +
										` ${this.t('of')} `
									}
									<NumberLabel number={totalResults} />
								</span>
								: null
							: null
					}
				</div>
				:
				<div className={`pagination-wrapper no-pages dynamic ${className} ${styles['wrapper']} ${styles['no-pages']} ${styles['dynamic']}`}>
					{
						!hideLabel ?
							<span className={`pagination-label total ${styles['label']} ${styles['total']}`}>
								<NumberLabel
									prefix={`${this.t('Total results', 'general')}:`}
									number={totalResults}
								/>
							</span>
							: null
					}
				</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
Pagination.propTypes = {
	// Pagination wrapper element class attribute
	className: PropTypes.string,
	// Current page number
	pageNo: PropTypes.number,
	// Number of items per page
	perPage: PropTypes.number,
	// Total number of items
	totalResults: PropTypes.number,
	// Flag that determines if navigation buttons for first and last page will be rendered
	hideFirstAndLast: PropTypes.bool,
	// Flag that determines if page number buttons will be rendered
	// @note If false, only navigation buttons (first, prev, next, ...) will be rendered.
	hideNumbers: PropTypes.bool,
	// Flag that determine if pagination label will be rendered
	hideLabel: PropTypes.bool,
	// Maximal number of page buttons to render including the current page
	maxPages: PropTypes.number,
	// Pagination label type
	labelType: PropTypes.oneOf(PAGINATION_LABEL_TYPES),

	// Events
	onPageSelect: PropTypes.func, // Arguments: pageNo
};

/**
 * Define component default values for own props
 */
Pagination.defaultProps = {
	className: '',
	pageNo: 1,
	perPage: pagination_default_per_page,
	hideFirstAndLast: false,
	hideNumbers: false,
	hideLabel: false,
	maxPages: pagination_default_max_page_buttons,
	labelType: PAGINATION_LABEL_TYPE.RANGE
};


/**
 * Static pagination
 * @description Component used to render static pagination where static means that it does not get the total number of 
 * results but it must receive the last page flag from the data source to determine if there should be a next page. This
 * component will not render individual page buttons.
 */
class PaginationStatic extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: 'Pagination',
		});

		// Initialize initial state
		this.initialState = {
			/**
			 * Last page number
			 * @note This becomes available when 'isLastPage' prop is set to true.
			 * @type {number}
			 */
			lastPageNo: undefined,
		};

		// Set initial component's internal state
		this.state = cloneDeep(this.initialState);

		// Custom component methods
		this.totalPages = this.totalPages.bind(this);
		this.getPageNo = this.getPageNo.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}
	
	componentDidMount() {
		super.componentDidMount();

		// Set last page number if last page has been reached
		if (this.props.isLastPage === true) this.setState({lastPageNo: this.props.pageNo});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// Set last page number if last page has been reached
		if (this.props.isLastPage !== prevProps.isLastPage && this.props.isLastPage === true) {
			this.setState({lastPageNo: this.props.pageNo});
		}
	}

	/**
	 * Get total number of pages
	 * @return {number|undefined}
	 */
	totalPages() { return this.state.lastPageNo; }

	/**
	 * Get page number
	 * @return {number}
	 */
	getPageNo() { return (this.props.pageNo < 1 ? 1 : this.props.pageNo); }

	/**
	 * Handle page button click
	 * @param {number} pageNo - Page number that has been clicked.
	 * @return {Promise<any>} Promise that resolves to the result of the executed event callback function or null if
	 * function does not exist or no data if sort event callback function was not celled at all.
	 */
	handleClick(pageNo) {
		// Trigger 'onPageSelect' event
		return executeComponentCallbackPromise(this.props.onPageSelect, pageNo);
	}

	render()	{
		const {className, perPage, hideFirstAndLast, hideLabel, isLastPage, pageItemsCount} = this.props;
		const pageNo = this.getPageNo();
		const singlePage = (isLastPage && pageNo === 1);
		const totalRows = (singlePage ? pageItemsCount : ((pageNo - 1) * perPage) + pageItemsCount);

		return (
			!singlePage ?
				<div className={`pagination-wrapper static ${className} ${styles['wrapper']} ${styles['static']}`}>
					{
						!hideFirstAndLast ?
							<Button
								displayType={BUTTON_DISPLAY_TYPE.NONE}
								className={`pagination-button first-button ${styles['button']} ${styles['first']}`}
								label={this.t('First')}
								allowHtmlLabel={true}
								disabled={(pageNo === 1)}
								onClick={() => this.handleClick(1)}
							/>
							: null
					}

					<Button
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						className={`pagination-button prev-button ${styles['button']} ${styles['prev']}`}
						label={this.t('Prev')}
						allowHtmlLabel={true}
						disabled={(pageNo === 1)}
						onClick={() => this.handleClick(pageNo - 1)}
					/>

					{
						!hideLabel ?
							<span className={`pagination-label range ${styles['label']} ${styles['range']}`}>
							{
								`${pageNo * perPage - perPage + 1} - ` +
								`${(isLastPage ? totalRows : pageNo * perPage)}` +
								` ${this.t('of')} `
							}
								<NumberLabel number={totalRows} suffix={(isLastPage ? '' : '+')} />
						</span>
							: null
					}

					<Button
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						className={`pagination-button next-button ${styles['button']} ${styles['next']}`}
						label={this.t('Next')}
						allowHtmlLabel={true}
						disabled={isLastPage}
						onClick={() => this.handleClick(pageNo + 1)}
					/>

					{
						!hideFirstAndLast && this.totalPages() ?
							<Button
								displayType={BUTTON_DISPLAY_TYPE.NONE}
								className={`pagination-button last-button ${styles['button']} ${styles['last']}`}
								label={this.t('Last')}
								allowHtmlLabel={true}
								disabled={isLastPage}
								onClick={() => this.handleClick(this.totalPages())}
							/>
							: null
					}
				</div>
				:
				<div className={`pagination-wrapper no-pages static ${className} ${styles['wrapper']} ${styles['no-pages']} ${styles['static']}`}>
					{
						!hideLabel ?
							<span className={`pagination-label total ${styles['label']} ${styles['total']}`}>
								<NumberLabel
									prefix={`${this.t('Total results', 'general')}: `}
									number={totalRows}
								/>
							</span>
							: null
					}
				</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PaginationStatic.propTypes = {
	// Pagination wrapper element class attribute
	className: PropTypes.string,
	// Current page number
	pageNo: PropTypes.number,
	// Number of items per page
	perPage: PropTypes.number,
	// Flag that determines if navigation buttons for first and last page will be rendered
	hideFirstAndLast: PropTypes.bool,
	// Flag that determine if pagination label will be rendered
	hideLabel: PropTypes.bool,
	// Flag that specifies if pagination is on last page
	// @note This is only used for pagination that does not have 'totalResults' prop specified.
	isLastPage: PropTypes.bool,
	// Actual number of items (rows) on the current page
	// @note This can only be different from 'perPage' on the last page if the last. 
	pageItemsCount: PropTypes.number,

	// Events
	onPageSelect: PropTypes.func, // Arguments: pageNo
};

/**
 * Define component default values for own props
 */
PaginationStatic.defaultProps = {
	className: '',
	pageNo: 1,
	perPage: pagination_default_per_page,
	hideFirstAndLast: false,
	hideLabel: false
};

export default Pagination;
export {PaginationStatic};
export * from "./const";