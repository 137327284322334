/**
 * @typedef {string} ReportStatus
 */
export const REPORT_STATUS_PENDING = 'PENDING';
export const REPORT_STATUS_PROCESSING = 'PROCESSING';
export const REPORT_STATUS_COMPLETED = 'COMPLETED';
export const REPORT_STATUS_ERROR = 'ERROR';
/**
 * @enum {ReportStatus}
 */
export const REPORT_STATUS = {
	PENDING: REPORT_STATUS_PENDING,
	PROCESSING: REPORT_STATUS_PROCESSING,
	COMPLETED: REPORT_STATUS_COMPLETED,
	ERROR: REPORT_STATUS_ERROR,
}
/**
 * @type {ReportStatus[]}
 */
export const REPORT_STATUSES = [
	REPORT_STATUS_PENDING, REPORT_STATUS_PROCESSING, REPORT_STATUS_COMPLETED, REPORT_STATUS_ERROR
];