import {ioJsonFetchAction, ioJsonFetchItemAction, ioJsonSaveAction} from "../../core/store/actions/io";
import {hideLoading, hideLoadingFunction, showLoading} from "../../core/helpers/loading";
import {actionCreators} from "../../core/store/reducers";
import * as accountPermissionListItemDataMap from "./dataMap/accountPermissionListItem";
import * as accountPermissionDataMap from "./dataMap/accountPermission";
import {getArray} from "../../core/helpers/data";

/**
 * Fetch the list of account permissions
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchAccountPermissionListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'permission/search',
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy,
		sortDir
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => ({
			...responseData,
			data: getArray(responseData, 'data').map(i => accountPermissionListItemDataMap.input(i))
		}));
};

/**
 * Load the list of account permissions into Redux store
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const loadAccountPermissionListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {
	const loading = showLoading("#main-page-table");
	return fetchAccountPermissionListAction(abortCallback, filter, pageNo, perPage, sortBy, sortDir)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators.accountPermissions.setAccountPermissionListData(responseData));
			hideLoading(loading);
			return responseData;
		});
};

/**
 * Clear account permission list form Redux store
 * 
 * @return {(function(*): void)|*}
 */
export const clearAccountPermissionListAction = () => dispatch => {
	actionCreators.accountPermissions.clearAccountPermissionListData();
}


/**
 * Fetch account permission
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - DB ID of the account permission to fetch.
 * @return {function(*=): Promise<AccountPermissionDataObject>}
 */
export const fetchAccountPermissionAction = (abortCallback, id) => dispatch => {
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'permission/fetch-by-id',
		id,
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => accountPermissionDataMap.input(responseData?.data));
};

/**
 * Load account permission into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - DB ID of the account permission to load.
 * @return {function(*=): Promise<AccountPermissionDataObject>}
 */
export const loadAccountPermissionAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#account-permission-popup');
	return fetchAccountPermissionAction(abortCallback, id)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators.accountPermissions.setAccountPermission(responseData));
			hideLoading(loading);
			return responseData;
		});
}

/**
 * Clear account permission from Redux store
 * @return {(function(*=): void)|*}
 */
export const clearAccountPermissionAction = () => dispatch => {
	dispatch(actionCreators.accountPermissions.clearAccountPermission());
};

/**
 * Create account permission
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {AccountPermissionDataObject} accountPermission - Account permission to save.
 * @return {function(*=): Promise<AccountPermissionDataObject>} Promise that wil resolve with the created account
 * permission received from IO.
 */
export const createAccountPermissionAction = (abortCallback, accountPermission) => dispatch => {
	const loading = showLoading('#account-permission-popup', true, true);
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'permission/create',
		{
			id: '',
			data: accountPermissionDataMap.output(accountPermission)
		},
		null,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => accountPermissionDataMap.input(responseData?.data));
};

/**
 * Update account permission
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} accountPermissionId - DB ID of the account permission to update.
 * @param {AccountPermissionDataObject} accountPermission - Account permission to save.
 * @return {function(*=): Promise<AccountPermissionDataObject>} Promise that wil resolve with the updated account 
 * permission received from IO.
 */
export const updateAccountPermissionAction = (abortCallback, accountPermissionId, accountPermission) => dispatch => {
	const loading = showLoading('#account-permission-popup', true, true);
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'permission/update-by-id',
		{
			id: accountPermissionId,
			data: accountPermissionDataMap.output(accountPermission)
		},
		null,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => accountPermissionDataMap.input(responseData?.data));
};