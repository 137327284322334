/**
 * Abstract dialog component using local state data used to create other dialog components
 * NOTE: Components created using this abstract component will have a 'data' field in the local state which will store
 * main component's data. This is a convention chosen by design to separate main component's data (data need by the
 * component to work properly) and other local state values used for GUI or other less significant or more specific
 * purposes.
 */

import React from "react";
import DataComponent, {executeComponentCallback} from "./DataComponent";
import PropTypes from "prop-types";
import {isset} from "../helpers/data";
import {isMacintosh} from "../helpers/system";
import Label from "./display/Label";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "./display/Button";
import {LABEL_ICON_POSITION} from "./display/Label";
import {
	icon_font_close_symbol,
	icon_font_create_symbol,
	icon_font_delete_symbol,
	icon_font_restore_symbol, icon_font_save_symbol
} from "../../config";
import {cloneDeep} from "lodash";

export default class DialogDataComponent extends DataComponent {
	/**
	 * Class constructor
	 *
	 * @param {object} [props] - Component props.
	 * @param {object} [initialState={}] - Initial state from child class that will override the default initial state.
	 * @param {DialogDataComponentOptions} [options={}] - Component options from child class that will override the 
	 * default options.
	 */
	constructor(props, initialState = {}, options = {}) {
		/**
		 * Set component options by combining default options overridden by any options from 'options' argument
		 * @type {DialogDataComponentOptions}
		 * @private
		 */
		const _options = {
			/**
			 * CSS text-align value that will be used for dialog content
			 * @type {'left'|'right'|'center'|'justify'|'initial'|'inherit'}
			 */
			alignContent: 'initial',

			...cloneDeep(options)
		};
		
		super(props, initialState, _options);

		// Dialog methods
		this.save = this.save.bind(this);
		this.delete = this.delete.bind(this);
		this.close = this.close.bind(this);

		// Render methods
		this.renderTitle = this.renderTitle.bind(this);
		this.renderCustomIconTitle = this.renderCustomIconTitle.bind(this);
		this.renderActionButtons = this.renderActionButtons.bind(this);
		this.renderDataActionButtons = this.renderDataActionButtons.bind(this);
		this.renderDialog = this.renderDialog.bind(this);
		this.renderDialogWithCustomButtons = this.renderDialogWithCustomButtons.bind(this);
	}

	/**
	 * Save dialog method
	 * @note This method should be called when dialog's save button is clicked. This method does not actually save any
	 * data to the DB or anywhere else. That should be done by the parent component.
	 */
	save() {
		const {dialogGUIID} = this.props;
		const data = this.getDataToReturn();

		// Do the validation
		const isValid = this.validate();

		// If validation is successful
		if(isValid){
			// Trigger component's onSave event with component's return data as param
			executeComponentCallback(this.props.onSave, data, dialogGUIID);
		}
	}

	/**
	 * Delete dialog method
	 * @note This method should be called when dialog's delete button is clicked. This method does not actually delete
	 * any data from the DB or anywhere else. That should be done by the parent component.
	 */
	delete() {
		const {dialogGUIID} = this.props;
		const data = this.getDataToReturn();

		// Trigger component's onDelete event with component's return data as param
		executeComponentCallback(this.props.onDelete, data, dialogGUIID);
	}

	/**
	 * Dialog close method
	 * @note This method should be called when dialog's close button is clicked. This method does not actually close the
	 * dialog. That should be done by the parent component.
	 */
	close() {
		const {dialogGUIID, dialogCloseAction} = this.props;
		dialogCloseAction(dialogGUIID);
	}

	/**
	 * Render standard dialog title
	 * @description Use this method to render a dialog title with standard CSS class and structure.
	 *
	 * @param {any} label - Dialog title label.
	 * @param {string} [className='title'] - Use this to override the title's standard CSS class.
	 * @return {JSX.Element|null} Standard dialog title element or null if 'label' is not specified or is empty.
	 */
	renderTitle(label, className = 'title') {
		return (
			label ? <div className={className}>{label}</div> : null
		);
	}

	/**
	 * Render a custom dialog title with icon
	 * @note Style should be defined by the skin.
	 *
	 * @param {string|string[]} icon - Dialog title icon symbol name or array symbol names.
	 * @param {string} [label=''] - Dialog title label.
	 * @param {string} [className='title'] - Use this to override the title's standard CSS class.
	 * @param {string} [iconSymbolPrefix] - Font icon symbol prefix.
	 * @return {JSX.Element|null} Custom dialog title with icon or null.
	 */
	renderCustomIconTitle(icon, label = '', className = 'title', iconSymbolPrefix) {
		return this.renderTitle(
			<Label
				icon={icon}
				iconPosition={LABEL_ICON_POSITION.NONE}
				iconSymbolPrefix={iconSymbolPrefix}
				content={label ? <span className="title-label">{label}</span> : null}
			/>,
			`custom ${className}`
		);
	}

	/**
	 * Render dialog action buttons
	 * @note Low-level method to render any dialog action buttons.
	 *
	 * @param {DialogButton[]} buttons - Array of buttons to render.
	 * @param {string} [className=''] - Buttons wrapper additional CSS class.
	 * @return {*} Action buttons JSX to use in the main render method.
	 */
	renderActionButtons(buttons = [], className = '') {
		return (
			buttons.length > 0 ?
				<div className={`buttons ${className}`}>
					{buttons.map((button, index) =>
						<Button
							key={index}
							icon={button.icon}
							displayType={button.type ? button.type : BUTTON_DISPLAY_TYPE.SOLID}
							displayStyle={button.style ? button.style : BUTTON_STYLE.DEFAULT}
							onClick={button.onClick}
						>
							{button.label ? button.label : null}
						</Button>
					)}
				</div>
				:
				null
		);
	}
	
	/**
	 * Render dialog action buttons used by data dialogs
	 * @note Every dialog that is used to manipulate some data should use these action buttons on the bottom of the main
	 * render method. Standard buttons include create, save, delete, restore and close and they will be rendered 
	 * depending on dialog type and available events (isNew, isRestore, onDelete, ...).
	 * 
	 * @param {string|null} [createLabel] - Label used for create button. Default value will be loaded from translation file.
	 * @param {string|null} [createIcon] - Optional icon used for create button.
	 * @param {string|null} [deleteLabel] - Label used for delete button. Default value will be loaded from translation file.
	 * @param {string|null} [deleteIcon] - Optional icon used for delete button.
	 * @param {string|null} [saveLabel] - Label used for save button. Default value will be loaded from translation file.
	 * @param {string|null} [saveIcon] - Optional icon used for save button.
	 * @param {string|null} [closeLabel] - Label used for close button. Default value will be loaded from translation file.
	 * @param {string|null} [closeIcon] - Optional icon used for close button.
	 * @param {string|null} [restoreLabel] - Label used for restore button. Default value will be loaded from translation 
	 * file.
	 * @param {string|null} [restoreIcon] - Optional icon used for restore button.
	 * @param {boolean} [hideClose=false] - If true, close button will not be rendered. This is useful for confirm 
	 * dialogs. 
	 * @return {*} Action buttons JSX to use in the main render method.
	 */
	renderDataActionButtons({
		createLabel = undefined, createIcon = undefined, deleteLabel = undefined, deleteIcon = undefined, 
		saveLabel = undefined, saveIcon = undefined, closeLabel = undefined, closeIcon = undefined, 
		restoreLabel = undefined, restoreIcon = undefined
	} = {}, hideClose = false) {
		const {isNew, isRestore, onDelete} = this.props;
		
		let buttons = [];
		
		// Close/cancel button
		if (isMacintosh() && !hideClose) buttons.push({
			style: BUTTON_STYLE.DEFAULT,
			label: (closeLabel ? closeLabel : this.t('Close', 'general')),
			icon: (isset(closeIcon) && closeLabel !== null ? closeIcon : icon_font_close_symbol),
			onClick: this.close,
		});
		
		// Delete button
		if (onDelete && !isNew) buttons.push({
			style: BUTTON_STYLE.ERROR,
			label: (deleteLabel ? deleteLabel : this.t('Delete', 'general')),
			icon: (isset(deleteIcon) && deleteIcon !== null ? deleteIcon : icon_font_delete_symbol),
			onClick: this.delete,
		});
		
		// Save buttons (create, restore and save)
		if (isNew) buttons.push({
			style: BUTTON_STYLE.SUCCESS,
			label: (createLabel ? createLabel : this.t('Create', 'general')),
			icon: (typeof createIcon === 'undefined' ? icon_font_create_symbol : createIcon),
			onClick: this.save,
		});
		else if (isRestore) buttons.push({
			style: BUTTON_STYLE.SUCCESS,
			label: (restoreLabel ? restoreLabel : this.t('Restore', 'general')),
			icon: (isset(restoreIcon) && restoreIcon !== null ? restoreIcon : icon_font_restore_symbol),
			onClick: this.save,
		});
		else buttons.push({
			style: BUTTON_STYLE.SUCCESS,
			label: (saveLabel ? saveLabel : this.t('Save', 'general')),
			icon: (isset(saveIcon) && saveIcon !== null ? saveIcon : icon_font_save_symbol),
			onClick: this.save,
		});

		// Close/cancel button
		if (!isMacintosh() && !hideClose) buttons.push({
			style: BUTTON_STYLE.DEFAULT,
			label: (closeLabel ? closeLabel : this.t('Close', 'general')),
			icon: (isset(closeIcon) && closeLabel !== null ? closeIcon : icon_font_close_symbol),
			onClick: this.close,
		});
		
		return this.renderActionButtons(buttons);
	}
	
	/**
	 * Use this method to render dialog structure with action buttons based on dialog type
	 * 
	 * @param title - Main dialog title to render inside the standard dialog structure.
	 * @param content - Main dialog content to render inside the standard dialog structure.
	 * @param buttonOptions - Action button options used by the 'renderDataActionButtons' method.
	 * @return {JSX.Element} - Dialog JSX with action buttons based on dialog type.
	 */
	renderDialog(title = null, content, ...buttonOptions) {
		const alignContent = this.getOption('alignContent');
		
		return (
			<div className={`dialog-content-component type-data`}>
				{title ? title : null}
				
				<div className="content" style={{textAlign: alignContent}}>
					{content}
				</div>

				{this.renderDataActionButtons(...buttonOptions)}
			</div>
		);
	}

	/**
	 * Use this method to render dialog structure with custom action buttons based on dialog type
	 *
	 * @param title - Main dialog title to render inside the standard dialog structure.
	 * @param content - Main dialog content to render inside the standard dialog structure.
	 * @param {DialogButton[]} buttons - Array of buttons to render.
	 * @return {JSX.Element} - Dialog JSX with action buttons based on dialog type.
	 */
	renderDialogWithCustomButtons(title = null, content, buttons = []) {
		const alignContent = this.getOption('alignContent');
		
		return (
			<div className={`dialog-content-component type-data`}>
				{title ? title : null}

				<div className="content" style={{textAlign: alignContent}}>
					{content}
				</div>

				{this.renderActionButtons(buttons)}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
DialogDataComponent.propTypes = {
	// Unique GUI ID of the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogGUIID: PropTypes.string,
	// Dialog options
	// @note This is automatically sent by the global Dialog component.
	dialogOptions: PropTypes.object,
	// Action used to close the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogCloseAction: PropTypes.func,
	// Component's input data
	data: PropTypes.any,
	// Component's original data used to determine used to determine if data has changed
	originalData: PropTypes.any,
	// Flag that specifies if dialog is opened for a new (create dialog) or an existing item (edit dialog). If true
	// dialog is opened for a new item.
	isNew: PropTypes.bool,
	// Flag that specifies if dialog is opened to restore the deleted item (restore dialog). If true dialog is opened to
	// restore an item.
	isRestore: PropTypes.bool,

	// Events
	onClose: PropTypes.func,
	onChange: PropTypes.func,
	onSave: PropTypes.func,
	onDelete: PropTypes.func
};

/**
 * Define component default values for own props
 */
DialogDataComponent.defaultProps = {
	isNew: false,
	isRestore: false,
};

export {executeComponentCallback, executeComponentCallbackPromise} from "./BaseComponent";
export {DIALOG_TYPE, DIALOG_TYPES} from "./DialogComponent";

// Type definitions
/**
 * @typedef {Object} DialogDataComponentOptions
 * @property {string} [translationPath] - Path inside the translation JSON file where component translations are
 * defined.
 * @property {string} [domPrefix='base-component'] - Prefix used for component's main DOM element. This is used in
 * methods like 'getDomId'.
 * @property {boolean} [forceLinearState=false] - Flag that determines if set state will use the linear mode. Linear
 * mode uses the 'setStateQueue' to ensure that 'setState' methods will be executed linearly. This means that
 * consequent 'setState' calls will run only after all previous 'setState' calls are finished setting the state.
 * @property {boolean} [forceFastState=false] - Flag that determines if set state queue will use the fast mode.
 * WARNING: Set state queue fast mode does not guarantee linear set state for non async set state calls, for example
 * if linear set state method is called within a for loop. Async calls should work properly.
 * @property {number} [domManipulationIntervalTimeout=0] - Timeout in ms (milliseconds) for DOM manipulation interval.
 * If less than zero DOM manipulation interval will be disabled.
 * @property {boolean} [optimizedUpdate=false] - Flag that determines if set component will skip updates if both props
 * and state are equal.
 * @property {boolean} [forceFastLoad=false] - Flag that determines if load queue will use the fast mode. WARNING: Load
 * queue fast mode does not guarantee linear loads for non async load calls, for example if load method s called within
 * a for loop. Async calls should work properly.
 * @property {boolean} [disableLoad=false] - Flag that determines if load functionality is disabled. If true 'load'
 * method will not load data from props into local state.
 * @property {boolean} [enableLoadOnDataPropChange=false] - Flag that determines if data will be loaded from props to
 * local state every time data prop changes. This flag will be ignored if 'disableLoad' is true.
 * @property {string} [dataPropAlias=''] - Main data prop alisa. This is used by child components that need to have a
 * different prop field for main data, like input components that use 'value' instead of 'data'.
 * @property {string} [originalDataPropAlias=''] - Original data prop alisa. This is used by child components that need
 * to have a different prop field for original data, like input components that use 'originalValue' instead of
 * 'originalData'.
 * @property {CustomTypeOptions} [customType={}] - Custom type options if component should use custom type as main data.
 * @property {boolean} [wholePropAsData=false] - Flag that determines if whole props will be used as main data on load
 * instead of 'data' prop or 'dataPropAlias' options.
 * @property {'left'|'right'|'center'|'justify'|'initial'|'inherit'} [alignContent='initial'] - CSS text-align value
 * that will be used for dialog content.
 */

/**
 * @typedef {object} DialogButton
 * @property {string} [type]
 * @property {string} [style]
 * @property {string} [label]
 * @property {string} [icon]
 * @property {Function} onClick
 */