import {translate, translatePath} from "../core/i18n";
import {SCHEDULE_INTERVAL_TYPE} from "../pages/schedule/const";
import {capitalize, cloneDeep} from "lodash";
import {formatNumber, getAppNumberLocaleCode} from "../core/helpers/number";
import {getDate, getDateString} from "../core/helpers/datetime";
import {getAppDatePluginLocale} from "../core/helpers/locale";

export const getScheduleIntervalDescription = (scheduleIntervalType, scheduleDays, reportGenerationTime) => {
	// Translate path
	let result = '';
	
	switch (scheduleIntervalType) {
		case SCHEDULE_INTERVAL_TYPE.DATE_OF_WEEK:
			if (scheduleDays.length === 1) {
				result += ` ${capitalize(translatePath(`constants.schedule.interval_days.${scheduleDays[0]}.prefix`))}`;
				result += ` ${translatePath(`constants.schedule.interval_days.${scheduleDays[0]}.label`)}`;
			} else if (scheduleDays.length > 1) {
				let scheduleWeekDaysWithoutLast = cloneDeep(scheduleDays);
				const lastWeekDay = scheduleWeekDaysWithoutLast.pop();
				scheduleWeekDaysWithoutLast.forEach((d, idx) => {
					if (idx === 0) {
						result += ` ${capitalize(translatePath(`constants.schedule.interval_days.${d}.prefix`))}`;
					}
					result += ` ${translatePath(`constants.schedule.interval_days.${d}.label`)}`;
					if (idx < scheduleWeekDaysWithoutLast.length - 1) result += `,`;
				});
				result += ` ${translate('AND', 'constants')}`;
				result += ` ${translatePath(`constants.schedule.interval_days.${lastWeekDay}.label`)}`;
			}
			break;
			
		case SCHEDULE_INTERVAL_TYPE.DATE_OF_MONTH:
			result += ` ${capitalize(translatePath('constants.schedule.every'))}`;
			if (scheduleDays.length === 1) {
				result += ` ${formatNumber(scheduleDays[0], '0o', getAppNumberLocaleCode())}`;
			} else if (scheduleDays.length > 1) {
				let scheduleMonthDaysWithoutLast = cloneDeep(scheduleDays);
				const lastMothDay = scheduleMonthDaysWithoutLast.pop();
				scheduleMonthDaysWithoutLast.forEach((d, idx) => {
					result += ` ${formatNumber(d, '0o', getAppNumberLocaleCode())}`;
					if (idx < scheduleMonthDaysWithoutLast.length - 1) result += `,`;
				});
				result += ` ${translate('AND', 'constants')}`;
				result += ` ${formatNumber(lastMothDay, '0o', getAppNumberLocaleCode())}`;
			}
			result += ` ${translatePath('constants.schedule.monthly_suffix')}`;
			break;
			
		case SCHEDULE_INTERVAL_TYPE.DATE_OF_YEAR:
			result += ` ${capitalize(translatePath('constants.schedule.every'))}`;
			if (scheduleDays.length === 1) {
				result += ` ${getDateString(
					getDate(scheduleDays[0], 'M-d'), 
					'do MMM', 
					getAppDatePluginLocale()
				)}`;
			} else if (scheduleDays.length > 1) {
				let scheduleYearDaysWithoutLast = cloneDeep(scheduleDays);
				const lastYearDay = scheduleYearDaysWithoutLast.pop();
				scheduleYearDaysWithoutLast.forEach((d, idx) => {
					result += ` ${getDateString(
						getDate(d, 'M-d'),
						'do MMM',
						getAppDatePluginLocale()
					)}`;
					if (idx < scheduleYearDaysWithoutLast.length - 1) result += `,`;
				});
				result += ` ${translate('AND', 'constants')}`;
				result += ` ${getDateString(
					getDate(lastYearDay, 'M-d'),
					'do MMM',
					getAppDatePluginLocale()
				)}`;
			}
			result += ` ${translatePath('constants.schedule.yearly_suffix')}`;
			break;
			
		// no default
	}
	
	result += ` ${translatePath('constants.schedule.at')} ${reportGenerationTime}`;
	result += translatePath('constants.schedule.timeSuffix');
	
	return result;
};