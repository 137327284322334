import {
	icon_font_loading_symbol, icon_font_close_symbol, icon_font_info_symbol, icon_font_help_symbol, 
	icon_font_help_circle_symbol, icon_font_required_symbol, icon_font_success_symbol, icon_font_warning_symbol, 
	icon_font_error_symbol, icon_font_create_symbol, icon_font_save_symbol, icon_font_edit_symbol, 
	icon_font_delete_symbol, icon_font_restore_symbol 
} from "../../config";

export const DEFAULT_ICON_SYMBOLS = {
	LOADING: icon_font_loading_symbol, 
	CLOSE: icon_font_close_symbol, 
	INFO: icon_font_info_symbol,
	HELP: icon_font_help_symbol,
	HELP_CIRCLE: icon_font_help_circle_symbol,
	REQUIRED: icon_font_required_symbol,
	SUCCESS: icon_font_success_symbol, 
	WARNING: icon_font_warning_symbol, 
	ERROR: icon_font_error_symbol,
	CREATE: icon_font_create_symbol, 
	SAVE: icon_font_save_symbol, 
	EDIT: icon_font_edit_symbol, 
	DELETE: icon_font_delete_symbol,
	RESTORE: icon_font_restore_symbol
}