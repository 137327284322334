import {forOwn, set, cloneDeep} from "lodash";
import {format} from "date-fns";
import {STANDARD_DATE_TIME_FORMAT} from "../../../core/const/datetime";
import {resetObjectValues} from "../../../core/helpers/data";

/**
 * Get output value from input
 * @param {any} input - Input value.
 * @return {*}
 */
export const output = input => {
	let result = cloneDeep(input);

	forOwn(result, (value, key) => {
		// Convert Date object to appropriate date string
		if (value instanceof Date) set(result, key, format(value, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S));
	});

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}

/**
 * Get input value from raw data
 * @param {any} data - Raw data.
 * @return {*}
 */
export const input = data => {
	let result = cloneDeep(data);

	forOwn(result, (value, key) => {
		// Do some conversions if needed
	});
	
	return result;
}