import {find} from "lodash";
import {REPORT_VALUE_PREFIX, REPORT_VALUE_SUFFIX, REPORT_VALUES} from "./const";

/**
 * Check if string contains any report insert value or a specific one (defined in 'value' argument)
 *
 * @param {string} string - String to check.
 * @param {ReportValue|string} [value] - Specific report insert value object or report value code to check. If not 
 * specified, function will check for any report value.
 * @return {boolean}
 */

export const containsReportValue = (string, value) => {
	if (typeof value === 'undefined') {
		return generateReportValuesRegex().test(string);
	} else if (typeof value === 'string') {
		return generateReportValueRegex(find(REPORT_VALUES, {code: value})).test(string);
	} else {
		return generateReportValueRegex(value).test(string);
	}
};

/**
 * Generate a regular expression string for a particular report value
 * @param {ReportValue} value - Report value to generate the regular expression for.
 * @return {string}
 */
export const generateReportValueRegexString = value => {
	return (`${REPORT_VALUE_PREFIX}${value.code}-(\\d+)${REPORT_VALUE_SUFFIX}`);
};
/**
 * Generate a regular expression for a particular report value
 * @param {ReportValue} value - Report value to generate the regular expression for.
 * @return {RegExp}
 */
export const generateReportValueRegex = value =>
	new RegExp(generateReportValueRegexString(value), 'mg');

/**
 * Generate the regular expression string that will check for all report values
 * @return {string}
 */
const generateReportValuesRegexString = () => REPORT_VALUES.map(generateReportValueRegexString).join('|');

/**
 * Generate the regular expression that will check for all report values
 * @return {RegExp}
 */
const generateReportValuesRegex = () => new RegExp(generateReportValuesRegexString(), 'mg');

export default generateReportValuesRegex;