import styles from "../index.module.css";

import React from "react";
import DataComponent from "../../../core/components/DataComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as pageConfig from "../config";
import * as builderActions from "../actions";
import {selectors} from "../../../core/store/reducers";
import {getPageActions} from "../../../core/helpers/redux";
import Label from "../../../core/components/display/Label";
import SelectItem from "./components/SelectItem";
import {ReactSortable} from "react-sortablejs";
import {getArray, getBoolean, getBooleanFromTinyInt, getTinyIntFormBoolean} from "../../../core/helpers/data";
import {CookieData} from "../../../core/dataProtection/objects/cookie";
import {getStorageValue, setStorageValue, STORAGE_TYPE} from "../../../core/storage";
import CookieConsent from "../../../core/dataProtection/cookieConsent";
import {app_id, icon_font_delete_symbol} from "../../../config";
import Button, {BUTTON_DISPLAY_TYPE} from "../../../core/components/display/Button";
import {Tooltip} from "react-tippy";
import ConfirmDialog from "../../../core/components/dialogs/ConfirmDialog";

/**
 * Section collapsed cookie
 * @type {CookieData}
 */
const collapsedCookie = new CookieData(
	'necessary', `${app_id}-builder_select_section_collapsed`, STORAGE_TYPE.SESSION
);

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	selectColumns: selectors.builder.getReportValue(state, 'selectColumns'),
});

class BuilderSelectSection extends DataComponent {
	constructor(props) {
		super(props, {
			data: {
				collapsed: (
					!getBoolean(props, 'isReportTemplate') && CookieConsent.isAllowed(collapsedCookie) ?
						getBooleanFromTinyInt(
							getStorageValue(`${app_id}-builder_select_section_collapsed`, STORAGE_TYPE.SESSION)
						) 
						:
						false
				),
			}
		}, {
			translationPath: `${pageConfig.translationPath}.select_section`,
			disableLoad: true,
		});

		// Action methods
		this.clearItems = this.clearItems.bind(this);
	}

	
	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Clear all select column items
	 */
	clearItems() {
		const {openDialogAction, closeDialogAction, clearReportValueAction} = this.props;
		
		const dialogGUIID = openDialogAction('', ConfirmDialog, {
			message: this.t('clear_confirmation'),
			onYes: () => {
				clearReportValueAction('selectColumns');
				closeDialogAction(dialogGUIID);
			},
			onNo: () => closeDialogAction(dialogGUIID)
		}, {
			closeOnEscape: true,
			closeOnClickOutside: true,
			hideCloseBtn: true,
			maxWidth: 400
		});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {setReportValueAction} = this.props;
		const selectColumns = getArray(this.getProp('selectColumns'));
		
		return (
			<div 
				className={
					`card ${styles['section']} ${styles['select']} ${this.getValue('collapsed')?styles['collapsed']:''}`
				}
			>
				<div className={`card-header ${styles['selectHeader']}`}>
					<Label
						element="div"
						elementProps={{
							className: `card-title ${styles['title']}`,
							onClick: () => this.invertBoolValue('collapsed')
								.then(() => {
									if (CookieConsent.isAllowed(collapsedCookie)) {
										setStorageValue(
											`${app_id}-builder_select_section_collapsed`,
											getTinyIntFormBoolean(this.getValue('collapsed')),
											STORAGE_TYPE.SESSION
										);
									}
								}),
						}}
						icon="columns"
						content={this.t('title')}
					/>
					<div className={`toolbar ${styles['toolbar']}`}>
						{
							selectColumns.length ?
								<Tooltip
									tag="div"
									title={this.t('Clear', 'general') + ' ...'}
									size="small"
									position="top-center"
									arrow={true}
									interactive={false}
								>
									<Button
										className={styles['button']}
										icon={icon_font_delete_symbol}
										displayType={BUTTON_DISPLAY_TYPE.NONE}
										onClick={this.clearItems}
									/>
								</Tooltip>
								: null
						}
						<Button
							className={`${styles['button']} ${styles['sizeBtn']}`}
							icon={getBoolean(this.getValue('collapsed')) ? 'caret-down' : 'caret-up'}
							displayType={BUTTON_DISPLAY_TYPE.NONE}
							onClick={() => this.invertBoolValue('collapsed')
								.then(() => {
									if (CookieConsent.isAllowed(collapsedCookie)) {
										setStorageValue(
											`${app_id}-builder_select_section_collapsed`,
											getTinyIntFormBoolean(this.getValue('collapsed')),
											STORAGE_TYPE.SESSION
										);
									}
								})
							}
						/>
					</div>
				</div>
				<div 
					className={
						`card-content ${styles['selectContent']} ${this.getValue('collapsed') ? 'no-display' : ''}`
					}
				>
					<ReactSortable
						className={`${styles['columns']}`}
						handle=".handle"
						list={selectColumns}
						setList={list => setReportValueAction(list, 'selectColumns')}
						animation={250}
						delayOnTouchStart={true}
						delay={2}
					>
						{selectColumns.map(selectColumn =>
							<SelectItem
								key={selectColumn.GUIID}
								id={`report-builder-select-column-${selectColumn.GUIID}`}
								className={styles['column']}
								GUIID={selectColumn.GUIID}
							/>
						)}
					</ReactSortable>
				</div>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
BuilderSelectSection.propTypes = {
	isReportTemplate: PropTypes.bool,
};

/**
 * Define component default values for own props
 */
BuilderSelectSection.defaultProps = {
	isReportTemplate: false,
};

export default connect(
	mapStateToProps, getPageActions(builderActions), null, {forwardRef: true}
)(BuilderSelectSection);