import ACL from "../../../../acl";

/**
 * Component used to determine if children should be rendered based on specified permissions
 * 
 * @param {*} [children] - Component's children.
 * @param {string[]} [permissions=[]] - List for permissions to check access for.
 * @return {*|null}
 * @constructor
 */
function AclVisible({children, permissions = []}) {
	return (ACL.checkPermission(permissions) ? children : null);
}

export default AclVisible;