import "./index.css";
import "./default.style.css";
import "./action.style.css";

import React from "react";
import PropTypes from "prop-types";
import Toggle from "react-toggle";
import {getBoolean, getString} from "../../../helpers/data";

/**
 * Toggle input component
 * @description Toggle button input component.
 * @note This is a controlled component which means it does not maintain it's own state and value is controlled by the
 * parent component.
 *
 * This component uses 'react-toggle' component.
 */
function ToggleInput({
	styleName, className = '', readOnly = false, disabled = false, checked = false, value = '', ...reactToggle
}) {
	return (
		<Toggle
			{...reactToggle}
			value={getString(value)}
			checked={getBoolean(checked)}
			className={`toggle-input-component ${readOnly ? 'readonly' : ''} ${className} ${styleName}-style`}
			disabled={readOnly ? true : disabled}
		/>
	);
}

/**
 * Define component's own props that can be passed to it by parent components
 * @note Based on 'react-toggle' component (https://github.com/aaronshaf/react-toggle).
 */
ToggleInput.propTypes = {
	// Component style name
	// @description Component style name is a name of the style that will be used to determine the CSS used to style the
	// component.
	styleName: PropTypes.string,
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	defaultChecked: PropTypes.bool,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	className: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	id: PropTypes.string,
	'aria-labelledby': PropTypes.string,
	'aria-label': PropTypes.string,
	icons: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({
		checked: PropTypes.node,
		unchecked: PropTypes.node
	})])
};

/**
 * Define component default values for own props
 */
ToggleInput.defaultProps = {
	styleName: 'default'
};

export default ToggleInput;