import styles from "./index.module.css";
import "./index.css";

import React from "react";
import DataComponent, {executeComponentCallback} from "../../../../core/components/DataComponent";
import PropTypes from "prop-types";
import {getArray, getString, isset} from "../../../../core/helpers/data";
import FormWrapper from "../../../../core/components/advanced/FormWrapper";
import {RELATIVE_DATE_VALUE_PREFIX, RELATIVE_DATE_VALUE_SUFFIX} from "./const";
import {cloneDeep} from "lodash";
import RelativeDateRow from "./row";
import {rtrimChar} from "../../../../core/helpers/string";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../../../core/components/display/Button";
import {v4} from "uuid";
import Separator from "../../../../core/components/display/Separator";
import {Tooltip} from "react-tippy";

/**
 * Relative date section for InsertValueDialog component
 */
class InsertValueDialogRelativeDateSection extends DataComponent {
	/**
	 * List of all relative date row components currently rendered
	 * @note List is cleared and populated on each render.
	 * @type {Object[]}
	 */
	rowRefs = [];
	
	constructor(props) {
		super(props, {
			/** @type {{GUIID: string, data: RelativeDateValue}[]} */
			data: [
				{GUIID: v4(), data: undefined}
			],
		}, {
			domPrefix: 'insert-value-dialog-relative-date-section',
			translationPath: 'InsertValueDialogRelativeDateSection',
			disableLoad: true,
		});

		// Action methods
		this.insert = this.insert.bind(this);
	}
	
	
	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Get data that will be returned to the parent component
	 * @description Create and return data that will be returned to paren component (usually through onChange event)
	 * based on the local component's raw state data
	 * @note This method will not mutate the passed data.
	 *
	 * @return {any} Data that will be returned to the parent component.
	 */
	getDataToReturn() {
		const {multi, multiSeparator, multiAsArray} = this.props;
		const data = getArray(cloneDeep(this.state.data));
		
		if (multi && multiAsArray) {
			return data.reduce(
				(result, row) => {
					let updatedResult = cloneDeep(result);
					const data = row.data;
					if (data && data.value && data.value.value) {
						updatedResult.push(
							RELATIVE_DATE_VALUE_PREFIX +
							data.value.value.code +
							(
								data.modifier === 0 || !isset(data.modifier) ?
									'' :
									(data.modifier > 0 ? `+${data.modifier}` : data.modifier)
							) +
							(data.modifier !== 0 && isset(data.modifier) && isset(data.unit) ? data.unit : '') +
							RELATIVE_DATE_VALUE_SUFFIX
						);
					}
					return updatedResult;
				}, []
			);
		} else {
			return rtrimChar(
				data.reduce(
					(result, row) => {
						const data = row.data;
						if (data && data.value && data.value.value) {
							return result + (
								RELATIVE_DATE_VALUE_PREFIX +
								data.value.value.code +
								(
									data.modifier === 0 || !isset(data.modifier) ?
										'' :
										(data.modifier > 0 ? `+${data.modifier}` : data.modifier)
								) +
								(data.modifier !== 0 && isset(data.modifier) && isset(data.unit) ? data.unit : '') +
								RELATIVE_DATE_VALUE_SUFFIX
							) + multiSeparator;
						}
						return result;
					}, ''
				),
				multiSeparator
			);
		}
	}


	// Validation and error methods -------------------------------------------------------------------------------------
	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		let result = true;
		
		/** @type {RelativeDateRow[]} */
		const rows = getArray(this.rowRefs);
		for (let row of rows) {
			if (!row.validate()) result = false;
		}
		
		return result;
	}

	
	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method to insert tha value
	 * @note This is a required function that must be implemented in every insert value section component created by
	 * extending this abstract component.
	 */
	insert() {
		if (this.validate()) executeComponentCallback(this.props.onInsert, this.getDataToReturn());
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {className, titleIcon, multi, filterValues} = this.props;
		const title = getString(this.props, 'title', this.t('default_title'));
		const rows = getArray(this.getData());
		
		return (
			<div 
				id={this.getDomId()} 
				className={
					`${this.getOption('domPrefix')} ${className} ${styles['wrapper']} ` +
					`${title !== '' ? styles['withTitle'] : ''} ${multi ? styles['isMulti'] : ''}`
				}
			>
				<div className={`${styles['title']}`}>
					{title !== '' ? <Separator icon={titleIcon} content={title} className={`${styles['separator']}`} />:null}
				</div>
				
				<FormWrapper className={`${styles['form']}`}>
					<div className={`${styles['formContent']}`}>
						{rows.map((r, idx) =>
							<RelativeDateRow
								key={r.GUIID}
								GUIID={r.GUIID}
								data={r.data}
								index={idx}
								count={rows.length}
								title={title}
								titleIcon={titleIcon}
								filterValues={filterValues}
								onChange={(GUIID, v) => this.handleItemValueChange({GUIID}, 'data', v)}
								onDelete={
									rows.length > 1 ?
										GUIID => this.removeItem({GUIID}) :
										GUIID => this.setItemValue({GUIID}, 'data', undefined)
								}
								onEnterKey={() => this.insert()}
								ref={node => {
									// Clear the list before adding the first row
									if (idx === 0) this.rowRefs = [];
									// Add row to the ref list
									this.rowRefs.push(node);
								}}
							/>
						)}

						{
							multi ?
								<Button
									className={`${styles['addBtn']}`}
									icon="ellipsis-h"
									displayStyle={BUTTON_STYLE.SUBTLE}
									displayType={BUTTON_DISPLAY_TYPE.NONE}
									onClick={() => this.addItem({GUIID: v4()})}
								/>
								: null
						}
					</div>

					<div className={`${styles['formSidebar']}`}>
						<Tooltip
							tag="div"
							title={this.t('Insert', 'general')}
							size="small"
							position="left"
							arrow={true}
							interactive={false}
						>
							<Button
								className={`${styles['insertBtn']}`}
								icon="level-down"
								displayStyle={BUTTON_STYLE.ACTION}
								onClick={this.insert}
								disabled={!rows.length}
							/>
						</Tooltip>
					</div>
				</FormWrapper>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
InsertValueDialogRelativeDateSection.propTypes = {
	// Component's wrapper element id attribute
	id: PropTypes.string,
	// Component's wrapper element class attribute
	className: PropTypes.string,
	// Section title
	// @note If not defined default title from translation will be used.
	title: PropTypes.string,
	// Section title icon
	titleIcon: PropTypes.string,
	// Flag that determines if multiple values can be inserted
	multi: PropTypes.bool,
	// If 'multi' is true this is a separator used to concat insert values
	multiSeparator: PropTypes.string,
	// Flag that determines if returned multi insert value will be returned as an array
	multiAsArray: PropTypes.bool,
	// Function to filter available relative date values
	// @param {RelativeDateValue} - Relative date value.
	// @param {number} - Index of the value in the RELATIVE_DATE_VALUES list.
	// @return {boolean} True to include, false to not include.
	filterValues: PropTypes.func,
	
	// Events
	onInsert: PropTypes.func, // Arguments: insert value
};

/**
 * Define component default values for own props
 */
InsertValueDialogRelativeDateSection.defaultProps = {
	id: '',
	className: '',
	titleIcon: '',
	multi: false,
	multiSeparator: ',',
	multiAsArray: false,
};

export * from "./regex";
export default InsertValueDialogRelativeDateSection;