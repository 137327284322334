/**
 * Deep replace 'GUIID' property with 'guiid' in a given object
 * @note This function mutates the original object.
 * @param {Object} obj - Any object.
 */
export const lowercaseGUIID = (obj) => {
	for (let k in obj) {
		if (obj.hasOwnProperty(k)) {
			if (obj[k] && typeof obj[k] === 'object') {
				lowercaseGUIID(obj[k]);
			} else if (k === 'GUIID') {
				obj['guiid'] = obj['GUIID'];
				delete obj.GUIID;
			}
		}
	}
	return obj;
}
/**
 * Deep replace 'guiid' property with 'GUIID' in a given object
 * @note This function mutates the original object.
 * @param {Object} obj - Any object.
 */
export const uppercaseGUIID = (obj) => {
	for (let k in obj) {
		if (obj.hasOwnProperty(k)) {
			if (obj[k] && typeof obj[k] === 'object') {
				uppercaseGUIID(obj[k]);
			} else if (k === 'guiid') {
				obj['GUIID'] = obj['guiid'];
				delete obj.guiid;
			}
		}
	}
	return obj;
}