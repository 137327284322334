/**
 * Schedule item data object used by the app
 */
export class ScheduleItemDataObject {
	/**
	 * @param {string} [id=''] - Schedule item ID or an empty string if new items is being created.
	 * @param {string} [name=''] - Schedule item name.
	 * @param {Date} [dateFrom=null] - Schedule period start date.
	 * @param {Date} [dateTo=null] - Schedule period end date.
	 * @param {string} [reportGenerationTime=''] - Schedule execution time of day string with seconds (ex: '08:00:00').
	 * @param {ScheduleIntervalType} [scheduleIntervalType=''] - Schedule interval type.
	 * @param {string[]} [scheduleDays=[]] - Schedule execution days/dates depending on 'scheduleIntervalType'.
	 * @param {string[]} [receiverEmails=[]] - Emails where automatically generated scheduled report will be sent.
	 * @param {ReportTemplateDataObject[]} [reportTemplateDataList=[]] - List of report template objects used to 
	 * automatically generate scheduled reports.
	 * @param {string[]} [reportTemplateIds=[]] - List of report template IDs used to automatically generate scheduled 
	 * reports.
	 */
	constructor(
		id = '', name = '', dateFrom = null, dateTo = null, reportGenerationTime = '', 
		scheduleIntervalType = '', scheduleDays = [], receiverEmails = [], reportTemplateDataList = [], 
		reportTemplateIds = []
	) {
		this.id = id;
		this.dateFrom = dateFrom; 
		this.dateTo = dateTo; 
		this.reportGenerationTime = reportGenerationTime; 
		this.scheduleIntervalType = scheduleIntervalType;
		this.scheduleDays = scheduleDays; 
		this.receiverEmails = receiverEmails; 
		this.reportTemplateDataList = reportTemplateDataList; 
		this.reportTemplateIds = reportTemplateIds;
	}
}

/**
 * Report template data object used by schedule item data object
 */
export class ReportTemplateDataObject {
	/**
	 * @param {string} id
	 * @param {Object[]} columns
	 * @param {Object[]} filters
	 * @param {Object[]} sortColumns
	 * @param {string} creationDate - ISO 8601 date and time string with seconds.
	 * @param {string} creator
	 * @param {Object} reportRequest
	 * @param {string} fileName
	 */
	constructor(id, columns, filters, sortColumns, creationDate, creator, reportRequest, fileName) {
		this.id = id;
		this.columns = columns;
		this.filters = filters;
		this.sortColumns = sortColumns;
		this.creationDate = creationDate;
		this.creator = creator;
		this.reportRequest = reportRequest;
		this.fileName = fileName;
	}
}