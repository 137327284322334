import React from "react";
import {isString} from "lodash";
import {v4} from "uuid";
import {actionCreators} from "./reducer";
import {MESSAGE_STYLE} from "./const";
import {
	icon_font_error_symbol,
	icon_font_help_symbol, icon_font_info_symbol,
	icon_font_success_symbol,
	icon_font_warning_symbol, message_as_dialog,
	messages_default_auto_hide_after
} from "../../../../config";
import Icon from "../../display/Icon";

/**
 * Renders a new global message
 *
 * @param {string} [GUIID] - Message unique GUI ID. If not specified, a unique guid will be generated and used.
 * @param {React.Component|JSX.Element|string} content - Message content. Can be a React component or string (HTML is 
 * supported).
 * @param {string} [icon=''] - Message content icon symbol name (depending on font icon set used).
 * @param {string} [style] - Message style (keyof MESSAGE_STYLE). If not specified MESSAGE_STYLE.DEFAULT will be used.
 * @param {number} [autoHideAfter] - Number of seconds for the message to be visible. If -1, message will be visible
 * indefinitely or until its closed manually. Default value is retrieved from app config ('/src/config/app.js' file).
 * @param {string} [customClass=''] - Custom CSS class used instead of style class if style is MESSAGE_STYLE_CUSTOM. Use
 * this option if you want to render a custom style message.
 * @param {boolean} [canClose=true] - Flag that determines if message can be manually closed.
 * @param {boolean} [showCloseButton=true] - Flag that determines if close button will be rendered. This flag will be 
 * ignored if 'canClose' prop is false.
 * @param {boolean} [closeOnClick=false] - Flag that determines if clicking anywhere inside the message will close it.
 * This flag will be ignored if 'canClose' prop is false.
 * @param {boolean} [asDialog=false] - Flag that determines if message will be rendered as a dialog.
 * @param {Object} [dialogOptions={}] - Message dialog options. This will be ignored if 'asDialog' is false.
 * @param {Object} [dialogProps={}] - Message dialog props. This will be ignored if 'asDialog' is false.
 * @return {function(*): string} GUIID of the message.
 */
export const addMessageAction = (
	GUIID = v4(), content, icon = '', style = MESSAGE_STYLE.DEFAULT, autoHideAfter = messages_default_auto_hide_after, 
	customClass = '', canClose = true, showCloseButton = true, closeOnClick = false, asDialog = message_as_dialog, 
	dialogOptions = {}, dialogProps = {}
) => dispatch => {
	if (icon) {
		content = (
			<React.Fragment>
				<Icon key="icon" symbol={icon} className="message-type-icon" />
				{isString(content) ? <span key="text">{content}</span> : content}
			</React.Fragment>
		);
	}
	
	dispatch(actionCreators.add(
		(GUIID ? GUIID : v4()), (style ? style : MESSAGE_STYLE.DEFAULT), content, autoHideAfter, customClass, canClose,
		showCloseButton, closeOnClick, asDialog, dialogOptions, dialogProps
	));
	return GUIID;
};
/**
 * Renders a new global message
 * @alias addMessageAction
 */
export const showMessageAction = addMessageAction;

/**
 * Renders a new default global message
 *
 * @param {React.Component|JSX.Element|string} content - Message content. Can be a React component or string (HTML is 
 * supported).
 * @param {string} [icon=''] - Message content icon symbol name (depending on font icon set used).
 * @param {number} [autoHideAfter] - Number of seconds for the message to be visible. If -1, message will be visible
 * indefinitely or until its closed manually. Default value is retrieved from app config ('/src/config/app.js' file).
 * @param {boolean} [canClose=true] - Flag that determines if message can be manually closed.
 * @param {boolean} [showCloseButton=true] - Flag that determines if close button will be rendered. This flag will be
 * ignored if 'canClose' prop is false.
 * @param {boolean} [closeOnClick=false] - Flag that determines if clicking anywhere inside the message will close it.
 * This flag will be ignored if 'canClose' prop is false.
 * @param {boolean} [asDialog=false] - Flag that determines if message will be rendered as a dialog.
 * @param {Object} [dialogOptions={}] - Message dialog options. This will be ignored if 'asDialog' is false.
 * @param {Object} [dialogProps={}] - Message dialog props. This will be ignored if 'asDialog' is false.
 * @return {function(*=): string} GUIID of the message.
 */
export const addDefaultMessageAction = (
	content, icon = '', autoHideAfter = messages_default_auto_hide_after, canClose = true, showCloseButton = true, 
	closeOnClick = false, asDialog = message_as_dialog, dialogOptions = {}, dialogProps = {}
) => dispatch => addMessageAction(
	v4(), content, icon, MESSAGE_STYLE.DEFAULT, autoHideAfter, '', canClose, showCloseButton, closeOnClick,
	asDialog, dialogOptions, dialogProps
)(dispatch);
/**
 * Renders a new default global message
 * @alias addDefaultMessageAction
 */
export const showDefaultMessageAction = addDefaultMessageAction;

/**
 * Renders a new info global message
 *
 * @param {React.Component|JSX.Element|string} content - Message content. Can be a React component or string (HTML is supported).
 * @param {number} [autoHideAfter] - Number of seconds for the message to be visible. If -1, message will be visible
 * @param {string} [icon=''] - Message content icon symbol name (depending on font icon set used).
 * indefinitely or until its closed manually. Default value is retrieved from app config ('/src/config/app.js' file).
 * @param {boolean} [canClose=true] - Flag that determines if message can be manually closed.
 * @param {boolean} [showCloseButton=true] - Flag that determines if close button will be rendered. This flag will be
 * ignored if 'canClose' prop is false.
 * @param {boolean} [closeOnClick=false] - Flag that determines if clicking anywhere inside the message will close it.
 * This flag will be ignored if 'canClose' prop is false.
 * @param {boolean} [asDialog=false] - Flag that determines if message will be rendered as a dialog.
 * @param {Object} [dialogOptions={}] - Message dialog options. This will be ignored if 'asDialog' is false.
 * @param {Object} [dialogProps={}] - Message dialog props. This will be ignored if 'asDialog' is false.
 * @return {function(*=): string} GUIID of the message.
 */
export const addInfoMessageAction = (
	content, autoHideAfter = messages_default_auto_hide_after, icon = icon_font_info_symbol, canClose = true,
	showCloseButton = true, closeOnClick = false, asDialog = message_as_dialog, dialogOptions = {}, dialogProps = {}
) => dispatch => addMessageAction(
	v4(), content, icon, MESSAGE_STYLE.INFO, autoHideAfter, '', canClose, showCloseButton, closeOnClick,
	asDialog, dialogOptions, dialogProps
)(dispatch);
/**
 * Renders a new info global message
 * @alias addInfoMessageAction
 */
export const showInfoMessageAction = addInfoMessageAction;

/**
 * Renders a new help global message
 *
 * @param {React.Component|JSX.Element|string} content - Message content. Can be a React component or string (HTML is 
 * supported).
 * @param {number} [autoHideAfter] - Number of seconds for the message to be visible. If -1, message will be visible
 * @param {string} [icon=''] - Message content icon symbol name (depending on font icon set used).
 * indefinitely or until its closed manually. Default value is retrieved from app config ('/src/config/app.js' file).
 * @param {boolean} [canClose=true] - Flag that determines if message can be manually closed.
 * @param {boolean} [showCloseButton=true] - Flag that determines if close button will be rendered. This flag will be
 * ignored if 'canClose' prop is false.
 * @param {boolean} [closeOnClick=false] - Flag that determines if clicking anywhere inside the message will close it.
 * This flag will be ignored if 'canClose' prop is false.
 * @param {boolean} [asDialog=false] - Flag that determines if message will be rendered as a dialog.
 * @param {Object} [dialogOptions={}] - Message dialog options. This will be ignored if 'asDialog' is false.
 * @param {Object} [dialogProps={}] - Message dialog props. This will be ignored if 'asDialog' is false.
 * @return {function(*=): string} GUIID of the message.
 */
export const addHelpMessageAction = (
	content, autoHideAfter = messages_default_auto_hide_after, icon = icon_font_help_symbol, canClose = true,
	showCloseButton = true, closeOnClick = false, asDialog = message_as_dialog, dialogOptions = {}, dialogProps = {}
) => dispatch => addMessageAction(
	v4(), content, icon, MESSAGE_STYLE.HELP, autoHideAfter, '', canClose, showCloseButton, closeOnClick,
	asDialog, dialogOptions, dialogProps
)(dispatch);
/**
 * Renders a new help global message
 * @alias addHelpMessageAction
 */
export const showHelpMessageAction = addHelpMessageAction;
/**
 * Renders a new success global message
 *
 * @param {React.Component|JSX.Element|string} content - Message content. Can be a React component or string (HTML is supported).
 * @param {number} [autoHideAfter] - Number of seconds for the message to be visible. If -1, message will be visible
 * @param {string} [icon=''] - Message content icon symbol name (depending on font icon set used).
 * indefinitely or until its closed manually. Default value is retrieved from app config ('/src/config/app.js' file).
 * @param {boolean} [canClose=true] - Flag that determines if message can be manually closed.
 * @param {boolean} [showCloseButton=true] - Flag that determines if close button will be rendered. This flag will be
 * ignored if 'canClose' prop is false.
 * @param {boolean} [closeOnClick=false] - Flag that determines if clicking anywhere inside the message will close it.
 * This flag will be ignored if 'canClose' prop is false.
 * @param {boolean} [asDialog=false] - Flag that determines if message will be rendered as a dialog.
 * @param {Object} [dialogOptions={}] - Message dialog options. This will be ignored if 'asDialog' is false.
 * @param {Object} [dialogProps={}] - Message dialog props. This will be ignored if 'asDialog' is false.
 * @return {function(*=): string} GUIID of the message.
 */
export const addSuccessMessageAction = (
	content, autoHideAfter = messages_default_auto_hide_after, icon = icon_font_success_symbol, canClose = true, 
	showCloseButton = true, closeOnClick = false, asDialog = message_as_dialog, dialogOptions = {}, dialogProps = {}
) => dispatch => addMessageAction(
	v4(), content, icon, MESSAGE_STYLE.SUCCESS, autoHideAfter, '', canClose, showCloseButton, closeOnClick,
	asDialog, dialogOptions, dialogProps
)(dispatch);
/**
 * Renders a new success global message
 * @alias addSuccessMessageAction
 */
export const showSuccessMessageAction = addSuccessMessageAction;

/**
 * Renders a new warning global message
 *
 * @param {React.Component|JSX.Element|string} content - Message content. Can be a React component or string (HTML is 
 * supported).
 * @param {number} [autoHideAfter] - Number of seconds for the message to be visible. If -1, message will be visible
 * @param {string} [icon=''] - Message content icon symbol name (depending on font icon set used).
 * indefinitely or until its closed manually. Default value is retrieved from app config ('/src/config/app.js' file).
 * @param {boolean} [canClose=true] - Flag that determines if message can be manually closed.
 * @param {boolean} [showCloseButton=true] - Flag that determines if close button will be rendered. This flag will be
 * ignored if 'canClose' prop is false.
 * @param {boolean} [closeOnClick=false] - Flag that determines if clicking anywhere inside the message will close it.
 * This flag will be ignored if 'canClose' prop is false.
 * @param {boolean} [asDialog=false] - Flag that determines if message will be rendered as a dialog.
 * @param {Object} [dialogOptions={}] - Message dialog options. This will be ignored if 'asDialog' is false.
 * @param {Object} [dialogProps={}] - Message dialog props. This will be ignored if 'asDialog' is false.
 * @return {function(*=): string} GUIID of the message.
 */
export const addWarningMessageAction = (
	content, autoHideAfter = messages_default_auto_hide_after, icon = icon_font_warning_symbol, canClose = true, 
	showCloseButton = true, closeOnClick = false, asDialog = message_as_dialog, dialogOptions = {}, dialogProps = {}
) => dispatch => addMessageAction(
	v4(), content, icon, MESSAGE_STYLE.WARNING, autoHideAfter, '', canClose, showCloseButton, closeOnClick,
	asDialog, dialogOptions, dialogProps
)(dispatch);
/**
 * Renders a new warning global message
 * @alias addWarningMessageAction
 */
export const showWarningMessageAction = addWarningMessageAction;

/**
 * Renders a new error global message
 *
 * @param {React.Component|JSX.Element|string} content - Message content. Can be a React component or string (HTML is 
 * supported).
 * @param {number} [autoHideAfter] - Number of seconds for the message to be visible. If -1, message will be visible
 * @param {string} [icon=''] - Message content icon symbol name (depending on font icon set used).
 * indefinitely or until its closed manually. Default value is retrieved from app config ('/src/config/app.js' file).
 * @param {boolean} [canClose=true] - Flag that determines if message can be manually closed.
 * @param {boolean} [showCloseButton=true] - Flag that determines if close button will be rendered. This flag will be
 * ignored if 'canClose' prop is false.
 * @param {boolean} [closeOnClick=false] - Flag that determines if clicking anywhere inside the message will close it.
 * This flag will be ignored if 'canClose' prop is false.
 * @param {boolean} [asDialog=false] - Flag that determines if message will be rendered as a dialog.
 * @param {Object} [dialogOptions={}] - Message dialog options. This will be ignored if 'asDialog' is false.
 * @param {Object} [dialogProps={}] - Message dialog props. This will be ignored if 'asDialog' is false.
 * @return {function(*=): string} GUIID of the message.
 */
export const addErrorMessageAction = (
	content, autoHideAfter = messages_default_auto_hide_after, icon = icon_font_error_symbol, canClose = true, 
	showCloseButton = true, closeOnClick = false, asDialog = message_as_dialog, dialogOptions = {}, dialogProps = {}
) => dispatch => addMessageAction(
	v4(), content, icon, MESSAGE_STYLE.ERROR, autoHideAfter, '', canClose, showCloseButton, closeOnClick,
	asDialog, dialogOptions, dialogProps
)(dispatch);
/**
 * Renders a new error global message
 * @alias addWarningMessageAction
 */
export const showErrorMessageAction = addErrorMessageAction;

/**
 * Renders a new custom global message
 *
 * @param {React.Component|JSX.Element|string} content - Message content. Can be a React component or string (HTML is 
 * supported).
 * @param {string} [icon=''] - Message content icon symbol name (depending on font icon set used).
 * @param {string} customClass - Custom CSS class used instead of style class if style is MESSAGE_STYLE_CUSTOM. Use
 * this option if you want to render a custom style message.
 * @param {number} [autoHideAfter] - Number of seconds for the message to be visible. If -1, message will be visible
 * indefinitely or until its closed manually. Default value is retrieved from app config ('/src/config/app.js' file).
 * @param {boolean} [canClose=true] - Flag that determines if message can be manually closed.
 * @param {boolean} [showCloseButton=true] - Flag that determines if close button will be rendered. This flag will be
 * ignored if 'canClose' prop is false.
 * @param {boolean} [closeOnClick=false] - Flag that determines if clicking anywhere inside the message will close it.
 * This flag will be ignored if 'canClose' prop is false.
 * @param {boolean} [asDialog=false] - Flag that determines if message will be rendered as a dialog.
 * @param {Object} [dialogOptions={}] - Message dialog options. This will be ignored if 'asDialog' is false.
 * @param {Object} [dialogProps={}] - Message dialog props. This will be ignored if 'asDialog' is false.
 * @return {function(*=): string} GUIID of the message.
 */
export const addCustomMessageAction = (
	content, icon = '', customClass, autoHideAfter = messages_default_auto_hide_after, canClose = true, 
	showCloseButton = true, closeOnClick = false, asDialog = message_as_dialog, dialogOptions = {}, dialogProps = {}
) => dispatch => addMessageAction(
	v4(), content, icon, MESSAGE_STYLE.CUSTOM, autoHideAfter, customClass, canClose, showCloseButton, closeOnClick,
	asDialog, dialogOptions, dialogProps
)(dispatch);
/**
 * Renders a new custom global message
 * @alias addCustomMessageAction
 */
export const showCustomMessageAction = addCustomMessageAction;

/**
 * Removes previously rendered global message
 *
 * @param {string} GUIID - Message unique GUI ID.
 */
export const removeMessageAction = GUIID => dispatch => { dispatch(actionCreators.remove(GUIID)) };
/**
 * Removes previously rendered global message
 * @alias removeMessageAction
 */
export const hideMessageAction = removeMessageAction;

/**
 * Clears global messages
 * 
 * @param {string} [style=''] - If specified, only messages with the specific style (keyof MESSAGE_STYLE) will be 
 * removed.
 */
export const clearMessagesAction = (style = '') => dispatch => { dispatch(actionCreators.clear(style)) };