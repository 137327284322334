/**
 * Account permission list item data object
 * @note This is usually used in data table as a row.
 */
export class AccountPermissionListItemDataObject {
	/**
	 * @param {string|number|null} [id] - DB ID of the account permission.
	 * @param {string} [code=''] - Account permission code.
	 * @param {string} [name=''] - Account permission name.
	 * @param {string} [description=''] - Account permission description.
	 */
	constructor(id = null, code = '', name = '', description = '') {
		this.id = id;
		this.code = code;
		this.name = name;
		this.description = description;
	}
}

/**
 * Account permission data object
 * @note This is usually used when creating or updating data table item using a popup or a dialog.
 */
export class AccountPermissionDataObject {
	/**
	 * @param {string|number|null} [id] - DB ID of the account permission.
	 * @param {string} [code=''] - Account permission code.
	 * @param {string} [name=''] - Account permission name.
	 * @param {string} [description=''] - Account permission description.
	 */
	constructor(id = null, code = '', name = '', description = '') {
		this.id = id;
		this.code = code;
		this.name = name;
		this.description = description;
	}
}