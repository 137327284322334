import "./default.style.css";

import React from "react";
import DataComponent from "../../../core/components/DataComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../../core/components/display/Button";
import {icon_font_close_symbol, icon_font_delete_symbol, icon_font_symbol_class_prefix} from "../../../config";
import {getArray, getString} from "../../../core/helpers/data";
import {Tooltip} from "react-tippy";
import Dropdown, {DROPDOWN_POSITION} from "../../../core/components/display/Dropdown";
import Label from "../../../core/components/display/Label";
import {v4} from "uuid";
import {get, find, findIndex} from "lodash"
import {selectors} from "../../../core/store/reducers";
import Separator from "../../../core/components/display/Separator";
import FormWrapper, {FormField} from "../../../core/components/advanced/FormWrapper";
import TextInput from "../../../core/components/input/TextInput";
import SelectInput from "../../../core/components/input/SelectInput";
import DynamicValues from "../DynamicValues";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	dynamicValues: selectors.dynamicValues.getDynamicValues(state),
});

class FormulaEditor extends DataComponent {
	constructor(props) {
		super(props, {
			/**
			 * List of all formula items
			 * @type {FormulaEditorItemDataObject[]}
			 */
			data: [],

			/**
			 * Selected formula item GUI IDs
			 * @type {string[]}
			 */
			selection: [],
		}, {
			translationPath: 'FormulaEditorComponent',
			domPrefix: 'formula-editor-component',
			enableLoadOnDataPropChange: true,
		});
		
		// Data methods
		this.insertItem = this.insertItem.bind(this);
		this.getItemData = this.getItemData.bind(this);

		// Action methods
		this.selectItem = this.selectItem.bind(this);
		this.addItemToSelection = this.addItemToSelection.bind(this);
		this.removeItemFromSelection = this.removeItemFromSelection.bind(this);
		this.clearItemSelection = this.clearItemSelection.bind(this);
		this.getSelectedItem = this.getSelectedItem.bind(this);

		// Event handler methods
		this.handleItemClick = this.handleItemClick.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);

		// Render methods
		this.isItemSelected = this.isItemSelected.bind(this);
		this.renderActionButton = this.renderActionButton.bind(this);
		this.renderOperator = this.renderOperator.bind(this);
		this.renderOperatorProperties = this.renderOperatorProperties.bind(this);
		this.renderParentheses = this.renderParentheses.bind(this);
		this.renderParenthesesProperties = this.renderParenthesesProperties.bind(this);
		this.renderDynamicValue = this.renderDynamicValue.bind(this);
		this.renderDynamicValueProperties = this.renderDynamicValueProperties.bind(this);
		this.renderFunction = this.renderFunction.bind(this);
		this.renderFunctionProperties = this.renderFunctionProperties.bind(this);
		this.renderExpression = this.renderExpression.bind(this);
		this.renderExpressionProperties = this.renderExpressionProperties.bind(this);
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Get component's main data from local state
	 *
	 * @return {any} Cloned component's main data from local state.
	 */
	getData() { return getArray(super.getData()); }

	/**
	 * Add item to component's main data in local state
	 * @note This method is used if component's main data is an array of objects.
	 *
	 * @param {FormulaEditorItemDataObject} item - Item to add.
	 * @param {number} index - Index where item will be added.
	 * @return {Promise<any>} Promise that is resolved with entire component's local state after data item has been added.
	 */	
	insertItem(item, index) {
		return this.setState(prevState => ({
			...prevState,
			data: [...prevState.data.slice(0, index), item, ...prevState.data.slice(index)]
		}))
			.then(() => this.selectItem(item))
			.then(() => this.getDomElement().querySelector('.formula-editor').focus())
			.then(() => this.state)
	}

	/**
	 * Get item data field name based on item type
	 * @note This method is used because data field is called differently for each type (like 'operatorData'). This is
	 * done because API cannot accept different data types in the same field name and data is different depending on the
	 * type.
	 * @param {FormulaEditorItemDataObject} item - Item to add.
	 * @return {string}
	 */
	getItemDataFieldName(item) {
		return `${item.type}Data`;
	}
	
	/**
	 * Get item data based on item type
	 * @note This method is used because data field is called differently for each type (like 'operatorData'). This is 
	 * done because API cannot accept different data types in the same field name and data is different depending on the
	 * type.
	 * @param {FormulaEditorItemDataObject} item - Item to add.
	 * @return {any}
	 */
	getItemData(item) { 
		return get(item, this.getItemDataFieldName(item));
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Select formula item
	 * @param {FormulaEditorItemDataObject} item - Item to select.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	selectItem(item) {
		const itemGUIID = getString(item, 'GUIID');
		if (itemGUIID) return this.setState({selection: [itemGUIID]});
		else return Promise.resolve(this.state);
	}

	/**
	 * Add formula item to selection
	 * @param {FormulaEditorItemDataObject} item - Item to add.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	addItemToSelection(item) {
		const itemGUIID = getString(item, 'GUIID');
		if (itemGUIID) {
			return this.setState(state => ({...state, selection: [...state.selection, itemGUIID]}));
		}
		return Promise.resolve(this.state);
	}

	/**
	 * Remove formula item from selection
	 * @param {FormulaEditorItemDataObject} item - Item to add.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */ 
	removeItemFromSelection(item) {
		const itemGUIID = getString(item, 'GUIID');
		if (itemGUIID) {
			return this.setState(state => ({...state, selection: state.selection.filter(i => i !== itemGUIID)}));
		}
	}

	/**
	 * Clear formula item selection
	 * @return {Promise<Object>}
	 */
	clearItemSelection() {
		return this.setState({selection: []});
	}

	/**
	 * Get selected formula item if only one item is selected
	 * @return {FormulaEditorItemDataObject|null}
	 */
	getSelectedItem() {
		const selection = getArray(this.state, 'selection');
		if (selection.length === 1) return find(this.getData(), {GUIID: selection[0]});
		return null;
	}


	// Event handler methods --------------------------------------------------------------------------------------------
	/**
	 * Handle formula item click event
	 * @param {FormulaEditorItemDataObject} item - Item data object.
	 * @param {MouseEvent} event - Mouse click event on the item.
	 */
	handleItemClick(item, event) {
		const itemGUIID = getString(item, 'GUIID');
		const selection = getArray(this.state, 'selection');
		const selectionItemIndex = selection.indexOf(itemGUIID);
		
		// If item is not selected
		if (selectionItemIndex === -1) {
			if (event.ctrlKey) return this.addItemToSelection(item);
			else return this.selectItem(item);
		}
		// If item is selected
		else {
			if (event.ctrlKey) return this.removeItemFromSelection(item);
			else return this.selectItem(item);
		}
	}

	/**
	 * Handle click event on component's main DOM element
	 * @param event
	 */
	handleClick(event) {
		event.target.focus();
	}
	
	/**
	 * Handle key down event on component's main DOM element
	 * @param event
	 */
	async handleKeyDown(event) {
		const items = this.getData();
		const selection = getArray(this.state, 'selection');
		const lastIndex = findIndex(items, {GUIID: get(selection, `[${selection.length - 1}]`)});
		const lastSelectedItem = get(items, `[${lastIndex}]`);
		const prevItem = get(items, `[${lastIndex - 1}]`);
		
		switch (event.nativeEvent.code) {
			case 'ArrowRight':
				event.preventDefault();
				const nextItem = get(items, `[${lastIndex + 1}]`);
				if (nextItem) await this.selectItem(nextItem);
				break;
				
			case 'ArrowLeft':
				event.preventDefault();
				await this.selectItem(selection.length === 1 ? prevItem : lastSelectedItem);
				break;
				
			case 'Escape':
				if (selection.length) await this.clearItemSelection();
				else this.getDomElement().querySelector('.formula-editor').blur();
				break;
				
			case 'Delete':
				const selectedItem = this.getSelectedItem();
				if (selectedItem) {
					await this.selectItem(prevItem);
					await this.removeItem({GUIID: selectedItem.GUIID});
				}
				
			// no default
		}
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Check if formula item is selected
	 * @param {FormulaEditorItemDataObject} item - Item to check.
	 * @return {boolean}
	 */
	isItemSelected(item) {
		const itemGUIID = getString(item, 'GUIID');
		if (itemGUIID) {
			const selection = getArray(this.state, 'selection');
			return (selection.indexOf(itemGUIID) !== -1);
		}
		return false;
	}
	
	/**
	 * Render action button item
	 * @description Action button allows adding new items to the formula.
	 * @param {number} index - Index position of the action button in main data array.
	 * @return {JSX.Element}
	 */
	renderActionButton(index) {
		return (
			<div className={`action-wrapper`}>
				<Dropdown
					className={`action-dropdown`}
					label={
						<Tooltip
							tag="div"
							className="dropdown-tooltip"
							title={this.t('action_btn')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<Button 
								className="action-btn"
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT} 
								displayStyle={BUTTON_STYLE.SUBTLE} 
							/>
						</Tooltip>
					}
					position={DROPDOWN_POSITION.LEFT}
				>
					<div className={`dropdown-item toolbar-item`}>
						{getArray(this.getProp('operators')).map(o =>
							<Button
								key={o.value}
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								displayStyle={BUTTON_STYLE.SUBTLE}
								label={o.label}
								onClick={() => this.insertItem(
									new FormulaEditorOperatorItemDataObject('', o.label, o.value),
									index
								)}
							/>
						)}
						{getArray(this.getProp('parentheses')).map(p =>
							<Button
								key={p.value}
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								displayStyle={BUTTON_STYLE.SUBTLE}
								label={p.label}
								onClick={() => this.insertItem(
									new FormulaEditorParenthesesItemDataObject('', p.label, p.value),
									index
								)}
							/>
						)}
					</div>
					<div 
						className={`dropdown-item`} 
						onClick={() => this.insertItem(
							new FormulaEditorDynamicValueItemDataObject('', null),
							index
						)}
					>
						<Label icon="hashtag" content={this.t('Dynamic value')} />
					</div>
					{/*
					<div 
						className={`dropdown-item`} 
						onClick={() => this.insertItem(
							new FormulaEditorFunctionItemDataObject('', 'Function', '', []),
							index
						)}
					>
						<span className="icon-function icon" style={{marginRight: '0.4em'}} />
						<Label content={this.t('Function')} />
					</div>
					*/}
					<div 
						className={`dropdown-item`} 
						onClick={() => this.insertItem(
							new FormulaEditorExpressionItemDataObject(),
							index
						)}
					>
						<Label icon="terminal" content={this.t('Expression')} />
					</div>
				</Dropdown>
			</div>
		);
	}

	/**
	 * Render operation item
	 * @param {FormulaEditorOperatorItemDataObject} item - Operator type item to render.
	 * @return {JSX.Element}
	 */
	renderOperator(item) {
		const dataField = this.getItemDataFieldName(item);
		return (
			<div 
				className={`item-wrapper type-operator ${this.isItemSelected(item) ? 'selected' : ''}`} 
				onClick={e => this.handleItemClick(item, e)}
			>
				<Label content={getString(item, `${dataField}.label`)} />
			</div>
		);
	}

	/**
	 * Render operator item properties section
	 * @return {JSX.Element}
	 */
	renderOperatorProperties() {
		const selectedItem = this.getSelectedItem();
		const dataField = this.getItemDataFieldName(selectedItem);
		if (selectedItem && selectedItem.type === 'operator') {
			return (
				<FormWrapper className={`content type-operator`}>
					<FormField label={this.tt('Operator', 'properties.operator')}>
						<SelectInput
							classNamePrefix="formula-editor-select select-input"
							isSearchable={false}
							simpleValue={false}
							value={get(selectedItem, dataField)}
							options={this.getProp('operators')}
							onChange={v => this.setItemValue({GUIID: selectedItem.GUIID}, dataField, v)}
						/>
					</FormField>
				</FormWrapper>
			);
		}
		return null;
	}

	/**
	 * Render parentheses item
	 * @param {FormulaEditorParenthesesItemDataObject} item - Parentheses type item to render.
	 * @return {JSX.Element}
	 */
	renderParentheses(item) {
		const dataField = this.getItemDataFieldName(item);
		return (
			<div 
				className={`item-wrapper type-parentheses ${this.isItemSelected(item) ? 'selected' : ''}`} 
				onClick={e => this.handleItemClick(item, e)}
			>
				<Label content={getString(item, `${dataField}.label`)} />
			</div>
		);
	}

	/**
	 * Render parentheses item properties section
	 * @return {JSX.Element}
	 */
	renderParenthesesProperties() {
		const selectedItem = this.getSelectedItem();
		const dataField = this.getItemDataFieldName(selectedItem);
		if (selectedItem && selectedItem.type === 'parentheses') {
			return (
				<FormWrapper className={`content type-parentheses`}>
					<FormField label={this.tt('Parentheses', 'properties.parentheses')}>
						<SelectInput
							classNamePrefix="formula-editor-select select-input"
							isSearchable={false}
							simpleValue={false}
							value={get(selectedItem, dataField)}
							options={this.getProp('parentheses')}
							onChange={v => this.setItemValue({GUIID: selectedItem.GUIID}, dataField, v)}
						/>
					</FormField>
				</FormWrapper>
			);
		}
		return null;
	}

	/**
	 * Render dynamic value item
	 * @param {FormulaEditorDynamicValueItemDataObject} item - Dynamic value type item to render.
	 * @return {JSX.Element}
	 */
	renderDynamicValue(item) {
		const dataField = this.getItemDataFieldName(item);
		return (
			<div 
				className={`item-wrapper type-dynamic-value ${this.isItemSelected(item) ? 'selected' : ''}`} 
				onClick={e => this.handleItemClick(item, e)}
			>
				<Label
					icon={get(item, `${dataField}.aggregateFunction`) ? 'ruler-alt-2' : 'hashtag'}
					iconSymbolPrefix={
						get(item, `${dataField}.aggregateFunction`) ? 'icofont-' : icon_font_symbol_class_prefix
					}
					iconClassName="item-icon"
					content={`${getString(item, `${dataField}.group.name`)}.${getString(item, `${dataField}.name`)}`}
					tooltip={getString(item, `${dataField}.description`)}
				/>
			</div>
		);
	}

	/**
	 * Render dynamic value item properties section
	 * @return {JSX.Element|null}
	 */
	renderDynamicValueProperties() {
		const selectedItem = this.getSelectedItem();
		const dataField = this.getItemDataFieldName(selectedItem);
		if (selectedItem && selectedItem.type === 'dynamicValue') {
			const dynamicValue = this.getItemValue({GUIID: selectedItem.GUIID}, 'data');
			
			return (
				<FormWrapper className={`content type-dynamic-value`}>
					<DynamicValues
						key={selectedItem.GUIID}
						className={`dynamic-values-tree`}
						hideToolbar={true}
						insertTrigger="click"
						data={getArray(this.getProp('dynamicValues'))}
						selection={dynamicValue ? [dynamicValue] : []}
						onInsert={value => this.setItemValue({GUIID: selectedItem.GUIID}, dataField, value)}
					/>
				</FormWrapper>
			);
		}
		return null;
	}

	/**
	 * TODO: Render function item
	 * @param {FormulaEditorFunctionItemDataObject} item - Dynamic value type item
	 * to render.
	 * @return {JSX.Element}
	 */
	renderFunction(item) {
		const dataField = this.getItemDataFieldName(item);
		/** @type {{label: string, value: string}[]} */
		const args = getArray(item, `${dataField}.args`);
		return (
			<div 
				className={`item-wrapper type-function ${this.isItemSelected(item) ? 'selected' : ''}`}
				onClick={e => this.handleItemClick(item, e)}
			>
				<span className="icon-function icon" style={{marginRight: '0.4em'}} />
				<Label content={getString(item, `${dataField}.label`)} />({args.map((a, idx) =>
				<Label key={idx} content={getString(a, 'label')} />
			)})
			</div>
		);
	}

	/**
	 * TODO: Render function item properties section
	 * @return {JSX.Element|null}
	 */
	renderFunctionProperties() {
		const selectedItem = this.getSelectedItem();
		const dataField = this.getItemDataFieldName(selectedItem);
		if (selectedItem && selectedItem.type === 'function') {
			return (
				<FormWrapper className={`content type-function`}>
					<FormField label={this.tt('Function', 'properties.function')}>
						<SelectInput
							simpleValue={false}
							options={[
								{
									label: 'Test funkcija', 
									value: 'testFunction', 
									args: [
										{label: 'Ime', value: 'name'},
										{label: 'Prezime', value: 'surname'},
										{label: 'Starost', value: 'age'},
									]}
							]}
							onChange={v => this.setItemValue({GUIID: selectedItem.GUIID}, dataField, v)}
						/>
					</FormField>
					{getArray(selectedItem, `${dataField}.args`).map((arg, index) =>
						<FormField key={index} label={getString(arg, 'label')}>
							<TextInput
								
							/>
						</FormField>
					)}
				</FormWrapper>
			);
		}
		return null;
	}

	/**
	 * Render expression item
	 * @param {FormulaEditorExpressionItemDataObject} item - Expression type item to render.
	 * @return {JSX.Element}
	 */
	renderExpression(item) {
		const dataField = this.getItemDataFieldName(item);
		return (
			<div
				className={`item-wrapper type-expression ${this.isItemSelected(item) ? 'selected' : ''}`}
				onClick={e => this.handleItemClick(item, e)}
			>
				<Label
					iconClassName="item-icon"
					content={getString(item, dataField)}
				/>
			</div>
		);
	}

	/**
	 * Render expression item properties section
	 * @return {JSX.Element|null}
	 */
	renderExpressionProperties() {
		const selectedItem = this.getSelectedItem();
		const dataField = this.getItemDataFieldName(selectedItem);
		if (selectedItem && selectedItem.type === 'expression') {
			return (
				<FormWrapper className={`content type-expression`}>
					<FormField
						label={this.tt('Expression', 'properties.expression')}
					>
						<TextInput
							value={getString(selectedItem, dataField)}
							onChange={e => 
								this.setItemValue(
									{GUIID: selectedItem.GUIID}, 
									dataField, 
									getString(e, 'target.value')
								)
							}
						/>
					</FormField>
				</FormWrapper>
			);
		}
		return null;
	}
	
	render() {
		const {styleName, className} = this.props;
		const items = this.getData();
		const selectedItem = this.getSelectedItem();
		
		return (
			<div 
				id={this.getDomId()} 
				className={`${this.getOption('domPrefix')} ${styleName}-style ${className}`}
			>
				<div className="toolbar standard">
					<Button
						icon={icon_font_close_symbol}
						label={this.t('Clear', 'general')}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						onClick={this.clearData}
					/>
				</div>

				<div className={`formula-editor-wrapper`}>
					<div
						className={`formula-editor`}
						tabIndex="0"
						onClick={this.handleClick}
						onKeyDown={this.handleKeyDown}
					>
						{this.renderActionButton(0)}

						{items.map((i, index) =>
							<React.Fragment key={i.GUIID}>
								{this[`render${i.type.charAt(0).toUpperCase() + i.type.slice(1)}`](i)}
								{this.renderActionButton(index + 1)}
							</React.Fragment>
						)}
					</div>
					
					<div className={`properties`}>
						<Separator icon="cog" content={this.t('Item properties')} />
						{selectedItem ?
							this[`render${selectedItem.type.charAt(0).toUpperCase() + selectedItem.type.slice(1)}Properties`]()
							:
							<Label 
								element="div" 
								elementProps={{className: 'content no-item-selected'}}
								content={this.tt('notice','properties')}
							/>
						}
						{
							selectedItem ?
								<div className="actions">
									<Button
										displayType={BUTTON_DISPLAY_TYPE.NONE}
										displayStyle={BUTTON_STYLE.DEFAULT}
										icon={icon_font_delete_symbol}
										onClick={() => this.removeItem({GUIID: selectedItem.GUIID})}
									/>
								</div>
								: null
						}
					</div>
				</div>
			</div>
		);
	}
}

// Data objects
export class FormulaEditorItemDataObject {
	/**
	 * @param {string} [GUIID] - GUI ID of the formula editor item. If not specified unique GUID will be used.
	 * @param {'operator'|'parentheses'|'dynamicValue'|'function'|'expression'} type - Formula editor item type.
	 * @param {Object} data - Formula editor item data (structure depends on item type).
	 */
	constructor(GUIID, type, data) {
		this.GUIID = (GUIID ? GUIID : v4());
		this.type = type;
		this[`${type}Data`] = data;
	}
}
export class FormulaEditorOperatorItemDataObject extends FormulaEditorItemDataObject {
	/**
	 * @param {string} [GUIID] - GUI ID of the formula editor item. If not specified unique GUID will be used.
	 * @param {string} label - Operator label
	 * @param {string} value - Operator value
	 */
	constructor(GUIID, label, value) { 
		super(GUIID, 'operator', {label, value});
	}
}
export class FormulaEditorParenthesesItemDataObject extends FormulaEditorItemDataObject {
	/**
	 * @param {string} [GUIID] - GUI ID of the formula editor item. If not specified unique GUID will be used.
	 * @param {string} label - Parentheses label.
	 * @param {string} value - Parentheses value.
	 */
	constructor(GUIID, label, value) { 
		super(GUIID, 'parentheses', {label, value});
	}
}
export class FormulaEditorDynamicValueItemDataObject extends FormulaEditorItemDataObject {
	/**
	 * @param {string} [GUIID] - GUI ID of the formula editor item. If not specified unique GUID will be used.
	 * @param {Object} value - Dynamic value.
	 */
	constructor(GUIID, value) {
		super(GUIID, 'dynamicValue', value);
	}
}
export class FormulaEditorFunctionItemDataObject extends FormulaEditorItemDataObject {
	/**
	 * @param {string} [GUIID] - GUI ID of the formula editor item. If not specified unique GUID will be used.
	 * @param {string} label - User-friendly function label that will be rendered in the item block.
	 * @param {string} name - Function name.
	 * @param {{label: string, value: string}[]} args - Function arguments where label is a user-friendly argument value.
	 */
	constructor(GUIID, label, name, args) {
		super(GUIID, 'function', {label, name, args});
	}
}
export class FormulaEditorExpressionItemDataObject extends FormulaEditorItemDataObject {
	/**
	 * @param {string} [GUIID] - GUI ID of the formula editor item. If not specified unique GUID will be used.
	 * @param {string} [expression=''] - Any expression.
	 */
	constructor(GUIID, expression = '') {
		super(GUIID, 'expression', expression);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
FormulaEditor.propTypes = {
	...DataComponent.propTypes,

	// Component style name
	// @description Component style name is a name of the style that will be used to determine the CSS used to style the
	// component.
	styleName: PropTypes.string,
	// Component's wrapper element id attribute
	id: PropTypes.string,
	// Component's wrapper element class attribute
	className: PropTypes.string,
	// Array of formula items
	data: PropTypes.array,
	// Array of all supported operators
	operators: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string
	})),
	// Array of all supported parentheses
	parentheses: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string
	})),
};

/**
 * Define component default values for own props
 */
FormulaEditor.defaultProps = {
	styleName: 'default',
	id: '',
	className: '',
	data: [],
	operators: [
		{label: '+', value: '+'},
		{label: '-', value: '-'},
		{label: '·', value: 'x'},
		{label: '÷', value: '/'},
	],
	parentheses: [
		{label: '(', value: '('},
		{label: ')', value: ')'},
	]
};

export default connect(mapStateToProps, null, null, {forwardRef: true})(FormulaEditor);