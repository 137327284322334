/**
 * Core layout component that renders all page layouts
 * @description All pages that use layouts are rendered by this component and all global components like dialogs are 
 * handled by it.
 */

import React from "react";
import {get} from "lodash";
import {useSelector} from "react-redux";
import {redux_storage_var} from "../../config";
import Dialogs from "../components/global/Dialogs";
import Overlay, {getOverlayTargetElement} from "../components/global/Overlay";
import CookieNotice from "../dataProtection/cookieConsent/components/CookieNotice";

function CoreLayout(props) {
	const overlays = useSelector(state => state.overlay.overlays);
	const showCookieNotice = useSelector(state => !!get(state, [redux_storage_var, 'show_cookie_notice']));
	
	return (
		<React.Fragment>
			{props.children}
			<Dialogs />
			{overlays.map(overlay =>
				getOverlayTargetElement(overlay) ?
					<Overlay key={overlay.GUIID} element={getOverlayTargetElement(overlay)} data={overlay} /> : null
			)}
			{showCookieNotice ? <CookieNotice /> : null}
		</React.Fragment>
	);
}

export default CoreLayout;