/** @typedef {string} ScheduleIntervalType */
export const SCHEDULE_INTERVAL_TYPE_DATE_OF_WEEK = 'DAY_OF_WEEK';
export const SCHEDULE_INTERVAL_TYPE_DATE_OF_MONTH = 'DAY_OF_MONTH';
export const SCHEDULE_INTERVAL_TYPE_DATE_OF_YEAR = 'DATE_OF_YEAR';
/** @enum {ScheduleIntervalType} */
export const SCHEDULE_INTERVAL_TYPE = {
	DATE_OF_WEEK: SCHEDULE_INTERVAL_TYPE_DATE_OF_WEEK,
	DATE_OF_MONTH: SCHEDULE_INTERVAL_TYPE_DATE_OF_MONTH,
	DATE_OF_YEAR: SCHEDULE_INTERVAL_TYPE_DATE_OF_YEAR,
}
/** @type {ScheduleIntervalType[]} */
export const SCHEDULE_INTERVAL_TYPES = [
	SCHEDULE_INTERVAL_TYPE_DATE_OF_WEEK,
	SCHEDULE_INTERVAL_TYPE_DATE_OF_MONTH,
	SCHEDULE_INTERVAL_TYPE_DATE_OF_YEAR,
];