import "./index.css";

import React from "react";
import DataComponent, {executeComponentCallback} from "../../../../core/components/DataComponent";
import PropTypes from "prop-types";
import {set, get, map} from "lodash";
import {LOGICAL_OPERATOR} from "../../../../core/const/global";
import {getArray, getBoolean, getObjectKeyByValue, getString, isset} from "../../../../core/helpers/data";
import Label from "../../../../core/components/display/Label";
import {FormField} from "../../../../core/components/advanced/FormWrapper";
import {FORM_FIELD_LABEL_POSITION} from "../../../../core/components/advanced/FormWrapper/FormField";
import SelectInput from "../../../../core/components/input/SelectInput";
import TextInput from "../../../../core/components/input/TextInput";
import NumberInput from "../../../../core/components/input/NumberInput";
import DateInput from "../../../../core/components/input/DateInput";
import SelectAsyncInput from "../../../../core/components/input/SelectAsyncInput";
import ToggleInput from "../../../../core/components/input/ToggleInput";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../../../core/components/display/Button";
import {STANDARD_DATE_TIME_FORMAT} from "../../../../core/const/datetime";
import {getDate, getDateString} from "../../../../core/helpers/datetime";
import {NUMBER_INPUT_USE_APP_LOCALE} from "../../../../core/components/input/NumberInput/const";
import Icon from "../../../../core/components/display/Icon";
import FormulaDialog from "../../../../pages/builder/SelectSection/components/SelectItem/dialogs/FormulaDialog";
import {closeDialog, openDialog} from "../../../../core/helpers/dialog";
import {
	REPORT_FILTER_COMPARATOR,
	REPORT_FILTER_DISPLAY_TYPE_COMPARATORS,
	REPORT_FILTER_ITEM_DISPLAY_TYPE
} from "../../../../const/report";
import {icon_font_loading_symbol, icon_font_symbol_class_prefix} from "../../../../config";
import DatetimeInput from "../../../../core/components/input/DatetimeInput";
import DataValueValidation from "../../../../core/validation";
import {REPORT_FILTER_ITEM_BETWEEN_SEPARATOR, REPORT_FILTER_ITEM_IN_SEPARATOR} from "./const";
import {getAppLocaleDateFormat, getAppLocaleDatetimeFormat} from "../../../../core/helpers/locale";
import {LOCALE_DATE_FORMAT_NAME, LOCALE_TIME_FORMAT_NAME} from "../../../../core/const/locale";
import {INSERT_VALUE_BUTTON_TYPE} from "../../../../core/components/advanced/InsertValueButton/const";
import {InsertValueDialogSectionDataObject} from "../../../../core/components/dialogs/InsertValueDialog/dataObjects";
import InsertValueDialogRelativeDateSection, {containsRelativeDataValue} from "../../../dialogs/insertValueDialogSection/RelativeDate";
import FileInput from "../../../../core/components/input/FileInput";
import InsertValueDialogReportSection, {containsReportValue} from "../../../dialogs/insertValueDialogSection/Report";

class ReportBuilderFilterItem extends DataComponent {
	/**
	 * Filter item validation rules for value field when comparator is 'in', based on filter item display type
	 * @type {Object<
	 * 	ReportFilterItemDisplayType, 
	 * 	ValidationRule[]|ValidationRuleObject[]|{name: string, [options]: {}}[]
	 * >}
	 */
	inValueValidationRules = {
		[REPORT_FILTER_ITEM_DISPLAY_TYPE.INTEGER]: [
			{name: 'integer', options: {insertValueCheck: [containsRelativeDataValue, containsReportValue]}}
		],
		[REPORT_FILTER_ITEM_DISPLAY_TYPE.FLOAT]: [
			{name: 'number', options: {insertValueCheck: [containsRelativeDataValue, containsReportValue]}}
		],
		[REPORT_FILTER_ITEM_DISPLAY_TYPE.DATE]: [
			{name: 'dateString', options: {insertValueCheck: [containsRelativeDataValue, containsReportValue]}}
		],
		[REPORT_FILTER_ITEM_DISPLAY_TYPE.DATETIME]: [
			{name: 'dateTimeString', options: {insertValueCheck: [containsRelativeDataValue, containsReportValue]}}
		],
	};
	
	/**
	 * Filter item validation conditions for value field when comparator is 'in', based on filter item display type
	 * @type {{}}
	 */
	inValueValidationConditions = {
		
	};
	
	constructor(props) {
		super(props, {
			/** @type {ReportFilterItemDataObject} */
			data: null,

			/**
			 * Select input options
			 * @note This is used if filter item value renders a select input that needs options.
			 * @type {Array}
			 */
			options: [],

			/**
			 * Flag specifying if select input options are loading
			 * @note This is used if display type is 'SELECT' because async select has it's own built-in loading flag.
			 * @type {boolean}
			 */
			optionsLoading: false,

			/**
			 * Flag specifying if 'in' values import from file is in progress
			 */
			inImportLoading: false,
		}, {
			translationPath: 'ReportBuilderFilterComponent.FilterItem',
			domPrefix: 'report-builder-filter-item-component',
			enableLoadOnDataPropChange: true,
			optimizedUpdate: true,
			optimizedUpdateIgnoreProps: ['onChange', 'onDelete', 'onValidate',  'onEnterKey']
		});
		
		// Data methods
		this.loadOptions = this.loadOptions.bind(this);
		this.getBetweenValue = this.getBetweenValue.bind(this);

		// Data change handling methods
		this.handleComparatorChange = this.handleComparatorChange.bind(this);
		this.handleBetweenValueChange = this.handleBetweenValueChange.bind(this);

		// Dialog methods
		this.openFormulaDialog = this.openFormulaDialog.bind(this);
		
		// Render methods
		this.getImportFromFileToolbar = this.getImportFromFileToolbar.bind(this);
		this.renderBetweenValue = this.renderBetweenValue.bind(this);
		this.renderBetweenSeparator = this.renderBetweenSeparator.bind(this);
		this.renderDefaultValue = this.renderDefaultValue.bind(this);
		this.renderValue_TEXT = this.renderValue_TEXT.bind(this);
		this.renderValue_INTEGER = this.renderValue_INTEGER.bind(this);
		this.renderValue_FLOAT = this.renderValue_FLOAT.bind(this);
		this.renderValue_DATE = this.renderValue_DATE.bind(this);
		this.renderValue_DATETIME = this.renderValue_DATETIME.bind(this);
		this.renderValue_MONTH = this.renderValue_MONTH.bind(this);
		this.renderValue_SELECT = this.renderValue_SELECT.bind(this);
		this.renderValue_ASYNC_SELECT = this.renderValue_ASYNC_SELECT.bind(this);
		this.renderValue_BOOLEAN = this.renderValue_BOOLEAN.bind(this);
	}
	
	componentDidMount(override = false) {
		return super.componentDidMount(override)
			// Load select options if needed
			.then(() => this.loadOptions());
	}


	// I18n -------------------------------------------------------------------------------------------------------------
	/**
	 * Handle locale change
	 * @note This method will be called every time locale changes.
	 *
	 * @param {object} translation - Newly loaded translation object.
	 * @return {Promise<Object>} - Promise that resolves to a newly loaded translation object.
	 */
	handleLocaleChange(translation) {
		return super.handleLocaleChange(translation)
			.then(translation => {
				this.validate();
				return translation;
			});
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Load select or autosuggest options
	 * @param {string} [query=''] - Set this to a query string if autosuggest is used.
	 * @return {Promise<Object>}
	 */
	loadOptions(query = '') {
		const {loadOptionsAction} = this.props;
		const displayType = this.getValue('column.dynamicValue.displayType');
		const dynamicValueId = this.getValue('column.dynamicValue.id');

		if (displayType === REPORT_FILTER_ITEM_DISPLAY_TYPE.SELECT) {
			return this.setState({optionsLoading: true})
				.then(() => this.executeAbortableAction(loadOptionsAction, dynamicValueId))
				.then(response => getArray(response, 'data'))
				.then(options => this.setState({options}))
				.then(() => this.setState({optionsLoading: false}))
		} else if (displayType === REPORT_FILTER_ITEM_DISPLAY_TYPE.ASYNC_SELECT) {
			if (query.length > 1) {
				const alreadySelected = getString(this.getValue('value')).split(',');
				return this.executeAbortableAction(loadOptionsAction, dynamicValueId, query, alreadySelected)
					.then(response => getArray(response, 'data'));
			} else {
				return Promise.resolve([]);
			}
		}
	}
	
	/**
	 * Get data to load into local component's state
	 * @description Create and return data that can be loaded directly into local component's state based on the raw
	 * external data (usually sent through props). In some sense this is a method that maps external data into format
	 * that component can use in its local state. This method should return data in the same format as 'getData' method.
	 * @note This method will not mutate the passed data.
	 *
	 * @param {any} rawData - External data that will be used to create local component's state compatible data.
	 * @return {ReportFilterItemDataObject} Local component's state compatible data or null if data could not be 
	 * loaded.
	 */
	getDataToLoad(rawData) {
		const {index} = this.props;
		/** @type {ReportFilterItemDataObject} */
		let result = super.getDataToLoad(rawData);

		// Clear logical operator for the first item in the list
		if (index === 0) set(result, 'logicalOperator', '');
		
		// Parse 'between' values string by separator into an array
		if (result && result.comparator === REPORT_FILTER_COMPARATOR.BETWEEN) {
			set(
				result, 
				'value', 
				result.value ? result.value.split(REPORT_FILTER_ITEM_BETWEEN_SEPARATOR) : []
			);
			set(
				result, 
				'valueLabel', 
				result.valueLabel ? result.valueLabel.split(REPORT_FILTER_ITEM_BETWEEN_SEPARATOR) : []
			);
		}
		
		// Parse 'in' values IO date strings into app locale date strings
		if (
			result && 
			(result.comparator === REPORT_FILTER_COMPARATOR.IN || result.comparator === REPORT_FILTER_COMPARATOR.NOT_IN)
		) {
			set(result, 'value', result.value.split(REPORT_FILTER_ITEM_IN_SEPARATOR)
				.map(v => {
					let format;
					const value = v.trim();
					const date = getDate(value, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S);
					
					switch (result.column.displayType) {
						case REPORT_FILTER_ITEM_DISPLAY_TYPE.DATE:
							format = getAppLocaleDateFormat(LOCALE_DATE_FORMAT_NAME.SHORT);
							break;
						case REPORT_FILTER_ITEM_DISPLAY_TYPE.DATETIME:
							format = getAppLocaleDatetimeFormat(
								LOCALE_DATE_FORMAT_NAME.SHORT, LOCALE_TIME_FORMAT_NAME.STANDARD
							);
							break;
						case REPORT_FILTER_ITEM_DISPLAY_TYPE.MONTH:
							format = 'MM y';
							break;
						// no default
					}
					
					if (format && date) {
						const resultString = getDateString(date, format);
						return (resultString ? resultString : value);
					} else {
						return value;
					}
				}).join(REPORT_FILTER_ITEM_IN_SEPARATOR)
			);
		}

		return result;
	}

	/**
	 * Get data that will be returned to the parent component
	 * @description Create and return data that will be returned to paren component (usually through onChange event)
	 * based on the local component's raw state data
	 * @note This method will not mutate the passed data.
	 *
	 * @return {ReportFilterItemDataObject} Data that will be returned to the parent component.
	 */
	getDataToReturn() {
		const {index} = this.props;
		let result = super.getDataToReturn();

		// Clear logical operator for the first item in the list
		if (index === 0) set(result, 'logicalOperator', '');

		// Concat 'between' values array into a string using the separator
		if (result && result.comparator === REPORT_FILTER_COMPARATOR.BETWEEN) {
			const values = getArray(result, 'value');
			const valuesString = values.join(REPORT_FILTER_ITEM_BETWEEN_SEPARATOR);
			const valueLabels = getArray(result, 'valueLabel');
			const valueLabelsString = valueLabels.join(REPORT_FILTER_ITEM_BETWEEN_SEPARATOR);
			
			set(
				result, 
				'value', 
				(valuesString !== REPORT_FILTER_ITEM_BETWEEN_SEPARATOR ? valuesString : '')
			);
			set(
				result, 
				'valueLabel', 
				(valueLabelsString !== REPORT_FILTER_ITEM_BETWEEN_SEPARATOR ? valueLabelsString : '')
			);
		}

		// Parse 'in' values app locale date strings into IO date strings
		if (
			result && 
			(result.comparator === REPORT_FILTER_COMPARATOR.IN || result.comparator === REPORT_FILTER_COMPARATOR.NOT_IN)
		) {
			set(result, 'value', result.value.split(REPORT_FILTER_ITEM_IN_SEPARATOR)
				.map(v => {
					let date;
					const value = v.trim();
					
					switch (result.column.displayType) {
						case REPORT_FILTER_ITEM_DISPLAY_TYPE.DATE:
							date = getDate(value, getAppLocaleDateFormat(LOCALE_DATE_FORMAT_NAME.SHORT));
							break;
						case REPORT_FILTER_ITEM_DISPLAY_TYPE.DATETIME:
							date = getDate(value, getAppLocaleDatetimeFormat(
								LOCALE_DATE_FORMAT_NAME.SHORT, LOCALE_TIME_FORMAT_NAME.STANDARD
							));
							break;
						case REPORT_FILTER_ITEM_DISPLAY_TYPE.MONTH:
							date = getDate(value, 'MM y');
							break;
						// no default
					}

					if (date) {
						const resultString = getDateString(date, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S);
						return (resultString ? resultString : value);
					} else {
						return value;
					}
				}).join(REPORT_FILTER_ITEM_IN_SEPARATOR)
			);
		}
		
		return result;
	}

	/**
	 * Get component's main data from local state
	 * @return {ReportFilterItemDataObject} Cloned component's main data from local state.
	 */
	getData() { return super.getData(); }

	/**
	 * Trigger component's onChange event with component's main data as param
	 * @param {Event} [event] - Optional JavaScript event that triggered the update.
	 * @return {any|null} Any return value from 'onChange' event function or null.
	 */
	update(event) { return executeComponentCallback(this.props.onChange, this.getDataToReturn()); }

	/**
	 * Get one of the between values
	 * @param {number} index - Section index to get.
	 */
	getBetweenValue(index) { return get(getArray(this.getValue('value')), index); }


	// Validation and error methods -------------------------------------------------------------------------------------
	/**
	 * Default component's data validation method
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		let result = true;
		
		/** @type {ReportFilterItemDisplayType} */
		const displayType = getString(
			this.getValue('column'),
			'dynamicValue.displayType',
			REPORT_FILTER_ITEM_DISPLAY_TYPE.TEXT,
			true
		);
		
		// Validate value for 'IN' comparator
		if (
			this.getValue('comparator') === REPORT_FILTER_COMPARATOR.IN || 
			this.getValue('comparator') === REPORT_FILTER_COMPARATOR.NOT_IN
		) {
			const rawValue = this.getValue('value');
			const values = rawValue.split(REPORT_FILTER_ITEM_IN_SEPARATOR).map(v => ({value: v.trim()}));
			const dataValidation = new DataValueValidation();
			const validationRules = getArray(this.inValueValidationRules, displayType);
			const validationConstraints = getArray(this.inValueValidationConditions, displayType);

			// Add custom validation error translations
			const customTranslations = ['integer', 'number', 'date', 'datetime'];
			customTranslations.forEach(t => {
				let translationVars = {};
				switch (t) {
					case 'date':
						translationVars = {
							now: 
								getDateString(new Date(), getAppLocaleDateFormat(LOCALE_DATE_FORMAT_NAME.SHORT)) + 
								", ##TODAY-5DAYS##"
						};
						break;
					case 'datetime':
						translationVars = {
							now: getDateString(
								new Date(),
								getAppLocaleDatetimeFormat(LOCALE_DATE_FORMAT_NAME.SHORT, LOCALE_TIME_FORMAT_NAME.STANDARD)
							) + ", ##TODAY-5DAYS##"
						};
						break;
					// no default
				}
				dataValidation.addTranslation(
					t, this.t(t, 'ReportBuilderFilterComponent.FilterItem.in_validation', '', translationVars)
				);
			});
			
			// Add validation rules and constraints
			if (validationRules.length) dataValidation.addRule('value', ...validationRules);
			if (validationConstraints.length) dataValidation.addConstraint('value', ...validationConstraints);

			// Validate
			const validationErrors = dataValidation.runOnArray(values);
			if (validationErrors) this.setValidationErrors('value', map(Object.values(validationErrors), 'value'));
			else this.setValidationErrors('value', []);
			
			result = !validationErrors;
		}
		
		executeComponentCallback(this.props.onValidate, result);
		return result;
	}

	
	// Data change handling methods -------------------------------------------------------------------------------------
	/**
	 * Handle comparator change
	 * @param {string} newComparator - Newly selected comparator.
	 * @return {Promise<Object>}
	 */
	handleComparatorChange(newComparator) {
		const currentComparator = this.getValue('comparator');
		let dataUpdater = {comparator: newComparator};

		if (newComparator !== currentComparator) {
			// Clear validation if comparator changes
			this.setValidationErrors('value', []);

			// Clear value if comparator changed to in 'IN' base one from non 'IN' based one
			if (
				(newComparator === REPORT_FILTER_COMPARATOR.IN && currentComparator !== REPORT_FILTER_COMPARATOR.NOT_IN) ||
				(newComparator === REPORT_FILTER_COMPARATOR.NOT_IN && currentComparator !== REPORT_FILTER_COMPARATOR.IN)
			) {
				set(dataUpdater, 'value', '');
				set(dataUpdater, 'valueLabel', '');
			}
			// Clear value if comparator changed to 'BETWEEN'
			else if (newComparator === REPORT_FILTER_COMPARATOR.BETWEEN) {
				set(dataUpdater, 'value', []);
				set(dataUpdater, 'valueLabel', []);
			}
			// Clear value if comparator changed from 'IN' based one or 'BETWEEN' into something other than 'IN' base one 
			// or 'BETWEEN'
			else if (
				(currentComparator === REPORT_FILTER_COMPARATOR.IN && newComparator !== REPORT_FILTER_COMPARATOR.NOT_IN) || 
				currentComparator === REPORT_FILTER_COMPARATOR.BETWEEN
			) {
				set(dataUpdater, 'value', '');
				set(dataUpdater, 'valueLabel', '');
			}
		}
		
		// Update state data
		return this.setSubState('data', dataUpdater)
			.then(() => this.update());
	}

	/**
	 * Handle change of the between value specified by the index
	 * @param {number} index - Section index to handle. Starts with 0.
	 * @param {any} value - Section value to set.
	 */
	handleBetweenValueChange(index, value) {
		let result = getArray(this.getValue('value'));
		if (!Array.isArray(result)) result = [];
		set(result, index, value);
		return this.setValue('value', result).then(() => this.update());
	}
	

	// Dialog methods ---------------------------------------------------------------------------------------------------
	/**
	 * Open builder item edit dialog
	 */
	openFormulaDialog() {
		const dialogGUIID = openDialog('', FormulaDialog, {
			data: this.getValue('column'),
			onSave: data => {
				this.setValue('column', data)
					.then(() => this.update())
					.then(() => closeDialog(dialogGUIID));
			},
			onClose: () => closeDialog(dialogGUIID),
		}, {
			id: 'SelectColumnFormulaDialog',
			className: 'bordered-title',
			closeOnEscape: false,
			closeOnClickOutside: false,
			hideCloseBtn: false,
			maxWidth: 1200
		});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that should return true if component can be rendered or false otherwise
	 * @return {boolean} True if component can be rendered or false otherwise.
	 */
	canRender() {
		const data = this.getData();
		return (isset(data) && data !== null);
	}

	/**
	 * Get import from file toolbar used on 'in' values
	 * @param {ReportFilterItemDisplayType} [displayType='TEXT'] - Display type for witch toolbar is rendered.
	 * @return {*[]}
	 */
	getImportFromFileToolbar(displayType = REPORT_FILTER_ITEM_DISPLAY_TYPE.TEXT) {
		const {readLinesFromFileAction} = this.props;
		const {inImportLoading} = this.state;

		let inToolbarButtons = [];

		// Add loading button to the toolbar if import is in progress
		if (inImportLoading) {
			inToolbarButtons.push({
				key: 'loading',
				position: 'left',
				icon: icon_font_loading_symbol,
				iconProps: {spin: true},
				displayType: BUTTON_DISPLAY_TYPE.NONE,
			});
		}
		// Add import from CSV button to the toolbar if import is not in progress
		else {
			inToolbarButtons.push({
				key: 'import-from-csv',
				position: 'left',
				buttonOnly: true,
				buttonOnlyProps: {
					icon: 'download',
					iconProps: {symbolPrefix: 'icofont-'},
					displayType: BUTTON_DISPLAY_TYPE.NONE,
					title: this.t('Import from file ...'),
				},
				acceptExtensions: ['csv'],
				maxSize: 52428800, // 50 MB
				disabled: inImportLoading,
				onChange: (event, fileName, additionalData) => {
					// Get select file for upload
					const file = get(event, 'target.files[0]');
					if (file) {
						return this.setState({inImportLoading: true})
							// Upload file and receive parsed data
							.then(() => this.executeAbortableAction(readLinesFromFileAction, file, additionalData, 'textFile'))
							// Import parsed data
							.then(r => {
								// Get parsed data as string (used for SELECT and ASYNC_SELECT)
								const selectedValues = getArray(r, 'data');
								
								// Import values and update component based on display type
								switch (displayType) {
									case REPORT_FILTER_ITEM_DISPLAY_TYPE.SELECT:
										return this.setSubState('data', subState => ({
											...subState,
											value: (selectedValues.length ? selectedValues.join(',') : ''),
										})).then(() => this.update());
										
									case REPORT_FILTER_ITEM_DISPLAY_TYPE.ASYNC_SELECT:
										return this.setSubState('data', subState => ({
											...subState,
											value: (selectedValues.length ? selectedValues.join(',') : ''),
											valueLabel: (selectedValues.length ? selectedValues.join(',') : ''),
										})).then(() => this.update());
										
									default:
										return this.handleValueChangeAndUpdate('value', getString(r, 'data'));
								}
							})
							.then(() => this.setState({inImportLoading: false}))
							.then(() => this.validate())
					}
				},
				component: FileInput
			});
		}

		return inToolbarButtons;
	}

	/**
	 * Render 'between' filter value
	 * @description This is a helper method that renders any type of value component if comparator is 'BETWEEN'.
	 * 
	 * @param {Function} renderComponent - Render function for a particular component.
	 * @return {JSX.Element}
	 */
	renderBetweenValue(renderComponent) {
		return (
			<React.Fragment>
				{renderComponent(null, this.getBetweenValue(0), this.handleBetweenValueChange, 0)}
				{this.renderBetweenSeparator()}
				{renderComponent(null, this.getBetweenValue(1), this.handleBetweenValueChange, 1)}
			</React.Fragment>
		);
	}

	/**
	 * Render 'between' filter values separator
	 * @return {JSX.Element}
	 */
	renderBetweenSeparator() { return (<Icon symbol="arrows-h" className="between-separator" />); }

	/**
	 * Render default value component
	 * @note This method takes current comparator into consideration ('BETWEEN', 'IN', etc.).
	 * @param renderComponent - Base value component to use for rendering.
	 * @param {function} [relativeDateFilter] - Function to filter available relative date values.
	 * @return {JSX.Element|*}
	 */
	renderDefaultValue(renderComponent, relativeDateFilter) {
		const {inImportLoading} = this.state;
		
		switch (this.getValue('comparator')) {
			case REPORT_FILTER_COMPARATOR.BETWEEN: return this.renderBetweenValue(renderComponent);
			case REPORT_FILTER_COMPARATOR.IN:
			case REPORT_FILTER_COMPARATOR.NOT_IN: return (
				<TextInput
					name="value"
					value={inImportLoading ? '' : getString(this.getValue('value'))}
					showClearButton={true}
					showInsertValueButton={true}
					insertValueType={INSERT_VALUE_BUTTON_TYPE.DIALOG}
					insertValueTypeOptions={{
						dialogProps: {
							sections: [
								new InsertValueDialogSectionDataObject(
									'RelativeDateSections',
									InsertValueDialogRelativeDateSection,
									{titleIcon: 'calendar', multi: true, filterValues: relativeDateFilter},
									containsRelativeDataValue
								),
								new InsertValueDialogSectionDataObject(
									'ReportSection',
									InsertValueDialogReportSection,
									{titleIconSymbolPrefix: 'icofont-', titleIcon: 'file-text'},
									containsReportValue
								)
							]
						}
					}}
					inputToolbarButtons={this.getImportFromFileToolbar()}
					readOnly={inImportLoading}
					placeholder={inImportLoading ? this.t('Importing from file ...') : ''}
					onChange={e => this.handleInputChangeAndUpdate(e).then(this.validate)}
					onEnterKey={() => this.update()}
				/>
			);
			default: return renderComponent(
				null, this.getValue('value'), this.handleValueChangeAndUpdate, 'value'
			);
		}
	}

	/**
	 * Render filter value with display type 'TEXT'
	 * @return {JSX.Element|*}
	 */
	renderValue_TEXT() {
		return this.renderDefaultValue((props, value, handleChange, ...handleChangeArgs) => (
			<TextInput
				name="value"
				value={getString(value)}
				showClearButton={true}
				showInsertValueButton={true}
				insertValueType={INSERT_VALUE_BUTTON_TYPE.DIALOG}
				insertValueTypeOptions={{
					dialogProps: {
						sections: [
							new InsertValueDialogSectionDataObject(
								'RelativeDateSections',
								InsertValueDialogRelativeDateSection,
								{titleIcon: 'calendar'},
								containsRelativeDataValue
							)
						]
					}
				}}
				onChange={e => handleChange(...handleChangeArgs, e.target.value)}
				onEnterKey={() => this.update()}
				{...props}
			/>
		));
	}
	
	/**
	 * Render filter value with display type 'INTEGER'
	 * @return {JSX.Element|*}
	 */
	renderValue_INTEGER() {
		const filterRelativeDates = v => v.isNumeric;
		return this.renderDefaultValue((props, value, handleChange, ...handleChangeArgs) => (
			<NumberInput
				renderFormat='0'
				useAppLocaleCode={NUMBER_INPUT_USE_APP_LOCALE.BOTH}
				name="value"
				value={value}
				showClearButton={true}
				showInsertValueButton={true}
				insertValueType={INSERT_VALUE_BUTTON_TYPE.DIALOG}
				insertValueTypeOptions={{
					dialogProps: {
						sections: [
							new InsertValueDialogSectionDataObject(
								'RelativeDateSections',
								InsertValueDialogRelativeDateSection,
								{titleIcon: 'calendar', filterValues: filterRelativeDates},
								containsRelativeDataValue
							)
						]
					}
				}}
				insertValueMethod="update"
				onChange={v => handleChange(...handleChangeArgs, v)}
				onEnterKey={() => this.update()}
				intOnly={true}
				{...props}
			/>
		), filterRelativeDates);
	}

	/**
	 * Render filter value with display type 'FLOAT'
	 * @return {JSX.Element|*}
	 */
	renderValue_FLOAT() {
		const filterRelativeDates = v => v.isNumeric;
		return this.renderDefaultValue((props, value, handleChange, ...handleChangeArgs) => (
			<NumberInput
				useAppLocaleCode={NUMBER_INPUT_USE_APP_LOCALE.BOTH}
				name="value"
				value={value}
				showClearButton={true}
				showInsertValueButton={true}
				insertValueType={INSERT_VALUE_BUTTON_TYPE.DIALOG}
				insertValueTypeOptions={{
					dialogProps: {
						sections: [
							new InsertValueDialogSectionDataObject(
								'RelativeDateSections',
								InsertValueDialogRelativeDateSection,
								{titleIcon: 'calendar', filterValues: filterRelativeDates},
								containsRelativeDataValue
							)
						]
					}
				}}
				insertValueMethod="update"
				onChange={v => handleChange(...handleChangeArgs, v)}
				onEnterKey={() => this.update()}
				{...props}
			/>
		), filterRelativeDates);
	}

	/**
	 * Render filter value with display type 'DATE'
	 * @return {JSX.Element|*}
	 */
	renderValue_DATE() {
		return this.renderDefaultValue((props, value, handleChange, ...handleChangeArgs) => (
			<DateInput
				value={value}
				valueFormat={STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S}
				showInsertValueButton={true}
				insertValueType={INSERT_VALUE_BUTTON_TYPE.DIALOG}
				insertValueTypeOptions={{
					dialogProps: {
						sections: [
							new InsertValueDialogSectionDataObject(
								'RelativeDateSections',
								InsertValueDialogRelativeDateSection,
								{titleIcon: 'calendar'},
								containsRelativeDataValue
							)
						]
					}
				}}
				insertValueMethod="update"
				onChange={v => handleChange(
					...handleChangeArgs,
					(typeof v === 'string' ? v : getDateString(v, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S))
				)}
				onEnterKey={() => this.update()}
				{...props}
			/>
		));
	}

	/**
	 * Render filter value with display type 'DATETIME'
	 * @return {JSX.Element|*}
	 */
	renderValue_DATETIME() {
		return this.renderDefaultValue((props, value, handleChange, ...handleChangeArgs) => (
			<DatetimeInput
				value={value}
				valueFormat={STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S}
				showInsertValueButton={true}
				insertValueType={INSERT_VALUE_BUTTON_TYPE.DIALOG}
				insertValueTypeOptions={{
					dialogProps: {
						sections: [
							new InsertValueDialogSectionDataObject(
								'RelativeDateSections',
								InsertValueDialogRelativeDateSection,
								{titleIcon: 'calendar'},
								containsRelativeDataValue
							)
						]
					}
				}}
				insertValueMethod="update"
				onChange={v => handleChange(
					...handleChangeArgs,
					(typeof v === 'string' ? v : getDateString(v, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S))
				)}
				onEnterKey={() => this.update()}
				{...props}
			/>
		));
	}

	/**
	 * Render filter value with display type 'MONTH'
	 * @return {JSX.Element|*}
	 */
	renderValue_MONTH() {
		return this.renderDefaultValue((props, value, handleChange, ...handleChangeArgs) => (
			<DateInput
				maxDetail="year"
				renderFormat="MM y"
				value={value}
				valueFormat={STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S}
				showInsertValueButton={true}
				insertValueType={INSERT_VALUE_BUTTON_TYPE.DIALOG}
				insertValueTypeOptions={{
					dialogProps: {
						sections: [
							new InsertValueDialogSectionDataObject(
								'RelativeDateSections',
								InsertValueDialogRelativeDateSection,
								{titleIcon: 'calendar'},
								containsRelativeDataValue
							)
						]
					}
				}}
				insertValueMethod="update"
				onChange={v => handleChange(
					...handleChangeArgs,
					(typeof v === 'string' ? v : getDateString(v, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S))
				)}
				onEnterKey={() => this.update()}
				{...props}
			/>
		));
	}

	/**
	 * Render filter value with display type 'SELECT'
	 * @return {JSX.Element|*}
	 */
	renderValue_SELECT() {
		const {inImportLoading} = this.state;
		
		const renderComponent = (props, value, handleChangeMethod, ...changeMethodArguments) => {
			const {optionsLoading} = this.state;
			const options = getArray(this.state, 'options');
			return (
				<SelectInput
					classNamePrefix="filter-select select-input"
					value={!inImportLoading && value ? value : null}
					options={options}
					isClearable={true}
					isLoading={optionsLoading}
					showInsertValueButton={true}
					insertValueType={INSERT_VALUE_BUTTON_TYPE.DIALOG}
					insertValueTypeOptions={{
						dialogProps: {
							sections: [
								new InsertValueDialogSectionDataObject(
									'RelativeDateSections',
									InsertValueDialogRelativeDateSection,
									{titleIcon: 'calendar'},
									containsRelativeDataValue
								)
							]
						}
					}}
					insertValueMethod="update"
					onChange={v => {
						if (changeMethodArguments.length) handleChangeMethod(...changeMethodArguments, v);
						else handleChangeMethod(v)
					}}
					{...props}
				/>
			);
		};

		switch (this.getValue('comparator')) {
			case REPORT_FILTER_COMPARATOR.BETWEEN: return this.renderBetweenValue(renderComponent);
			case REPORT_FILTER_COMPARATOR.IN:
			case REPORT_FILTER_COMPARATOR.NOT_IN: {
				const multiSeparator = ',';
				return renderComponent(
					{
						isMulti: true,
						closeMenuOnSelect: false,
						insertValueTypeOptions: {
							dialogProps: {
								sections: [
									new InsertValueDialogSectionDataObject(
										'RelativeDateSections',
										InsertValueDialogRelativeDateSection,
										{titleIcon: 'calendar', multi: true, multiAsArray: true},
										containsRelativeDataValue
									)
								]
							}
						},
						insertValueMethod: 'insert',
						toolbarButtons: this.getImportFromFileToolbar(REPORT_FILTER_ITEM_DISPLAY_TYPE.SELECT),
						isDisabled: inImportLoading,
						placeholder: (inImportLoading ? this.t('Importing from file ...') : '')
					},
					this.getValue('value'),
					selectedValue => {
						const selectedValues = getArray(selectedValue);
						return this.setSubState('data', subState => ({
							...subState,
							value: (selectedValues.length ? selectedValues.join(multiSeparator) : ''),
						})).then(() => this.update());
					}
				);
			}
			default: return renderComponent(
				null, this.getValue('value'), this.handleValueChangeAndUpdate, 'value'
			);
		}
	}

	/**
	 * Render filter value with display type 'ASYNC_SELECT'
	 * @return {JSX.Element|*}
	 */
	renderValue_ASYNC_SELECT() {
		const {inImportLoading} = this.state;
		
		const renderComponent = (props, value, handleChangeMethod, ...changeMethodArguments) => (
			<SelectAsyncInput
				classNamePrefix="filter-select select-input"
				value={!inImportLoading && value ? value : null}
				loadOptions={this.loadOptions}
				isClearable={true}
				showInsertValueButton={true}
				insertValueType={INSERT_VALUE_BUTTON_TYPE.DIALOG}
				insertValueTypeOptions={{
					dialogProps: {
						sections: [
							new InsertValueDialogSectionDataObject(
								'RelativeDateSections',
								InsertValueDialogRelativeDateSection,
								{titleIcon: 'calendar'},
								containsRelativeDataValue
							)
						]
					}
				}}
				insertValueMethod="update"
				onChange={v => {
					if (changeMethodArguments.length) handleChangeMethod(...changeMethodArguments, v);
					else handleChangeMethod(v)
				}}
				{...props}
			/>
		);

		switch (this.getValue('comparator')) {
			case REPORT_FILTER_COMPARATOR.BETWEEN:
				/**
				 * Handle change of the between value specified by the index
				 * @param {number} index - Section index to handle. Starts with 0.
				 * @param {any} value - Section value to set.
				 */
				const handleChange = (index, value) => {
					let resultValues = getArray(this.getValue('value'));
					let resultValueLabels = getArray(this.getValue('valueLabel'));

					if (!Array.isArray(resultValues)) resultValues = [];
					if (!Array.isArray(resultValueLabels)) resultValueLabels = [];

					set(resultValues, index, value?.value);
					set(resultValueLabels, index, value?.label);
					
					return this.setSubState('data', subState => ({
						...subState,
						value: resultValues,
						valueLabel: resultValueLabels
					})).then(() => this.update())
				}

				/**
				 * Get one of the between values
				 * @param {number} index - Section index to get.
				 * @return {{label: string, value: string|number}|null}
				 */
				const getBetweenValue = index => {
					const valueAtIndex = get(getArray(this.getValue('value')), index);
					const valueLabelAtIndex = get(getArray(this.getValue('valueLabel')), index);
					return (
						valueAtIndex ? {
							label: valueLabelAtIndex,
							value: valueAtIndex
						} : null
					);
				}
				
				return (
					<React.Fragment>
						{renderComponent(null, getBetweenValue(0), handleChange, 0)}
						{this.renderBetweenSeparator()}
						{renderComponent(null, getBetweenValue(1), handleChange, 1)}
					</React.Fragment>
				);
			case REPORT_FILTER_COMPARATOR.IN:
			case REPORT_FILTER_COMPARATOR.NOT_IN:
				const multiSeparator = ',';
				let values = getString(this.getValue('value'), '', '', true);
				if (values) values = values.split(multiSeparator);
				let valueLabels = getString(this.getValue('valueLabel'), '', '', true);
				if (valueLabels) valueLabels = valueLabels.split(multiSeparator);
				
				return renderComponent(
					{
						isMulti: true,
						closeMenuOnSelect: false,
						insertValueTypeOptions: {
							dialogProps: {
								sections: [
									new InsertValueDialogSectionDataObject(
										'RelativeDateSections',
										InsertValueDialogRelativeDateSection,
										{titleIcon: 'calendar', multi: true, multiAsArray: true, multiSeparator},
										containsRelativeDataValue
									),
									new InsertValueDialogSectionDataObject(
										'ReportSection',
										InsertValueDialogReportSection,
										{titleIconSymbolPrefix: 'icofont-', titleIcon: 'file-text'},
										containsReportValue
									)
								]
							}
						},
						insertValueMethod: 'insert',
						toolbarButtons: this.getImportFromFileToolbar(REPORT_FILTER_ITEM_DISPLAY_TYPE.ASYNC_SELECT),
						isDisabled: inImportLoading,
						placeholder: (inImportLoading ? this.t('Importing from file ...') : '')
					},
					(values.length ? map(values, (v, idx) => ({label: valueLabels[idx], value: v})) : null),
					selectedValue => {
						const selectedValues = getArray(selectedValue);
						return this.setSubState('data', subState => ({
							...subState,
							value: (selectedValues.length ? map(selectedValues, 'value').join(multiSeparator) : ''),
							valueLabel: (selectedValues.length ? map(selectedValues, 'label').join(multiSeparator) : ''),
						})).then(() => this.update());
					}
			);
			default: {
				return renderComponent(
					null,
					this.getValue('value') ? 
						{label: getString(this.getValue('valueLabel')), value: this.getValue('value')} :
						null,
					selectedValue => this.setSubState('data', subState => ({
						...subState,
						value: (selectedValue ? selectedValue.value : ''),
						valueLabel: getString(selectedValue, 'label', '', true)
					})).then(() => this.update())
				);
			}
		}
	}

	/**
	 * Render filter value with display type 'BOOLEAN'
	 * @return {JSX.Element|*}
	 */
	renderValue_BOOLEAN() {
		const renderComponent = (props, value, handleChangeMethod, ...changeMethodArguments) => (
			<SelectInput
				classNamePrefix="filter-select select-input"
				isClearable={true}
				value={value ? value : null}
				options={[
					{label: this.t('Yes', 'general'), value: '1'},
					{label: this.t('No', 'general'), value: '0'}
				]}
				onChange={v => {
					if (changeMethodArguments.length) handleChangeMethod(...changeMethodArguments, v);
					else handleChangeMethod(v)
				}}
				{...props}
			/>
		);

		switch (this.getValue('comparator')) {
			case REPORT_FILTER_COMPARATOR.BETWEEN: return this.renderBetweenValue(renderComponent);
			case REPORT_FILTER_COMPARATOR.IN:
			case REPORT_FILTER_COMPARATOR.NOT_IN:
				const multiSeparator = ',';
				return renderComponent(
					{
						isMulti: true
					}, 
					this.getValue('value'),
					selectedValue => {
						const selectedValues = getArray(selectedValue);
						return this.setSubState('data', subState => ({
							...subState,
							value: (selectedValues.length ? selectedValues.join(multiSeparator) : ''),
						})).then(() => this.update());
					},
			);
			default: return renderComponent(
				null, this.getValue('value'), this.handleValueChangeAndUpdate, 'value'
			);
		}
	}
	
	render() {
		// Do not render component if 'canRender' returns false
		if (!this.canRender()) return null;
		
		const {className, index} = this.props;
		const {GUIID, logicalOperator, column, comparator} = this.getData();
		/** @type {ReportFilterItemDisplayType} */
		const displayType = getString(
			column, 'dynamicValue.displayType', REPORT_FILTER_ITEM_DISPLAY_TYPE.TEXT, true
		);
		
		return (
			<div
				id={this.getDomId()}
				className={`${this.getOption('domPrefix')} ${className} ${index === 0 ? 'first' : ''}`}
			>
				{index > 0 ?
					<div className={`logical-operator-wrapper`}>
						<ToggleInput
							icons={{
								checked: this.t('OR', 'constants'),
								unchecked: this.t('AND', 'constants'),
							}}
							checked={(logicalOperator === LOGICAL_OPERATOR.OR)}
							onChange={e => this.handleValueChangeAndUpdate(
								'logicalOperator', 
								(getBoolean(e, 'target.checked') ? LOGICAL_OPERATOR.OR : LOGICAL_OPERATOR.AND)
							)}
						/>
					</div>
					: null
				}
				
				<div className={`handle-wrapper`}>
					<div>
						<Icon symbol="ellipsis-v" />
						<Icon symbol="ellipsis-v" />
					</div>
				</div>
				
				<div className={`field-wrapper`} onClick={this.openFormulaDialog}>
					<Label
						element="div"
						elementProps={{className: `dynamic-value-group`,}}
						icon="folder"
						content={
							getBoolean(column, 'customHeadings') ?
								getString(column, 'groupHeading') :
								getString(column, 'dynamicValue.group.name')
						}
						supportHtml={getBoolean(column, 'htmlAllowedInHeadings')}
					/>
					<Label
						element="div"
						elementProps={{className: `dynamic-value`}}
						icon={column.aggregateFunction ? 'ruler-alt-2' : 'hashtag'}
						iconSymbolPrefix={column.aggregateFunction ? 'icofont-' : icon_font_symbol_class_prefix}
						content={
							getBoolean(column, 'customHeadings') ?
								getString(column, 'columnHeading') :
								getString(column, 'dynamicValue.name')
						}
						supportHtml={getBoolean(column, 'htmlAllowedInHeadings')}
					/>
				</div>
				
				<FormField 
					className={`comparator-wrapper`} 
					labelPosition={FORM_FIELD_LABEL_POSITION.STACKED} 
					label={this.t('Condition')}
				>
					<SelectInput
						classNamePrefix="filter-select select-input"
						options={REPORT_FILTER_DISPLAY_TYPE_COMPARATORS[displayType].map(
							c => ({label: this.t(c, 'comparators'), value: c}))
						}
						value={comparator ? comparator : null}
						onChange={this.handleComparatorChange}
					/>
				</FormField>
				
				<FormField 
					className={`value-wrapper ${comparator === REPORT_FILTER_COMPARATOR.BETWEEN ? 'form-field-between':''}`} 
					labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
					label={this.t('Criteria')}
					errorMessages={this.getValidationErrors('value')}
					htmlMessages={true}
				>
					{
						getArray(column, 'formula').length > 0 ?
							this.renderValue_TEXT() :
							this[`renderValue_${getObjectKeyByValue(REPORT_FILTER_ITEM_DISPLAY_TYPE, displayType)}`]()
					}
				</FormField>

				<div className={`remove-wrapper`}>
					<Button
						icon="ui-delete"
						iconProps={{symbolPrefix: 'icofont-'}}
						displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
						displayStyle={BUTTON_STYLE.SUBTLE}
						onClick={() => executeComponentCallback(this.props.onDelete, GUIID)}
					/>
				</div>
			</div>
		);
	}
}

// Data objects


/**
 * Define component's own props that can be passed to it by parent components
 */
ReportBuilderFilterItem.propTypes = {
	...DataComponent.propTypes,

	// Component's wrapper element id attribute
	id: PropTypes.string,
	// Component's wrapper element class attribute
	className: PropTypes.string,
	// Main component data
	// @type {ReportFilterItemDataObject}
	data: PropTypes.object,
	// Index of the item in the list
	// @note Starts from 0.
	index: PropTypes.number,
	
	// Actions
	loadOptionsAction: PropTypes.func,
	readLinesFromFileAction: PropTypes.func,

	// Events
	onChange: PropTypes.func, // Arguments: {ReportFilterItemDataObject} item - Condition item object.
	onDelete: PropTypes.func, // Arguments: GUI ID of the item.
	onValidate: PropTypes.func, // Arguments: Boolean validation result.
}

/**
 * Define component default values for own props
 */
ReportBuilderFilterItem.defaultProps = {
	id: '',
	className: '',
};

export default ReportBuilderFilterItem;
